import React, { useState, useEffect } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Edit,
  Search,
  Menu,
  Briefcase,
  MapPin,
  Clock,
  Heart,
  Edit2,
} from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";

import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";

import BorderAllIcon from "@material-ui/icons/BorderAll";
import MenuIcon from "@material-ui/icons/Menu";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core";
import JobListDropDown from "../../components/JobListDropDown/JobListDropDown";
import FindYourJob from "../../components/FindYourJob";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import firebase from "firebase";
import fire from "../../database";
import { Grid } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  formControl: {
    margin: "0 8px",
    minWidth: 120,
    // maxWidth: 300,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: "1.7rem",
    fontWeight: 500,
    color: "#212529",
  },
  dropdownHead: {
    "&.MuiAccordion-root .MuiAccordionSummary-root": {
      "&:hover div": {
        color: "blue",
      },
      "&:hover div > h4": {
        color: "#246df8",
      },
    },
  },
  listingContainer: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
}));



const CandidateListing = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [candidateData2, setCandidateData2] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  useEffect(async () => {

    if (searchVal == "") {
      // alert("fi")
      let tempArr = [];
      firebase
        .database()
        .ref(`profile/candidate`)
        .on("value", (snapshot) => {

          snapshot.forEach((employers) => {
            tempArr.push(employers.val());
            console.log("tempArrrrr=>>>>", tempArr);

          });
          setCandidateData(tempArr);
          setCandidateData2(tempArr)
        });
    }
    else {
      // alert("else")
      let results = candidateData2.filter((item) => {
        return item.fullName.toLowerCase().includes(searchVal.toLowerCase());
        // console.log(item, "ITMMMMMMMMMMMMMMMMMMMMMMMMM");
      });
      console.log(
        results,
        "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO_RESSSSSSSSSSSSs"
      );
      await setCandidateData(results);
    }

  }, [searchVal]);



  // const performSearch = (val) => {
  //   console.log(candidateData, "CCCCCCCCCCCCCCCCCCCCCCCCCCC");
  //   setSearchVal(val);
  //   let results = candidateData.filter((item) => {
  //     return item.fullName.toLowerCase().includes(val.toLowerCase());
  //     // console.log(item, "ITMMMMMMMMMMMMMMMMMMMMMMMMM");
  //   });
  //   console.log(
  //     results,
  //     "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO_RESSSSSSSSSSSSs"
  //   );
  //   setCandidateData(results);
  // };

  // useEffect(() => {

  //   let results = candidateData.filter((item) => {
  //     return item.fullName.toLowerCase().includes(searchVal.toLowerCase());
  //     // console.log(item, "ITMMMMMMMMMMMMMMMMMMMMMMMMM");
  //   });
  //   console.log(
  //     results,
  //     "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO_RESSSSSSSSSSSSs"
  //   );
  //   setCandidateData(results);
  // }, [searchVal])

  console.log("candidateData=>>>", candidateData);

  const classes = useStyles();
  const history = useHistory();
  const [age, setAge] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [changeIcon, setChangeIcon] = useState(false);
  const [changeIconExp, setChangeIconExp] = useState(false);
  const [changeIconRange, setChangeIconRange] = useState(false);
  const [changeIconPosted, setChangeIconPosted] = useState(false);
  const [changeIconGender, setChangeIconGender] = useState(false);
  const [changeIconQualification, setChangeIconQualification] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const navigate = () => {
    history.push("/");
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const navigateToResume = async (uid) => {
    firebase.database().ref(`resumes/${uid}`).on("value", snapshot => {
      if (snapshot.val() == null) {
        setAlertData({
          msg: "Employee Resume Not Created",
          severity: "error",
          open: true
        })
      }
      else {
        history.push({ pathname: "/CandidateResume", state: uid });
      }
    })

  };

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };





  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      {/* <div style={{padding:30}}>
            <NotificationAndAccount  />
            <Header />
        </div>   */}

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      {/* BreadCrumb  */}

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Candidates List</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Candidates List
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text"
                    placeholder="Enter Keywords"
                    value={searchVal}
                    onChange={(e) => setSearchVal(e.target.value)}
                  />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bread Crumb End      */}

      {/* JobListing */}

      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row no-gutters">
            <div class="col">
              <Grid container spacing={1} className={classes.listingContainer}>
                <Grid item xs={12} sm={8} md={12}>
                  <div
                    class="filtered-candidate-wrapper"
                    style={{ height: "100%" }}
                  >
                    <div class="candidate-view-controller-wrapper">
                      <div class="candidate-view-controller">
                        {/* <div class="controller list active">
                          <MenuIcon style={{ fontSize: "26px" }} />
                        </div>
                        <div class="controller grid">
                          <BorderAllIcon style={{ fontSize: "26px" }} />
                        </div> */}
                        {/* <div class="candidate-view-filter">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Filter
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={age}
                              onChange={handleChange}
                              className="select-picker"
                            >
                           
                              <MenuItem value={10}>Most Recent</MenuItem>
                              <MenuItem value={20}>Top Rated</MenuItem>
                              <MenuItem value={30}>Most Popular</MenuItem>
                            </Select>
                          </FormControl>
                        </div> */}
                      </div>
                      {/* <div class="showing-number">
                        <span>Showing 1–12 of 28 Jobs</span>
                      </div> */}
                    </div>
                    <div class="candidate-filter-result">
                      {candidateData.map((data, index) => {
                        return (
                          <div class="candidate">
                            <div class="thumb">
                              <a href="#">
                                <img
                                  src={data.avatar}
                                  class="img-fluid"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="body">
                              <div class="content">
                                <h4>
                                  <a >
                                    {data.fullName}
                                  </a>
                                </h4>
                                <div class="info">
                                  <span class="work-post">
                                    <a >
                                      <i data-feather="check-square"></i>
                                      {data.indestryExp}
                                    </a>
                                  </span>
                                  <span class="location">
                                    {/* <a href="#">
                                      <i data-feather="map-pin"></i>
                                      {data.address}
                                    </a> */}
                                  </span>
                                </div>
                              </div>
                              <div class="button-area">
                                <button
                                  onClick={() => navigateToResume(data.uid)}
                                >
                                  View Resume
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div class="pagination-list text-center">
                    <nav class="navigation pagination">
                      <div class="nav-links">
                        <a class="prev page-numbers" href="#">
                          <i class="fas fa-angle-left"></i>
                        </a>
                        <a class="page-numbers" href="#">
                          1
                        </a>
                        <span aria-current="page" class="page-numbers current">
                          2
                        </span>
                        <a class="page-numbers" href="#">
                          3
                        </a>
                        <a class="page-numbers" href="#">
                          4
                        </a>
                        <a class="next page-numbers" href="#">
                          <i class="fas fa-angle-right"></i>
                        </a>
                      </div>
                    </nav>
                  </div> */}
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm={4} md={3}>
                  <div class="candidate-filter-wrapper">
                    <div class="selected-options same-pad">
                      <div class="selection-title">
                        <h4>You Selected</h4>
                        <a href="#">Clear All</a>
                      </div>
                      <ul class="filtered-options"></ul>
                    </div>
                    <div class="candidate-filter-dropdown same-pad category">
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleChangeCategory}
                        >
                          <MenuItem value={1}>Category</MenuItem>
                          <MenuItem value={2}>Accounting / Finance</MenuItem>
                          <MenuItem value={3}>Education</MenuItem>
                          <MenuItem value={4}>Design Creative</MenuItem>
                          <MenuItem value={5}>Health Care</MenuItem>
                          <MenuItem value={6}>Engineer & Architects</MenuItem>
                          <MenuItem value={7}>Marketing & Sales</MenuItem>
                          <MenuItem value={8}>Garments / Textile</MenuItem>
                          <MenuItem value={9}>Customer Support</MenuItem>
                          <MenuItem value={10}>Digital Media</MenuItem>
                          <MenuItem value={11}>Telecommunication</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div class="candidate-filter-dropdown same-pad location">
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Location
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={location}
                          onChange={handleChangeLocation}
                        >
                          <MenuItem value={1}>New York City</MenuItem>
                          <MenuItem value={2}>San Francisco</MenuItem>
                          <MenuItem value={3}>Washington</MenuItem>
                          <MenuItem value={4}>Boton</MenuItem>
                          <MenuItem value={5}>Los Angeles</MenuItem>
                          <MenuItem value={6}>Seattle</MenuItem>
                          <MenuItem value={6}>Las Vegas</MenuItem>
                          <MenuItem value={7}>San Diego</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      data-id="candidate-type"
                      class="candidate-filter same-pad candidate-type"
                    >
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIcon ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          // onChange={handleChangeIcon}
                          onClick={() => setChangeIcon(!changeIcon)}
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Job Type
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <ul style={{ padding: 0 }}>
                            <li class="full-time">
                              <i>
                                <Clock />
                              </i>
                              <a href="#" data-attr="Full Time">
                                Full Time
                              </a>
                            </li>
                            <li class="part-time">
                              <i>
                                <Clock />
                              </i>
                              <a href="#" data-attr="Part Time">
                                Part Time
                              </a>
                            </li>
                            <li class="freelance">
                              <i>
                                <Clock />
                              </i>
                              <a href="#" data-attr="Freelance">
                                Freelance
                              </a>
                            </li>
                            <li class="temporary">
                              <i>
                                <Clock />
                              </i>
                              <a href="#" data-attr="Temporary">
                                Temporary
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      data-id="experience"
                      class="candidate-filter same-pad experience"
                    >
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIconExp ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          onClick={() => setChangeIconExp(!changeIconExp)}
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Experience
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <ul style={{ padding: 0 }}>
                            <li>
                              <a href="#" data-attr="Fresh">
                                Fresh
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="Less than 1 year">
                                Less than 1 year
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="2 Year">
                                2 Year
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="3 Year">
                                3 Year
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="4 Year">
                                4 Year
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="5 Year">
                                5 Year
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="Avobe 5 Years">
                                Avobe 5 Years
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div class="candidate-filter same-pad">
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIconRange ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          onClick={() => setChangeIconRange(!changeIconRange)}
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Salary Range
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <div class="price-range-slider">
                            <div
                              class="nstSlider"
                              data-range_min="0"
                              data-range_max="10000"
                              data-cur_min="0"
                              data-cur_max="6130"
                            >
                              <div class="bar"></div>
                              <div class="leftGrip"></div>
                              <div class="rightGrip"></div>
                              <div class="grip-label">
                                <span class="leftLabel"></span>
                                <span class="rightLabel"></span>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      data-id="gender"
                      class="candidate-filter same-pad gender"
                    >
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIconGender ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          onClick={() => setChangeIconGender(!changeIconGender)}
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Gender
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <ul style={{ padding: 0 }}>
                            <li>
                              <a href="#" data-attr="Male">
                                Male
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="Female">
                                Female
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      data-id="qualification"
                      class="candidate-filter same-pad qualification"
                    >
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIconQualification ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          onClick={() =>
                            setChangeIconQualification(!changeIconQualification)
                          }
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Qualification
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <ul style={{ padding: 0 }}>
                            <li>
                              <a href="#" data-attr="Matriculation">
                                Matriculation
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="Intermidiate">
                                Intermidiate
                              </a>
                            </li>
                            <li>
                              <a href="#" data-attr="Gradute">
                                Gradute
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </div>
      </div>

      {/* JobListingEnd */}

      <FindYourJob />

      {/* footer */}
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CandidateListing;
