import React, { useEffect, useState } from "react";
import {
  EmployerDashboardSidebar,
  Footer,
  Header,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import {
  CheckSquare,
  Search,
  MapPin,
  CheckCircle,
  Download,
  Mail,
  Trash2,
} from "react-feather";
import FindYourJob from "../../components/FindYourJob";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase";
import fire from "../../database";
import { jsPDF } from "jspdf"

const ManageCandidate = (props) => {

  console.log('porops', props)
  const history = useHistory();
  const [userDetails, setUserDetails] = useState({});
  const [aboutUser, setAboutUser] = useState({});

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setAboutUser(userData);

    firebase
      .database()
      .ref(`profile/employer/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        console.log("snapshot=>>>", snapshot.val());
        setUserDetails(snapshot.val());
      });
  }, []);

  // const handleMessage = () => { }
  console.log(props.location, "PROPS_LOCATION=>>>>");
  const { state } = props.location;

  const downloadCV = (data) => {
    console.log('data', data)
    if(data.cvURL){
      window.open(data.cvURL, '_blank')
    }
    else{
      pdfGenerator(data)
    }
  }

  const pdfGenerator = (resume) => {
    var doc = new jsPDF('p', 'pt');
   
    // var imgWidth = 297;
    // var pageHeight = 210;
    // var imgHeight = canvas.height * imgWidth / canvas.width;

    doc.setFontSize(30);
    doc.text(resume.fullname, 80, 10);
    doc.setFontSize(10)
    doc.text(`Category: ${resume.category}`, 10, 20)
    doc.text(`Gender: ${resume.gender}`, 10, 30)
    doc.text(`Location: ${resume.location}`, 10, 40)
    doc.text(`Type: ${resume.type}`, 10, 50)
    doc.text(`Experience: ${resume.exp}`, 10, 60)
    doc.text(`Salary Range: ${resume.salaryRange}`, 10, 70)
    doc.text(`Qualification: ${resume.qualification}`, 10, 80)
    doc.text(`Special Qualification: ${resume.specialQualification}`, 10, 90)
    doc.text(`Skills: ${resume.skills}`, 10, 100)
    doc.text(`About: ${resume.aboutYou}`, 10, 110)
    doc.text(`Nationality: ${resume.nationality}`, 10, 120)
    doc.text(`Address: ${resume.address}`, 10, 130)
    doc.text(`Cover Letter: ${resume.coverLetter}`, 10, 140)
    doc.text(`DOB: ${resume.dateOfBirth}`, 10, 150)
    console.log('doc', doc.output())
    doc.text('Educations', 10, 160)
    let y = 170;
    resume.educationList.map((data, index) => {
      if (data.Designation) {
        console.log('daata', data)
        doc.text(`Education ${index + 1}`, 10, y)
        doc.text(`Designation ${data.Designation}`, 10, y + 10)
        doc.text(`Institute ${data.Institute}`, 10, y + 20)
        doc.text(`Period ${data.Period}`, 10, y + 30)
        doc.text(`Description ${data.Institute}`, 10, y + 40)
        y = y + 50;
      }

    })
    resume.workExpList.map((data, index) => {
      if (data.Designation) {
        console.log('daata', data)
        doc.text(`Work Experience ${index + 1}`, 20, y)
        doc.text(`Designation ${data.Designation}`, 20, y + 20)
        doc.text(`Company Name ${data.companyName}`, 20, y + 20)
        doc.text(`Period ${data.Period}`, 20, y + 30)
        doc.text(`Description ${data.Institute}`, 20, y + 40)
        y = y + 50;
      }
    })
    doc.save(`${resume.fullname}.pdf`);
  }

  const handleChat = (data) => {
    console.log(userDetails, data, "USRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
    // alert(id)
    // alert(JSON.stringify(userDetails))
    firebase
      .database()
      .ref(
        `communication/${firebase.auth()?.currentUser?.uid}/messageUser/${
          data.id
        }`
      )
      .set({
        name: data.userResume.fullname
          ? data.userResume.fullname
          : data.userResume.name,
        skills: data.userResume.skills ? data.userResume.skills : "",
        id: data.id,
        jobname: state.jobname,
      });
    console.log();
    firebase
      .database()
      .ref(
        `communication/${data.id}/messageCandidate/${
          firebase.auth()?.currentUser?.uid
        }`
      )
      .set({
        name: aboutUser.name,
        id: aboutUser.uuid,
      });
    history.push("/DashboardMessage");
  };

  console.log(userDetails, aboutUser, "USER_DETAILSSSSSSSS_ABOUTTTTTTTTT");
  return (
    <>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Employer Dashboard</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Employer Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="dashboard-container">
                <div class="dashboard-content-wrapper">
                  <div class="manage-candidate-container">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          {/* <th>Status</th> */}
                          <th class="action">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state?.applicant?.map((data, index) => {
                          console.log("DATasssss=>>>>", data);
                          return (
                            <tr class="candidates-list">
                              <td class="title">
                                <div class="thumb">
                                  <img
                                    src="dashboard/images/user-1.jpg"
                                    class="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div class="body">
                                  <h5>
                                    <a>
                                      {data?.userResume?.fullname
                                        ? data?.userResume?.fullname
                                        : data?.userResume?.name}
                                    </a>
                                  </h5>
                                  <div class="info">
                                    <span class="designation">
                                      <a>
                                        <i>
                                          <CheckSquare />
                                        </i>
                                        {data?.userResume?.qualification}
                                      </a>
                                    </span>
                                    <span class="location">
                                      <a>
                                        <i>
                                          <MapPin />
                                        </i>
                                        Pakistan
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {/* <td class="status">
                                <i>
                                  <CheckCircle />
                                </i>
                            Shortlisted
                          </td> */}
                              <td class="action">
                                <a onClick={()=> downloadCV(data.userResume)} class="download">
                                  <i>
                                    <Download />
                                  </i>
                                </a>
                                {/* <Link
                                  to={{
                                    pathname: "/CandidateMessage", state: {
                                      data
                                    }
                                  }} class="inbox"
                                > */}
                                <i
                                  onClick={() => handleChat(data)}
                                  style={{
                                    padding: "0 10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Mail />
                                </i>
                                {/* </Link> */}
                                <a class="remove">
                                  <i>
                                    <Trash2 />
                                  </i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* <div class="pagination-list text-center">
                      <nav class="navigation pagination">
                        <div class="nav-links">
                          <a class="prev page-numbers" href="#">
                            <i class="fas fa-angle-left"></i>
                          </a>
                          <a class="page-numbers" href="#">
                            1
                          </a>
                          <span
                            aria-current="page"
                            class="page-numbers current"
                          >
                            2
                          </span>
                          <a class="page-numbers" href="#">
                            3
                          </a>
                          <a class="page-numbers" href="#">
                            4
                          </a>
                          <a class="next page-numbers" href="#">
                            <i class="fas fa-angle-right"></i>
                          </a>
                        </div>
                      </nav>
                    </div> */}
                  </div>
                </div>
                <div class="dashboard-sidebar">
                  <div class="company-info">
                    <div class="thumb">
                      <img src={userDetails?.avatar} class="img-fluid" alt="" />
                    </div>
                    <div class="company-body">
                      <h5>{aboutUser?.name}</h5>
                      <span>@username</span>
                    </div>
                  </div>

                  <div class="dashboard-menu">
                    {/* <ul>
                      <li>
                        <i class="fas fa-home"></i>
                        <a href="employer-dashboard.html">Dashboard</a>
                      </li>
                      <li>
                        <i class="fas fa-user"></i>
                        <a href="employer-dashboard-edit-profile.html">
                          Edit Profile
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-briefcase"></i>
                        <a href="employer-dashboard-manage-job.html">
                          Manage Jobs
                        </a>
                      </li>
                      <li class="active">
                        <i class="fas fa-users"></i>
                        <a href="employer-dashboard-manage-candidate.html">
                          Manage Candidates
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-heart"></i>
                        <a href="#">Shortlisted Resumes</a>
                      </li>
                      <li>
                        <i class="fas fa-plus-square"></i>
                        <a href="employer-dashboard-post-job.html">
                          Post New Job
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-comment"></i>
                        <a href="employer-dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i class="fas fa-calculator"></i>
                        <a href="employer-dashboard-pricing.html">
                          Pricing Plans
                        </a>
                      </li>
                    </ul> */}
                    <EmployerDashboardSidebar dashboardLink="manageCandidate" />
                    <ul class="delete">
                      <li>
                        <i class="fas fa-power-off"></i>
                        <a href="#">Logout</a>
                      </li>
                      {/* <li>
                        <i class="fas fa-trash-alt"></i>
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#modal-delete"
                        >
                          Delete Profile
                        </a>
                      </li> */}
                    </ul>
                    {/* <!-- Modal --> */}
                    <div
                      class="modal fade modal-delete"
                      id="modal-delete"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body">
                            <h4>
                              <i>
                                <Trash2 />
                              </i>
                              Delete Account
                            </h4>
                            <p>
                              Are you sure! You want to delete your profile.
                              This can't be undone!
                            </p>
                            <form action="#">
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder="Enter password"
                                />
                              </div>
                              <div class="buttons">
                                <button class="delete-button">
                                  Save Update
                                </button>
                                <button class="">Cancel</button>
                              </div>
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                />
                                <label class="form-check-label">
                                  You accepts our{" "}
                                  <a href="#">Terms and Conditions</a> and{" "}
                                  <a href="#">Privacy Policy</a>
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};
export default ManageCandidate;
