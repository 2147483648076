import React from "react";

const Candidate = () => {
  return (
    <div class="tab-content faq-tab-content" id="myTabContent">
      <div
        class="tab-pane fade active show"
        id="feature"
        role="tabpanel"
        aria-labelledby="feature-tab"
      >
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Lorem ipsum dolor sit amet, Candidate?
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="latest"
        role="tabpanel"
        aria-labelledby="latest-tab"
      >
        <div class="accordion" id="accordionExample2">
          <div class="card">
            <div class="card-header" id="headingSix">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseSix"
              class="collapse show"
              aria-labelledby="headingSix"
              data-parent="#accordionExample2"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSeven">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseSeven"
              class="collapse"
              aria-labelledby="headingSeven"
              data-parent="#accordionExample2"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingEight">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseEight"
              class="collapse"
              aria-labelledby="headingEight"
              data-parent="#accordionExample2"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingNine">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseNine"
              class="collapse"
              aria-labelledby="headingNine"
              data-parent="#accordionExample2"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTen">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseTen"
              class="collapse"
              aria-labelledby="headingTen"
              data-parent="#accordionExample2"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="contact"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <div class="accordion" id="accordionExample3">
          <div class="card">
            <div class="card-header" id="headingEleven">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseEleven"
                  aria-expanded="true"
                  aria-controls="collapseEleven"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseEleven"
              class="collapse show"
              aria-labelledby="headingEleven"
              data-parent="#accordionExample3"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTweleve">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTweleve"
                  aria-expanded="false"
                  aria-controls="collapseTweleve"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseTweleve"
              class="collapse"
              aria-labelledby="headingTweleve"
              data-parent="#accordionExample3"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Did you like this answer ? <a href="#">Yes</a> or{" "}
                  <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThirteen">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThirteen"
                  aria-expanded="false"
                  aria-controls="collapseThirteen"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseThirteen"
              class="collapse"
              aria-labelledby="headingThirteen"
              data-parent="#accordionExample3"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur? <a href="#">Yes</a>{" "}
                  or <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFourteen">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFourteen"
                  aria-expanded="false"
                  aria-controls="collapseFourteen"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseFourteen"
              class="collapse"
              aria-labelledby="headingFourteen"
              data-parent="#accordionExample3"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Did you like this answer ? <a href="#">Yes</a> or{" "}
                  <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFifteen">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFifteen"
                  aria-expanded="false"
                  aria-controls="collapseFifteen"
                >
                  Lorem ipsum dolor sit amet, consectetur?
                </button>
              </h5>
            </div>
            <div
              id="collapseFifteen"
              class="collapse"
              aria-labelledby="headingFifteen"
              data-parent="#accordionExample3"
            >
              <div class="card-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Mauris pharetra et ultrices neque ornare aenean. Libero nunc
                  consequat interdum varius sit.
                </p>
                <p>
                  Did you like this answer ? <a href="#">Yes</a> or{" "}
                  <a href="#">No</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Candidate;
