import React, { useState, useEffect } from "react";
import EditorTable from "./../../components/Editor/Editor";
import { Button, TextField, Container } from "@material-ui/core";
import firebase from "firebase";
import {
  Footer,
  Header,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Blog = () => {
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const history = useHistory();
  const [user, setUser] = useState(null);
  const [blog, setBlog] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    console.log(userData?.uid, "ASDASDASDASD");
    firebase
      .database()
      .ref(`users/${userData?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setUser(data);
        sessionStorage.setItem("usertype", JSON.stringify(data));
      });
  }, []);

  const getAboutContent = (content, type) => {
    setBlog(content);
  };

  const saveButton = () => {
    firebase
      .database()
      .ref(`blogs/${firebase.auth().currentUser.uid}`)
      .push({
        title,
        blog,
        commentCount: 0,
        name: user.name,
        date: new Date().toLocaleString(),
        commentsArray: [],
      })
      .then((res) => {
        setAlertData({
          open: true,
          severity: "success",
          msg: "Blog Created Successfully",
        });
        setTimeout(() => {
          history.push("/");
        }, 2000);
      });
  };

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  console.log(user, "USDASDASDOMASDOASMDOASMDAODMQWOMQOWEM");
  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header userData={user} />
            </div>
          </div>
        </div>
      </header>
      <div>
        <Container>
          <div style={{ width: "90%", margin: "0 auto", marginTop: "20px" }}>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              fullWidth
              style={{ padding: "20px 0" }}
              placeholder="Title Here"
            />
            <EditorTable getAboutContent={getAboutContent} type="blog" />
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <Button
                variant="contained"
                style={{ background: "#fff" }}
                onClick={saveButton}
              >
                Upload Blog
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Blog;
