import React, { useState, useEffect } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { Edit } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase";
import fire from "../../database";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Register = () => {
  var userNameRegex = new RegExp("[a-zA-Z0-9\b]{6}$");
  const history = useHistory();
  console.log(history, "historyhistoryhistory");
  const cureentDate = new Date();
  const [username, setUsername] = useState("");
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const handleFacebookSignIn = () => {
    if (type == "") {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Select Type",
      });
      return;
    }
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // The signed-in user info.
        var user = result.user;
        let uid = firebase.auth().currentUser.uid;
        var user = result.user;
        firebase
          .database()
          .ref(`/users/${firebase.auth().currentUser.uid}`)
          .set({
            name: user.displayName,
            email: user.email,
            uuid: uid,
            type: type,
            date: cureentDate,
          })
          .then((res) => {
            setAlertData({
              open: true,
              severity: "success",
              msg: "successfully Signed In",
            });
            sessionStorage.setItem("user", JSON.stringify(user));
            // history.push("/")
            window.location.replace("/");
          });
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorMessage = error.message;
        alert(errorMessage);
      });
  };

  const handleGoogleSignIn = () => {
    if (type == "") {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Select Type",
      });
      return;
    }
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log("result", result);
        let uid = firebase.auth().currentUser.uid;
        var user = result.user;
        firebase
          .database()
          .ref(`/users/${firebase.auth().currentUser.uid}`)
          .set({
            name: user.displayName,
            email: user.email,
            uuid: uid,
            type: type,
            date: cureentDate,
          })
          .then((res) => {
            setAlertData({
              open: true,
              severity: "success",
              msg: "successfully Signed In",
            });
            sessionStorage.setItem("user", JSON.stringify(user));
            // history.push("/")
            window.location.replace("/");
          });
      })
      .catch((error) => {
        // Handle Errors here.
        console.log("err", error);
      });
  };

  const registerUser = () => {
    if (type == "") {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Select Type",
      });
      return;
    }
    else if (username.length < 6) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Username length must be 6 characters long",
      });
      return;
    }
    else if (checked == false) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Agree To Terms And Conditions",
      });
      return;
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
          let uid = firebase.auth().currentUser.uid;
          firebase
            .database()
            .ref(`/users/${firebase.auth().currentUser.uid}`)
            .set({
              name: username,
              email: email,
              uuid: uid,
              type: type,
              date: cureentDate,
              package:"Starter"
            })
            .then((res) => {
              setAlertData({
                open: true,
                severity: "success",
                msg: "successfully Signed In",
              });
              sessionStorage.setItem("user", JSON.stringify(response.user));
              // history.push("/")
              setTimeout(() => {
                history.push("/Checkout");
              }, 2000)
            });
        })
        .catch((err) => {
          setAlertData({
            open: true,
            severity: "error",
            msg: err.message,
          });
        });
    }
  };

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };
  // alert(type)

  const typeChange = (type) => {
    // setPassword("")
    // setUsername("")
    // setEmail("")
    setType(type);
  };

  console.log(checked, "CHECKKKKKKKKKKKKKKKKKKK");

  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <header class="header-2 access-page-nav">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="header-top">
                <div class="logo-area">
                  <a href="index.html">
                    <img
                      src={logoMain}
                      className="img-fluid"
                      style={{ height: "68px" }}
                      alt=""
                    />
                  </a>
                </div>
                <div class="top-nav">
                  <Link
                    to="/login"
                    class="account-page-link"
                    style={{ background: "#023b92" }}
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="padding-top-90 padding-bottom-90 access-page-bg">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-md-6">
              <div class="access-form">
                <div class="form-header">
                  <h5>
                    <Edit />
                    Register Account
                  </h5>
                </div>
                <div class="account-type">
                  <label for="idRegisterCan">
                    <input
                      id="idRegisterCan"
                      type="radio"
                      name="register"
                      onChange={() => typeChange("Candidate")}
                    />
                    <span>Candidate</span>
                  </label>
                  <label for="idRegisterEmp">
                    <input
                      id="idRegisterEmp"
                      type="radio"
                      name="register"
                      onChange={() => typeChange("Employer")}
                    />
                    <span>Employer</span>
                  </label>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Username"
                    class="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    placeholder="Email Address"
                    class="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    placeholder="Password"
                    class="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div class="more-option terms">
                  <div class="mt-0 terms">
                    <input
                      class="custom-radio"
                      type="checkbox"
                      id="radio-4"
                      name="termsandcondition"
                      onChange={() => setChecked(!checked)}
                    />
                    <label for="radio-4">
                      <span class="dot"></span> I accept the{" "}
                      <Link to="/TermsAndConditions">terms & conditions</Link>
                    </label>
                  </div>
                </div>
                <button
                  onClick={registerUser}
                  class="button primary-bg btn-block"
                  style={{ background: "#023b92" }}
                >
                  Register
                </button>
                <div class="shortcut-login">
                  <span>Or connect with</span>
                  <div class="buttons">
                    <Link onClick={handleFacebookSignIn} class="facebook">
                      <i class="fab fa-facebook-f"></i>Facebook
                    </Link>
                    <Link onClick={handleGoogleSignIn} class="google">
                      <i class="fab fa-google"></i>Google
                    </Link>
                  </div>
                  <p>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
