import React from "react";
import { Link } from "react-router-dom"

const NewsLetterFooter = () => {
  return (
    <div className="footer-widget-wrapper padding-bottom-60 padding-top-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-sm-8">
            <div className="footer-widget footer-shortcut-link">
              <h4>Information</h4>
              <div className="widget-inner">
                <ul>
                  <li>
                    <Link to="/AboutUs">About Us</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">Contact Us</Link>
                  </li>
                  {/* <li>
                    <a href="terms-and-condition.html">Privacy Policy</a>
                  </li> */}
                  <li>
                    <Link to="/TermsAndConditions">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="footer-widget footer-shortcut-link">
              <h4>Job Seekers</h4>
              <div className="widget-inner">
                <ul>
                  <li>
                    <Link to="/login">Create Account</Link>
                  </li>
                  {/* <li>
                    <Link to="javascript:void(0);">Career Counseling</Link>
                  </li>
                  <li>
                    <Link to="javascript:void(0);">My Oficiona</Link>
                  </li> */}
                  <li>
                    <Link to="/FAQ">FAQ</Link>
                  </li>
                  {/* <li>
                    <Link to="javascript:void(0);">Video Guides</Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="footer-widget footer-shortcut-link">
              <h4>Employers</h4>
              <div className="widget-inner">
                <ul>
                  <li>
                    <Link to="/login"> Create Account</Link>
                  </li>
                  {/* <li>
                    <Link to="/">Products/Service</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/">Post a Job</Link>
                  </li> */}
                  <li>
                    <Link to="/FAQ">FAQ</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-5 offset-lg-1 col-sm-6">
            <div className="footer-widget footer-newsletter">
              <h4>Newsletter</h4>
              <p>
                Quis lectus nulla at volutpat diam ut. Congue quisque egestas
                diam in arcu.
              </p>
              <form action="#" className="newsletter-form form-inline">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email address..."
                  />
                </div>
                <button className="btn button primary-bg">
                  Submit<i className="fas fa-caret-right"></i>
                </button>
                <p className="newsletter-error">0 - Please enter a value</p>
                <p className="newsletter-success">Thank you for subscribing!</p>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </div >
  );
};

export default NewsLetterFooter;
