import "./App.css";
import {
  CandidateListing,
  Home,
  HowItWorks,
  JobDetails,
  JobListing,
  Login,
  Register,
  AddResume,
  CandidateDetails,
  FAQ,
  ContactUS,
  TermsAndConditions,
  Checkout,
  Payment,
  EmployerListing,
  EmployerDetails,
  PostJob,
  CandidateDashboard,
  CandidateEditProfile,
  CandidateResume,
  CandidateEditResume,
  CandidateBookmark,
  CandidateApplied,
  CandidateMessage,
  EmployerDashboard,
  EmployerEditProfile,
  ManageCandidate,
  ManageJobs,
  DashboardMessage,
  CategoryJobListing,
  Blog,
  BlogDetails,
  ForgotPassword
} from "./containers";

import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

import "./assests/css/main.css";
import "./assests/assets/css/bootstrap-select.min.css";
import "./assests/assets/css/bootstrap.min.css";
import "./assests/dashboard/css/dashboard.css";
import "./assests/assets/css/et-line.css";
import "./assests/assets/css/flag.css";
import "./assests/assets/css/fontawesome-all.min.css";
import "./assests/assets/css/html5-simple-date-input-polyfill.css";
import "./assests/assets/css/jquery.nstSlider.min.css";
import "./assests/assets/css/owl.carousel.min.css";
import "./assests/assets/css/plyr.css";
import "./assests/assets/css/slick.css";
import "./assests/assets/css/themify-icons.css";
import "./assests/sass/_about.scss";
import "./assests/sass/_account.scss";
import "./assests/sass/_advice.scss";
import "./assests/sass/_animated-number.scss";
import "./assests/sass/_app.scss";
import "./assests/sass/_banner.scss";
import "./assests/sass/_blog.scss";
import "./assests/sass/_breadcrumb.scss";
import "./assests/sass/_call-to-action.scss";
// import "./assests/sass/_candidate.scss"
import "./assests/sass/_category.scss";
import "./assests/sass/_company.scss";
import "./assests/sass/_contact.scss";
// import "./assests/sass/_employer.scss"
import "./assests/sass/_faq.scss";
import "./assests/sass/_footer.scss";
import "./assests/sass/_form.scss";
import "./assests/sass/_general.scss";
import "./assests/sass/_header.scss";
import "./assests/sass/_job.scss";
import "./assests/sass/_nav.scss";
import "./assests/sass/_pagination.scss";
import "./assests/sass/_pricing.scss";
import "./assests/sass/_progress-bar.scss";
import "./assests/sass/_search.scss";
import "./assests/sass/_testimonial.scss";
import "./assests/sass/_working-process.scss";
import "./assests/sass/main.scss";

import AboutUs from "./containers/AboutUs";
import CandidateAlert from './containers/CandidateAlert/index';

const isUser = { isAuthenticated: false };

const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = rest.props;
  let isAuthenticated = user;
  isUserAuthenticate();
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuthenticated || (isUser && isUser.isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/BlogDetails" component={BlogDetails} />
      <Route exact path="/Blog" component={Blog} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/howItWorks" component={HowItWorks} />
      <Route exact path="/JobListing" component={JobListing} />
      <Route exact path="/JobDetails" component={JobDetails} />
      <Route exact path="/AddResume" component={AddResume} />
      <Route exact path="/CandidateListing" component={CandidateListing} />
      <Route exact path="/CandidateDetails" component={CandidateDetails} />
      <Route exact path="/AboutUs" component={AboutUs} />
      <Route exact path="/FAQ" component={FAQ} />
      <Route exact path="/ContactUs" component={ContactUS} />
      <Route exact path="/TermsAndConditions" component={TermsAndConditions} />
      <Route exact path="/Checkout" component={Checkout} />
      <Route exact path="/Payment" component={Payment} />
      <Route exact path="/EmployerListing" component={EmployerListing} />
      <Route exact path="/EmployerDetails" component={EmployerDetails} />
      <Route exact path="/PostJob" component={PostJob} />
      <Route exact path="/CandidateDashboard" component={CandidateDashboard} />
      <Route exact path="/CandidateProfile" component={CandidateEditProfile} />
      <Route exact path="/CandidateResume" component={CandidateResume} />
      <Route exact path="/Alert" component={CandidateAlert} />
      <Route exact path="/categoryJobListing/:id" component={CategoryJobListing} />
      <Route
        exact
        path="/CandidateEditResume"
        component={CandidateEditResume}
      />
      <Route exact path="/CandidateBookmark" component={CandidateBookmark} />
      <Route exact path="/CandidateApplied" component={CandidateApplied} />
      <PrivateRoute exact path="/CandidateMessage" component={CandidateMessage} />
      <Route exact path="/EmployerDashboard" component={EmployerDashboard} />
      <Route
        exact
        path="/EmployerEditProfile"
        component={EmployerEditProfile}
      />
      <Route exact path="/ManageCandidate" component={ManageCandidate} />
      <Route exact path="/ManageJobs" component={ManageJobs} />
      <Route exact path="/DashboardMessage" component={DashboardMessage} />
      <Route exact path="/ForgotPassword" component={ForgotPassword} />
      <Route render={() => <Redirect to={{ pathname: "/" }} />} />
    </Router>
  );
}

export default App;
