import React, { useEffect, useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Search,
  Briefcase,
  MapPin,
  Clock,
  Heart,
  Edit2,
  Menu,
  Grid,
  AlignLeft,
  Video,
  Eye,
  Link2,
  CheckCircle,
} from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/employer/thumb-1.png";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase";
import thumb1 from "../../assests/images/portfolio/thumb-1.jpg";
import thumb2 from "../../assests/images/portfolio/thumb-2.jpg";
import thumb3 from "../../assests/images/portfolio/thumb-3.jpg";
import fire from "../../database";
import FindYourJob from "../../components/FindYourJob";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LinkIcon from "@material-ui/icons/Link";
import Slider from "react-slick";
import LogoImage from "../../assests/assets/companyImage.jpg";

import { makeStyles } from "@material-ui/core";
// import ReactPlayer from "react-player";
import ReactPlayer from "react-player/vimeo";

const useStyles = makeStyles((theme) => ({
  positionBtn: {
    background: "#023b92 !important",
    "&:hover": {
      background: "#246df8 !important",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isUser = { isAuthenticated: false };
const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const EmployerDetails = ({ location }) => {
  console.log(location.state, "LOCAAAAAAAAAAAAAAAAAAAAAAAA");
  const [totalJobs, setTotalJobs] = useState(0);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    firebase
      .database()
      .ref(`jobs/${location.state.uid}`)
      .on("value", (snapshot) => {
        let tempArr = [];
        snapshot.forEach((job) => {
          tempArr.push(job.val());
        });
        console.log("temp", tempArr);
        setJobs(tempArr);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`jobs/${location.state.uid}`)
      .on("value", (snapshot) => {
        setTotalJobs(snapshot.numChildren());
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`views/${firebase?.auth()?.currentUser?.uid}`)
      .push({
        count: 1,
      });
  }, []);

  console.log("location=>>>>", location, totalJobs);
  const classes = useStyles();
  isUserAuthenticate();
  const [open, setOpen] = React.useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const history = useHistory();

  const submitForm = () => {
    setOpen(false);
    setAlertData({
      open: true,
      severity: "success",
      msg: "Applied Successfully",
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 3,
    // centerPadding: "30px !important",
  };

  console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL", location);

  return (
    <>
      <div>
        {/* <NotificationAndAccount  />
            <Header /> */}

        <header className="header-2">
          <div className="container">
            <div className="row">
              <div className="col">
                <NotificationAndAccount />
                <Header />
              </div>
            </div>
          </div>
        </header>

        {/* <!-- Company Details --> */}
        <div class="alice-bg padding-top-60 section-padding-bottom">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="company-details">
                  <div class="title-and-info">
                    <div class="title">
                      <div class="thumb">
                        <img
                          src={
                            location?.state?.avatar
                              ? location?.state?.avatar
                              : LogoImage
                          }
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                      <div class="title-body">
                        <h4>{location.state.companyName}</h4>
                        <div class="info">
                          <span class="company-type">
                            <i>
                              <Briefcase />
                            </i>
                            {location.state.category}
                          </span>
                          <span class="office-location">
                            <i>
                              <MapPin />
                            </i>
                            Pakistan
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="download-resume">
                      <a class="save-btn">
                        <i>
                          <Heart />
                        </i>
                        Save
                      </a>
                      <a className={classes.positionBtn}>
                        {jobs.length} Open Positions
                      </a>
                    </div>
                  </div>
                  <div class="details-information padding-top-60">
                    <div class="row">
                      <div class="col-xl-7 col-lg-8">
                        <div class="about-details details-section">
                          <h4>
                            <i>
                              <AlignLeft />
                            </i>
                            About Us
                          </h4>
                          <p>{location.state.aboutUs}</p>
                        </div>
                        <div class="intor-video details-section">
                          <h4>
                            <i>
                              <Video />
                            </i>
                            Intro Video
                          </h4>
                          <div class="video-area">
                            <div
                              data-type="vimeo"
                              data-video-id="126777001"
                            ></div>
                            {/* <div
                              id="player"
                              data-plyr-provider="vimeo"
                              data-plyr-embed-id="126777001"
                            ></div> */}

                            {/* <ReactPlayer url="https://www.youtube.com/watch?v=ysz5S6PUM-U" /> */}
                            <iframe
                              width={500}
                              style={{
                                borderRadius: 5,
                                textAlign: "center",
                                marginTop: 20,
                                marginBottom: 20,
                              }}
                              height={200}
                              src={location.state.videoRef}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                        <div class="portfolio details-section">
                          <h4>
                            <i>
                              <Grid />
                            </i>
                            Image Gallery
                          </h4>
                          <Slider
                            {...settings}
                            slidesToShow={
                              location?.state?.gallery?.length > 3 ? 3 : 2
                            }
                            className="portfolio-slider"
                          >
                            {location?.state?.gallery?.map((data, index) => {
                              return (
                                <div class="portfolio-item" key={index}>
                                  <img
                                    src={data}
                                    class="img-fluid"
                                    width="95%"
                                    alt=""
                                  />
                                  <div class="overlay">
                                    <a target="blank" href={data}>
                                      <VisibilityIcon
                                        style={{ fontSize: "28px" }}
                                      />
                                    </a>
                                    <a target="blank">
                                      <LinkIcon style={{ fontSize: "28px" }} />
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                        <div class="open-job details-section">
                          <h4>
                            <i>
                              <CheckCircle />
                            </i>
                            Open Job
                          </h4>
                          {jobs &&
                            jobs.map((job, index) => (
                              <div class="job-list">
                                <div class="body">
                                  <div class="content">
                                    <h4>
                                      <Link
                                        to={{
                                          pathname: "/JobDetails",
                                          state: {
                                            data: job,
                                            type: "Candidate",
                                          },
                                        }}
                                      >
                                        {job.title}
                                      </Link>
                                    </h4>
                                    <div class="info">
                                      <span class="office-location">
                                        <a>
                                          <i>
                                            <MapPin />
                                          </i>
                                          {job.location}
                                        </a>
                                      </span>
                                      <span class="job-type temporary">
                                        <a>
                                          <i>
                                            <Clock />
                                          </i>
                                          {job.type}
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="more">
                                    <p class="deadline">
                                      Deadline:{" "}
                                      {new Date(job.date).toDateString()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div class="col-xl-4 offset-xl-1 col-lg-4">
                        <div class="information-and-contact">
                          <div class="information">
                            <h4>Information</h4>
                            <ul>
                              <li>
                                <span>Category:</span> {location.state.category}
                              </li>
                              <li>
                                <span>Location:</span> {location.state.address}
                              </li>
                              <li>
                                <span>Hotline:</span> {location.state.phone}
                              </li>
                              <li>
                                <span>Email:</span>{" "}
                                {location.state.emailAddress}
                              </li>
                              <li>
                                <span>Company Size:</span> 20-50
                              </li>
                              <li>
                                <span>Website:</span>{" "}
                                <a href="#">Lorem ipsum dolor</a>
                              </li>
                            </ul>
                          </div>
                          <div class="buttons">
                            <a
                              onClick={() => history.push("/ContactUs")}
                              class="button contact-button"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              style={{ background: "#023b92" }}
                            >
                              Contact Us
                            </a>
                          </div>
                          {/* <!-- Modal --> */}
                          <div
                            class="modal fade contact-form-modal"
                            id="exampleModal"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-body">
                                  <h4>
                                    <i>
                                      <Edit2 />
                                    </i>
                                    Contact Us
                                  </h4>
                                  <form action="#">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Your Name"
                                      />
                                    </div>
                                    <div class="form-group">
                                      <input
                                        type="email"
                                        class="form-control"
                                        placeholder="Your Email"
                                      />
                                    </div>
                                    <div class="form-group">
                                      <textarea
                                        class="form-control"
                                        placeholder="Your Message"
                                      ></textarea>
                                    </div>
                                    <button class="button">Submit</button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="job-location">
                          <h4>Our Location</h4>
                          <div id="map-area">
                            <div
                              class="cp-map"
                              id="location"
                              data-lat="40.713355"
                              data-lng="-74.005535"
                              data-zoom="10"
                            ></div>
                          </div>
                        </div>
                        <div class="share-job-post">
                          <span class="share">Social Profile:</span>
                          <a href={location.state.facebookLink} target="_blank">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                          <a href="#">
                            <i class="fab fa-twitter"></i>
                          </a>
                          {/* <a href="#">
                            <i class="fab fa-linkedin-in"></i>
                          </a> */}
                          <a href="#">
                            <i class="fab fa-google-plus-g"></i>
                          </a>
                          {/* <a href="#">
                            <i class="fab fa-pinterest-p"></i>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Company Details End --> */}

        <FindYourJob />
        <SocialShare />
        <NewsLetterFooter />
        <Footer />
      </div>
    </>
  );
};

export default EmployerDetails;
