import React, { useState, useEffect } from "react";
import {
  Footer,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import { Send, Image, Paperclip } from "react-feather";
import FindYourJob from "../../components/FindYourJob";
import Header from "./../../components/Header/index";
import CandidateDashboardSidebar from "./../../components/CandidateDashboardSidebar/CandidateDashboardSidebar";
import firebase from "firebase";
import fire from "../../database";
import MessageBox from "../MessageBox";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const CandidateMessage = () => {
  const classes = useStyles();
  const [aboutUser, setAboutUser] = useState("");
  const [useraboutDetails, setaboutUserDetails] = useState({});

  const [userDetails, setuserDetails] = useState({
    id: "",
    name: "",
    profileImg: "",
    token: "",
  });

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setAboutUser(userData);
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/candidate/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        setaboutUserDetails(snapshot.val());
      });
  }, []);

  const [allUsers, setallUsers] = useState([]);
  const [currentCandidate, setcurrentCandidate] = useState({});
  useEffect(() => {
    try {
      firebase
        .database()
        .ref(
          `communication/${firebase.auth().currentUser.uid}/messageCandidate`
        )
        .on("value", (datasnapShot) => {
          let users = [];
          let currentUser = {
            id: "",
            name: "",
            profileImg: "",
            token: "",
          };
          datasnapShot.forEach((child) => {
            if (firebase.auth().currentUser.uid === child.val().uuid) {
              console.log("if");
              currentUser.id = child.val().uuid;
              currentUser.name = child.val().name;
              currentUser.token = child.val().token;
              currentUser.profileImg = child.val().img;
            } else {
              console.log("elssdsdse");
              users.push({
                id: child.val().id,
                name: child.val().name,
                skills: child.val().skills,
              });
            }
          });
          setuserDetails(currentUser);
          setallUsers(users);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleUserChange = (data) => {
    console.log(data, "DATAAAA");
    setcurrentCandidate(data);
  };

  console.log(allUsers, "setallUserssetallUserssetallUsers");
  return (
    <>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Candidates Dashboard</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Candidates Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              {/* <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i data-feather="search"></i>
                  </button>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="dashboard-container">
                <div class="dashboard-content-wrapper no-padding">
                  <div class="dashboard-message-wrapper">
                    <div class="message-lists">
                      <form action="#" class="message-search">
                        <input type="text" placeholder="Search Friend..." />
                        <button>
                          <i data-feather="search"></i>
                        </button>
                      </form>
                      {/* <a href="#" class="message-single">
                        <div class="thumb">
                          <img
                            src="dashboard/images/user-1.jpg"
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <div class="body">
                          <h6 class="username">Lorem ipsum</h6>
                          <span class="text-number">2</span>
                        </div>
                      </a> */}
                      {allUsers.map((data, index) => {
                        console.log(data, "datadatadatadata");
                        return (
                          <a class="message-single">
                            <div class="thumb">
                              <img
                                src="dashboard/images/user-2.jpg"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                            <div
                              class="body"
                              onClick={() => handleUserChange(data)}
                            >
                              <h6 class="username">{data.name}</h6>
                            </div>
                          </a>
                        );
                      })}
                      {/* <a href="#" class="message-single active">
                        <div class="thumb">
                          <img
                            src="dashboard/images/user-3.jpg"
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <div class="body">
                          <h6 class="username">Lorem ipsum</h6>
                          <span class="send-time">6 min</span>
                        </div>
                      </a> */}
                      {/* <a href="#" class="message-single">
                        <div class="thumb">
                          <img
                            src="dashboard/images/user-4.jpg"
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                        <div class="body">
                          <h6 class="username">Lorem ipsum</h6>
                          <span class="send-time">45 min</span>
                        </div>
                      </a> */}
                    </div>
                    {currentCandidate.name ? (
                      <MessageBox
                        activeUser={firebase.auth().currentUser.uid}
                        candidate={currentCandidate}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                          fontSize: 30,
                        }}
                      >
                        Please Select a chat
                      </div>
                    )}
                  </div>
                </div>
                <div class={`${classes.hide} dashboard-sidebar`}>
                  <div class="user-info">
                    <div class="thumb">
                      <img
                        src={useraboutDetails?.avatar}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="user-body">
                      <h5>{aboutUser?.name}</h5>
                      <span>@username</span>
                    </div>
                  </div>
                  {/* <div class="profile-progress">
                                        <div class="progress-item">
                                            <div class="progress-head">
                                                <p class="progress-on">Profile</p>
                                            </div>
                                            <div class="progress-body">
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div>
                                                </div>
                                                <p class="progress-to">70%</p>
                                            </div>
                                        </div>
                                    </div> */}
                  <div class="dashboard-menu">
                    {/* <ul>
                      <li>
                        <i class="fas fa-home"></i>
                        <a href="dashboard.html">Dashboard</a>
                      </li>
                      <li>
                        <i class="fas fa-user"></i>
                        <a href="dashboard-edit-profile.html">Edit Profile</a>
                      </li>
                      <li>
                        <i class="fas fa-file-alt"></i>
                        <a href="resume.html">Resume</a>
                      </li>
                      <li>
                        <i class="fas fa-edit"></i>
                        <a href="edit-resume.html">Edit Resume</a>
                      </li>
                      <li>
                        <i class="fas fa-heart"></i>
                        <a href="dashboard-bookmark.html">Bookmarked</a>
                      </li>
                      <li>
                        <i class="fas fa-check-square"></i>
                        <a href="dashboard-applied.html">Applied Job</a>
                      </li>
                      <li class="active">
                        <i class="fas fa-comment"></i>
                        <a href="dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i class="fas fa-calculator"></i>
                        <a href="dashboard-pricing.html">Pricing Plans</a>
                      </li>
                    </ul> */}
                    <CandidateDashboardSidebar dashboardLink="message" />
                    <ul class="delete">
                      <li>
                        <i class="fas fa-power-off"></i>
                        <a href="#">Logout</a>
                      </li>
                      {/* <li>
                        <i class="fas fa-trash-alt"></i>
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#modal-delete"
                        >
                          Delete Profile
                        </a>
                      </li> */}
                    </ul>

                    <div
                      class="modal fade modal-delete"
                      id="modal-delete"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body">
                            <h4>
                              <i data-feather="trash-2"></i>Delete Account
                            </h4>
                            <p>
                              Are you sure! You want to delete your profile.
                              This can't be undone!
                            </p>
                            <form action="#">
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder="Enter password"
                                />
                              </div>
                              <div class="buttons">
                                <button class="delete-button">
                                  Save Update
                                </button>
                                <button class="">Cancel</button>
                              </div>
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                />
                                <label class="form-check-label">
                                  You accepts our{" "}
                                  <a href="#">Terms and Conditions</a> and{" "}
                                  <a href="#">Privacy Policy</a>
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CandidateMessage;
