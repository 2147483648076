import { Link } from "@material-ui/core";
import React, { useRef, useEffect, useState } from "react";
import {
  EmployerDashboardSidebar,
  Footer,
  SocialShare,
} from "../../components";
import {
  Header,
  NewsLetterFooter,
  NotificationAndAccount,
} from "../../components";
import FindYourJob from "./../../components/FindYourJob";
import firebase from "firebase";
import fire from "../../database";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LogoImage from "../../assests/assets/companyImage.jpg";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import LogoutAndDelete from "../../components/logoutAndDelete";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EmployerEditProfile = () => {
  const classes = useStyles();
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const history = useHistory();

  const [ImageSelected, setImageSelected] = useState({});
  const companyNameRef = useRef();
  const usernameRef = useRef();
  const emailRef = useRef();
  const websiteLink = useRef();
  const companySize = useRef();
  const location = useRef();
  const phoneRef = useRef();
  const addressRef = useRef();
  const categoryRef = useRef();
  const aboutRef = useRef();
  const videoRef = useRef();
  const facebookLinkRef = useRef();
  const twitterLinkRef = useRef();
  const gmailLinkref = useRef();
  const [galleryImage, setGalleryImage] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [newpass, setNewPass] = useState("");
  const [currentPass, setcurrentPass] = useState("");
  const [reTypePass, setreTypePass] = useState("");
  const [aboutUser, setAboutUser] = useState("");
  const [videoLink, setVideoLink] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setAboutUser(userData);

    // alert()
    firebase
      .database()
      .ref(`profile/employer/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        console.log("snapshot=>>>", snapshot.val());
        setUserDetails(snapshot.val());
        setVideoLink(snapshot?.val()?.videoRef)
        setImage(snapshot?.val()?.avatar);
        console.log("snap shot gallery", snapshot?.val()?.gallery);
        setGalleryImage(snapshot.val()?.gallery ? snapshot.val().gallery : []);
      });
  }, []);

  const [image, setImage] = useState(userDetails?.avatar);

  const handleUpdatePass = async (e) => {
    e.preventDefault();
    if (newpass && currentPass && reTypePass) {
      if (newpass == reTypePass) {
        const user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          firebase.auth().currentUser.email,
          currentPass
        );
        user
          .reauthenticateWithCredential(credential)
          .then((res) => {
            user
              .updatePassword(newpass)
              .then((res) => {
                setAlertData({
                  msg: "New password set successfully",
                  open: true,
                  severity: "success",
                });
                setNewPass("");
                setcurrentPass("");
                setreTypePass("");
              })
              .catch((err) => {
                setAlertData({
                  msg: "Error has occured",
                  open: true,
                  severity: "error",
                });
              });
          })
          .catch((err) => {
            // console.log(err,"ERRRRRRR");
            setAlertData({
              open: true,
              severity: "error",
              msg: err.message,
            });
          });
        console.log(credential, "USERRRR");
      } else {
        setAlertData({
          open: true,
          severity: "error",
          msg: "Password must be same",
        });
      }
    } else {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Fill all required fields to change password!",
      });
    }
    // try {
    //   await user.updatePassword(newpass)
    //   console.log('Password Updated!')
    // } catch (err) {
    //   console.log(err, "ERRRRRRR")
    // }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    let params = {
      companyName: companyNameRef.current.value,
      // userName: usernameRef.current.value,
      emailAddress: emailRef.current.value,
      phone: phoneRef.current.value,
      address: addressRef.current.value,
      category: categoryRef.current.value,
      aboutUs: aboutRef.current.value,
      avatar: image,
      videoRef: videoLink,
      facebookLink: facebookLinkRef?.current.value,
      twitterLink: twitterLinkRef.current.value,
      gmailLink: gmailLinkref.current.value,
      // companySize: companySize.current.value,
      // location: location.current.value,
      // websiteLink: websiteLink.current.value,
      uid: firebase.auth().currentUser.uid,
      gallery: galleryImage,
      totalJobs: userDetails?.totalJobs ? userDetails?.totalJobs : 0
    };
    console.log("res", params);
    if (!image) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please insert Image",
      });

    } else if (videoRef === undefined) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please insert Video",
      });
    }
    else {
      firebase
        .database()
        .ref(`profile/employer/${firebase.auth().currentUser.uid}`)
        .update({
          ...params,
        })
        .then((res) => {
          history.push("/EmployerDashboard");
        });
    }
  };
  console.log("userDetails=>>>>", userDetails);

  useEffect(() => {
    UploadImage();
  }, [ImageSelected]);

  const UploadImage = () => {
    console.log("upload Image");
    const form = new FormData();
    form.append("file", ImageSelected);
    form.append("upload_preset", "pn7uz8ak");
    axios
      .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", form)
      .then((res) => {
        setImage(res.data.url);
      })
      .catch((err) => {
        console.log("rrrrrrr=>>>>", err);
      });
  };

  const UploadGalleryImage = (ImageUri) => {
    const form = new FormData();
    form.append("file", ImageUri);
    form.append("upload_preset", "pn7uz8ak");
    axios
      .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", form)
      .then((res) => {
        console.log(res, "RRRRRRRRRRRRRRRRRRRRRRRR");
        setGalleryImage([...galleryImage, res.data.url]);
      })
      .catch((err) => {
        console.log("rrrrrrr=>>>>", err);
      });
  };

  const uploadVideo = (videoUri) => {
    alert('Please wait while uploading video')
    setIsLoading(true)
    const form = new FormData();
    form.append("file", videoUri);
    form.append("upload_preset", "pn7uz8ak");
    axios
      .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", form)
      .then((res) => {
        console.log(res, "RRRRRRRRRRRRRRRRRRRRRRRR");
        setVideoLink(res.data.secure_url)
        setIsLoading(false)
        // setGalleryImage([...galleryImage, res.data.url]);
      })
      .catch((err) => {
        console.log("rrrrrrr=>>>>", err);
        setIsLoading(false)
        alert(err)
      });
  }

  // console.log("IMAASDASASD=>>>", videoRef?.current?.value);

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const removeItem = async (id) => {
    let arr = [...galleryImage];
    let res = await arr.splice(id, 1);
    console.log("RESULTTTTT=>>>>", res, arr);
    setGalleryImage([...arr]);
  };


  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Employer Dashboard</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Employer Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                {/* <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i data-feather="search"></i>
                  </button>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="dashboard-container">
                <div class="dashboard-content-wrapper">
                  <form action="#" class="dashboard-form">
                    <div class="dashboard-section upload-profile-photo">
                      <div class="update-photo">
                        <img
                          class="image"
                          src={image ? image : LogoImage}
                          alt=""
                        />
                      </div>
                      <div class="file-upload">
                        <input
                          type="file"
                          class="file-input"
                          onChange={(e) => setImageSelected(e.target.files[0])}
                        />
                        Change Avatar
                      </div>
                    </div>
                    <div class="dashboard-section basic-info-input">
                      <h4>
                        <i data-feather="user-check"></i>Basic Info
                      </h4>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Company Name
                        </label>
                        <div class="col-sm-9">
                          <input
                            ref={companyNameRef}
                            type="text"
                            class="form-control"
                            placeholder="Company Name"
                            defaultValue={userDetails?.companyName}
                          />
                        </div>
                      </div>
                      {/* <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Username</label>
                        <div class="col-sm-9">
                          <input
                            ref={usernameRef}
                            type="text"
                            class="form-control"
                            placeholder="@username"
                            defaultValue={userDetails?.userName}
                          />
                        </div>
                      </div> */}
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Email Address
                        </label>
                        <div class="col-sm-9">
                          <input
                            ref={emailRef}
                            type="text"
                            class="form-control"
                            placeholder="email@example.com"
                            defaultValue={userDetails?.emailAddress}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Phone</label>
                        <div class="col-sm-9">
                          <input
                            ref={phoneRef}
                            type="number"
                            class="form-control"
                            placeholder="+55 123 4563 4643"
                            defaultValue={userDetails?.phone}
                          />
                        </div>
                      </div>
                      {/* <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Location</label>
                        <div class="col-sm-9">
                          <input
                            ref={location}
                            type="text"
                            class="form-control"
                            placeholder="Sindhi Mushlim Housing Society"
                            defaultValue={userDetails?.location}
                          />
                        </div>
                      </div> */}
                      {/* <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Company Size</label>
                        <div class="col-sm-9">
                          <input
                            ref={companySize}
                            type="text"
                            class="form-control"
                            placeholder="20-50"
                            defaultValue={userDetails?.companySize}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Website Link</label>
                        <div class="col-sm-9">
                          <input
                            ref={websiteLink}
                            type="text"
                            class="form-control"
                            placeholder="www.website.com"
                            defaultValue={userDetails?.website}
                          />
                        </div>
                      </div> */}
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Address</label>
                        <div class="col-sm-9">
                          <input
                            ref={addressRef}
                            type="text"
                            class="form-control"
                            placeholder="Atlanta"
                            defaultValue={userDetails?.address}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Category</label>
                        <div class="col-sm-9">
                          <input
                            ref={categoryRef}
                            type="text"
                            class="form-control"
                            placeholder="UI & UX Designer"
                            defaultValue={userDetails?.category}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">About Us</label>
                        <div class="col-sm-9">
                          <textarea
                            ref={aboutRef}
                            class="form-control"
                            placeholder=""
                            defaultValue={userDetails?.aboutUs}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="dashboard-section media-inputs">
                      <h4>
                        <i data-feather="image"></i>Photo &amp; Video
                      </h4>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Intro Video
                        </label>
                        <div class="col-sm-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              {
                                videoLink &&
                                <iframe width={200} style={{ borderRadius: 5, textAlign: 'center', marginTop: 20, marginBottom: 20 }} height={200} src={videoLink ? videoLink : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                              }
                            </div>
                            <input
                              ref={videoRef}
                              type="file"
                              accept="video"
                              class="form-control"
                              onChange={e => uploadVideo(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Gallery</label>
                        <div class="col-sm-9">
                          <div class="input-group image-upload-input">
                            {galleryImage &&
                              galleryImage.length > 0 &&
                              galleryImage.map((image, index) => {
                                return (
                                  <div class="input-group-prepend">
                                    <img
                                      style={{ width: 100, height: 150 }}
                                      src={image}
                                      class="input-group-text"
                                    />
                                    <div
                                      style={{ position: "absolute" }}
                                      onClick={() => removeItem(index)}
                                    >
                                      <ClearIcon />
                                    </div>
                                  </div>
                                );
                              })}
                            <div
                              style={{ width: 100, height: 100 }}
                              class="active"
                            >
                              <div class="upload-images">
                                <div class="pic">
                                  <span class="ti-plus">
                                    <input
                                      type="file"
                                      style={{ opacity: 0 }}
                                      onChange={(e) => {
                                        console.log("e target", e.target.files);
                                        UploadGalleryImage(e.target.files[0]);
                                      }}
                                      class="file-input"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="dashboard-section social-inputs">
                      <h4>
                        <i data-feather="cast"></i>Social Networks
                      </h4>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Social Links
                        </label>
                        <div class="col-sm-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="fab fa-facebook-f"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              ref={facebookLinkRef}
                              defaultValue={userDetails?.facebookLink}
                              placeholder="facebook.com/username"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-3 col-sm-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="fab fa-twitter"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="twitter.com/username"
                              defaultValue={userDetails?.twitterLink}
                              ref={twitterLinkRef}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-3 col-sm-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <i class="fab fa-google-plus"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="google.com/username"
                              ref={gmailLinkref}
                              defaultValue={userDetails?.gmailLink}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div class="form-group row">
                        <div class="offset-sm-3 col-sm-9">
                          <div class="input-group add-new">
                            <div class="input-group-prepend">
                              <div class="input-group-text dropdown-label">
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>Select</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                                <i class="fa fa-caret-down"></i>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Input Profile Link"
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div class="dashboard-section basic-info-input">
                      <h4>
                        <i data-feather="lock"></i>Change Password
                      </h4>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Current Password
                        </label>
                        <div class="col-sm-9">
                          <input
                            value={currentPass}
                            onChange={(e) => setcurrentPass(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="Current Password"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          New Password
                        </label>
                        <div class="col-sm-9">
                          <input
                            value={newpass}
                            onChange={(e) => setNewPass(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="New Password"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Retype Password
                        </label>
                        <div class="col-sm-9">
                          <input
                            name={reTypePass}
                            onChange={(e) => setreTypePass(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="Retype Password"
                          />
                        </div>
                      </div>
                      <div style={{ textAlign: "end" }}>
                        <button
                          style={{ backgroundColor: "#cee3f5", color: "black" }}
                          className="button"
                          onClick={handleUpdatePass}
                        >
                          Update Password
                        </button>
                      </div>
                      <div>
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label"></label>
                          <div class="col-sm-9">
                            <button disabled={isLoading} onClick={onSubmit} class="button">
                              Save Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class={`${classes.hide} dashboard-sidebar`}>
                  <div class="company-info">
                    <div class="thumb">
                      <img src={userDetails?.avatar} class="img-fluid" alt="" />
                    </div>
                    <div class="company-body">
                      <h5>{aboutUser.name}</h5>
                      <span>@username</span>
                    </div>
                  </div>

                  <div class="dashboard-menu">
                    {/* <ul>
                      <li>
                        <i class="fas fa-home"></i>
                        <a href="employer-dashboard.html">Dashboard</a>
                      </li>
                      <li class="active">
                        <i class="fas fa-user"></i>
                        <a href="employer-dashboard-edit-profile.html">
                          Edit Profile
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-briefcase"></i>
                        <a href="employer-dashboard-manage-job.html">
                          Manage Jobs
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-users"></i>
                        <a href="employer-dashboard-manage-candidate.html">
                          Manage Candidates
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-heart"></i>
                        <a href="#">Shortlisted Resumes</a>
                      </li>
                      <li>
                        <i class="fas fa-plus-square"></i>
                        <a href="employer-dashboard-post-job.html">
                          Post New Job
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-comment"></i>
                        <a href="employer-dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i class="fas fa-calculator"></i>
                        <a href="employer-dashboard-pricing.html">
                          Pricing Plans
                        </a>
                      </li>
                    </ul> */}
                    <EmployerDashboardSidebar dashboardLink="editProfile" />
                    {/* <logou */}
                    {/* <!-- Modal --> */}
                    <LogoutAndDelete />
                    <div
                      class="modal fade modal-delete"
                      id="modal-delete"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body">
                            <h4>
                              <i data-feather="trash-2"></i>Delete Account
                            </h4>
                            <p>
                              Are you sure! You want to delete your profile.
                              This can't be undone!
                            </p>
                            <form action="#">
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder="Enter password"
                                />
                              </div>
                              <div class="buttons">
                                <button class="delete-button">
                                  Save Update
                                </button>
                                <button class="">Cancel</button>
                              </div>
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                />
                                <label class="form-check-label">
                                  You accepts our{" "}
                                  <a href="#">Terms and Conditions</a> and{" "}
                                  <a href="#">Privacy Policy</a>
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};
export default EmployerEditProfile;
