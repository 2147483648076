import React from "react";
import { Container } from "@material-ui/core";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import slderImg1 from "../../assests/images/slider1.png";
import slderImg2 from "../../assests/images/slider2.png";
import slderImg3 from "../../assests/images/slider3.png";
import "./style.css";

const HomePageSlider = () => {
  return (
    <div style={{ marginTop: "20px" }}>
      <Container maxWidth="xl">
        {/* <h4>React-Bootstrap Carousel Component</h4>    */}
        <div className="home-slider">
          <Carousel>
            <Carousel.Item interval={4000}>
              <img className="d-block w-100" src={slderImg1} alt="Image One" />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img className="d-block w-100" src={slderImg2} alt="Image Two" />
            </Carousel.Item>
            <Carousel.Item interval={4000}>
              <img className="d-block w-100" src={slderImg3} alt="Image Two" />
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default HomePageSlider;
