import React, { useEffect, useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import Thumb1 from "../../assests/images/feature/thumb-1.png";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import RegisterBox from "../../assests/images/register-box/1.png";
import RegisterBox2 from "../../assests/images/register-box/2.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";
import FindYourJob from "./../../components/FindYourJob";
import Candidate from "./Candidate";
import Employer from "./Employer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    background: "#fff",
    boxShadow: "none",
    "&.MuiPaper-root .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "&.MuiPaper-root .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root":
      {
        color: "#246df8",
        textTransform: "none",
        // color: "#6f7484",
        fontSize: "1.5rem",
      },
    "&.MuiPaper-root .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator": {
      backgroundColor: "#246df8",
    },
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };
  const [tablValue, setTablValue] = useState("1");
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleChangeTab = (event, newValue) => {
    setTablValue(newValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Email has been sent to us
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* <NotificationAndAccount  />
            <Header /> */}
      {/* <!-- Breadcrumb --> */}
      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>FAQ Page</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      FAQ Page
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i data-feather="search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Breadcrumb End --> */}
      {/* <!-- How It Work --> */}
      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="white-bg others-block">
                {/* <!-- FAQ --> */}
                <div class="padding-top-60 padding-bottom-60">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <div class="section-header section-header-center">
                          <h6>Your answer here</h6>
                          <h2>Frequently Asked Questions</h2>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-lg-10">
                        <div className={classes.root}>
                          <TabContext value={tablValue}>
                            <AppBar
                              position="static"
                              // style={{ background: "#fff", boxShadow: "none" }}
                              className={classes.tabRoot}
                            >
                              <TabList
                                onChange={handleChangeTab}
                                aria-label="simple tabs example"
                                style={{ justifyContent: "center" }}
                              >
                                <Tab label="Candidate" value="1" />
                                <Tab label="Employer" value="2" />
                              </TabList>
                            </AppBar>
                            <TabPanel value="1">
                              <Candidate />
                            </TabPanel>
                            <TabPanel value="2">
                              <Employer />
                            </TabPanel>
                          </TabContext>
                        </div>
                        {/* <ul
                          class="nav nav-tabs faq-tab"
                          id="myTab"
                          role="tablist"
                        >
                          <li class="nav-item">
                            <a
                              class="nav-link active show"
                              id="feature-tab"
                              data-toggle="tab"
                              href="#feature"
                              role="tab"
                              aria-controls="feature"
                              aria-selected="false"
                            >
                              Candidate
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="latest-tab"
                              data-toggle="tab"
                              href="#latest"
                              role="tab"
                              aria-controls="latest"
                              aria-selected="false"
                            >
                              Employer
                            </a>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- FAQ End --> */}
                <div class="padding-bottom-60">
                  <div class="quick-contact">
                    <div class="icon">
                      <img
                        src="images/chat-icon.png"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <h4>Don’t get Answer?</h4>
                    <p>
                      We have a record of answering everything in 3 hours of
                      less.
                    </p>
                    <div
                      class="buttons"
                      onClick={() => setOpenDialog(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <a class="email">Email Us</a>
                      {/* <a href="#" class="chat">
                          Let's Chat
                        </a> */}
                    </div>
                    {/* <p class="call">
                      Or maybe you’d like to call us:{" "}
                      <span>(+7 (966) 552-88-46)</span>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />

      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </div>
  );
};

export default FAQ;
