import React from "react"
import Loader from "react-loader-spinner";

const LoaderComponent = ({isloading}) => {
    return (
        <div className="loader" style={{ display: isloading ? "flex" : "none" }}>
            <Loader
                visible={isloading}
                type="ThreeDots"
                color="white"
                height={100}
                width={100}
            />
        </div>
    )
}

export default LoaderComponent