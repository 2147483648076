import React, { useEffect, useRef, useState } from "react";
import {
  Footer,
  Header,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import FindYourJob from "../../components/FindYourJob";
import CandidateDashboardSidebar from "./../../components/CandidateDashboardSidebar/CandidateDashboardSidebar";
import firebase from "firebase";
import fire from "../../database";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LogoImage from "../../assests/assets/companyImage.jpg";
import axios from "axios";
import LogoutAndDelete from "../../components/logoutAndDelete";
import { makeStyles, createMuiTheme } from "@material-ui/core";

const customTheme = createMuiTheme({
  breakpoints: {
    values: {
      exSmall: 412,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  updateBtn: {
    [theme.breakpoints.down(customTheme.breakpoints.values.exSmall)]: {
      marginTop: 10,
    },
  },
  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CandidateProfile = () => {
  const classes = useStyles();
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  // const inputRef = useRef({});
  const fullName = useRef();
  const userName = useRef();
  const emailAddress = useRef();
  const phone = useRef();
  const address = useRef();
  const indestryExp = useRef();
  const about = useRef();
  const [userDetails, setUserDetails] = useState({});
  const [newpass, setNewPass] = useState("");
  const [currentPass, setcurrentPass] = useState("");
  const [reTypePass, setreTypePass] = useState("");
  const [ImageSelected, setImageSelected] = useState({});
  const [image, setImage] = useState("");
  const [aboutUser, setAboutUser] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const videoRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [userFields, setUserFields] = useState("");

  useEffect(() => {
    firebase
      .database()
      .ref(`users/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setUserFields(data);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/candidate/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        console.log(snapshot.val(), "VAAAAAA");
        setUserDetails(snapshot.val());
        setVideoLink(snapshot?.val()?.video);
        setImage(snapshot.val()?.avatar);
        // setImage(snapshot?.val()?.avatar);
      });
  }, []);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setAboutUser(userData);
  }, []);

  useEffect(() => {
    UploadImage();
  }, [ImageSelected]);

  const UploadImage = () => {
    setIsLoading(true);
    console.log("upload Image", ImageSelected);
    const form = new FormData();
    form.append("file", ImageSelected);
    form.append("upload_preset", "pn7uz8ak");
    axios
      .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", form)
      .then((res) => {
        setIsLoading(false);
        setImage(res.data.url);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("rrrrrrr=>>>>", err);
      });
  };

  const handleSaveChanges = (event) => {
    event.preventDefault();
    let params = {
      fullName: fullName.current.value,
      phone: phone.current.value,
      address: address.current.value,
      indestryExp: indestryExp.current.value,
      about: about.current.value,
      avatar: image,
      video: videoLink,
    };
    if (
      fullName.current.value != "" &&
      phone.current.value != "" &&
      address.current.value != "" &&
      indestryExp.current.value != "" &&
      about.current.value != "" &&
      image != null
    ) {
      // alert("cal")
      firebase
        .database()
        .ref(`profile/candidate/${firebase?.auth().currentUser.uid}`)
        .set({
          ...params,
          uid: firebase.auth().currentUser.uid,
        })
        .then((res) => {
          setAlertData({
            open: true,
            severity: "success",
            msg: "Profile Edited Succesfully",
          });
        })
        .catch((err) => {
          setAlertData({
            open: true,
            severity: "error",
            msg: "Some error has occured",
          });
        });
    } else {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please fill all the fields",
      });
    }
  };

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const handleUpdatePass = async (e) => {
    e.preventDefault();
    if (newpass == reTypePass) {
      const user = firebase.auth().currentUser;
      var credential = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        currentPass
      );
      user
        .reauthenticateWithCredential(credential)
        .then((res) => {
          user
            .updatePassword(newpass)
            .then((res) => {
              setAlertData({
                msg: "New password set successfully",
                open: true,
                severity: "success",
              });
            })
            .catch((err) => {
              setAlertData({
                msg: "Error has occured",
                open: true,
                severity: "error",
              });
            });
        })
        .catch((err) => {
          // console.log(err,"ERRRRRRR");
          setAlertData({
            open: true,
            severity: "error",
            msg: err.message,
          });
        });
      console.log(credential, "USERRRR");
    } else {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Password must be same",
      });
    }
    // try {
    //   await user.updatePassword(newpass)
    //   console.log('Password Updated!')
    // } catch (err) {
    //   console.log(err, "ERRRRRRR")
    // }
  };

  const handleLogOut = () => {
    console.log("handle ligitu");
    firebase
      .auth()
      .signOut()
      .then((res) => {
        setAlertData({
          open: true,
          msg: "Loged Out Successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
    sessionStorage.removeItem("user");
    window.location.reload();
  };

  const loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  const getVideo = async (e) => {
    const video = await loadVideo(e.currentTarget.files[0]);
    return video.duration;
  };

  const uploadVideo = async (videoUri, e) => {
    alert("Please wait while uploading video");

    let duration = await getVideo(e);

    let newDuration = duration.toString().split(".");

    let seconds = newDuration[0];

    if (seconds > 30) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Cannot upload video more than 30 seconds",
      });
    } else {
      console.log("uploading videoooo");
      setIsLoading(true);
      const form = new FormData();
      form.append("file", videoUri);
      form.append("upload_preset", "pn7uz8ak");
      axios
        .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", form)
        .then((res) => {
          console.log(res, "RRRRRRRRRRRRRRRRRRRRRRRR");
          setVideoLink(res.data.secure_url);
          setIsLoading(false);
          // setGalleryImage([...galleryImage, res.data.url]);
        })
        .catch((err) => {
          console.log("rrrrrrr=>>>>", err);
          setIsLoading(false);
          alert(err);
        });
    }
  };

  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Candidates Dashboard</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Candidates Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                {/* <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i data-feather="search"></i>
                  </button>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="dashboard-container">
                <div class="dashboard-content-wrapper">
                  <form action="#" class="dashboard-form">
                    <div class="dashboard-section upload-profile-photo">
                      <div class="update-photo">
                        <img
                          class="image"
                          src={image ? image : LogoImage}
                          alt=""
                        />
                      </div>
                      <div class="file-upload">
                        <input
                          type="file"
                          class="file-input"
                          onChange={(e) => setImageSelected(e.target.files[0])}
                        />
                        Change Avatar
                      </div>
                    </div>
                    <div class="dashboard-section basic-info-input">
                      <h4>
                        <i data-feather="user-check"></i>Basic Info
                      </h4>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Full Name</label>
                        <div class="col-sm-9">
                          <input
                            ref={fullName}
                            type="text"
                            class="form-control"
                            placeholder="Full Name"
                            defaultValue={userDetails?.fullName}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Username</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="@username"
                            value={userFields?.name}
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Email Address
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="email@example.com"
                            value={userFields?.email}
                            disabled
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Phone</label>
                        <div class="col-sm-9">
                          <input
                            ref={phone}
                            type="text"
                            class="form-control"
                            placeholder="+55 123 4563 4643"
                            defaultValue={userDetails?.phone}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Address</label>
                        <div class="col-sm-9">
                          <input
                            ref={address}
                            type="text"
                            class="form-control"
                            placeholder="America"
                            defaultValue={userDetails?.address}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Indestry Expertise
                        </label>
                        <div class="col-sm-9">
                          <input
                            ref={indestryExp}
                            type="text"
                            class="form-control"
                            placeholder="UI & UX Designer"
                            defaultValue={userDetails?.indestryExp}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">About Me</label>
                        <div class="col-sm-9">
                          <textarea
                            ref={about}
                            class="form-control"
                            placeholder="Introduce Yourself"
                            defaultValue={userDetails?.about}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-3 col-form-label">Intro Video</label>

                      <div class="col-sm-9">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            {videoLink && (
                              <iframe
                                width={200}
                                style={{
                                  borderRadius: 5,
                                  textAlign: "center",
                                  marginTop: 20,
                                  marginBottom: 20,
                                }}
                                height={200}
                                src={videoLink ? videoLink : ""}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            )}
                          </div>
                          <input
                            ref={videoRef}
                            type="file"
                            accept="video"
                            class="form-control"
                            onChange={(e) => uploadVideo(e.target.files[0], e)}
                          />
                          {/* <input
                            type="file"
                            id="fileUp"
                            name="fileUpload"
                            onChange={(e) => getVideo(e)}
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div class="dashboard-section basic-info-input">
                      <h4>
                        <i data-feather="lock"></i>Change Password
                      </h4>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Current Password
                        </label>
                        <div class="col-sm-9">
                          <input
                            value={currentPass}
                            onChange={(e) => setcurrentPass(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="Current Password"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          New Password
                        </label>
                        <div class="col-sm-9">
                          <input
                            value={newpass}
                            onChange={(e) => setNewPass(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="New Password"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Retype Password
                        </label>
                        <div class="col-sm-9">
                          <input
                            name={reTypePass}
                            onChange={(e) => setreTypePass(e.target.value)}
                            type="password"
                            class="form-control"
                            placeholder="Retype Password"
                          />
                        </div>
                      </div>
                      {/* <div style={{ textAlign: "end" }}></div> */}
                      <div>
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label"></label>
                          <div
                            class="col-sm-9"
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexWrap: "wrap",
                            }}
                          >
                            {!isLoading ? (
                              <button
                                class="button"
                                onClick={handleSaveChanges}
                              >
                                Save Changes
                              </button>
                            ) : (
                              <div>Uploading Please Wait </div>
                            )}
                            <button
                              style={{
                                backgroundColor: "#cee3f5",
                                color: "black",
                              }}
                              className={`${classes.updateBtn} button`}
                              onClick={handleUpdatePass}
                            >
                              Update Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class={`${classes.hide} dashboard-sidebar`}>
                  <div class="user-info">
                    <div class="thumb">
                      <img src={userDetails?.avatar} class="img-fluid" alt="" />
                    </div>
                    <div class="user-body">
                      <h5>{aboutUser?.name}</h5>
                      <span>@username</span>
                    </div>
                  </div>

                  <div class="dashboard-menu">
                    {/* <ul>
                      <li>
                        <i class="fas fa-home"></i>
                        <a href="dashboard.html">Dashboard</a>
                      </li>
                      <li class="active">
                        <i class="fas fa-user"></i>
                        <a href="dashboard-edit-profile.html">Edit Profile</a>
                      </li>
                      <li>
                        <i class="fas fa-file-alt"></i>
                        <a href="resume.html">Resume</a>
                      </li>
                      <li>
                        <i class="fas fa-edit"></i>
                        <a href="edit-resume.html">Edit Resume</a>
                      </li>
                      <li>
                        <i class="fas fa-heart"></i>
                        <a href="dashboard-bookmark.html">Bookmarked</a>
                      </li>
                      <li>
                        <i class="fas fa-check-square"></i>
                        <a href="dashboard-applied.html">Applied Job</a>
                      </li>
                      <li>
                        <i class="fas fa-comment"></i>
                        <a href="dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i class="fas fa-calculator"></i>
                        <a href="dashboard-pricing.html">Pricing Plans</a>
                      </li>
                    </ul> */}
                    <CandidateDashboardSidebar dashboardLink="editProfile" />
                    <LogoutAndDelete />

                    <div
                      class="modal fade modal-delete"
                      id="modal-delete"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body">
                            <h4>
                              <i data-feather="trash-2"></i>Delete Account
                            </h4>
                            <p>
                              Are you sure! You want to delete your profile.
                              This can't be undone!
                            </p>
                            <form action="#">
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder="Enter password"
                                />
                              </div>
                              <div class="buttons">
                                <button class="delete-button">
                                  Save Update
                                </button>
                                <button class="">Cancel</button>
                              </div>
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                />
                                <label class="form-check-label">
                                  You accepts our{" "}
                                  <a href="#">Terms and Conditions</a> and{" "}
                                  <a href="#">Privacy Policy</a>
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CandidateProfile;
