import React, { useEffect } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Edit,
  Search,
  Cast,
  Layout,
  button,
  Compass,
  Briefcase,
  User,
  Edit2,
  FileText,
  Award,
  MessageCircle,
  MapPin,
  Mail,
  PhoneCall,
  ArrowDownCircle,
} from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import Thumb1 from "../../assests/images/feature/thumb-1.png";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import RegisterBox from "../../assests/images/register-box/1.png";
import RegisterBox2 from "../../assests/images/register-box/2.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";
import FindYourJob from "./../../components/FindYourJob";
const TermsAndConditions = () => {
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <NotificationAndAccount  />
            <Header /> */}

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      {/* <!-- Breadcrumb --> */}
      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Privacy Policy</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Privacy Policy
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb end */}

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="terms-container">
                <div class="terms-content-wrapper">
                  <div class="policy-tab">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="privacy-tab"
                          data-toggle="tab"
                          href="#privacy"
                          role="tab"
                          aria-controls="privacy"
                          aria-selected="true"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      {/* <li class="nav-item">
                        <a
                          class="nav-link"
                          id="terms-tab"
                          data-toggle="tab"
                          href="#terms"
                          role="tab"
                          aria-controls="terms"
                          aria-selected="false"
                        >
                          Terms of Service
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="regal-tab"
                          data-toggle="tab"
                          href="#regal"
                          role="tab"
                          aria-controls="regal"
                          aria-selected="false"
                        >
                          Regal
                        </a>
                      </li> */}
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="privacy"
                        role="tabpanel"
                        aria-labelledby="privacy-tab"
                      >
                        <h4 id="welcome">Welcome To Employ</h4>
                        <p>
                          These terms and conditions outline the rules and
                          regulations for the use of Employ Find&#39;s Website,
                          located at www.employfind.com.
                        </p>
                        <p>
                          By accessing this website, we assume you accept these
                          terms and conditions. Do not continue to use Employ if
                          you do not agree to take all of the terms and
                          conditions stated on this page.
                        </p>
                        <h5 id="termss">Cookies:</h5>
                        <p>
                          The website uses cookies to help personalize your
                          online experience. By accessing Employ , you agreed to
                          use the required cookies.
                        </p>
                        <p>
                          A cookie is a text file that is placed on your hard
                          disk by a web page server. Cookies cannot be used to
                          run programs or deliver viruses to your computer.
                          Cookies are uniquely assigned to you and can only be
                          read by a web server in the domain that issued the
                          cookie to you.
                        </p>
                        <p>
                          We may use cookies to collect, store, and track
                          information for statistical or marketing purposes to
                          operate our website. You have the ability to accept or
                          decline optional Cookies. There are some required
                          Cookies that are necessary for the operation of our
                          website. These cookies do not require your consent as
                          they always work. Please keep in mind that by
                          accepting required Cookies, you also accept
                          third-party Cookies, which might be used via
                          third-party provided services if you use such services
                          on our website, for example, a video display window
                          provided by third parties and integrated into our
                          website.
                        </p>
                        {/* <ul>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                        </ul> */}
                        <h5 id="condition">License:</h5>
                        <p>
                          Unless otherwise stated, Employ Find and/or its
                          licensors own the intellectual property rights for all
                          material on Employ . All intellectual property rights
                          are reserved. You may access this from Employ for your
                          own personal use subjected to restrictions set in
                          these terms and conditions.
                        </p>
                        <p>You must not:</p>
                        <ul>
                          <li>Copy or republish material from Employ</li>
                          <li>
                            Sell, rent, or sub-license material from Employ
                          </li>
                          <li>
                            Reproduce, duplicate or copy material from Employ
                          </li>
                          <li>Redistribute content from Employ</li>
                          <li>
                            This Agreement shall begin on the date hereof.
                          </li>
                        </ul>
                        <p>
                          Parts of this website offer users an opportunity to
                          post and exchange opinions and information in certain
                          areas of the website. Employ Find does not filter,
                          edit, publish or review Comments before their presence
                          on the website. Comments do not reflect the views and
                          opinions of Employ Find, its agents, and/or
                          affiliates. Comments reflect the views and opinions of
                          the person who posts their views and opinions. To the
                          extent permitted by applicable laws, Employ Find shall
                          not be liable for the Comments or any liability,
                          damages, or expenses caused and/or suffered as a
                          result of any use of and/or posting of and/or
                          appearance of the Comments on this website.
                        </p>
                        <p>
                          Employ Find reserves the right to monitor all Comments
                          and remove any Comments that can be considered
                          inappropriate, offensive, or causes breach of these
                          Terms and Conditions.
                        </p>
                        <p>You warrant and represent that:</p>

                        <ul>
                          <li>
                            You are entitled to post the Comments on our website
                            and have all necessary licenses and consents to do
                            so;
                          </li>
                          <li>
                            The Comments do not invade any intellectual property
                            right, including without limitation copyright,
                            patent, or trademark of any third party;
                          </li>
                          <li>
                            The Comments do not contain any defamatory,
                            libelous, offensive, indecent, or otherwise unlawful
                            material, which is an invasion of privacy.
                          </li>
                          <li>
                            The Comments do not contain any defamatory,
                            libelous, offensive, indecent, or otherwise unlawful
                            material, which is an invasion of privacy.
                          </li>
                          <li>
                            The Comments do not contain any defamatory,
                            libelous, offensive, indecent, or otherwise unlawful
                            material, which is an invasion of privacy.
                          </li>
                        </ul>

                        <h5 id="privacy-2">Hyperlinking to our Content:</h5>
                        <p>
                          The following organizations may link to our Website
                          without prior written approval:
                        </p>

                        <ul>
                          <li>Government agencies;</li>
                          <li>Search engines;</li>
                          <li>News organizations;</li>
                          <li>
                            Online directory distributors may link to our
                            Website in the same manner as they hyperlink to the
                            Websites of other listed businesses; and
                          </li>
                          <li>
                            System-wide Accredited Businesses except soliciting
                            non-profit organizations, charity shopping malls,
                            and charity fundraising groups which may not
                            hyperlink to our Web site. These organizations may
                            link to our home page, to publications, or to other
                            Website information so long as the link: (a) is not
                            in any way deceptive; (b) does not falsely imply
                            sponsorship, endorsement, or approval of the linking
                            party and its products and/or services; and (c) fits
                            within the context of the linking party&#39;s site.
                          </li>
                        </ul>

                        <p>
                          We may consider and approve other link requests from
                          the following types of organizations:
                        </p>

                        <ul>
                          <li>
                            commonly-known consumer and/or business information
                            sources;
                          </li>
                          <li>dot.com community sites;</li>
                          <li>
                            associations or other groups representing charities;
                          </li>
                          <li>online directory distributors;</li>
                          <li>internet portals;</li>
                          <li>accounting, law, and consulting firms; and</li>
                          <li>
                            educational institutions and trade associations. We
                            will approve link requests from these organizations
                            if we decide that: (a) the link would not make us
                            look unfavorably to ourselves or to our accredited
                            businesses; (b) the organization does not have any
                            negative records with us; (c) the benefit to us from
                            the visibility of the hyperlink compensates the
                            absence of Employ Find; and (d) the link is in the
                            context of general resource information. These
                            organizations may link to our home page so long as
                            the link: (a) is not in any way deceptive; (b) does
                            not falsely imply sponsorship, endorsement, or
                            approval of the linking party and its products or
                            services; and (c) fits within the context of the
                            linking party&#39;s site. If you are one of the
                            organizations listed in paragraph 2 above and are
                            interested in linking to our website, you must
                            inform us by sending an e-mail to Employ Find.
                            Please include your name, your organization name,
                            contact information as well as the URL of your site,
                            a list of any URLs from which you intend to link to
                            our Website, and a list of the URLs on our site to
                            which you would like to link. Wait 2-3 weeks for a
                            response.
                          </li>
                        </ul>

                        <p>
                          Approved organizations may hyperlink to our Website as
                          follows:
                        </p>

                        <ul>
                          <li>By use of our corporate name; or</li>
                          <li>
                            By use of the uniform resource locator being linked
                            to; or
                          </li>
                          <li>
                            Using any other description of our Website being
                            linked to that makes sense within the context and
                            format of content on the linking party&#39;s site.
                            No use of Employ Find&#39;s logo or other artwork
                            will be allowed for linking absent a trademark
                            license agreement.
                          </li>
                        </ul>

                        <h5 id="information">Content Liability:</h5>
                        <p>
                          We shall not be held responsible for any content that
                          appears on your Website. You agree to protect and
                          defend us against all claims that are raised on your
                          Website. No link(s) should appear on any Website that
                          may be interpreted as libelous, obscene, or criminal,
                          or which infringes, otherwise violates, or advocates
                          the infringement or other violation of, any third
                          party rights.
                        </p>

                        <h5 id="security">Reservation of Rights:</h5>
                        <p>
                          We reserve the right to request that you remove all
                          links or any particular link to our Website. You
                          approve to immediately remove all links to our Website
                          upon request. We also reserve the right to amend these
                          terms and conditions and its linking policy at any
                          time. By continuously linking to our Website, you
                          agree to be bound to and follow these linking terms
                          and conditions.
                        </p>

                        <h5 id="update">Removal of links from our website:</h5>
                        <p>
                          If you find any link on our Website that is offensive
                          for any reason, you are free to contact and inform us
                          at any moment. We will consider requests to remove
                          links, but we are not obligated to or so or to respond
                          to you directly.
                        </p>
                        <p>
                          We do not ensure that the information on this website
                          is correct. We do not warrant its completeness or
                          accuracy, nor do we promise to ensure that the website
                          remains available or that the material on the website
                          is kept up to date.
                        </p>
                        <h5 id="update">Disclaimer:</h5>

                        <p>
                          To the maximum extent permitted by applicable law, we
                          exclude all representations, warranties, and
                          conditions relating to our website and the use of this
                          website. Nothing in this disclaimer will:
                        </p>

                        <ul>
                          <li>
                            limit or exclude our or your liability for death or
                            personal injury;
                          </li>
                          <li>
                            limit or exclude our or your liability for fraud or
                            fraudulent misrepresentation;
                          </li>
                          <li>
                            limit any of our or your liabilities in any way that
                            is not permitted under applicable law; or
                          </li>
                          <li>
                            exclude any of our or your liabilities that may not
                            be excluded under applicable law. The limitations
                            and prohibitions of liability set in this Section
                            and elsewhere in this disclaimer: (a) are subject to
                            the preceding paragraph; and (b) govern all
                            liabilities arising under the disclaimer, including
                            liabilities arising in contract, in tort, and for
                            breach of statutory duty. As long as the website and
                            the information and services on the website are
                            provided free of charge, we will not be liable for
                            any loss or damage of any nature.
                          </li>
                        </ul>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="terms"
                        role="tabpanel"
                        aria-labelledby="terms-tab"
                      >
                        <h4 id="welcome">Welcome To Employ</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <p>
                          Arcu dui vivamus arcu felis bibendum ut tristique.
                          Enim diam vulputate ut pharetra sit amet aliquam id
                          diam. Integer feugiat scelerisque varius morbi enim
                          nunc faucibus. Et ligula ullamcorper malesuada proin
                          libero. Ac tortor dignissim convallis aenean et. Arcu
                          cursus vitae congue mauris rhoncus aenean. Pretium
                          lectus quam id leo in vitae.
                        </p>
                        <h5 id="termss">Our Terms</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <ul>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                        </ul>
                        <h5 id="condition">Conditions</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <ul>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                        </ul>
                        <h5 id="privacy-2">Your Privacy</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <h5 id="information">Informations We Collect</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <ul class="substyle">
                          <li>
                            <span>Cookies:</span> Our Website uses "Cookies" to
                            identify the areas of our Website that you have
                            visited. A Cookie is a small piece of data stored on
                            your computer or mobile device by your web browser.
                            We use Cookies to personalize the Content that you
                            see on our Website. Most web browsers can be set to
                            disable the use of Cookies. However, if you disable
                            Cookies, you may not be able to access functionality
                            on our Website correctly or at all. We never place
                            Personally Identifiable Information in Cookies.
                          </li>
                          <li>
                            <span>Geographical Information:</span>Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Mauris pharetra et ultrices neque ornare
                            aenean. Libero nunc consequat interdum varius sit.
                            Mattis vulputate enim nulla aliquet. Placerat in
                            egestas erat imperdiet. Vitae elementum curabitur
                            vitae nunc. Massa vitae tortor condimentum lacinia
                            quis. Adipiscing enim eu turpis egestas pretium
                            aenean pharetra magna. Nisl suscipit adipiscing
                            bibendum est ultricies integer quis auctor elit.
                          </li>
                          <li>
                            <span>Log Data:</span> Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Mauris
                            pharetra et ultrices neque ornare aenean. Libero
                            nunc consequat interdum varius sit. Mattis vulputate
                            enim nulla aliquet. Placerat in egestas erat
                            imperdiet. Vitae elementum curabitur vitae nunc.
                            Massa vitae tortor condimentum lacinia quis.
                            Adipiscing enim eu turpis egestas pretium aenean
                            pharetra magna. Nisl suscipit adipiscing bibendum
                            est ultricies integer quis auctor elit.
                          </li>
                        </ul>
                        <h5 id="security">Security</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <h5 id="update">Privacy Policy Update</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="regal"
                        role="tabpanel"
                        aria-labelledby="regal-tab"
                      >
                        <h4 id="welcome">Welcome To Employ</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <p>
                          Arcu dui vivamus arcu felis bibendum ut tristique.
                          Enim diam vulputate ut pharetra sit amet aliquam id
                          diam. Integer feugiat scelerisque varius morbi enim
                          nunc faucibus. Et ligula ullamcorper malesuada proin
                          libero. Ac tortor dignissim convallis aenean et. Arcu
                          cursus vitae congue mauris rhoncus aenean. Pretium
                          lectus quam id leo in vitae.
                        </p>
                        <h5 id="termss">Our Terms</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <ul>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                        </ul>
                        <h5 id="condition">Conditions</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <ul>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                          <li>
                            Pretium aenean pharetra magna. Nisl suscipit
                            adipiscing
                          </li>
                        </ul>
                        <h5 id="privacy-2">Your Privacy</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <h5 id="information">Informations We Collect</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <ul class="substyle">
                          <li>
                            <span>Cookies:</span> Our Website uses "Cookies" to
                            identify the areas of our Website that you have
                            visited. A Cookie is a small piece of data stored on
                            your computer or mobile device by your web browser.
                            We use Cookies to personalize the Content that you
                            see on our Website. Most web browsers can be set to
                            disable the use of Cookies. However, if you disable
                            Cookies, you may not be able to access functionality
                            on our Website correctly or at all. We never place
                            Personally Identifiable Information in Cookies.
                          </li>
                          <li>
                            <span>Geographical Information:</span>Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Mauris pharetra et ultrices neque ornare
                            aenean. Libero nunc consequat interdum varius sit.
                            Mattis vulputate enim nulla aliquet. Placerat in
                            egestas erat imperdiet. Vitae elementum curabitur
                            vitae nunc. Massa vitae tortor condimentum lacinia
                            quis. Adipiscing enim eu turpis egestas pretium
                            aenean pharetra magna. Nisl suscipit adipiscing
                            bibendum est ultricies integer quis auctor elit.
                          </li>
                          <li>
                            <span>Log Data:</span> Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Mauris
                            pharetra et ultrices neque ornare aenean. Libero
                            nunc consequat interdum varius sit. Mattis vulputate
                            enim nulla aliquet. Placerat in egestas erat
                            imperdiet. Vitae elementum curabitur vitae nunc.
                            Massa vitae tortor condimentum lacinia quis.
                            Adipiscing enim eu turpis egestas pretium aenean
                            pharetra magna. Nisl suscipit adipiscing bibendum
                            est ultricies integer quis auctor elit.
                          </li>
                        </ul>
                        <h5 id="security">Security</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <h5 id="update">Privacy Policy Update</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Mauris pharetra et ultrices neque
                          ornare aenean. Libero nunc consequat interdum varius
                          sit. Mattis vulputate enim nulla aliquet. Placerat in
                          egestas erat imperdiet. Vitae elementum curabitur
                          vitae nunc. Massa vitae tortor condimentum lacinia
                          quis. Adipiscing enim eu turpis egestas pretium aenean
                          pharetra magna. Nisl suscipit adipiscing bibendum est
                          ultricies integer quis auctor elit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="terms-sidebar">
                  <h5>
                    <i>
                      <ArrowDownCircle />
                    </i>
                    Navigation
                  </h5>
                  <ul class="sidebar-menu">
                    <li>
                      <a href="#welcome">Welcome Text</a>
                    </li>
                    <li>
                      <a href="#termss">Cookies</a>
                    </li>
                    <li>
                      <a href="#condition">License</a>
                    </li>
                    <li>
                      <a href="#privacy-2">Hyperlinking to our Content</a>
                    </li>
                    <li>
                      <a href="#information">Content Liability</a>
                    </li>
                    <li>
                      <a href="#security">Reservation of Rights:</a>
                    </li>
                    <li>
                      <a href="#update">Removal of links from our website</a>
                    </li>
                  </ul>
                  <div class="signin-option">
                    <p>
                      Have an Account ? Before submit job you need to sign in !
                    </p>
                    <div class="buttons">
                      <a
                        href="#"
                        class="signin"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                        style={{ background: "#023b92" }}
                      >
                        Sign in
                      </a>
                      <a
                        href="#"
                        class="register"
                        data-toggle="modal"
                        data-target="#exampleModalLong2"
                      >
                        Register
                      </a>
                    </div>

                    {/* <!-- Modal --> */}
                    <div class="account-entry">
                      <div
                        class="modal fade"
                        id="exampleModalLong"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">
                                <i>
                                  <User />
                                </i>
                                Login
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <form action="#">
                                <div class="form-group">
                                  <input
                                    type="email"
                                    placeholder="Email Address"
                                    class="form-control"
                                  />
                                </div>
                                <div class="form-group">
                                  <input
                                    type="password"
                                    placeholder="Password"
                                    class="form-control"
                                  />
                                </div>
                                <div class="more-option">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="defaultCheck1"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="defaultCheck1"
                                    >
                                      Remember Me
                                    </label>
                                  </div>
                                  <a href="#">Forget Password?</a>
                                </div>
                                <button class="button primary-bg btn-block">
                                  Login
                                </button>
                              </form>
                              <div class="shortcut-login">
                                <span>Or connect with</span>
                                <div class="buttons">
                                  <a href="#" class="facebook">
                                    <i class="fab fa-facebook-f"></i>Facebook
                                  </a>
                                  <a href="#" class="google">
                                    <i class="fab fa-google"></i>Google
                                  </a>
                                </div>
                                <p>
                                  Don't have an account?{" "}
                                  <a href="#">Register</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="modal fade"
                        id="exampleModalLong2"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">
                                <i>
                                  <Edit2 />
                                </i>
                                Registration
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="account-type">
                                <a href="#" class="candidate-acc active">
                                  <i>
                                    <User />
                                  </i>
                                  Candidate
                                </a>
                                <a href="#" class="employer-acc">
                                  <i>
                                    <Briefcase />
                                  </i>
                                  Employer
                                </a>
                              </div>
                              <form action="#">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    placeholder="Username"
                                    class="form-control"
                                  />
                                </div>
                                <div class="form-group">
                                  <input
                                    type="email"
                                    placeholder="Email Address"
                                    class="form-control"
                                  />
                                </div>
                                <div class="form-group">
                                  <input
                                    type="password"
                                    placeholder="Password"
                                    class="form-control"
                                  />
                                </div>
                                <div class="more-option terms">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id="defaultCheck2"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="defaultCheck2"
                                    >
                                      I accept the{" "}
                                      <a href="#">terms & conditions</a>
                                    </label>
                                  </div>
                                </div>
                                <button class="button primary-bg btn-block">
                                  Register
                                </button>
                              </form>
                              <div class="shortcut-login">
                                <span>Or connect with</span>
                                <div class="buttons">
                                  <a href="#" class="facebook">
                                    <i class="fab fa-facebook-f"></i>Facebook
                                  </a>
                                  <a href="#" class="google">
                                    <i class="fab fa-google"></i>Google
                                  </a>
                                </div>
                                <p>
                                  Already have an account? <a href="#">Login</a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />

      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
