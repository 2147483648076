import React, { useState, useEffect } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Search,
  Briefcase,
  MapPin,
  Clock,
  Heart,
  Edit2,
  Menu,
  Grid,
  AlignLeft,
  Video,
  Eye,
  Link2,
  PlusCircle,
  CheckCircle,
  ArrowDownCircle,
  UserCheck,
  User,
  Trash2,
} from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
  EmployerDashboardSidebar,
} from "../../components";
import { v4 as uuidv4 } from "uuid";
import { Link, useHistory } from "react-router-dom";
import EditorTable from "./../../components/Editor/Editor";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/employer/thumb-1.png";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase";
import fire from "../../database";
import FindYourJob from "../../components/FindYourJob";
import JobDetails from "../JobDetails";
import { parseJSON } from "jquery";
import LogoutAndDelete from "../../components/logoutAndDelete";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import Loader from "./../../components/Loader/index";
import professions from "../JobListing/professions.json";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isUser = { isAuthenticated: false };
const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

{
  /* <option>Accounting / Finance</option>
<option>Health Care</option>
<option>Garments / Textile</option>
<option>Telecommunication</option> */
}

const Categories = [
  "Select Category",
  "Accounting / Finance",
  "Health Care",
  "Garments / Textile",
  "Telecommunication",
];

const PostJob = (props) => {
  console.log(props, "PRRRRRRRRRRRRRRRRRRR");

  const [userDetails, setUserDetails] = useState({});
  const [aboutUser, setAboutUser] = useState({});
  const [allCountries, setAllCountries] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v2/all")
      .then((res) => {
        setAllCountries(res.data);
        setIsLoading(false);
        console.log(res, "Ressssssssssssssssssssssssssss");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "errrrrrrrr");
      });
  }, []);

  console.log(allCountries, "allCountriesallCountries");

  isUserAuthenticate();
  const [open, setOpen] = React.useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setAboutUser(userData);

    // alert()
    firebase
      .database()
      .ref(`profile/employer/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        if (snapshot.val() == null) {
          setAlertData({
            open: true,
            severity: "error",
            msg: "Please Create Your Profile First To Post a Job",
          });
          setTimeout(() => {
            props.history.push("/EmployerEditProfile");
          }, 2000);
        }
        setUserDetails(snapshot.val());
      });
  }, []);

  const [jobDetails, setJobDetails] = useState({
    title: "",
    category: "",
    location: "",
    type: "",
    exp: "",
    salaryRange: "",
    Qualification: "",
    gender: "",
    vacancy: "",
    date: "",
    jobDes: "",
    respo: "",
    education: "",
    benefits: "",
    yourcountry: "",
    yourstate: "",
    yourcity: "",
    zipcode: "",
    yourlocation: "",
    companyName: "",
    webAddress: "",
    companyProfile: "",
    Selectpackage: "",
    applicant: [],
    jobId: uuidv4(),
    uid: firebase?.auth()?.currentUser?.uid,
    publishDate: new Date().toLocaleString(),
  });
  const [terms, setTerms] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const [countries, setCountries] = useState([]);

  // useEffect(() => {
  //   axios.post("https://countriesnow.space/api/v0.1/countries/cities", {
  //     country: "United States"
  //   })
  //     .then(res => {
  //       setCountries(res.data.data)
  //     })
  //     .catch(err => {
  //       console.log(err, "ERRRRRRRRRRRRRRRRRRRRRRR");
  //     })
  // }, [jobDetails.yourcountry])

  console.log(countries, "COUNTTTTTTTTTTTTTTTTTTTTTTTT");

  const history = useHistory();

  const submitForm = (event) => {
    let id = uuidv4();
    console.log("idididid=>>>>", id);
    const {
      Qualification,
      companyProfile,
      companyName,
      webAddress,
      yourlocation,
      zipcode,
      yourcity,
      education,
      gender,
      location,
      Selectpackage,
      benefits,
      category,
      date,
      exp,
      jobDes,
      respo,
      salaryRange,
      title,
      type,
      vacancy,
      yourstate,
      yourcountry,
    } = jobDetails;
    // setOpen(false);
    // setAlertData({
    //   open: true,
    //   severity: "success",
    //   msg: "Applied Successfully",
    // });

    let stateValue = yourstate.split(",");
    console.log(stateValue, "stateValuestateValue");
    let countryVal = yourcountry.split(",");
    let locationRegex = /([A-Za-z]+(?: [A-Za-z]+)*),? ([A-Za-z]{2})/;

    event.preventDefault();

    if (!terms) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Agree To Terms And Condition",
      });
    } else if (
      (!Qualification,
      !companyProfile,
      !companyName,
      !webAddress,
      !yourlocation,
      !zipcode,
      !yourcity,
      !education,
      !gender,
      !location,
      !Selectpackage,
      !benefits,
      !category,
      !date,
      !jobDes,
      !respo,
      !salaryRange,
      !title,
      !type,
      !vacancy,
      !yourstate,
      !yourcountry)
    ) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Add Every Detail",
      });
    } else if (!locationRegex.test(location)) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please enter City and Country with correct format",
      });
    } else if (!jobDes) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Add Job Description",
      });
    } else if (!respo) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Add Job Responsibilites",
      });
    } else if (!education) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Add Job Education",
      });
    } else if (!benefits) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Add Job Benefits",
      });
    } else {
      console.log("job details", jobDetails, userDetails, stateValue[1]);
      let total = 0;

      setIsLoading(true);

      console.log(jobDetails, "jobDetailsjobDetailsjobDetails");

      firebase
        .database()
        .ref(`jobs/${firebase.auth().currentUser.uid}`)
        .push({
          ...jobDetails,
          userDetails,
          yourstate: stateValue[1],
          yourcountry: countryVal[1],
          // Applicant: []
        })
        .then((res) => {
          firebase
            .database()
            .ref(`jobsCategory/${jobDetails.category}`)
            .push({
              ...jobDetails,
              userDetails,
              yourstate: stateValue[1],
              yourcountry: countryVal[1],
            })
            .then((res) => {
              firebase
                .database()
                .ref(`notifications/${firebase.auth().currentUser.uid}`)
                .push({
                  title: `You Posted a job for ${title} `,
                  time: Date().toLocaleString(),
                });

              firebase
                .database()
                .ref(`jobAccordingUid/${firebase.auth().currentUser.uid}`)
                .push({
                  title: `You Posted a job for ${title} `,
                  time: Date().toLocaleString(),
                })
                .then((res) => {
                  console.log(
                    firebase.auth().currentUser.uid,
                    "VVVVVVVVVVVasdasds"
                  );
                  firebase
                    .database()
                    .ref(`profile/employer/${firebase.auth().currentUser.uid}`)
                    .on("value", (snapshot) => {
                      console.log(
                        snapshot.val(),
                        firebase.auth().currentUser.uid,
                        "VVVVVVVVVVV"
                      );
                      total = snapshot.val().totalJobs;
                      total = total + 1;
                    });
                })
                .then((res) => {
                  firebase
                    .database()
                    .ref(`profile/employer/${firebase.auth().currentUser.uid}`)
                    .update({
                      totalJobs: total,
                    });
                  // .then((res) => {
                  //   window.location.replace("/");
                  // });
                  setIsLoading(false);
                  setAlertData({
                    open: true,
                    severity: "success",
                    msg: "Job Posted Successfully",
                  });
                  setTimeout(() => {
                    window.location.replace("/");
                  }, 2000);
                });
              // setJobDetails({
              //   title: "",
              //   category: "2",
              //   location: "",
              //   type: "",
              //   exp: "",
              //   salaryRange: "",
              //   Qualification: "",
              //   gender: "",
              //   vacancy: "",
              //   date: "",
              //   jobDes: "",
              //   respo: "",
              //   education: "",
              //   benefits: "",
              //   yourcountry: "",
              //   yourcity: "",
              //   zipcode: "",
              //   yourlocation: "",
              //   companyName: "",
              //   webAddress: "",
              //   companyProfile: "",
              //   Selectpackage: "",
              //   applicant: [],
              //   jobId: uuidv4(),
              // });
            });
        });
    }
  };

  const [allStates, setAllState] = useState([]);
  const [allcities, setAllCities] = useState([]);

  useEffect(() => {
    let countryVal = jobDetails.yourcountry.split(",");
    if (jobDetails.yourcountry != "" && jobDetails.yourcountry != "Country") {
      let res = State.getStatesOfCountry(
        // jobDetails.yourcountry
        countryVal[0]
      );
      console.log(res, "RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
      setAllState(res);
    } else {
      setAllState([]);
      setAllCities([]);
    }
  }, [jobDetails.yourcountry]);

  useEffect(() => {
    let selectedState = jobDetails.yourstate.split(",");
    let countryVal = jobDetails.yourcountry.split(",");
    if (jobDetails.yourstate != "" && jobDetails.yourstate != "State") {
      let res = City.getCitiesOfState(countryVal[0], selectedState[0]);

      setAllCities(res);
    } else {
      setAllCities([]);
    }
  }, [jobDetails.yourstate]);

  const onChange = (e) => {
    console.log(e.target.value, "valllllllllllllllll");

    setJobDetails({
      ...jobDetails,
      [e.target.name]: e.target.value,
    });
  };

  const getAboutContent = (content, type) => {
    // if (type == "aboutYou") {
    setJobDetails({
      ...jobDetails,
      [type]: content,
    });
    // }
    console.log("content=>>>>>>>>>>>", content);
  };

  console.log("jobDetails=>>>>", jobDetails);

  const [categoryAdmin, setCategoryAdmin] = useState("");

  useEffect(() => {
    firebase
      .database()
      .ref(`category`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setCategoryAdmin(data);
      });
  }, []);

  const Data = Object.keys(categoryAdmin);
  console.log(jobDetails, "CATEGORYYYYYYYYYYYYYYYYY");

  return (
    <>
      <Loader isloading={isLoading} />
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <div>
        {/* <NotificationAndAccount  />
            <Header /> */}

        <header className="header-2">
          <div className="container">
            <div className="row">
              <div className="col">
                <NotificationAndAccount />
                <Header />
              </div>
            </div>
          </div>
        </header>

        {/* <!-- Breadcrumb --> */}
        <div class="alice-bg padding-top-70 padding-bottom-70">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="breadcrumb-area">
                  <h1>Employer Dashboard</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a onClick={() => props.history.push("/")}>Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Employer Dashboard
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div class="col-md-6">
                {/* <div class="breadcrumb-form">
                  <form action="#">
                    <input type="text" placeholder="Enter Keywords" />
                    <button>
                      <i>
                        <Search />
                      </i>
                    </button>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Breadcrumb End --> */}
        <div class="alice-bg section-padding-bottom">
          <div class="container no-gliters">
            <div class="row no-gliters">
              <div class="col">
                <div class="dashboard-container">
                  <div class="dashboard-content-wrapper">
                    <form action="#" class="dashboard-form job-post-form">
                      <div class="dashboard-section basic-info-input">
                        <h4>
                          <i>
                            <UserCheck />
                          </i>
                          Post A Job
                        </h4>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">
                            Job Title
                          </label>
                          <div class="col-md-9">
                            <input
                              value={jobDetails.title}
                              onChange={onChange}
                              type="text"
                              name="title"
                              class="form-control"
                              placeholder="Your job title here"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-md-3 col-form-label">
                            Job Summery
                          </label>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <select
                                    id="category"
                                    class="form-control"
                                    value={jobDetails.category}
                                    onChange={onChange}
                                    name="category"
                                  >
                                    <option value={"0"}>
                                      {"Select Category"}
                                    </option>
                                    {/* {Data.map((data, index) => { */}
                                    {professions.Sheet.map((data, index) => {
                                      return (
                                        <option
                                          value={data.Professions}
                                          // value={categoryAdmin[data].category}
                                        >
                                          {data.Professions}
                                        </option>
                                      );
                                    })}
                                    {/* <option value={'1'} >{'Accounting / Finance'}</option>
                                    <option value={'2'} >{'Health Care'}</option>
                                    <option value={'3'} >{'Garments / Textile'}</option>
                                    <option value={'4'} >{'Telecommunication'}</option> */}
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  {/* <input
                                    value={jobDetails.location}
                                    onChange={onChange}
                                    type="text"
                                    name="location"
                                    class="form-control"
                                    placeholder="Karachi, Pakistan"
                                  /> */}

                                  <select
                                    class="form-control"
                                    value={jobDetails.yourcountry}
                                    onChange={onChange}
                                    name="yourcountry"
                                  >
                                    {allCountries.length > 0 &&
                                      allCountries.map((item) => {
                                        return (
                                          <option
                                            value={`${item.alpha2Code},${item.name}`}
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    {/* <option>United States</option>
                                    <option>Canada</option> */}
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    id="exampleFormControlSelect11"
                                    value={jobDetails.yourstate}
                                    onChange={onChange}
                                    name="yourstate"
                                  >
                                    <option>State</option>
                                    {allStates.map((res) => {
                                      return (
                                        <option
                                          value={`${res.isoCode},${res.name}`}
                                        >{`${res.name},${res.isoCode}`}</option>
                                      );
                                    })}
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    id="exampleFormControlSelect11"
                                    value={jobDetails.yourcity}
                                    onChange={onChange}
                                    name="yourcity"
                                  >
                                    <option>City</option>
                                    {allcities.map((res) => {
                                      return (
                                        <option value={res.name}>
                                          {res.name}
                                        </option>
                                      );
                                    })}
                                    {/* <option>Islamabad</option>
                                    <option>New york</option> */}
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    value={jobDetails.type}
                                    onChange={onChange}
                                    name="type"
                                  >
                                    <option>Job Type</option>
                                    <option>Part Time</option>
                                    <option>Full Time</option>
                                    <option>Temperory</option>
                                    <option>Permanent</option>
                                    <option>Freelance</option>
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    value={jobDetails.exp}
                                    onChange={onChange}
                                    name="exp"
                                  >
                                    <option>Experience (Optional)</option>
                                    <option>Less than 1 Year</option>
                                    <option>2 Year</option>
                                    <option>3 Year</option>
                                    <option>4 Year</option>
                                    <option>Over 5 Year</option>
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    value={jobDetails.salaryRange}
                                    onChange={onChange}
                                    type="text"
                                    name="salaryRange"
                                    class="form-control"
                                    placeholder="Salary Range"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    value={jobDetails.Qualification}
                                    onChange={onChange}
                                    name="Qualification"
                                  >
                                    <option>Qualification</option>
                                    <option>Elementary School</option>
                                    <option>Middle School</option>
                                    <option>Diploma</option>
                                    <option>High School</option>
                                    <option>Undergraduate</option>
                                    <option>Graduate</option>
                                    <option>Master's</option>
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <select
                                    class="form-control"
                                    value={jobDetails.gender}
                                    onChange={onChange}
                                    name="gender"
                                  >
                                    <option>Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                  </select>
                                  <i class="fa fa-caret-down"></i>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    value={jobDetails.vacancy}
                                    onChange={onChange}
                                    name="vacancy"
                                    class="form-control"
                                    placeholder="Vacancy"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group datepicker">
                                  <label style={{ fontWeight: "bold" }}>
                                    Deadline
                                  </label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    value={jobDetails.date}
                                    name="date"
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" style={{ marginTop: 10 }}>
                          <label class="col-md-3 col-form-label">
                            Job Description
                          </label>
                          <div className="col-md-9">
                            <EditorTable
                              getAboutContent={getAboutContent}
                              type="jobDes"
                            />
                          </div>

                          {/* <div class="col-md-9">
                            <textarea
                              id="mytextarea"
                              className="tinymce-editor tinymce-editor-1"
                              placeholder="Description text here"
                              onChange={onChange}
                              value={jobDetails.jobDes}
                              name="jobDes"
                            ></textarea>
                          </div> */}
                        </div>
                        <div class="row" style={{ marginTop: 10 }}>
                          <label class="col-md-3 col-form-label">
                            Responsibilities
                          </label>
                          <div className="col-md-9">
                            <EditorTable
                              getAboutContent={getAboutContent}
                              type="respo"
                            />
                          </div>
                          {/* <div class="col-md-9">
                            <textarea
                              id="mytextarea2"
                              class="tinymce-editor tinymce-editor-2"
                              placeholder="Responsibilities (Optional)"
                              onChange={onChange}
                              value={jobDetails.respo}
                              name="respo"
                            ></textarea>
                          </div> */}
                        </div>
                        <div class="row" style={{ marginTop: 10 }}>
                          <label class="col-md-3 col-form-label">
                            Education
                          </label>
                          <div className="col-md-9">
                            <EditorTable
                              getAboutContent={getAboutContent}
                              type="education"
                            />
                          </div>
                          {/* <div class="col-md-9">
                            <textarea
                              id="mytextarea3"
                              class="tinymce-editor tinymce-editor-2"
                              placeholder="Education (Optional)"
                              onChange={onChange}
                              value={jobDetails.education}
                              name="education"
                            ></textarea>
                          </div> */}
                        </div>
                        <div class="row" style={{ marginTop: 10 }}>
                          <label class="col-md-3 col-form-label">
                            Other Benefits
                          </label>
                          <div className="col-md-9">
                            <EditorTable
                              getAboutContent={getAboutContent}
                              type="benefits"
                            />
                          </div>
                          {/* <div class="col-md-9">
                            <textarea
                              id="mytextarea4"
                              class="tinymce-editor tinymce-editor-2"
                              placeholder="Description text here"
                              onChange={onChange}
                              value={jobDetails.benefits}
                              name="benefits"
                            ></textarea>
                          </div> */}
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label">
                            Your Location
                          </label>

                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    value={jobDetails.location}
                                    onChange={onChange}
                                    type="text"
                                    name="location"
                                    class="form-control"
                                    placeholder="Karachi, Pakistan"
                                  />
                                </div>

                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Zip Code"
                                    value={jobDetails.zipcode}
                                    onChange={onChange}
                                    name="zipcode"
                                  />
                                </div>
                                {/* <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Your Location"
                                    value={jobDetails.yourlocation}
                                    onChange={onChange}
                                    name="yourlocation"
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-md-3 col-form-label">
                            About Company
                          </label>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    value={jobDetails.companyName}
                                    onChange={onChange}
                                    name="companyName"
                                    type="text"
                                    class="form-control"
                                    placeholder="Company Name"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    value={jobDetails.webAddress}
                                    onChange={onChange}
                                    name="webAddress"
                                    type="text"
                                    class="form-control"
                                    placeholder="Web Address"
                                  />
                                </div>
                              </div>
                              {/* <div class="col-md-12">
                                <div class="form-group">
                                  <textarea
                                    value={jobDetails.companyProfile}
                                    onChange={onChange}
                                    name="companyProfile"
                                    class="form-control"
                                    placeholder="Company Profile"
                                  ></textarea>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div class="row">
                          <label class="col-md-3 col-form-label">
                            Select Package
                          </label>
                          <div class="col-md-9">
                            <div class="package-select">
                              <div class="package-select-inputs">

                                <div class="form-group">
                                  <input
                                    class="custom-radio"
                                    type="radio"
                                    id="radio_1"
                                    name="Selectpackage"
                                    value={jobDetails.Selectpackage}
                                    checked
                                    onChange={onChange}
                                  />
                                  <label for="radio_1">
                                    <span class="dot"></span>{" "}
                                    <span class="package-type">Free</span>{" "}
                                    RS:0.00
                                  </label>
                                </div>
                                <div class="form-group">
                                  <input
                                    class="custom-radio"
                                    type="radio"
                                    id="radio_2"
                                    name="my-radio"
                                    value="1"
                                  />
                                  <label for="radio_2">
                                    <span class="dot"></span>{" "}
                                    <span class="package-type">Stater</span>{" "}
                                    RS:22.00
                                  </label>
                                </div>
                                <div class="form-group">
                                  <input
                                    class="custom-radio"
                                    type="radio"
                                    id="radio_3"
                                    name="my-radio"
                                    value="1"
                                  />
                                  <label for="radio_3">
                                    <span class="dot"></span>{" "}
                                    <span class="package-type">Advance</span>{" "}
                                    RS:44.00
                                  </label>
                                </div>
                              </div>
                              
                              <div class="payment-method">
                                <a href="#" class="credit active">
                                  <i class="fas fa-credit-card"></i>Credit Card
                                </a>
                                <a href="#" class="paypal">
                                  <i class="fab fa-cc-paypal"></i>PayPal
                                </a>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div class="row">
                          <div class="col-md-9 offset-md-3">
                            <div class="form-group terms">
                              <input
                                class="custom-radio"
                                type="checkbox"
                                id="radio-4"
                                name="termsandcondition"
                                value={terms}
                                onChange={(e) => setTerms(!terms)}
                              />

                              <label for="radio-4">
                                <span class="dot"></span> You accepts our{" "}
                                <Link to="/TermsAndConditions">
                                  Terms and Conditions
                                </Link>
                                {/* and{" "} */}
                                {/* <a href="#">Privacy Policy</a> */}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-md-3 col-form-label"></label>
                          <div class="col-md-9">
                            <button onClick={submitForm} class="button">
                              Post Your Job
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="dashboard-sidebar">
                    <div class="company-info">
                      <div class="thumb">
                        <img
                          src={userDetails?.avatar}
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                      <div class="company-body">
                        <h5>{aboutUser?.name}</h5>
                        <span>@username</span>
                      </div>
                    </div>

                    <div class="dashboard-menu">
                      {/* <ul>
                        <li>
                          <i class="fas fa-home"></i>
                          <a href="employer-dashboard.html">Dashboard</a>
                        </li>
                        <li>
                          <i class="fas fa-user"></i>
                          <a href="employer-dashboard-edit-profile.html">
                            Edit Profile
                          </a>
                        </li>
                        <li>
                          <i class="fas fa-briefcase"></i>
                          <a href="employer-dashboard-manage-job.html">
                            Manage Jobs
                          </a>
                        </li>
                        <li>
                          <i class="fas fa-users"></i>
                          <a href="employer-dashboard-manage-candidate.html">
                            Manage Candidates
                          </a>
                        </li>
                        <li>
                          <i class="fas fa-heart"></i>
                          <a href="#">Shortlisted Resumes</a>
                        </li>
                        <li class="active">
                          <i class="fas fa-plus-square"></i>
                          <a href="employer-dashboard-post-job.html">
                            Post New Job
                          </a>
                        </li>
                        <li>
                          <i class="fas fa-comment"></i>
                          <a href="employer-dashboard-message.html">Message</a>
                        </li>
                        <li>
                          <i class="fas fa-calculator"></i>
                          <a href="employer-dashboard-pricing.html">
                            Pricing Plans
                          </a>
                        </li>
                      </ul> */}
                      <EmployerDashboardSidebar dashboardLink="postJob" />
                      <LogoutAndDelete />

                      <div
                        class="modal fade modal-delete"
                        id="modal-delete"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-body">
                              <h4>
                                <i>
                                  <Trash2 />
                                </i>
                                Delete Account
                              </h4>
                              <p>
                                Are you sure! You want to delete your profile.
                                This can't be undone!
                              </p>
                              <form action="#">
                                <div class="form-group">
                                  <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter password"
                                  />
                                </div>
                                <div class="buttons">
                                  <button class="delete-button">
                                    Save Update
                                  </button>
                                  <button class="">Cancel</button>
                                </div>
                                <div class="form-group form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    checked=""
                                  />
                                  <label class="form-check-label">
                                    You accepts our{" "}
                                    <a href="#">Terms and Conditions</a> and{" "}
                                    <a href="#">Privacy Policy</a>
                                  </label>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FindYourJob />
        <SocialShare />
        <NewsLetterFooter />
        <Footer />
      </div>
    </>
  );
};

export default PostJob;
