import React from "react";
import { Link } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import GroupIcon from "@material-ui/icons/Group";
import BallotIcon from "@material-ui/icons/Ballot";

const CandidateDashboardSidebar = ({ dashboardLink }) => {
  return (
    <ul>
      <li className={dashboardLink === "dashboard" ? "active" : ""}>
        <i className="fas fa-home"></i>
        <Link to="/CandidateDashboard">Dashboard</Link>
      </li>
      <li className={dashboardLink === "editProfile" ? "active" : ""}>
        <i className="fas fa-user"></i>
        <Link to="/CandidateProfile">Edit Profile</Link>
      </li>
      {/* <li className={dashboardLink === "candidateResume" ? "active" : ""}>
        <i className="fas fa-file-alt"></i>
        <Link to="/CandidateResume">Resume</Link>
      </li> */}
      <li className={dashboardLink === "editResume" ? "active" : ""}>
        <i className="fas fa-edit"></i>
        <Link to="/CandidateEditResume">Edit Resume</Link>
      </li>
      <li className={dashboardLink === "bookmarked" ? "active" : ""}>
        <i className="fas fa-heart"></i>
        <Link to="/CandidateBookmark">Bookmarked</Link>
      </li>
      <li className={dashboardLink === "applied" ? "active" : ""}>
        <i className="fas fa-check-square"></i>
        <Link to="/CandidateApplied">Applied Job</Link>
      </li>
      <li className={dashboardLink === "message" ? "active" : ""}>
        <i className="fas fa-comment"></i>
        <Link to="/CandidateMessage">Message</Link>
      </li>
      <li>
        <i className="fas fa-calculator"></i>
        <Link to={{ pathname: "/CheckOut", state: "skip" }}>Pricing Plans</Link>
      </li>
    </ul>
  );
};

export default CandidateDashboardSidebar;
