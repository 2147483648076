import React, { useEffect, useState, useRef } from "react";
import {
  Footer,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import CandidateDashboardSidebar from "../../components/CandidateDashboardSidebar/CandidateDashboardSidebar";
import FindYourJob from "../../components/FindYourJob";
import EditorTable from "./../../components/Editor/Editor";
import Header from "./../../components/Header/index";
import CreateIcon from "@material-ui/icons/Create";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { makeStyles } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LinkIcon from "@material-ui/icons/Link";
import thumb1 from "../../assests/images/portfolio/thumb-1.jpg";
import Slider from "react-slick";
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 70,
  },
  skillModal: {
    "&.MuiDialog-root .MuiDialog-container .MuiPaper-root": {
      width: "100%",
      maxWidth: "900px",
      margin: 0,
      padding: "20px",
    },
  },
  headSkill: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "30px 0",
  },
  head1: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
  },
  addMore: {
    fontSize: "1.3rem",
    fontWeight: 500,
    padding: "3px 15px",
    // marginRight: 0,
    border: 0,
    borderRadius: "3px",
    background: "rgba(36, 109, 248, 0.15)",
    color: "#246df8",
    "&:hover": {
      background: "#246df8",
      color: "#fff",
    },
  },
  skillInput: {
    height: "50px",
    // s
    fontSize: "1.8rem",
  },
  saveBtn: {
    boxShadow: "none",
    outline: "none",
    fontWeight: 500,
    fontSize: "1.4rem",
    border: 0,
    padding: "15px 30px",
    cursor: "pointer",
    borderRadius: "3px",
    marginRight: "10px",
    marginBottom: "10px",
    background: "#246df8",
    color: "#fff",
  },
  cancelBtn: {
    boxShadow: "none",
    outline: "none",
    fontWeight: 500,
    fontSize: "1.4rem",
    border: 0,
    padding: "15px 30px",
    cursor: "pointer",
    borderRadius: "3px",
    marginRight: "10px",
    marginBottom: "10px",
    background: "#246df826",
    transition: "all .3s ease",
    color: "#246df8",
    "&:hover": {
      background: "#246df8",
      color: "#fff",
      transition: "all .3s ease",
    },
  },
  uploadBox: {
    height: "34px",
    "&:hover": {
      background: "#246df8e6",
    },
  },

  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const sliderContent = [
  {
    image: thumb1,
    view: "",
    link: "",
  },
  {
    image: thumb1,
    view: "",
    link: "",
  },
  {
    image: thumb1,
    view: "",
    link: "",
  },
  {
    image: thumb1,
    view: "",
    link: "",
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CandidateEditResume = () => {
  const classes = useStyles();
  const [openSkillDialog, setOpenSkillDialog] = useState(false);
  const [openSocialDialog, setOpenSocialDialog] = useState(false);
  const [openAboutMeDialog, setOpeAboutMeDialog] = useState(false);
  const [openEducationDialog, setOpeEducationDialog] = useState(false);
  const [openExpDialog, setOpeExpDialog] = useState(false);
  const [openProSkillDialog, setOpenProSkillDialog] = useState(false);
  const [openQualificationDialog, setOpenQualificationDialog] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [openPortfolioDialog, setOpenPortfolioDialog] = useState(false);
  const [counts, setCounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [aboutUser, setAboutUser] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [isResumeUploaded, setIsResumeUploaded] = useState(true);
  const [params, setParams] = useState({
    yourSkills: "",
    about: "",
    category: "",
    loaction: "",
    gender: "",
    type: "",
    salaryRange: "",
    experience: "",
    qualification: "",
    age: "",
    education: [],
    workExperience: [],
    fullName: "",
    fatherName: "",
    motherName: "",
    dob: "",
    nationality: "",
    address: "",
    portfolio: [],
    skillList: [],
    specialQualification: "",
  });

  const yourSkillsRef = useRef();
  const genderRef = useRef();
  const addressRef = useRef();
  const fatherNameRef = useRef();
  const motherNameRef = useRef();
  const nationalityRef = useRef();
  const ageRef = useRef();
  const fullNameRef = useRef();

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setAboutUser(userData);
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/candidate/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        setUserDetails(snapshot.val());
      });
  }, []);

  useEffect(() => {
    firebase.auth().currentUser?.uid &&
      firebase
        .database()
        .ref(`resumes/${firebase.auth().currentUser.uid}`)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            setParams({
              ...params,
              yourSkills: snapshot.val().skills,
              about: snapshot.val().aboutYou,
              category: snapshot.val().category,
              loaction: snapshot.val().location,
              type: snapshot.val().type,
              gender: snapshot.val().gender,
              experience: snapshot.val().exp,
              salaryRange: snapshot.val().salaryRange,
              age: snapshot.val().age,
              qualification: snapshot.val().qualification,
              education: snapshot.val().educationList,
              workExperience: snapshot.val().workExpList,
              address: snapshot.val().address,
              fullName: snapshot.val().fullname,
              fatherName: snapshot.val().fatherName,
              motherName: snapshot.val().motherName,
              nationality: snapshot.val().nationality,
              dob: snapshot.val().date,
              portfolio: snapshot.val().portfolioList,
              skillList: snapshot.val().skillsList,
              specialQualification: removeTag(
                snapshot.val().specialQualification
              ),
            });
          } else {
            setIsResumeUploaded(false);
          }
        });
  }, []);

  const removeTag = (data) => {
    const regex = /(<([^>]+)>)/gi;
    const result = data?.replace(regex, "");
    const final = result?.replace("&nbsp;", "");
    return final;
  };

  const handleAddExperience = (event) => {
    event.preventDefault();
    setParams({
      ...params,
      education: [
        ...params.education,
        { Designation: "", Institute: "", Period: "", Description: "" },
      ],
    });
  };

  const handleEducationRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...params.education];
    list.splice(index, 1);
    setParams({
      ...params,
      education: list,
    });
  };

  const handlePortfolioRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...params.portfolio];
    list.splice(index, 1);
    setParams({
      ...params,
      portfolio: list,
    });
  };

  const handleSkillRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...params.skillList];
    list.splice(index, 1);
    setParams({
      ...params,
      skillList: list,
    });
  };

  const handleExperienceRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...params.workExperience];
    list.splice(index, 1);
    setParams({
      ...params,
      workExperience: list,
    });
  };

  const getAboutContent = (content, type) => {
    // if (type == "aboutYou") {
    console.log("contnt", content);
    setParams({
      ...params,
      [type]: content,
    });
    // }
    console.log("content=>>>>>>>>>>>", content);
  };

  const saveSpecialQualification = () => {
    firebase
      .database()
      .ref(`resumes/${firebase.auth().currentUser.uid}`)
      .update({
        specialQualification: params.specialQualification,
      })
      .then((res) => {
        console.log("res", res);
        handleCloseQualificationDialog();
      });
  };

  const handleAddSkills = (event) => {
    event.preventDefault();
    setParams({
      ...params,
      skillList: [...params.skillList, { title: "", percentage: "" }],
    });
  };

  const handleProfessionalSkillChange = (e, index) => {
    const { name, value } = e.target;
    console.log("EEE_AND_INDEX=>>>", name, value, index);
    const list = [...params.skillList];
    list[index][name] = value;
    setParams({
      ...params,
      skillList: list,
    });
  };

  const saveSkillList = () => {
    firebase
      .database()
      .ref(`resumes/${firebase.auth().currentUser.uid}`)
      .update({
        skillsList: params.skillList,
      })
      .then((res) => {
        console.log("res", res);
        handleCloseProSkillDialog();
      });
  };

  const handleAddPortfolio = (event) => {
    event.preventDefault();
    setParams({
      ...params,
      portfolio: [...params.portfolio, { title: "", image: "", link: "" }],
    });
  };

  const onChangePicture = async (e, index) => {
    const form = new FormData();
    form.append("file", e.target.files[0]);
    form.append("upload_preset", "pn7uz8ak");
    try {
      let res = await axios.post(
        "https://api.cloudinary.com/v1_1/dnaleybjd/upload",
        form
      );
      if (res) {
        handlePortfolioImageChange(res.data, index);
      }
    } catch (error) {
      console.log(error, "ERRRRR=>>>>>");
    }
    // axios
    //   .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", form)
    //   .then((res) => {
    //     setImage(res.data.url);
    //   })
    //   .catch((err) => {
    //     console.log("rrrrrrr=>>>>", err);
    //   });
  };

  const handlePortfolioImageChange = (data, index) => {
    const list = [...params.portfolio];
    list[index]["image"] = data.url;
    setParams({
      ...params,
      portfolio: list,
    });
  };

  const savePersonalDetail = () => {
    firebase
      .database()
      .ref(`resumes/${firebase.auth().currentUser.uid}`)
      .update({
        fatherName: fatherNameRef.current.value,
        fullname: fullNameRef.current.value,
        gender: genderRef.current.value,
        motherName: motherNameRef.current.value,
        nationality: nationalityRef.current.value,
        dateOfBirth: params.dob,
        address: addressRef.current.value,
      })
      .then((res) => {
        console.log("res", res);
        handleCloseDetailsDialog();
      });
  };

  const handleAddWorkExperience = (event) => {
    event.preventDefault();
    setParams({
      ...params,
      workExperience: [
        ...params.workExperience,
        { Designation: "", Institute: "", Period: "", Description: "" },
      ],
    });
  };

  const handleWorkExperienceChange = (e, index) => {
    const { name, value } = e.target;
    console.log("EEE_AND_INDEX=>>>", name, value, index);
    const list = [...params.workExperience];
    list[index][name] = value;
    setParams({
      ...params,
      workExperience: list,
    });
  };

  const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    console.log("EEE_AND_INDEX=>>>", name, value, index);
    const list = [...params.education];
    list[index][name] = value;
    setParams({
      ...params,
      education: list,
    });
  };

  const saveSkills = () => {
    firebase
      .database()
      .ref(`resumes/${firebase.auth().currentUser.uid}`)
      .update({
        skills: yourSkillsRef.current.value,
      })
      .then((res) => {
        console.log("res", res);
        handleCloseSkillDialog();
      });
  };

  const savePortfolio = () => {
    firebase
      .database()
      .ref(`resumes/${firebase.auth().currentUser.uid}`)
      .update({
        portfolioList: params.portfolio,
      })
      .then((res) => {
        console.log("res", res);
        handleClosePortfolioDialog();
      });
  };

  const saveEducation = () => {
    console.log("education", params.education);
    firebase
      .database()
      .ref(`resumes/${firebase.auth().currentUser.uid}`)
      .update({
        educationList: params.education,
      })
      .then((res) => {
        console.log("res", res);
        handleCloseEducationDialog();
      });
  };

  const saveWorkExperience = () => {
    console.log("education", params.workExperience);
    firebase
      .database()
      .ref(`resumes/${firebase.auth().currentUser.uid}`)
      .update({
        workExpList: params.workExperience,
      })
      .then((res) => {
        console.log("res", res);
        handleCloseExpDialog();
      });
  };

  const handleChangeCounts = (event) => {
    setCounts(event.target.value);
  };

  const handleClickOpenSkillDialog = () => {
    setOpenSkillDialog(true);
  };

  const handleCloseSkillDialog = () => {
    setOpenSkillDialog(false);
  };

  const handleSocialDialog = () => {
    setOpenSocialDialog(true);
  };

  const handleCloseSocialDialog = () => {
    setOpenSocialDialog(false);
  };

  const handleClickOpenAboutMeDialog = () => {
    setOpeAboutMeDialog(true);
  };

  const handleCloseAboutMeDialog = () => {
    setOpeAboutMeDialog(false);
  };

  const handleClickOpenEducationDialog = () => {
    setOpeEducationDialog(true);
  };

  const handleCloseEducationDialog = () => {
    setOpeEducationDialog(false);
  };

  const handleClickOpenExpDialog = () => {
    setOpeExpDialog(true);
  };

  const handleCloseExpDialog = () => {
    setOpeExpDialog(false);
  };

  const handleOpenProSkillDialog = () => {
    setOpenProSkillDialog(true);
  };

  const handleCloseProSkillDialog = () => {
    setOpenProSkillDialog(false);
  };

  const handleOpenQualificationDialog = () => {
    setOpenQualificationDialog(true);
  };

  const handleCloseQualificationDialog = () => {
    setOpenQualificationDialog(false);
  };

  const handleOpenDetailsDialog = () => {
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleOpenPortfolioDialog = () => {
    setOpenPortfolioDialog(true);
  };

  const handleClosePortfolioDialog = () => {
    setOpenPortfolioDialog(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // slidesToShow: 2,
    slidesToScroll: 1,
    // centerPadding: "30px !important",
  };

  return (
    <>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Edit Resume</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Edit Resume
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                {/* <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i data-feather="search"></i>
                  </button>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isResumeUploaded ? (
        <div class="alice-bg section-padding-bottom">
          <div class="container no-gliters">
            <div class="row no-gliters">
              <div class="col">
                <div class="dashboard-container">
                  <div class="dashboard-content-wrapper">
                    <div class="download-resume dashboard-section">
                      <div class="update-file">
                        <input type="file" />
                        Update CV <CreateIcon />
                      </div>
                      <div class="update-file">
                        <input type="file" />
                        Update Cover Letter <CreateIcon />
                      </div>
                      <span>Upload PDF File</span>
                    </div>
                    <div class="skill-and-profile dashboard-section">
                      <div class="skill">
                        <label>Skills:</label>
                        <span style={{ marginRight: 20 }}>
                          {params.yourSkills}
                        </span>
                        <button
                          type="button"
                          class="btn btn-primary edit-button"
                          onClick={handleClickOpenSkillDialog}
                        >
                          <CreateIcon />
                        </button>

                        <Dialog
                          open={openSkillDialog}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseSkillDialog}
                          aria-labelledby="edit-skills"
                          aria-describedby="edit-skills"
                          className={classes.skillModal}
                        >
                          <div className={classes.headSkill}>
                            <h4 className={classes.head1}>
                              <ClearAllIcon
                                style={{ marginRight: 10, fontSize: "20px" }}
                              />
                              MY SKILL
                            </h4>
                            {/* <a href="#" className={classes.addMore}>
                            + Add Skills
                          </a> */}
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <label
                              class="col-sm-3 col-form-label"
                              style={{
                                color: "#6f7484",
                                fontSize: "1.4rem",
                                fontWeight: 600,
                              }}
                            >
                              Type Skills
                            </label>
                            <div class="col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend"></div>
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  defaultValue={params.yourSkills}
                                  ref={yourSkillsRef}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <div class="offset-sm-3 col-sm-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div
                                  class="input-group-text"
                                  style={{
                                    height: "35px",
                                    background: "#fff",
                                    borderTop: 0,
                                    borderLeft: 0,
                                  }}
                                >
                                  02
                                </div>
                              </div>
                              <input
                                type="text"
                                class={`${classes.skillInput} form-control`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <div class="offset-sm-3 col-sm-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div
                                  class="input-group-text"
                                  style={{
                                    height: "35px",
                                    background: "#fff",
                                    borderTop: 0,
                                    borderLeft: 0,
                                  }}
                                >
                                  03
                                </div>
                              </div>
                              <input
                                type="text"
                                class={`${classes.skillInput} form-control`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <div class="offset-sm-3 col-sm-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div
                                  class="input-group-text"
                                  style={{
                                    height: "35px",
                                    background: "#fff",
                                    borderTop: 0,
                                    borderLeft: 0,
                                  }}
                                >
                                  04
                                </div>
                              </div>
                              <input
                                type="text"
                                class={`${classes.skillInput} form-control`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <div class="offset-sm-3 col-sm-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div
                                  class="input-group-text"
                                  style={{
                                    height: "35px",
                                    background: "#fff",
                                    borderTop: 0,
                                    borderLeft: 0,
                                  }}
                                >
                                  05
                                </div>
                              </div>
                              <input
                                type="text"
                                class={`${classes.skillInput} form-control`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <div class="offset-sm-3 col-sm-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div
                                  class="input-group-text"
                                  style={{
                                    height: "35px",
                                    background: "#fff",
                                    borderTop: 0,
                                    borderLeft: 0,
                                  }}
                                >
                                  06
                                </div>
                              </div>
                              <input
                                type="text"
                                class={`${classes.skillInput} form-control`}
                              />
                            </div>
                          </div>
                        </div> */}
                          <div class="row">
                            <div class="offset-sm-3 col-sm-9">
                              <div
                                class="buttons"
                                style={{ padding: "10px 0 20px" }}
                              >
                                <button
                                  className={classes.saveBtn}
                                  onClick={saveSkills}
                                >
                                  Save Update
                                </button>
                                <button
                                  className={classes.cancelBtn}
                                  onClick={handleCloseSkillDialog}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dialog>

                        {/* <div
                        class="modal fade"
                        id="modal-skill"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div class="title">
                                <h4>
                                  <i data-feather="git-branch"></i>MY SKILL
                                </h4>
                                <a href="#" class="add-more">
                                  + Add Skills
                                </a>
                              </div>
                              <div class="content">
                                <form action="#">
                                  <div class="form-group row">
                                    <label class="col-sm-3 col-form-label">
                                      Type Skills
                                    </label>
                                    <div class="col-sm-9">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">01</div>
                                        </div>
                                        <input
                                          type="text"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="offset-sm-3 col-sm-9">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">02</div>
                                        </div>
                                        <input
                                          type="text"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="offset-sm-3 col-sm-9">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">03</div>
                                        </div>
                                        <input
                                          type="text"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="offset-sm-3 col-sm-9">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">04</div>
                                        </div>
                                        <input
                                          type="text"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="offset-sm-3 col-sm-9">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">05</div>
                                        </div>
                                        <input
                                          type="text"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="offset-sm-3 col-sm-9">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">06</div>
                                        </div>
                                        <input
                                          type="text"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="offset-sm-3 col-sm-9">
                                      <div class="buttons">
                                        <button class="primary-bg">
                                          Save Update
                                        </button>
                                        <button class="" data-dismiss="modal">
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                      <div class="social-profile">
                        <label>Social:</label>
                        <a href="#">
                          <i class="fab fa-facebook-f"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-google-plus"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-pinterest-p"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-behance"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-dribbble"></i>
                        </a>
                        <a href="#">
                          <i class="fab fa-github"></i>
                        </a>
                        <button
                          type="button"
                          class="btn btn-primary edit-button"
                          onClick={handleSocialDialog}
                        >
                          <CreateIcon />
                        </button>
                        <Dialog
                          open={openSocialDialog}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseSocialDialog}
                          aria-labelledby="edit-socials"
                          aria-describedby="edit-socials"
                          className={classes.skillModal}
                        >
                          <div className={classes.headSkill}>
                            <h4 className={classes.head1}>
                              <ClearAllIcon
                                style={{ marginRight: 10, fontSize: "20px" }}
                              />
                              Social Networks
                            </h4>
                            <a href="#" className={classes.addMore}>
                              + Add Social
                            </a>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <label
                              class="col-sm-3 col-form-label"
                              style={{
                                color: "#6f7484",
                                fontSize: "1.4rem",
                                fontWeight: 600,
                              }}
                            >
                              Social Links
                            </label>
                            <div class="col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-facebook-f"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="facebook.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-twitter"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="twitter.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-google-plus"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="google.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-linkedin-in"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="linkedin.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-pinterest-p"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="pinterest.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-instagram"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="instagram.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-behance"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="behance.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-dribbble"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="dribbble.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <div
                                    class="input-group-text"
                                    style={{
                                      height: "35px",
                                      background: "#fff",
                                      borderTop: 0,
                                      borderLeft: 0,
                                    }}
                                  >
                                    <i
                                      class="fab fa-github"
                                      style={{ fontSize: "15px" }}
                                    ></i>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  placeholder="github.com/username"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            class="form-group row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div class="offset-sm-3 col-sm-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  {/* <div
                                  class="input-group-text dropdown-label"
                                  style={{ background: "#fff" }}
                                > */}
                                  <FormControl className={classes.formControl}>
                                    {/* <InputLabel id="demo-simple-select-autowidth-label">
                                    3
                                  </InputLabel> */}
                                    <Select
                                      labelId="demo-simple-select-autowidth-label"
                                      id="demo-simple-select-autowidth"
                                      value={counts}
                                      onChange={handleChangeCounts}
                                      autoWidth
                                      className={classes.menuItem}
                                      style={{
                                        background: "#fff",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {/* <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem> */}
                                      <MenuItem
                                        value={10}
                                        style={{ fontSize: "15px" }}
                                      >
                                        2
                                      </MenuItem>
                                      <MenuItem
                                        value={20}
                                        style={{ fontSize: "15px" }}
                                      >
                                        3
                                      </MenuItem>
                                      <MenuItem
                                        value={30}
                                        style={{ fontSize: "15px" }}
                                      >
                                        4
                                      </MenuItem>
                                      <MenuItem
                                        value={40}
                                        style={{ fontSize: "15px" }}
                                      >
                                        5
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  {/* </div> */}
                                </div>
                                <input
                                  type="text"
                                  placeholder="Input Profile Link"
                                  class={`${classes.skillInput} form-control`}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="offset-sm-3 col-sm-9">
                              <div
                                class="buttons"
                                style={{ padding: "10px 0 20px" }}
                              >
                                <button
                                  class="primary-bg"
                                  onClick={handleCloseSocialDialog}
                                  className={classes.saveBtn}
                                >
                                  Save Update
                                </button>
                                <button
                                  className={classes.cancelBtn}
                                  onClick={handleCloseSocialDialog}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dialog>
                      </div>
                    </div>

                    <div class="about-details details-section dashboard-section">
                      <h4>
                        <i data-feather="align-left"></i>About Me
                      </h4>
                      <p>{params.about}</p>
                      <div class="information-and-contact">
                        <div class="information">
                          <h4>Information</h4>
                          <ul>
                            <li>
                              <span>Category:</span>
                              {params.category}
                            </li>
                            <li>
                              <span>Location:</span>
                              {params.loaction}
                            </li>
                            <li>
                              <span>Status:</span> {params.type}
                            </li>
                            <li>
                              <span>Experience:</span> {params.experience}
                            </li>
                            <li>
                              <span>Salary:</span> {params.salaryRange}
                            </li>
                            <li>
                              <span>Gender:</span> {params.gender}
                            </li>
                            <li>
                              <span>Age:</span> {params.age} year(s)
                            </li>
                            <li>
                              <span>Qualification:</span> {params.qualification}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <button
                        type="button"
                        disabled={true}
                        class="btn btn-primary edit-resume"
                        onClick={handleClickOpenAboutMeDialog}
                      >
                        <CreateIcon />
                      </button>
                      <Dialog
                        open={openAboutMeDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseAboutMeDialog}
                        aria-labelledby="edit-education"
                        aria-describedby="edit-education"
                        className={classes.skillModal}
                        role="document"
                      >
                        <div className={classes.headSkill}>
                          <h4 className={classes.head1}>
                            <ClearAllIcon
                              style={{ marginRight: 10, fontSize: "20px" }}
                            />
                            About Me
                          </h4>
                        </div>
                        <div class="content">
                          <form action="#">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">
                                Write Yourself
                              </label>
                              <div class="col-sm-9">
                                <textarea
                                  class="form-control"
                                  placeholder="Write Yourself"
                                  rows="6"
                                  style={{
                                    border: 0,
                                    borderBottom: "1px solid #00000014",
                                    fontSize: "1.4rem",
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <h4
                              className={classes.head1}
                              style={{ padding: "30px 0 20px" }}
                            >
                              <ClearAllIcon
                                style={{ marginRight: 10, fontSize: "20px" }}
                              />
                              Information
                            </h4>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Category
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="Design &amp; Creative"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Location
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="Pakistan"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Status
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="Full Time"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Experience
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="3 Year"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Salary Range
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="30k - 40k"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Gender
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="Male"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">Age</label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="25 Years"
                                />
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Qualification
                              </label>
                              <div class="col-sm-9">
                                <input
                                  type="text"
                                  class={`${classes.skillInput} form-control`}
                                  style={{ borderLeft: 0 }}
                                  placeholder="Gradute"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="offset-sm-3 col-sm-9">
                                <div class="buttons">
                                  <button
                                    className={classes.saveBtn}
                                    onClick={handleCloseAboutMeDialog}
                                  >
                                    Save Update
                                  </button>
                                  <button
                                    className={classes.cancelBtn}
                                    onClick={() => alert("hello")}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Dialog>
                    </div>

                    <div class="education-background details-section dashboard-section">
                      <h4>
                        <i data-feather="book"></i>Education Background
                      </h4>
                      {params.education.map((item, index) => (
                        <div key={index} class="education-label">
                          <span class="study-year">{item.Period}</span>
                          <h5>
                            {item.Designation}
                            <span>@ {item.Institute}</span>
                          </h5>
                          <p>{item.Description}</p>
                        </div>
                      ))}
                      <button
                        type="button"
                        class="btn btn-primary edit-resume"
                        onClick={handleClickOpenEducationDialog}
                      >
                        <CreateIcon />
                      </button>
                      <div
                        class="modal fade modal-education"
                        id="modal-education"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <Dialog
                          open={openEducationDialog}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleCloseEducationDialog}
                          aria-labelledby="edit-education"
                          aria-describedby="edit-education"
                          className={classes.skillModal}
                          role="document"
                        >
                          <div class="title" className={classes.headSkill}>
                            <h4 className={classes.head1}>
                              <ClearAllIcon
                                style={{ marginRight: 10, fontSize: "20px" }}
                              />
                              Education
                            </h4>

                            <a
                              onClick={handleAddExperience}
                              className={classes.addMore}
                            >
                              + Add Education
                            </a>
                          </div>
                          {params.education.map((item, index) => (
                            <div
                              style={{ marginTop: 30 }}
                              class="input-block-wrap"
                            >
                              <div
                                class="form-group row"
                                style={{ marginBottom: "20px" }}
                              >
                                <label class="col-sm-3 col-form-label">
                                  0{index + 1}
                                </label>
                                <div class="col-sm-9">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <div
                                        class="input-group-text"
                                        style={{
                                          height: "35px",
                                          background: "#fff",
                                          borderTop: 0,
                                          borderLeft: 0,
                                          width: "80px",
                                          fontSize: "1.4rem",
                                        }}
                                      >
                                        Title
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      name="Designation"
                                      class={`${classes.skillInput} form-control`}
                                      defaultValue={item.Designation}
                                      onChange={(e) =>
                                        handleExperienceChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="form-group row"
                                style={{ marginBottom: "20px" }}
                              >
                                <div class="offset-sm-3 col-sm-9">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <div
                                        class="input-group-text"
                                        style={{
                                          height: "35px",
                                          background: "#fff",
                                          borderTop: 0,
                                          borderLeft: 0,
                                          width: "80px",
                                          fontSize: "1.4rem",
                                        }}
                                      >
                                        Institute
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      class={`${classes.skillInput} form-control`}
                                      defaultValue={item.Institute}
                                      name="Institute"
                                      onChange={(e) =>
                                        handleExperienceChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="form-group row"
                                style={{ marginBottom: "20px" }}
                              >
                                <div class="col-sm-9 offset-sm-3">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <div
                                        class="input-group-text"
                                        style={{
                                          height: "35px",
                                          background: "#fff",
                                          borderTop: 0,
                                          borderLeft: 0,
                                          width: "80px",
                                          fontSize: "1.4rem",
                                        }}
                                      >
                                        Period
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      class={`${classes.skillInput} form-control`}
                                      defaultValue={item.Period}
                                      name="Period"
                                      onChange={(e) =>
                                        handleExperienceChange(e, index)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="form-group row"
                                style={{ marginBottom: "20px" }}
                              >
                                <div class="offset-sm-3 col-sm-9">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <div
                                        class="input-group-text"
                                        style={{
                                          height: "35px",
                                          background: "#fff",
                                          borderTop: 0,
                                          borderLeft: 0,
                                          width: "80px",
                                          height: "100%",
                                          fontSize: "1.4rem",
                                        }}
                                      >
                                        Description
                                      </div>
                                    </div>
                                    <textarea
                                      class={`${classes.skillInput} form-control`}
                                      rows="3"
                                      defaultValue={item.Description}
                                      onChange={(e) =>
                                        handleExperienceChange(e, index)
                                      }
                                      name="Description"
                                    ></textarea>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  {params.education.length - 1 === index &&
                                    params.education.length > 1 && (
                                      <button
                                        onClick={(e) =>
                                          handleEducationRemove(e, index)
                                        }
                                        class="button"
                                        style={{
                                          textAlign: "center",
                                          padding: 5,
                                          marginBottom: 20,
                                          marginTop: 20,
                                          marginRight: 20,
                                          backgroundColor: "grey",
                                          color: "#fff",
                                        }}
                                      >
                                        Remove
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div class="row">
                            <div class="offset-sm-3 col-sm-9">
                              <div class="buttons">
                                <button
                                  class="primary-bg"
                                  className={classes.saveBtn}
                                  onClick={saveEducation}
                                >
                                  Save Update
                                </button>
                                <button
                                  className={classes.cancelBtn}
                                  onClick={handleCloseEducationDialog}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dialog>
                      </div>
                    </div>

                    <div class="experience dashboard-section details-section">
                      <h4>
                        <i data-feather="briefcase"></i>Work Experiance
                      </h4>
                      {params.workExperience.map((item, index) => (
                        <div class="experience-section">
                          <span class="service-year">{item.Period}</span>
                          <h5>
                            {item.Designation}
                            <span>@ {item.companyName}</span>
                          </h5>
                          <p>{item.Description}</p>
                        </div>
                      ))}
                      <button
                        type="button"
                        class="btn btn-primary edit-resume"
                        onClick={handleClickOpenExpDialog}
                      >
                        <CreateIcon />
                      </button>
                      <Dialog
                        open={openExpDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseExpDialog}
                        aria-labelledby="edit-education"
                        aria-describedby="edit-education"
                        className={classes.skillModal}
                        role="document"
                      >
                        <div className={classes.headSkill}>
                          <h4 className={classes.head1}>
                            <ClearAllIcon
                              style={{ marginRight: 10, fontSize: "20px" }}
                            />
                            Experience
                          </h4>
                          <a
                            onClick={handleAddWorkExperience}
                            className={classes.addMore}
                          >
                            + Add Experience
                          </a>
                        </div>
                        {params.workExperience.map((item, index) => (
                          <div
                            style={{ marginTop: 40 }}
                            class="input-block-wrap"
                          >
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                0{index + 1}
                              </label>
                              <div class="col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Title
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    name="Designation"
                                    onChange={(e) =>
                                      handleWorkExperienceChange(e, index)
                                    }
                                    class={`${classes.skillInput} form-control`}
                                    defaultValue={item.Designation}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <div class="offset-sm-3 col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Company
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class={`${classes.skillInput} form-control`}
                                    onChange={(e) =>
                                      handleWorkExperienceChange(e, index)
                                    }
                                    name="companyName"
                                    defaultValue={item.companyName}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <div class="col-sm-9 offset-sm-3">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Period
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class={`${classes.skillInput} form-control`}
                                    name="Period"
                                    onChange={(e) =>
                                      handleWorkExperienceChange(e, index)
                                    }
                                    defaultValue={item.Period}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <div class="offset-sm-3 col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        height: "100%",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Description
                                    </div>
                                  </div>
                                  <textarea
                                    class={`${classes.skillInput} form-control`}
                                    rows="3"
                                    defaultValue={item.Description}
                                    name="Description"
                                    onChange={(e) =>
                                      handleWorkExperienceChange(e, index)
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              {params.workExperience.length - 1 === index &&
                                params.workExperience.length > 1 && (
                                  <button
                                    onClick={(e) =>
                                      handleExperienceRemove(e, index)
                                    }
                                    class="button"
                                    style={{
                                      textAlign: "center",
                                      padding: 5,
                                      marginBottom: 20,
                                      marginTop: 20,
                                      marginRight: 20,
                                      backgroundColor: "grey",
                                      color: "#fff",
                                    }}
                                  >
                                    Remove
                                  </button>
                                )}
                            </div>
                          </div>
                        ))}
                        <div class="row">
                          <div class="offset-sm-3 col-sm-9">
                            <div class="buttons">
                              <button
                                class="primary-bg"
                                className={classes.saveBtn}
                                onClick={saveWorkExperience}
                              >
                                Save Update
                              </button>
                              <button
                                className={classes.cancelBtn}
                                onClick={handleCloseExpDialog}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>

                    <div class="professonal-skill dashboard-section details-section">
                      <h4>
                        <ClearAllIcon
                          style={{ marginRight: 10, fontSize: "20px" }}
                        />
                        Professional Skill
                      </h4>
                      <ul>
                        {params.skillList.map((skill, index) => (
                          <div style={{ marginBottom: 30 }}>
                            <li>
                              <span style={{ fontWeight: "bold" }}>Title:</span>{" "}
                              {skill.title}
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  Percentage:
                                </span>{" "}
                                {skill.percentage}
                              </div>
                            </li>
                          </div>
                        ))}
                      </ul>
                      {/* <div class="progress-group">
                                            <div class="progress-item">
                                                <div class="progress-head">
                                                    <p class="progress-on">Photoshop</p>
                                                </div>
                                                <div class="progress-body">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div>
                                                    </div>
                                                    <p class="progress-to">70%</p>
                                                </div>
                                            </div>
                                            <div class="progress-item">
                                                <div class="progress-head">
                                                    <p class="progress-on">HTML/CSS</p>
                                                </div>
                                                <div class="progress-body">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div>
                                                    </div>
                                                    <p class="progress-to">90%</p>
                                                </div>
                                            </div>
                                            <div class="progress-item">
                                                <div class="progress-head">
                                                    <p class="progress-on">JavaScript</p>
                                                </div>
                                                <div class="progress-body">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="74" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div>
                                                    </div>
                                                    <p class="progress-to">74%</p>
                                                </div>
                                            </div>
                                            <div class="progress-item">
                                                <div class="progress-head">
                                                    <p class="progress-on">PHP</p>
                                                </div>
                                                <div class="progress-body">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="86" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div>
                                                    </div>
                                                    <p class="progress-to">86%</p>
                                                </div>
                                            </div>
                                        </div> */}
                      <button
                        type="button"
                        class="btn btn-primary edit-resume"
                        onClick={handleOpenProSkillDialog}
                      >
                        <CreateIcon />
                      </button>
                      <Dialog
                        open={openProSkillDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseProSkillDialog}
                        aria-labelledby="edit-proSkill"
                        aria-describedby="edit-proSkill"
                        className={classes.skillModal}
                      >
                        <div class="title" className={classes.headSkill}>
                          <h4 className={classes.head1}>
                            <ClearAllIcon />
                            Professional Skill
                          </h4>
                          <a
                            onClick={handleAddSkills}
                            className={classes.addMore}
                          >
                            + Add Skill
                          </a>
                        </div>
                        <div class="input-block-wrap">
                          {/* <div
                          class="form-group row"
                          style={{ marginBottom: "50px" }}
                        >
                          <label class="col-sm-3 col-form-label">
                            About Skill
                          </label>
                          <div class="col-sm-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div
                                  class="input-group-text"
                                  style={{
                                    height: "35px",
                                    background: "#fff",
                                    borderTop: 0,
                                    borderLeft: 0,
                                    width: "80px",
                                    height: "100%",
                                    fontSize: "1.4rem",
                                  }}
                                >
                                  Description
                                </div>
                              </div>
                              <textarea
                                class={`${classes.skillInput} form-control`}
                                rows="3"
                              ></textarea>
                            </div>
                          </div>
                        </div> */}
                        </div>
                        {params.skillList.map((skill, index) => (
                          <div class="input-block-wrap">
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Skill 0 {index + 1}
                              </label>
                              <div class="col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Skill Name
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class={`${classes.skillInput} form-control`}
                                    defaultValue={skill.title}
                                    onChange={(e) => {
                                      handleProfessionalSkillChange(e, index);
                                    }}
                                    name="title"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "50px" }}
                            >
                              <div class="offset-sm-3 col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Percentage
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class={`${classes.skillInput} form-control`}
                                    defaultValue={skill.percentage}
                                    onChange={(e) => {
                                      handleProfessionalSkillChange(e, index);
                                    }}
                                    name="percentage"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                alignItems: "flex-end",
                              }}
                            >
                              {params.skillList.length - 1 === index &&
                                params.skillList.length > 1 && (
                                  <button
                                    onClick={(e) => handleSkillRemove(e, index)}
                                    class="button"
                                    style={{
                                      textAlign: "center",
                                      padding: 5,
                                      marginTop: 20,
                                      marginBottom: 20,
                                      marginRight: 20,
                                      backgroundColor: "grey",
                                      color: "#fff",
                                    }}
                                  >
                                    Remove
                                  </button>
                                )}
                            </div>
                          </div>
                        ))}

                        <div class="row" style={{ marginTop: 20 }}>
                          <div class="offset-sm-3 col-sm-9">
                            <div class="buttons">
                              <button
                                className={classes.saveBtn}
                                onClick={saveSkillList}
                              >
                                Save Update
                              </button>
                              <button
                                className={classes.cancelBtn}
                                onClick={handleCloseProSkillDialog}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>

                    <div class="special-qualification dashboard-section details-section">
                      <h4>
                        <i data-feather="gift"></i>Special Qualification
                      </h4>
                      <p>{params.specialQualification}</p>
                      <button
                        type="button"
                        class="btn btn-primary edit-resume"
                        onClick={handleOpenQualificationDialog}
                      >
                        <CreateIcon />
                      </button>
                      <Dialog
                        open={openQualificationDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseQualificationDialog}
                        aria-labelledby="edit-qualification"
                        aria-describedby="edit-qualification"
                        className={classes.skillModal}
                        role="document"
                      >
                        <div class={classes.headSkill}>
                          <h4 className={classes.head1}>
                            <ClearAllIcon
                              style={{ fontSize: "20px", marginRight: 10 }}
                            />
                            Special Qualification
                          </h4>
                        </div>

                        <div class="col-md-9">
                          <EditorTable
                            type="specialQualification"
                            getAboutContent={getAboutContent}
                          />
                        </div>

                        <div class="row" style={{ marginTop: "20px" }}>
                          <div class="offset-sm-3 col-sm-9">
                            <div class="buttons">
                              <button
                                className={classes.saveBtn}
                                onClick={saveSpecialQualification}
                              >
                                Save Update
                              </button>
                              <button
                                className={classes.cancelBtn}
                                onClick={handleCloseQualificationDialog}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>

                    <div class="portfolio dashboard-section details-section">
                      <h4>
                        <i data-feather="gift"></i>Portfolio
                      </h4>

                      <Slider
                        {...settings}
                        // slidesToShow={
                        //   resume?.portfolioList?.length > 2
                        //     ? resume?.portfolioList?.length
                        //     : 2
                        // }
                        slidesToShow={params.portfolio?.length > 3 ? 3 : 3}
                        className="portfolio-slider"
                      >
                        {params.portfolio?.map((slide, i) => {
                          // console.log(slide, "dsfsadfsa");
                          return (
                            <>
                              <div class="portfolio-item" key={i}>
                                <div class="portfolio-item">
                                  <img
                                    src={slide.image}
                                    // src={thumb1}
                                    class="img-fluid"
                                    width="80%"
                                    height="100%  "
                                    alt=""
                                  />
                                  <div class="overlay">
                                    <a href={slide.title}>
                                      <VisibilityIcon
                                        style={{ fontSize: "28px" }}
                                        // onClick={() => setIsOpen(true)}
                                        // src={src}
                                        // onClick={() => openImageViewer(slide.id)}
                                      />
                                    </a>
                                    <a href={slide.link}>
                                      <LinkIcon style={{ fontSize: "28px" }} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </Slider>

                      <button
                        type="button"
                        class="btn btn-primary edit-resume"
                        onClick={handleOpenPortfolioDialog}
                      >
                        <CreateIcon />
                      </button>
                      <Dialog
                        open={openPortfolioDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClosePortfolioDialog}
                        aria-labelledby="edit-portfolio"
                        aria-describedby="edit-portfolio"
                        className={classes.skillModal}
                        role="document"
                      >
                        <div className={classes.headSkill}>
                          <h4 className={classes.head1}>
                            <ClearAllIcon
                              style={{ fontSize: "20px", marginRight: 10 }}
                            />
                            Portfolio
                          </h4>
                          <a
                            onClick={handleAddPortfolio}
                            className={classes.addMore}
                          >
                            + Add Another
                          </a>
                        </div>
                        {params.portfolio.map((item, index) => (
                          <div class="input-block-wrap">
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <label class="col-sm-3 col-form-label">
                                Portfolio 01
                              </label>
                              <div class="col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Title
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class={`${classes.skillInput} form-control`}
                                    defaultValue={item.title}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <div class="offset-sm-3 col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        height: "100%",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Image
                                    </div>
                                  </div>

                                  {/* <div class="upload-profile-photo">
                                <div class="update-photo">
                                  <img class="image" src={thumb1} alt="" />
                                </div>
                              </div> */}

                                  <div class="upload-portfolio-image">
                                    <div
                                      class="update-photo"
                                      style={{ width: "130px", height: "90px" }}
                                    >
                                      <img
                                        class="image"
                                        src={item.image ? item.image : thumb1}
                                        alt=""
                                      />
                                    </div>
                                    {/* <div class={`${classes.uploadBox} file-upload`}> */}
                                    <input
                                      type="file"
                                      onChange={(e) =>
                                        onChangePicture(e, index)
                                      }
                                      class="file-input"
                                    />
                                    {/* <i data-feather="plus"></i> */}
                                    {/* <p style={{ fontSize: "15px" }}>
                                    Change Avatar
                                  </p> */}
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              style={{ marginBottom: "20px" }}
                            >
                              <div class="offset-sm-3 col-sm-9">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      style={{
                                        height: "35px",
                                        background: "#fff",
                                        borderTop: 0,
                                        borderLeft: 0,
                                        width: "80px",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      Link
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class={`${classes.skillInput} form-control`}
                                    defaultValue={item.link}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              {params.portfolio.length - 1 === index &&
                                params.portfolio.length > 1 && (
                                  <button
                                    onClick={(e) =>
                                      handlePortfolioRemove(e, index)
                                    }
                                    class="button"
                                    style={{
                                      textAlign: "center",
                                      padding: 5,
                                      marginBottom: 20,
                                      marginTop: 20,
                                      marginRight: 20,
                                      backgroundColor: "grey",
                                      color: "#fff",
                                    }}
                                  >
                                    Remove
                                  </button>
                                )}
                            </div>
                          </div>
                        ))}
                        <div class="row">
                          <div class="offset-sm-3 col-sm-9">
                            <div class="buttons">
                              <button
                                className={classes.saveBtn}
                                onClick={savePortfolio}
                              >
                                Save Update
                              </button>
                              <button
                                className={classes.cancelBtn}
                                onClick={handleClosePortfolioDialog}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>

                    <div class="personal-information dashboard-section last-child details-section">
                      <h4>
                        <i data-feather="user-plus"></i>Personal Deatils
                      </h4>
                      <ul>
                        <li>
                          <span>Full Name:</span> {params.fullName}
                        </li>
                        <li>
                          <span>Father's Name:</span> {params.fatherName}
                        </li>
                        <li>
                          <span>Mother's Name:</span> {params.motherName}
                        </li>
                        <li>
                          <span>Date of Birth:</span> {params.dob}
                        </li>
                        <li>
                          <span>Nationality:</span> {params.nationality}{" "}
                        </li>
                        <li>
                          <span>Sex:</span> {params.gender}
                        </li>
                        <li>
                          <span>Address:</span> {params.address}
                        </li>
                      </ul>
                      <button
                        type="button"
                        class="btn btn-primary edit-resume"
                        onClick={handleOpenDetailsDialog}
                      >
                        <CreateIcon />
                      </button>
                      <Dialog
                        open={openDetailsDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDetailsDialog}
                        aria-labelledby="edit-qualification"
                        aria-describedby="edit-qualification"
                        className={classes.skillModal}
                        role="document"
                      >
                        <div class={classes.headSkill}>
                          <h4 className={classes.head1}>
                            <ClearAllIcon
                              style={{ fontSize: "20px", marginRight: 10 }}
                            />
                            Personal Details
                          </h4>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">
                            Full Name
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              placeholder="Lorem ipsum"
                              ref={fullNameRef}
                              defaultValue={params.fullName}
                            />
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">
                            Father’s Name
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              ref={fatherNameRef}
                              placeholder="Lorem ipsum"
                              defaultValue={params.fatherName}
                            />
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">
                            Mother’s Name
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              ref={motherNameRef}
                              placeholder="Lorem ipsum"
                              defaultValue={params.motherName}
                            />
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">
                            Date Of Birth
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="date"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              value={params.dob}
                              defaultValue={params.dob}
                            />
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">
                            Nationality
                          </label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              placeholder="Pakistan"
                              ref={nationalityRef}
                              defaultValue={params.nationality}
                            />
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">Gender</label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              placeholder="Male"
                              ref={genderRef}
                              defaultValue={params.gender}
                            />
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">Age</label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              placeholder="Age"
                              defaultValue={params.age}
                            />
                          </div>
                        </div>
                        <div
                          class="form-group row"
                          style={{ marginBottom: "20px" }}
                        >
                          <label class="col-sm-3 col-form-label">Address</label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              class={`${classes.skillInput} form-control`}
                              style={{ borderLeft: 0 }}
                              ref={addressRef}
                              // placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                              defaultValue={params.address}
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="offset-sm-3 col-sm-9">
                            <div class="buttons">
                              <button
                                className={classes.saveBtn}
                                onClick={savePersonalDetail}
                              >
                                Save Update
                              </button>
                              <button
                                className={classes.cancelBtn}
                                onClick={handleCloseDetailsDialog}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </div>
                  </div>

                  <div class={`${classes.hide} dashboard-sidebar`}>
                    <div class="user-info">
                      <div class="thumb">
                        <img
                          src={userDetails?.avatar}
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                      <div class="user-body">
                        <h5>{aboutUser?.name}</h5>
                        <span>@username</span>
                      </div>
                    </div>
                    {/* <div class="profile-progress">
                                        <div class="progress-item">
                                            <div class="progress-head">
                                                <p class="progress-on">Profile</p>
                                            </div>
                                            <div class="progress-body">
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div>
                                                </div>
                                                <p class="progress-to">70%</p>
                                            </div>
                                        </div>
                                    </div> */}
                    <div class="dashboard-menu">
                      <CandidateDashboardSidebar dashboardLink="editResume" />

                      {/* <ul>
                      <li>
                        <i class="fas fa-home"></i>
                        <a href="dashboard.html">Dashboard</a>
                      </li>
                      <li>
                        <i class="fas fa-user"></i>
                        <a href="dashboard-edit-profile.html">Edit Profile</a>
                      </li>
                      <li>
                        <i class="fas fa-file-alt"></i>
                        <a href="resume.html">Resume</a>
                      </li>
                      <li class="active">
                        <i class="fas fa-edit"></i>
                        <a href="edit-resume.html">Edit Resume</a>
                      </li>
                      <li>
                        <i class="fas fa-heart"></i>
                        <a href="dashboard-bookmark.html">Bookmarked</a>
                      </li>
                      <li>
                        <i class="fas fa-check-square"></i>
                        <a href="dashboard-applied.html">Applied Job</a>
                      </li>
                      <li>
                        <i class="fas fa-comment"></i>
                        <a href="dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i class="fas fa-calculator"></i>
                        <a href="dashboard-pricing.html">Pricing Plans</a>
                      </li>
                    </ul> */}
                      <ul class="delete">
                        <li>
                          <i class="fas fa-power-off"></i>
                          <a href="#">Logout</a>
                        </li>
                        {/* <li>
                          <i class="fas fa-trash-alt"></i>
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#modal-delete"
                          >
                            Delete Profile
                          </a>
                        </li> */}
                      </ul>

                      <div
                        class="modal fade modal-delete"
                        id="modal-delete"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-body">
                              <h4>
                                <i data-feather="trash-2"></i>Delete Account
                              </h4>
                              <p>
                                Are you sure! You want to delete your profile.
                                This can't be undone!
                              </p>
                              <form action="#">
                                <div class="form-group">
                                  <input
                                    type="password"
                                    class="form-control"
                                    placeholder="Enter password"
                                  />
                                </div>
                                <div class="buttons">
                                  <button class="delete-button">
                                    Save Update
                                  </button>
                                  <button class="" data-dismiss="modal">
                                    Cancel
                                  </button>
                                </div>
                                <div class="form-group form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    checked=""
                                  />
                                  <label class="form-check-label">
                                    You accepts our{" "}
                                    <a href="#">Terms and Conditions</a> and{" "}
                                    <a href="#">Privacy Policy</a>
                                  </label>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3 style={{ textAlign: "center", marginBottom: 20 }}>
          Please Create your Resume first in Add Resume Section
        </h3>
      )}

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CandidateEditResume;
