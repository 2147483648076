import React, { useEffect, useRef, useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Search,
  Briefcase,
  MapPin,
  Clock,
  Heart,
  Edit2,
  DollarSign,
  Video,
} from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase";
import fire, { app } from "../../database";
import FindYourJob from "./../../components/FindYourJob";
import JobListDropDown from "../../components/JobListDropDown/JobListDropDown";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import MenuIcon from "@material-ui/icons/Menu";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import professions from "./professions.json";

import { Country, State, City } from "country-state-city";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  formControl: {
    margin: "0 8px",
    minWidth: 120,
    // maxWidth: 300,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: "1.7rem",
    fontWeight: 500,
    color: "#212529",
  },
  dropdownHead: {
    "&.MuiAccordion-root .MuiAccordionSummary-root": {
      "&:hover div": {
        color: "blue",
      },
      "&:hover div > h4": {
        color: "#246df8",
      },
    },
  },
  listingContainer: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isUser = { isAuthenticated: false };
const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const JobListing = (props) => {
  const classes = useStyles();
  const [jobData, setJobData] = useState([]);
  const [JobData2, setJobData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [ArrayData, setArrayData] = useState([]);
  const [age, setAge] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [selectedResume, setSelectedResume] = useState(false);
  const [location, setLocation] = React.useState("");
  const [qualification, setQualification] = useState("");
  const [open, setOpen] = React.useState(false);
  const [changeIcon, setChangeIcon] = useState(false);
  const [jobType, setJobtype] = useState("");
  const [experience, setExperience] = useState("");
  const [changeIconExp, setChangeIconExp] = useState(false);
  const [fileCV, setFileCV] = useState(null);
  const [changeIconRange, setChangeIconRange] = useState(false);
  const [changeIconPosted, setChangeIconPosted] = useState(false);
  const [changeIconGender, setChangeIconGender] = useState(false);
  const [resumeUrl, setResumeUrl] = useState("");
  const [isSelectResumeUrl, setIsSelectResumeUrl] = useState(false);
  const [changeIconQualification, setChangeIconQualification] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });
  const [userResume, setUserResume] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [videoLink, setVideoLink] = useState(null);
  isUserAuthenticate();
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const ResumeRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let data = [];
    firebase
      .database()
      .ref(`jobs`)
      .once("value", (snapshot) => {
        console.log(snapshot.val(), "VALLLLLLLLLLLLLLLLLLLLLL");
        snapshot.forEach(function (child) {
          console.log(child.val(), "VAAAAAAAAA");
          child.forEach(function (inner) {
            console.log(inner.val(), "INNNNNNNNNNNNNNNNNnn");

            if (inner.val().category == category) {
              data.push(inner.val());
            }
            // setJobData()
          });
        });
        console.log(data, "DAAAAAAAAAAAAAAAAAAxzczxczxc");
      });
  }, [category]);

  useEffect(() => {
    // alert("ran component")
    console.log(props.location.state, "LISINGGGGGGGGGGGGGGGGGGGGGGGGGGGGG");
    if (props.location.state != undefined) {
      if (
        props.location?.state?.jobTitle != "" &&
        props.location?.state?.location != ""
      ) {
        firebase
          .database()
          .ref(`jobs`)
          .once("value", (snapshot) => {
            let tempArr = [];
            snapshot.forEach((employers) => {
              employers.forEach((jobs) => {
                let locationFromCountry =
                  jobs.val().yourcity + jobs.val().yourstate;

                if (
                  locationFromCountry
                    .toLowerCase()
                    .includes(
                      props.location?.state?.location
                        .toLowerCase()
                        .replace(/[, ]+/g, "")
                    ) &&
                  jobs
                    .val()
                    .category.toLowerCase()
                    .includes(props.location?.state?.jobTitle.toLowerCase())
                ) {
                  // alert("if")

                  let data = { ...jobs.val() };
                  console.log(data, "datatatatatata");
                  tempArr.push(data);
                }
                // console.log("tempArrrrr=>>>>", tempArr);
                // setJobData(tempArr);
              });
            });
            setJobData2(tempArr);
            setJobData(tempArr);
          });
      } else if (props.location?.state?.jobTitle != "") {
        firebase
          .database()
          .ref(`jobs`)
          .once("value", (snapshot) => {
            let tempArr = [];
            snapshot.forEach((employers) => {
              employers.forEach((jobs) => {
                if (
                  // props.location?.state?.categoryValue == jobs.val().category
                  jobs
                    .val()
                    .category.toLowerCase()
                    .includes(props.location?.state?.jobTitle.toLowerCase())
                ) {
                  let data = { ...jobs.val() };

                  tempArr.push(data);
                }
                // console.log("tempArrrrr=>>>>", tempArr);
                // setJobData(tempArr);
              });
            });
            setJobData2(tempArr);
            setJobData(tempArr);
          });
      } else if (props.location?.state?.location != "") {
        firebase
          .database()
          .ref(`jobs`)
          .once("value", (snapshot) => {
            let tempArr = [];
            snapshot.forEach((employers) => {
              employers.forEach((jobs) => {
                console.log(props.location?.state?.location, "sakjdasdjsahdka");
                console.log(jobs.val(), "VALUEEEEEEEEE");
                // if (props.location?.state?.cityValue == jobs.val().yourstate) {

                let locationFromCountry =
                  jobs.val().yourcity + jobs.val().yourstate;

                let locationState = props.location?.state?.location
                  .toLowerCase()
                  .replace(/[, ]+/g, "");

                if (locationFromCountry.toLowerCase().includes(locationState)) {
                  let data = { ...jobs.val() };
                  tempArr.push(data);
                }
                // console.log("tempArrrrr=>>>>", tempArr);
                // setJobData(tempArr);
              });
            });
            setJobData2(tempArr);
            setJobData(tempArr);
          });
      } else {
        // alert("else");
        firebase
          .database()
          .ref(`jobs`)
          .once("value", (snapshot) => {
            let tempArr = [];
            snapshot.forEach((employers) => {
              employers.forEach((jobs) => {
                let data = { ...jobs.val() };
                tempArr.push(data);
              });
            });
            setJobData(tempArr);
            setJobData2(tempArr);
          });
      }
    } else {
      firebase
        .database()
        .ref(`jobs`)
        .once("value", (snapshot) => {
          let tempArr = [];
          snapshot.forEach((employers) => {
            employers.forEach((jobs) => {
              let data = { ...jobs.val() };
              tempArr.push(data);
            });
          });
          setJobData(tempArr);
          setJobData2(tempArr);
        });
    }
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`bookmark/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setArrayData(HistoryItems);
      });
  }, []);

  const openDialogBox = (jobData) => {
    setSelectedJob(jobData);
    setOpen(true);
  };
  const Data = Object.keys(ArrayData);

  const bookmarkJob = (data) => {
    firebase
      .database()
      .ref(`bookmark/${firebase?.auth()?.currentUser?.uid}`)
      .push({
        ...data,
      })
      .then((res) => {
        setAlertData({
          open: true,
          severity: "success",
          msg: "Bookmarked job Succesfully",
        });
      })
      .catch((err) => {
        setAlertData({
          open: true,
          severity: "error",
          msg: "Some error has occured",
        });
      });
  };

  const [appliedJob, setappliedJob] = useState([]);

  useEffect(() => {
    firebase
      .database()
      .ref(`applied/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setappliedJob(HistoryItems);
      });
  }, []);

  const appliedData = Object.keys(appliedJob);

  const emptyAllFields = () => {
    setResumeUrl(false);
    setIsSelectResumeUrl(false);
    setSelectedResume(false);
    setUserResume({});
    nameRef.current.value = "";
    emailRef.current.value = "";
    messageRef.current.value = "";
  };

  const applyJob = (appliedJob) => {
    setLoading(true);

    if (
      nameRef.current.value &&
      emailRef.current.value &&
      (selectedResume || isSelectResumeUrl)
    ) {
      firebase
        .database()
        .ref(`jobs`)
        .once("value", (snapshot) => {
          snapshot.forEach((employers) => {
            employers.forEach((jobs) => {
              if (selectedJob.jobId == jobs.val().jobId) {
                let arr = [];
                // let params = {
                //   applicant: userResume,
                //   email: emailRef.current.value,
                //   name: nameRef.current.value
                // }
                if (selectedResume) {
                  let params = {
                    userResume,
                    id: firebase.auth().currentUser.uid,
                  };
                } else {
                  let params = {
                    resumeUrl,
                    id: firebase.auth().currentUser.uid,
                  };
                }

                if (jobs.val().applicant) {
                  if (selectedResume) {
                    let params = {
                      userResume,
                      id: firebase.auth().currentUser.uid,
                    };
                    arr = jobs.val().applicant;
                    arr.push({ ...params });
                    // console.log(selectedJob, "SELECTEDD_JOBBBB");
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: arr,
                      })
                      .then((res) => {
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });

                        setOpen(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  } else {
                    console.log("resume url", resumeUrl);
                    let params = {
                      userResume: {
                        cvURL: resumeUrl,
                        name: nameRef.current.value,
                        email: emailRef.current.value,
                        message: messageRef.current.value,
                      },
                      id: firebase.auth().currentUser.uid,
                    };
                    emptyAllFields();
                    arr = jobs.val().applicant;
                    arr.push({ ...params });
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: arr,
                      })
                      .then((res) => {
                        // console.log(selectedJob, "SELECTEDD_JOBBBB");
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });

                        setOpen(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  }
                } else {
                  if (selectedResume) {
                    let params = {
                      userResume,
                      id: firebase.auth().currentUser.uid,
                    };
                    // console.log(selectedJob, "SELECTEDD_JOBBBB");
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: [params],
                      })
                      .then((res) => {
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });
                        setOpen(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      })
                      .then((res) => {
                        setOpen(false);
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  } else {
                    let params = {
                      userResume: {
                        cvURL: resumeUrl,
                        name: nameRef.current.value,
                        email: emailRef.current.value,
                        message: messageRef.current.value,
                      },
                      id: firebase.auth().currentUser.uid,
                    };
                    // console.log(selectedJob, "SELECTEDD_JOBBBB");
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: [params],
                      })
                      .then((res) => {
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });
                        setOpen(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      })
                      .then((res) => {
                        setOpen(false);
                        setLoading(false);
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  }
                }
                firebase
                  .database()
                  .ref(`applied/${firebase.auth().currentUser.uid}`)
                  .push({
                    ...selectedJob,
                  });
                // firebase.database().ref(`jobs/${employers.key}/${jobs.key}`).update({
                //  sinan:"hello"
                // })
              }
            });
          });
        });
    } else {
      setLoading(false);
      console.log("else");
      setAlertData({
        open: true,
        severity: "error",
        msg: "fill all the fields",
      });
    }
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
    handleonChange();
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeLocation = (event) => {
    setState(event.target.value);
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const handleonChange = () => {
    // let filter = jobData.filter(item => {
    //   console.log(item, category, "ITEMMSADASDASD");
    //   if (category && location) {
    //     return item.location == location && item.category == category
    //   }
    //   if (category) {
    //     return item.category == category
    //   }
    // })
    // console.log(filter, "FILTERRRRRRRRRRRRRRRR");
  };
  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllState] = useState([]);
  const [allcities, setAllCities] = useState([]);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v2/all")
      .then((res) => {
        setAllCountries(res.data);
        console.log(res, "Ressssssssssssssssssssssssssss");
      })
      .catch((err) => {
        console.log(err, "errrrrrrrr");
      });
  }, []);

  // useEffect(() => {
  //   let res = State.getStatesOfCountry("US");
  //   setAllState(res);
  // }, []);

  useEffect(() => {
    let countryVal = country.split(",");
    if (country != "") {
      let res = State.getStatesOfCountry(countryVal[0]);
      console.log(res, "RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
      setAllState(res);
    } else {
      setAllState([]);
      setAllCities([]);
    }
  }, [country]);

  useEffect(() => {
    let selectedState = state.split(",");
    let countryVal = country.split(",");
    if (state != "") {
      let res = City.getCitiesOfState(countryVal[0], selectedState[0]);

      setAllCities(res);
    } else {
      setAllCities([]);
    }
  }, [state]);

  useEffect(() => {
    let filter = JobData2.filter((item) => {
      console.log(item, "itemitemitemitem");

      if (category && city && jobType && experience && qualification && state) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (category && city && jobType && experience && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (category && city && jobType && state && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (category && city && state && experience && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (category && city && jobType && experience && state) {
        console.log("CALEEEEEEEEEEEE", item);
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (category && state && jobType && experience && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (state && city && jobType && experience && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      //4 conditions
      if (category && city && jobType && experience) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase()
        );
      }
      if (category && city && jobType && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (category && city && experience && qualification) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (category && jobType && experience && qualification) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (city && jobType && experience && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (state && jobType && experience && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (state && city && experience && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (state && jobType && city && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (state && jobType && experience && city) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase()
        );
      }

      if (category && city && state && country) {
        let newCountry = country.split(",");
        let newState = state.split(",");
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.yourcountry.toLowerCase() == newCountry[1].toLowerCase() &&
          item.yourstate.toLowerCase() == newState[1].toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase()
        );
      }

      // 3 conditions

      if (category && city && jobType) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase()
        );
      }
      if (category && city && experience) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase()
        );
      }
      if (category && city && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (city && jobType && experience) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase()
        );
      }
      if (city && jobType && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (category && jobType && experience) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase()
        );
      }
      if (category && jobType && qualification) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (jobType && experience && qualification) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (jobType && city && state) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (category && experience && qualification) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (category && state && qualification) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (city && experience && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (city && experience && state) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (city && state && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (state && experience && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (category && country && state) {
        let newCountry = country.split(",");
        let newState = state.split(",");
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.yourcountry.toLowerCase() == newCountry[1].toLowerCase() &&
          item.yourstate.toLowerCase() == newState[1].toLowerCase()
        );
      }

      // 2 conditions
      if (category && location) {
        return (
          item.yourcity.toLowerCase() == location.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase()
        );
      }
      if (category && jobType) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase()
        );
      }
      if (category && experience) {
        return (
          item.yourcity.toLowerCase() == location.toLowerCase() &&
          item.category.toLowerCase() == category.toLowerCase()
        );
      }
      if (category && qualification) {
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (jobType && location) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourcity.toLowerCase() == location.toLowerCase()
        );
      }

      if (location && qualification) {
        return (
          item.yourcity.toLowerCase() == location.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (location && experience) {
        return (
          item.yourcity.toLowerCase() == location.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase()
        );
      }

      if (jobType && experience) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase()
        );
      }
      if (jobType && qualification) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (experience && qualification) {
        return (
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (jobType && state) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (category && country) {
        let newCountry = country.split(",");
        return (
          item.category.toLowerCase() == category.toLowerCase() &&
          item.yourcountry.toLowerCase() == newCountry[1].toLowerCase()
        );
      }

      if (experience && state) {
        return (
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (state && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (city && state) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      //single condition

      if (category) {
        return item.category.toLowerCase() == category.toLowerCase();
      }
      if (jobType) {
        return item.type.toLowerCase() == jobType.toLowerCase();
      }
      if (experience) {
        return item.exp.toLowerCase() == experience.toLowerCase();
      }
      if (state) {
        console.log("LOGEDDDDDDDDDDDDDDDDDDD");
        return item.yourstate.toLowerCase() == state.toLowerCase();
      }
    });
    console.log(filter, "filterfilterfilterfilterfilter");
    console.log(state, "statestatestate");

    setJobData(filter);

    if (
      location == "" &&
      category == "" &&
      jobType == "" &&
      experience == "" &&
      state == "" &&
      city == ""
    ) {
      setJobData(JobData2);
    }
  }, [category, jobType, experience, qualification, state, city, country]);

  console.log(jobData, "jobdatatatasdasdasdasdasddsad");

  const removeBookmarkJob = (id, data) => {
    Data.map((data) => {
      if (ArrayData[data].jobId == id) {
        firebase
          .database()
          .ref(`bookmark/${firebase.auth().currentUser.uid}/${data}`)
          .remove()
          .then((res) => {
            setAlertData({
              open: true,
              severity: "success",
              msg: "Bookmark removed Successfully",
            });
          });
      } else {
      }
    });
  };

  const renderButton = (id, data) => {
    const isFind = Data.find((data) => ArrayData[data].jobId == id);
    if (isFind) {
      return (
        <a onClick={() => removeBookmarkJob(id, data)} class="favourite">
          <i>
            <Heart color="red" />
          </i>
        </a>
      );
    } else {
      return (
        <a onClick={() => bookmarkJob(data)} class="favourite">
          <i>
            <Heart />
          </i>
        </a>
      );
    }
  };

  const history = useHistory();

  // console.log(category, "categorycategorycategorycategorycategorycategory");

  // useEffect(() => {
  //   if (category != "" && location != "") {
  //     firebase
  //       .database()
  //       .ref(`jobs`)
  //       .once("value", (snapshot) => {
  //         let tempArr = []
  //         snapshot.forEach((employers) => {
  //           employers.forEach((jobs) => {
  //             if (category == jobs.val().category && location == jobs.val().location) {
  //               // alert("if")
  //               let data = { ...jobs.val() }
  //               tempArr.push(data)
  //             }
  //             // console.log("tempArrrrr=>>>>", tempArr);
  //             // setJobData(tempArr);
  //           });
  //         });
  //         // console.log(tempArr, "ARRRRRRRRRRRRRRRRRRRRRRRRRRR_LOCA_AND_CATEEE");
  //         setJobData(tempArr);
  //       });
  //   }
  // }, [category, location])

  // useEffect(() => {
  //   if (category != "") {
  //     firebase
  //       .database()
  //       .ref(`jobs`)
  //       .once("value", (snapshot) => {
  //         let tempArr = []
  //         snapshot.forEach((employers) => {
  //           employers.forEach((jobs) => {
  //             if (category == jobs.val().category) {
  //               // alert("if")
  //               let data = { ...jobs.val() }
  //               tempArr.push(data)
  //             }
  //             // console.log("tempArrrrr=>>>>", tempArr);
  //             // setJobData(tempArr);
  //           });
  //         });
  //         // console.log(tempArr, "ARRRRRRRRRRRRRRRRRRRRRRRRRRR");
  //         setJobData(tempArr);
  //       });
  //   }
  // }, [category])

  // useEffect(() => {
  //   if (location != "") {
  //     firebase
  //       .database()
  //       .ref(`jobs`)
  //       .once("value", (snapshot) => {
  //         let tempArr = []
  //         snapshot.forEach((employers) => {
  //           employers.forEach((jobs) => {
  //             if (location == jobs.val().location) {
  //               console.log("OFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
  //               // alert("if")
  //               let data = { ...jobs.val() }
  //               tempArr.push(data)
  //             }
  //             // console.log("tempArrrrr=>>>>", tempArr);
  //             // setJobData(tempArr);
  //           });
  //         });
  //         // console.log(tempArr, "ARRRRRRRRRRRRRRRRRRRRRRRRRRR");
  //         setJobData(tempArr);
  //       });
  //   }
  // }, [location])

  const submitForm = () => {
    setOpen(false);
    setAlertData({
      open: true,
      severity: "success",
      msg: "Applied Successfully",
    });
  };

  // const handleChangeIcon = (e) => {
  //   setChangeIcon({ check: !changeIcon.check.value });
  // };

  const renderApplyButton = (id, data) => {
    // console.log(data, "datadatadatadata");
    // log
    const isFind = appliedData.find((data) => appliedJob[data].jobId == id);
    if (!isFind) {
      return (
        <button
          onClick={() => {
            console.log("data", data);
            firebase
              .database()
              .ref(`profile/employer/${data.uid}`)
              .on("value", (snapshot) => {
                let res = snapshot.val();
                setVideoLink(res.videoRef);
              });
            isUser.isAuthenticated
              ? openDialogBox(data)
              : setAlertData({
                  open: true,
                  severity: "error",
                  msg: "Please Login App first",
                });
          }}
          href="#"
          class="button"
          data-toggle="modal"
          data-target="#apply-popup-id"
        >
          Apply Now
        </button>
      );
    } else {
      return (
        <button
          // onClick={() => {
          //   isUser.isAuthenticated
          //     ?
          //     openDialogBox(jobData)
          //     : setAlertData({
          //       open: true,
          //       severity: "error",
          //       msg: "Please Login App first",
          //     });

          // }}
          href="#"
          class="button"
          data-toggle="modal"
          data-target="#apply-popup-id"
        >
          Already Applied
        </button>
      );
    }
  };

  // console.log(results, "RESULESS_AREA_HEREEE");
  console.log(jobType, experience, "jobtype");

  const performSearch = (val) => {
    console.log(JobData2, "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO");
    setSearchVal(val);
    let results = JobData2.filter((item) => {
      return item.title.toLowerCase().startsWith(val.toLowerCase());
    });
    console.log(
      results,
      jobData,
      "resultsresults"
    );
    setJobData(results);
  };

  const callfunction = (e, data) => {
    e.preventDefault();
    setJobtype("");
    setCategory("");
    setLocation("");
    setExperience("");
    setQualification("");
    setState("");
    setCountry("");
    setCity("");
    setJobData([...jobData]);
    console.log(data, jobData, "DDDDDDDDDDDDDDDDDDDDDDDDDDDd");
  };

  const fileRefer = useRef();

  const [categoryAdmin, setCategoryAdmin] = useState("");

  useEffect(function effectFunction() {
    firebase
      .database()
      .ref("category")
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setCategoryAdmin(HistoryItems);
      });
  }, []);

  const CatData = Object.keys(categoryAdmin);
  console.log(CatData, "CAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");

  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div class="apply-popup">
          <div
            id="apply-popup-id"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    <i>
                      <Edit2 />
                    </i>
                    APPLY FOR THIS JOB
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span onClick={() => setOpen(false)} aria-hidden="true">
                      &times;
                    </span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <input
                      ref={nameRef}
                      type="text"
                      placeholder="Full Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      ref={emailRef}
                      type="email"
                      placeholder="Email Address"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      ref={messageRef}
                      class="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="form-group file-input-wrap">
                    <label>Upload CV</label>
                    <input
                      type="file"
                      ref={fileRefer}
                      disabled={selectedResume}
                      onChange={(e) => {
                        if (!selectedResume) {
                          setIsSelectResumeUrl(!isSelectResumeUrl);
                          if (!isSelectResumeUrl) {
                            if (e.target.files[0]) {
                              setLoading(true);
                              const file = e.target.files[0];
                              const storageRef = app.storage().ref();
                              const fileRef = storageRef.child(file.name);
                              fileRef.put(file).then((res) => {
                                storageRef
                                  .child(file.name)
                                  .getDownloadURL()
                                  .then(function (url) {
                                    setResumeUrl(url);
                                    setLoading(false);
                                  });
                              });
                            }
                          }
                        } else {
                          setAlertData({
                            open: true,
                            severity: "error",
                            msg: "You have already selected created resume!",
                          });
                        }
                      }}
                      title="Upload CV"
                    />
                    {isSelectResumeUrl && (
                      <button
                        onClick={() => {
                          setIsSelectResumeUrl(false);
                          setResumeUrl(null);
                          fileRefer.current.value = null;
                          // fileRefer(null)
                        }}
                      >
                        Remove
                      </button>
                    )}
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 20,
                      }}
                    >
                      <h4>Or</h4>
                    </div>
                    <button
                      class="button secondary-bg btn-block"
                      style={{
                        backgroundColor: selectedResume ? "#94d1ff" : "#f0eded",
                        color: "black",
                      }}
                      onClick={() => {
                        if (!isSelectResumeUrl) {
                          setSelectedResume(!selectedResume);
                          if (!selectedResume) {
                            firebase
                              .database()
                              .ref(`resumes`)
                              .on("value", (snapshot) => {
                                let tempArr = [];
                                snapshot.forEach((resume) => {
                                  tempArr.push(resume.key);
                                });
                                let isResume = tempArr.find(
                                  (id) => id == firebase.auth().currentUser.uid
                                );
                                if (isResume) {
                                  firebase
                                    .database()
                                    .ref(`resumes/${isResume}`)
                                    .on("value", (snapshot) => {
                                      setUserResume(snapshot.val());
                                    });
                                } else {
                                  setAlertData({
                                    open: true,
                                    severity: "error",
                                    msg: "Please make your resume first in Add resume section!",
                                  });
                                  setSelectedResume(false);
                                }
                              });
                          }
                        } else {
                          setAlertData({
                            open: true,
                            severity: "error",
                            msg: "You have already selected Uploaded resume!",
                          });
                        }
                      }}
                    >
                      Select created resume
                    </button>
                  </div>
                  <div class="more-option">
                    <input
                      class="custom-radio"
                      type="checkbox"
                      id="radio-4"
                      name="termsandcondition"
                    />
                    <label for="radio-4">
                      <span class="dot"></span> I accept the{" "}
                      <a href="#">terms & conditions</a>
                    </label>
                  </div>
                  <div>
                    <h4 style={{ fontWeight: "bold" }}>
                      Get To Know More About This Employer
                    </h4>
                  </div>
                  {videoLink && (
                    <iframe
                      width={400}
                      style={{
                        borderRadius: 5,
                        textAlign: "center",
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                      height={300}
                      src={videoLink ? videoLink : ""}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  )}

                  <button
                    disabled={loading}
                    style={{ backgroundColor: loading && "grey" }}
                    class="button primary-bg btn-block"
                    onClick={applyJob}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* <div style={{ padding: 30 }}>
        <NotificationAndAccount />
        <Header />
      </div> */}
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Job Listing</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a
                        onClick={() => {
                          history.push("/");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Job Listing
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            {/* const results = jobData.filter(item => {
    return item.title.toLowerCase().includes(searchVal.toLowerCase());
  }); */}

            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input
                    type="text"
                    placeholder="Enter Keywords"
                    value={searchVal}
                    onChange={(e) => performSearch(e.target.value)}
                  />
                  {/* <button>
                    <Search />
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Jo Listing */}
      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row no-gutters">
            <div class="col">
              {/* <div class="job-listing-container"> */}
              <Grid container spacing={1} className={classes.listingContainer}>
                <Grid item xs={12} sm={8} md={9}>
                  <div class="filtered-job-listing-wrapper">
                    <div class="job-view-controller-wrapper">
                      <div class="job-view-controller"></div>
                    </div>
                    {console.log(
                      jobData,
                      "setJobDatasetJobDatasetJobDatasetJobData"
                    )}
                    <div class="job-filter-result">
                      {jobData.length > 0 ? (
                        jobData.map((jobDat) => {
                          console.log(jobDat, "DDDDDDDDDd");
                          return (
                            <div class="job-list">
                              <div class="thumb">
                                <a href="#">
                                  <img
                                    src={jobDat?.userDetails?.avatar}
                                    class="img-fluid"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div class="body">
                                <div class="content">
                                  <h4>
                                    <Link
                                      to={{
                                        pathname: "/JobDetails",
                                        state: {
                                          data: jobDat,
                                          type: "Candidate",
                                        },
                                      }}
                                    >
                                      {jobDat.title}
                                    </Link>
                                  </h4>
                                  <div class="info">
                                    <span class="company">
                                      <a href="#">
                                        <i>
                                          <MapPin />
                                        </i>
                                        {/* {jobDat.location} */}
                                        {`${jobDat.yourcity} ${jobDat.yourstate} ${jobDat.yourcountry}`}
                                      </a>
                                    </span>
                                    <span class="job-type temporary">
                                      <a href="#">
                                        <i>
                                          <Clock />
                                        </i>
                                        {jobDat.type}
                                      </a>
                                    </span>
                                    <span class="office-location">
                                      {jobDat.salaryRange}
                                    </span>
                                  </div>
                                </div>
                                <div class="more">
                                  <div class="buttons">
                                    {renderApplyButton(jobDat.jobId, jobDat)}

                                    {renderButton(jobDat.jobId, jobDat)}
                                  </div>
                                  <p class="deadline">
                                    Deadline: {jobDat.date}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "30px",
                          }}
                        >
                          No Jobs Found{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <div class="job-filter-wrapper" style={{ height: "100%" }}>
                    <button
                      onClick={(e) => callfunction(e, JobData2)}
                      class="button"
                      style={{ marginTop: 5, marginLeft: 10 }}
                    >
                      Remove Filters
                    </button>

                    <div class="job-filter-dropdown same-pad category">
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={category}
                          onChange={handleChangeCategory}
                        >
                          {/* {CatData.map((data, index) => { */}
                          {professions.Sheet.map((data, index) => {
                            return (
                              <MenuItem value={data.Professions}>
                                {data.Professions}
                                {/* {categoryAdmin[data].category} */}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div class="job-filter-dropdown same-pad category">
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={country}
                          onChange={handleChangeCountry}
                        >
                          {allCountries.length > 0 &&
                            allCountries.map((item) => {
                              return (
                                <MenuItem
                                  value={`${item.alpha2Code},${item.name}`}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                    <div class="job-filter-dropdown same-pad location">
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          State
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={state}
                          onChange={handleChangeLocation}
                        >
                          {allStates.map((res) => {
                            return (
                              <MenuItem value={`${res.isoCode},${res.name}`}>
                                {`${res.name},${res.isoCode}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div class="job-filter-dropdown same-pad location">
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          City
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={city}
                          onChange={handleChangeCity}
                        >
                          {allcities.map((res) => {
                            return (
                              <MenuItem value={res.name}>{res.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div
                      data-id="job-type"
                      class="job-filter job-type same-pad"
                    >
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                        // onClick={()=>alert("claed")}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIcon ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          // onChange={handleChangeIcon}
                          onClick={() => setChangeIcon(!changeIcon)}
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Job Type
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <ul style={{ padding: 0 }}>
                            <li class="full-time">
                              <i>
                                <Clock />
                              </i>
                              <a
                                style={{
                                  color: jobType == "Full Time" ? "blue" : "",
                                }}
                                onClick={() => setJobtype("Full Time")}
                                data-attr="Full Time"
                              >
                                Full Time
                              </a>
                            </li>
                            <li class="part-time">
                              <i>
                                <Clock />
                              </i>
                              <a
                                style={{
                                  color: jobType == "Part Time" ? "blue" : "",
                                }}
                                onClick={() => setJobtype("Part Time")}
                                data-attr="Part Time"
                              >
                                Part Time
                              </a>
                            </li>
                            <li class="freelance">
                              <i>
                                <Clock />
                              </i>
                              <a
                                style={{
                                  color: jobType == "Freelance" ? "blue" : "",
                                }}
                                onClick={() => setJobtype("Freelance")}
                                data-attr="Freelance"
                              >
                                Freelance
                              </a>
                            </li>
                            <li class="temporary">
                              <i>
                                <Clock />
                              </i>
                              <a
                                style={{
                                  color: jobType == "Temporary" ? "blue" : "",
                                }}
                                onClick={() => setJobtype("Temporary")}
                                data-attr="Temporary"
                              >
                                Temporary
                              </a>
                            </li>
                            <li class="Permanent">
                              <i>
                                <Clock />
                              </i>
                              <a
                                style={{
                                  color: jobType == "Permanent" ? "blue" : "",
                                }}
                                onClick={() => setJobtype("Permanent")}
                                data-attr="Permanent"
                              >
                                Permanent
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                      {/* <h4 class="option-title">Job Type</h4> */}
                    </div>
                    <div
                      data-id="experience"
                      class="job-filter experience same-pad"
                    >
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIconExp ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          onClick={() => setChangeIconExp(!changeIconExp)}
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Experience
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <ul style={{ padding: 0 }}>
                            <li onClick={(e) => setExperience("Fresh")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: experience == "Fresh" ? "blue" : "",
                                }}
                                data-attr="Fresh"
                              >
                                Fresh
                              </a>
                            </li>
                            <li
                              value="Less than 1 year"
                              onClick={(e) => setExperience("Less than 1 year")}
                            >
                              <a
                                style={{
                                  cursor: "pointer",
                                  color:
                                    experience == "Less than 1 year"
                                      ? "blue"
                                      : "",
                                }}
                                data-attr="Less than 1 year"
                              >
                                Less than 1 year
                              </a>
                            </li>
                            <li onClick={(e) => setExperience("2 Year")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: experience == "2 Year" ? "blue" : "",
                                }}
                                data-attr="2 Year"
                              >
                                2 Year
                              </a>
                            </li>
                            <li onClick={(e) => setExperience("3 Year")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: experience == "3 Year" ? "blue" : "",
                                }}
                                data-attr="3 Year"
                              >
                                3 Year
                              </a>
                            </li>
                            <li onClick={(e) => setExperience("4 Year")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: experience == "4 Year" ? "blue" : "",
                                }}
                                data-attr="4 Year"
                              >
                                4 Year
                              </a>
                            </li>
                            <li onClick={(e) => setExperience("5 Year")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: experience == "5 Year" ? "blue" : "",
                                }}
                                data-attr="5 Year"
                              >
                                5 Year
                              </a>
                            </li>
                            <li onClick={(e) => setExperience("Above 5 Years")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color:
                                    experience == "Above 5 Years" ? "blue" : "",
                                }}
                                data-attr="Avobe 5 Years"
                              >
                                Above 5 Years
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {/* <div class="job-filter same-pad">
                    <Accordion
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                    >
                    <AccordionSummary
                        expandIcon={
                          !changeIconRange ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() => setChangeIconRange(!changeIconRange)}
                      >
                      <Typography variant="h4" className={classes.heading}>
                          Salary Range
                          </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <div class="price-range-slider">
                        <div
                            class="nstSlider"
                            data-range_min="0"
                            data-range_max="10000"
                            data-cur_min="0"
                            data-cur_max="6130"
                          >
                          <div class="bar"></div>
                            <div class="leftGrip"></div>
                            <div class="rightGrip"></div>
                            <div class="grip-label">
                              <span class="leftLabel"></span>
                              <span class="rightLabel"></span>
                            </div>
                            </div>
                            </div>
                            </AccordionDetails>
                    </Accordion>
                  </div> */}
                    {/* <div data-id="post" class="job-filter post same-pad">
                    <Accordion
                      style={{
                        backgroundImage:
                        "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                      >
                      <AccordionSummary
                        expandIcon={
                          !changeIconPosted ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                              )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() => setChangeIconPosted(!changeIconPosted)}
                        >
                        <Typography variant="h4" className={classes.heading}>
                        Date Posted
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                      <ul style={{ padding: 0 }}>
                      <li>
                      <a href="#" data-attr="Last hour">
                      Last hour
                      </a>
                      </li>
                          <li>
                          <a href="#" data-attr="Last 24 hour">
                              Last 24 hour
                              </a>
                              </li>
                          <li>
                          <a href="#" data-attr="Last 7 days">
                              Last 7 days
                              </a>
                              </li>
                          <li>
                          <a href="#" data-attr="Last 14 days">
                              Last 14 days
                              </a>
                              </li>
                          <li>
                          <a href="#" data-attr="Last 30 days">
                              Last 30 days
                              </a>
                              </li>
                        </ul>
                        </AccordionDetails>
                    </Accordion>
                  </div> */}
                    {/* <div data-id="gender" class="job-filter same-pad gender">
                    <Accordion
                    style={{
                        backgroundImage:
                        "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                      >
                      <AccordionSummary
                      expandIcon={
                          !changeIconGender ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() => setChangeIconGender(!changeIconGender)}
                      >
                      <Typography variant="h4" className={classes.heading}>
                      Gender
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                      <ul style={{ padding: 0 }}>
                          <li>
                          <a href="#" data-attr="Male">
                          Male
                            </a>
                            </li>
                            <li>
                            <a href="#" data-attr="Female">
                              Female
                              </a>
                          </li>
                          </ul>
                          </AccordionDetails>
                    </Accordion>
                  </div> */}
                    <div
                      data-id="qualification"
                      class="job-filter qualification same-pad"
                    >
                      <Accordion
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #ffffff, #f7f9fc)",
                          boxShadow: "none",
                        }}
                        className={classes.dropdownHead}
                      >
                        <AccordionSummary
                          expandIcon={
                            !changeIconQualification ? (
                              <AddIcon style={{ fontSize: "20px" }} />
                            ) : (
                              <RemoveIcon style={{ fontSize: "20px" }} />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ padding: 0 }}
                          onClick={() =>
                            setChangeIconQualification(!changeIconQualification)
                          }
                        >
                          <Typography variant="h4" className={classes.heading}>
                            Qualification
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                          <ul style={{ padding: 0 }}>
                            <li
                              onClick={(e) =>
                                setQualification("Elementary School")
                              }
                            >
                              <a
                                style={{
                                  cursor: "pointer",
                                  color:
                                    qualification == "Matriculation"
                                      ? "blue"
                                      : "",
                                }}
                              >
                                Elementary School
                              </a>
                            </li>
                            <li
                              onClick={(e) => setQualification("Middle School")}
                            >
                              <a
                                style={{
                                  cursor: "pointer",
                                  color:
                                    qualification == "Intermidiate"
                                      ? "blue"
                                      : "",
                                }}
                              >
                                Middle School
                              </a>
                            </li>
                            <li onClick={(e) => setQualification("Diploma")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color:
                                    qualification == "Graduate" ? "blue" : "",
                                }}
                              >
                                Diploma
                              </a>
                            </li>
                            <li
                              onClick={(e) => setQualification("High School")}
                            >
                              <a
                                style={{
                                  cursor: "pointer",
                                  color:
                                    qualification == "Master" ? "blue" : "",
                                }}
                              >
                                High School
                              </a>
                            </li>
                            <li
                              onClick={(e) => setQualification("Undergraduate")}
                            >
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: qualification == "PHD" ? "blue" : "",
                                }}
                              >
                                Undergraduate
                              </a>
                            </li>
                            <li onClick={(e) => setQualification("Graduate")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: qualification == "PHD" ? "blue" : "",
                                }}
                              >
                                Graduate
                              </a>
                            </li>
                            <li onClick={(e) => setQualification("Master")}>
                              <a
                                style={{
                                  cursor: "pointer",
                                  color: qualification == "PHD" ? "blue" : "",
                                }}
                              >
                                Master
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />

      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default JobListing;
