import React,{useEffect} from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { Edit, Search, Check } from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import FindYourJob from "./../../components/FindYourJob";

const Payment = (props) => {
  console.log(props,"PROPS");
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    setTimeout(() => {
      history.push("/")
    }, 3000);
  })

  return (
    <div>
      {/* <NotificationAndAccount  />
            <Header /> */}
      {/* <!-- Breadcrumb --> */}

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Payment Page</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Payment Page
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="block-wrapper">
                <div class="payment-result">
                  <div class="icon" style={{ background: "#023b92" }}>
                    <i>
                      <Check />
                    </i>
                  </div>
                  <h3>
                    You Unlocked <span>{props.location.state}</span> Package
                  </h3>
                  <p>Thanks!</p>
                  <div class="result">
                    <span>Your payment has been processed successfully.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </div>
  );
};

export default Payment;
