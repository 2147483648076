import React from "react";
import firebase from "firebase";
import userImg1 from "../../assests/dashboard/images/user-1.jpg";
import avatar2 from "../../assests/dashboard/images/avater-2.jpg";

const ChatBox = ({ msg, userId, type }) => {
  let isCurrentUser = userId === firebase.auth().currentUser.uid ? true : false;
  console.log(msg, userId, "MSG_AND_USER_ID");
  return (
    <li class={isCurrentUser ? "conversation in" : "conversation out"}>
      <div class="avater">
        {/* <img src="dashboard/images/avater-1.jpg" class="img-fluid" alt="" /> */}
        <img src={userImg1} class="img-fluid" alt="" />
      </div>
      <div class="message">
        {type == "2" ? (
          <a href={msg} target="_blank">
            <img src={msg} style={{ height: 50, width: 50 }} />
          </a>
        ) : (
          <span>{msg}</span>
        )}
      </div>
      <span class="send-time">2.32 am</span>
    </li>
  );
};

export default ChatBox;
