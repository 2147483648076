import React, { useEffect, useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Edit,
  Search,
  Cast,
  Layout,
  button,
  Compass,
  Briefcase,
  User,
  Edit2,
  FileText,
  Award,
  MessageCircle,
} from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import Thumb1 from "../../assests/images/feature/thumb-1.png";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import RegisterBox from "../../assests/images/register-box/1.png";
import RegisterBox2 from "../../assests/images/register-box/2.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";
import renderHTML from "react-render-html";
import { makeStyles } from "@material-ui/core";
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  started: {
    background: "#023b92 !important",
    "&:hover": {
      background: "#246df8 !important",
    },
  },
}));

const AboutUs = () => {
  const [jobData, setJobData] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [resume, setResume] = useState([]);
  const [companies, setCompanies] = useState([]);

  const classes = useStyles();
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let tempArr = [];
    firebase
      .database()
      .ref("jobs")
      .on("value", (snapshot) => {
        console.log(snapshot.val(), "SSSSSSSSSSSSSSSSSSSSSSSSS");
        snapshot.forEach((employers) => {
          employers.forEach((jobs) => {
            let data = { ...jobs.val() };
            console.log(data, "DDDDDDDDDDDDDDDDDDDDDDDDDDDDD");
            tempArr.push(data);
          });
          console.log(tempArr, "AasdSDASDASd");
        });
        setJobData(tempArr);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/candidate`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setCandidate(data);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/employer`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setCompanies(data);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`resumes`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setResume(data);
      });
  }, []);

  const candidateData = Object.keys(candidate);
  const companiesData = Object.keys(companies);
  const resumeData = Object.keys(resume);
  console.log(candidateData?.length, "JOSLASDASD");

  const [aboutContent, setAboutContent] = useState("");

  useEffect(() => {
    firebase
      .database()
      .ref("aboutApp")
      .on("value", (snapshot) => {
        console.log(snapshot.val(), "VALUESSSSSSSSSSSSSSSS");
        let data = snapshot.val() ? snapshot.val() : {};
        setAboutContent(data);
      });
  }, []);

  console.log(aboutContent,"aboutContentaboutContentaboutContent");

  return (
    <div>
      {/* <NotificationAndAccount  />
            <Header /> */}

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      {/* <!-- Breadcrumb --> */}
      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>About Us</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Breadcrumb End --> */}
      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="white-bg others-block">
                <div class="about-company">
                  <div class="row">
                    <div class="col-lg-6 order-lg-2">
                      {/* <div class="feature-thumb">
                        <img src={Thumb1} class="img-fluid" alt="" />
                      </div> */}
                    </div>
                    <div class="col-lg-6 order-lg-1">
                      <div class="feature-content">
                        {/* <h3>Over 10,00,000 + Jobs</h3> */}
                        {/* {renderHTML(aboutContent)} */}
                        {/* <p>
                          Welcome to Employ, we are the number #1 job website connecting employers and employees using unique features such as user friendly searches and video profile uploads.  Both parties will be able to showcase a video recording uploads why there the most suited.  Employers will be able to list their available position along with a 20 second video upload about their company.   Also all potential employees will have the ability to upload their resume and a 20 second video pitch why they are most qualify for the position.  We strives to connect right job with the right people.
                        </p>
                        <p>
                          Let's get everyone Employ-ed... Our mission is to bridge the employers and employees to find the best fit expeditiously.  We have job placement team ready, willing and able to collaborate job seeks
                        </p> */}
                        <a href="#" className={`${classes.started} button`}>
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="infobox-wrap">
                  <div class="row">
                    <div class="col-lg-4 col-md-6">
                      <div class="info-box">
                        <div class="icon">
                          <i>
                            <Cast />
                          </i>
                        </div>
                        <h4>Advertise A Job</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="info-box">
                        <div class="icon">
                          <i>
                            <Layout />
                          </i>
                        </div>
                        <h4>Recruiter Profiles</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="info-box">
                        <div class="icon">
                          <i>
                            <Compass />
                          </i>
                        </div>
                        <h4>Find Your dream job</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Fun Facts --> */}
                <div class="padding-top-90 padding-bottom-60 fact-bg">
                  <div class="container">
                    <div class="row fact-items">
                      <div class="col-md-3 col-sm-6">
                        <div class="fact">
                          <div class="fact-icon">
                            <i>
                              <Briefcase />
                            </i>
                          </div>
                          <p class="fact-number">
                            <span class="count" data-form="0" data-to="12376">
                              {jobData?.length}
                            </span>
                          </p>
                          <p class="fact-name">Live Jobs</p>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="fact">
                          <div class="fact-icon">
                            <i>
                              <User />
                            </i>
                          </div>
                          <p class="fact-number">
                            <span class="count" data-form="0" data-to="89562">
                              {candidateData?.length}
                            </span>
                          </p>
                          <p class="fact-name">Candidate</p>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="fact">
                          <div class="fact-icon">
                            <i>
                              <FileText />
                            </i>
                          </div>
                          <p class="fact-number">
                            <span class="count" data-form="0" data-to="28166">
                              {resumeData?.length}
                            </span>
                          </p>
                          <p class="fact-name">Resume</p>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="fact">
                          <div class="fact-icon">
                            <i>
                              <Award />
                            </i>
                          </div>
                          <p class="fact-number">
                            <span class="count" data-form="0" data-to="1366">
                              {companiesData?.length}
                            </span>
                          </p>
                          <p class="fact-name">Companies</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Fun Facts End --> */}
                <div class="padding-top-90 padding-bottom-90">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="call-to-action-box candidate-box">
                          <div class="icon">
                            <img src={RegisterBox} alt="" />
                          </div>
                          <span>Are You</span>
                          <h3>Candidate?</h3>
                          <Link
                            to="/register"
                            data-toggle="modal"
                            data-target="#exampleModalLong3"
                          >
                            Register Now <i class="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="call-to-action-box employer-register">
                          <div class="icon">
                            <img src={RegisterBox2} alt="" />
                          </div>
                          <span>Are You</span>
                          <h3>Employer?</h3>
                          <Link
                            to="/register"
                            data-toggle="modal"
                            data-target="#exampleModalLong3"
                          >
                            Register Now <i class="fas fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Modal --> */}
                <div class="account-entry">
                  <div
                    class="modal fade"
                    id="exampleModalLong3"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">
                            <i>
                              <Edit2 />
                            </i>
                            Registration
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="account-type">
                            <a href="#" class="candidate-acc active">
                              <i>
                                <User />
                              </i>
                              Candidate
                            </a>
                            <a href="#" class="employer-acc">
                              <i>
                                <Briefcase />
                              </i>
                              Employer
                            </a>
                          </div>
                          <form action="#">
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="Username"
                                class="form-control"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="email"
                                placeholder="Email Address"
                                class="form-control"
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="password"
                                placeholder="Password"
                                class="form-control"
                              />
                            </div>
                            <div class="more-option terms">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck2"
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck2"
                                >
                                  I accept the{" "}
                                  <a href="#">terms & conditions</a>
                                </label>
                              </div>
                            </div>
                            <button class="button primary-bg btn-block">
                              Register
                            </button>
                          </form>
                          <div class="shortcut-login">
                            <span>Or connect with</span>
                            <div class="buttons">
                              <a href="#" class="facebook">
                                <i class="fab fa-facebook-f"></i>Facebook
                              </a>
                              <a href="#" class="google">
                                <i class="fab fa-google"></i>Google
                              </a>
                            </div>
                            <p>
                              Already have an account? <a href="#">Login</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Testimonial --> */}
                <div class="padding-bottom-90">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="testimonial">
                          {/* <div class="testimonial-quote">
                            <img src={quoteLogo} class="img-fluid" alt="" />
                          </div> */}
                          <div class="testimonial-for">
                            {/* <div class="testimonial-item">
                              <p>
                                "Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua
                                egestas"
                              </p>
                            </div> */}
                            {/* <div class="testimonial-item">
                  <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"</p>
                </div>
                <div class="testimonial-item">
                  <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna"</p>
                </div>
                <div class="testimonial-item">
                  <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua cursus"</p>
                </div>
                <div class="testimonial-item">
                  <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua egestas"</p>
                </div>
                <div class="testimonial-item">
                  <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua cursus"</p>
                </div>
                <div class="testimonial-item">
                  <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua egestas"</p>
                </div> */}
                          </div>
                          <div class="testimonial-nav">
                            <div class="commenter-thumb">
                              <img
                                src="images/testimonial/thumb-1.jpg"
                                class="img-fluid com menter"
                                alt=""
                              />
                              <img
                                src="images/testimonial/1.png"
                                class="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div class="commenter-thumb">
                              <img
                                src="images/testimonial/thumb-2.jpg"
                                class="img-fluid com menter"
                                alt=""
                              />
                              <img
                                src="images/testimonial/2.png"
                                class="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div class="commenter-thumb">
                              <img
                                src="images/testimonial/thumb-3.jpg"
                                class="img-fluid com menter"
                                alt=""
                              />
                              <img
                                src="images/testimonial/3.png"
                                class="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div class="commenter-thumb">
                              <img
                                src="images/testimonial/thumb-4.jpg"
                                class="img-fluid com menter"
                                alt=""
                              />
                              <img
                                src="images/testimonial/4.png"
                                class="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div class="commenter-thumb">
                              <img
                                src="images/testimonial/thumb-5.jpg"
                                class="img-fluid com menter"
                                alt=""
                              />
                              <img
                                src="images/testimonial/5.png"
                                class="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div class="commenter-thumb">
                              <img
                                src="images/testimonial/thumb-2.jpg"
                                class="img-fluid com menter"
                                alt=""
                              />
                              <img
                                src="images/testimonial/2.png"
                                class="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div class="commenter-thumb">
                              <img
                                src="images/testimonial/thumb-3.jpg"
                                class="img-fluid com menter"
                                alt=""
                              />
                              <img
                                src="images/testimonial/3.png"
                                class="comapnyLogo"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Testimonial End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Candidates Details End --> */}

      {/* <!-- Career Advice --> */}
      {/* <div class="padding-bottom-90">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section-he  ader">
                <h2>Career Advice</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <article class="blog-grid">
                <div class="body">
                  <span class="date">
                    22 <span>Dec</span>
                  </span>
                  <h3>
                    <a href="blog-details.html">Lorem ipsum dolor sit amet</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
                <div class="info">
                  <span class="author">
                    <a href="#">
                      <i>
                        <User />
                      </i>
                      Lorem ipsum
                    </a>
                  </span>
                  <span class="comments">
                    <a href="#">
                      <i>
                        <MessageCircle />
                      </i>
                      42
                    </a>
                  </span>
                </div>
              </article>
            </div>
            <div class="col-md-6 col-lg-4">
              <article class="blog-grid">
                <div class="body">
                  <span class="date">
                    22 <span>Dec</span>
                  </span>
                  <h3>
                    <a href="blog-details.html">Lorem ipsum dolor sit amet</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
                <div class="info">
                  <span class="author">
                    <a href="#">
                      <i>
                        <User />
                      </i>
                      Lorem ipsum
                    </a>
                  </span>
                  <span class="comments">
                    <a href="#">
                      <i>
                        <MessageCircle />
                      </i>
                      42
                    </a>
                  </span>
                </div>
              </article>
            </div>
            <div class="col-md-6 col-lg-4">
              <article class="blog-grid">
                <div class="body">
                  <span class="date">
                    22 <span>Dec</span>
                  </span>
                  <h3>
                    <a href="blog-details.html">Lorem ipsum dolor sit amet</a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                </div>
                <div class="info">
                  <span class="author">
                    <a href="#">
                      <i>
                        <User />
                      </i>
                      Lorem ipsum
                    </a>
                  </span>
                  <span class="comments">
                    <a href="#">
                      <i>
                        <MessageCircle />
                      </i>
                      42
                    </a>
                  </span>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Career Advice End --> */}

      {/* <!-- App Download --> */}
      <div class="padding-top-90 padding-bottom-90 app-download-bg">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="app-download">
                <h2>Get Employ App</h2>
                <p>
                  Quis lectus nulla at volutpat diam ut. Congue quisque egestas
                  diam in arcu cursus euismod quis
                </p>
                <div class="app-download-button">
                  <a
                    href="https://www.apple.com/store"
                    target="_blank"
                    class="apple-app"
                  >
                    Apple Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.risingwings.castlecraft&gclid=CjwKCAjw2bmLBhBREiwAZ6ugo73co5MKfIqX9q0dDtcUhgAoodMpajgOWgId7kh6yZZCFKtX-KpcFBoCon8QAvD_BwE"
                    target="_blank"
                    class="android-app"
                  >
                    Google Play
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- App Download End --> */}

      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </div>
  );
};

export default AboutUs;
