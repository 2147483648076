import firebase from "firebase"

const firebaseConfig = {
    apiKey: "AIzaSyAEimAP17QCYzuUIIxwdbqweVvlSGIDqtg",
    authDomain: "employee-web-98f41.firebaseapp.com",
    projectId: "employee-web-98f41",
    storageBucket: "employee-web-98f41.appspot.com",
    messagingSenderId: "232247226457",
    appId: "1:232247226457:web:7659d8655c18360d464148",
    measurementId: "G-CPBKFGEHW8"
};
// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);