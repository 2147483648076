import React, { useEffect, useRef, useState } from "react";
import {
  EmployerDashboardSidebar,
  Footer,
  Header,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import {
  CheckSquare,
  Search,
  MapPin,
  CheckCircle,
  Download,
  Mail,
  Trash2,
  Eye,
  Edit2,
  Clock,
} from "react-feather";
import FindYourJob from "../../components/FindYourJob";
import firebase from "firebase";
import fire from "../../database";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
import LogoutAndDelete from "../../components/logoutAndDelete";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageJobs = () => {
  const classes = useStyles();
  const [userDetails, setUserDetails] = useState({});
  const [aboutUser, setAboutUser] = useState({});

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setAboutUser(userData);

    // alert()
    firebase
      .database()
      .ref(`profile/employer/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        console.log("snapshot=>>>", snapshot.val());
        setUserDetails(snapshot.val());
      });
  }, []);

  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const [ownJobs, setownJobs] = useState([]);
  const [ownJobs2, setownJobs2] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState(null);
  const titleRef = useRef();
  const categoryRef = useRef();
  const locationRef = useRef();
  const jobTypeRef = useRef();
  const expRef = useRef();
  const rangeRef = useRef();
  const qualificationRef = useRef();
  const genderRef = useRef();
  const vacancyRef = useRef();
  const dateRef = useRef();
  const respoRef = useRef();
  const benefitsRef = useRef();
  const educationRef = useRef();
  const yourLocationRef = useRef();
  const countryRef = useRef();
  const cityRef = useRef();
  const zipcodeRef = useRef();
  const jobDesRef = useRef();

  console.log("selectedJob", selectedJob);

  const onChange = (e) => {
    console.log(e.target.name, e.target.value, "TINGGGGGGGGGGGGGGG");
    setSelectedJob({
      ...selectedJob,
      [e.target.name]: e.target.value,
    });
  };

  const saveJob = () => {
    if (
      (!titleRef.current.value,
        !categoryRef.current.value,
        !locationRef.current.value,
        !jobTypeRef.current.value,
        !expRef.current.value,
        !rangeRef.current.value,
        !qualificationRef.current.value,
        !genderRef.current.value,
        !vacancyRef.current.value,
        !dateRef.current.value,
        !jobDesRef.current.value,
        !benefitsRef.current.value,
        !countryRef.current.value,
        !cityRef.current.value,
        !zipcodeRef.current.value,
        !yourLocationRef.current.value)
    ) {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Add Every Detail",
      });
    } else {
      firebase
        .database()
        .ref(`jobs/${firebase.auth().currentUser.uid}/${jobs}`)
        .set({
          title: titleRef.current.value,
          category: categoryRef.current.value,
          location: locationRef.current.value,
          type: jobTypeRef.current.value,
          exp: expRef.current.value,
          salaryRange: rangeRef.current.value,
          Qualification: qualificationRef.current.value,
          gender: genderRef.current.value,
          vacancy: vacancyRef.current.value,
          date: dateRef.current.value,
          jobDes: jobDesRef.current.value,
          respo: respoRef.current.value,
          education: educationRef.current.value,
          benefits: benefitsRef.current.value,
          yourcountry: countryRef.current.value,
          yourcity: cityRef.current.value,
          zipcode: zipcodeRef.current.value,
          yourlocation: yourLocationRef.current.value,
          companyName: "",
          webAddress: "",
          companyProfile: "",
          Selectpackage: "",
          // Applicant: []
        })
        .then((res) => {
          setAlertData({
            open: true,
            severity: "success",
            msg: "Job Posted Successfully",
          });
          setSelectedJob(null);
          setOpenEdit(false);
          setJobs(null);
        });
    }
  };

  const [searchVal, setSearchVal] = useState("")

  useEffect(async () => {
    if (searchVal == "") {
      let tempArr = []
      firebase
        .database()
        .ref(`jobs/${firebase?.auth()?.currentUser?.uid}`)
        .on("value", (snapshot) => {
          // let data = snapshot.val() ? snapshot.val() : {};
          // let Items = { ...data };
          // setownJobs(Items);
          snapshot.forEach(inner => {
            console.log(inner.val(), "VLUUUUUUUUUUUUUUUUU");
            tempArr.push(inner.val())
          })
        });
      setownJobs(tempArr)
      setownJobs2(tempArr)
    }
    else {
      // alert("else")
      let results = ownJobs2.filter((item) => {
        return item.title.toLowerCase().includes(searchVal.toLowerCase());
        // console.log(item, "ITMMMMMMMMMMMMMMMMMMMMMMMMM");
      });
      console.log(
        results,
        "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO_RESSSSSSSSSSSSs"
      );
      await setownJobs(results);
    }
  }, [searchVal]);




  console.log("OWNJOBSSSS=>", ownJobs);

  const DeleteJob = (key) => {
    firebase
      .database()
      .ref(`jobs/${firebase?.auth()?.currentUser?.uid}/${key}`)
      .remove()
      .then((res) => {
        alert("deleted successfullyyy");
      });
    setOpen(false);
    setJobs(null);
  };

  const handleClose = () => {
    setOpen(false);
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const NavigateTojobDescription = (data) => {
    history.push({
      pathname: "/jobDetails",
      state: { data: data, type: "employer" },
    });
  };

  // const data = Object.keys(ownJobs);

  // useEffect(() => {

  // }, [searchVal])

  // console.log("DATAAAAAAAAAAAAAAAAAAAA", data);

  console.log(ownJobs, "OWNNNNNNNNNNNNNNNNNN");

  return (
    <div>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <Dialog
        open={openEdit}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenEdit(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div class="apply-popup">
          <div
            style={{ width: "452px" }}
            id="apply-popup-id"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    <i>
                      <Edit2 />
                    </i>
                    Edit this Job
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span onClick={() => setOpenEdit(false)} aria-hidden="true">
                      &times;
                    </span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <input
                      ref={titleRef}
                      type="text"
                      placeholder="Job title"
                      class="form-control"
                      defaultValue={selectedJob ? selectedJob.title : ""}
                    />
                  </div>

                  <div class="form-group">
                    <select
                      ref={categoryRef}
                      // defaultChecked={selectedJob ? 'Accounting / Finance' : 'Health Care'}
                      // defaultValue={selectedJob ? 'Accounting / Finance' : 'Health Care'}
                      value={selectedJob && selectedJob.category}
                      onChange={onChange}
                      name="category"
                      class="form-control"
                    >
                      <option value={"Select Category"}>Select Category</option>
                      <option value={"Accounting / Finance"}>
                        Accounting / Finance
                      </option>
                      <option value={"Health Care"}>Health Care</option>
                      <option value={"Garments / Textile"}>
                        Garments / Textile
                      </option>
                      <option value={"Telecommunication"}>
                        Telecommunication
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      ref={locationRef}
                      defaultValue={selectedJob && selectedJob.location}
                      type="text"
                      placeholder="Job Location"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <select
                      // defaultChecked={selectedJob && selectedJob.type}
                      // defaultValue={selectedJob && selectedJob.type}
                      class="form-control"
                      ref={jobTypeRef}
                      name="type"
                      onChange={onChange}
                      value={selectedJob && selectedJob.type}
                    >
                      <option>Job Type</option>
                      <option>Part Time</option>
                      <option>Full Time</option>
                      <option>Temperory</option>
                      <option>Permanent</option>
                      <option>Freelance</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <select
                      class="form-control"
                      ref={expRef}
                      value={selectedJob && selectedJob.exp}
                      // onChange={onChange}
                      // defaultChecked={selectedJob && selectedJob.exp}
                      // defaultValue={selectedJob && selectedJob.exp}
                      name="exp"
                      onChange={onChange}
                    >
                      <option>Experience (Optional)</option>
                      <option>Less than 1 Year</option>
                      <option>2 Year</option>
                      <option>3 Year</option>
                      <option>4 Year</option>
                      <option>Over 5 Year</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      defaultValue={selectedJob && selectedJob.salaryRange}
                      ref={rangeRef}
                      // value={jobDetails.salaryRange}
                      // onChange={onChange}
                      type="text"
                      name="salaryRange"
                      class="form-control"
                      placeholder="Salary Range"
                    />
                  </div>
                  <div class="form-group">
                    <select
                      //   defaultChecked={selectedJob && selectedJob.Qualification}
                      //   defaultValue={selectedJob && selectedJob.Qualification}
                      class="form-control"
                      ref={qualificationRef}
                      value={selectedJob && selectedJob.Qualification}
                      onChange={onChange}
                      name="Qualification"
                    >
                      <option>Qualification</option>
                      <option>Matriculation</option>
                      <option>Intermidiate</option>
                      <option>Gradute</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <select
                      class="form-control"
                      // defaultChecked={selectedJob && selectedJob.gender}
                      // defaultValue={selectedJob && selectedJob.gender}
                      ref={genderRef}
                      value={selectedJob && selectedJob.gender}
                      onChange={onChange}
                      name="gender"
                    >
                      <option>Gender</option>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      defaultValue={selectedJob && selectedJob.vacancy}
                      ref={vacancyRef}
                      // value={jobDetails.vacancy}
                      // onChange={onChange}
                      name="vacancy"
                      class="form-control"
                      placeholder="Vacancy"
                    />
                  </div>

                  <div class="form-group datepicker">
                    <input
                      type="date"
                      class="form-control"
                      defaultValue={selectedJob && selectedJob.date}
                      ref={dateRef}
                      // value={jobDetails.date}
                      name="date"
                    // onChange={onChange}
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      id="mytextarea"
                      style={{ width: "100%" }}
                      ref={jobDesRef}
                      class="tinymce-editor tinymce-editor-1"
                      placeholder="Description text here"
                      defaultValue={selectedJob && selectedJob.jobDes}
                      //   onChange={onChange}
                      //   value={jobDetails.jobDes}
                      name="jobDes"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <textarea
                      id="mytextarea2"
                      class="tinymce-editor tinymce-editor-2"
                      placeholder="Responsibilities (Optional)"
                      defaultValue={selectedJob && selectedJob.respo}
                      ref={respoRef}
                      //   onChange={onChange}
                      //   value={jobDetails.respo}
                      style={{ width: "100%" }}
                      name="respo"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <textarea
                      id="mytextarea3"
                      class="tinymce-editor tinymce-editor-2"
                      placeholder="Education (Optional)"
                      defaultValue={selectedJob && selectedJob.education}
                      ref={educationRef}
                      //   onChange={onChange}
                      //   value={jobDetails.education}
                      style={{ width: "100%" }}
                      name="education"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <textarea
                      id="mytextarea4"
                      class="tinymce-editor tinymce-editor-2"
                      placeholder="Benefits here"
                      style={{ width: "100%" }}
                      defaultValue={selectedJob && selectedJob.benefits}
                      ref={benefitsRef}
                      //   onChange={onChange}
                      //   value={jobDetails.benefits}
                      name="benefits"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <select
                      class="form-control"
                      value={selectedJob && selectedJob.yourcountry}
                      onChange={onChange}
                      ref={countryRef}
                      // defaultChecked={selectedJob && selectedJob.yourCountry}
                      // defaultValue={selectedJob && selectedJob.yourCountry}
                      name="yourcountry"
                    >
                      <option>Country</option>
                      <option>Lorem ipsum</option>
                      <option>Lorem ipsum dolor</option>
                      <option>Lorem ipsum dolor</option>
                      <option>Lorem ipsum dolor</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="exampleFormControlSelect11"
                      value={selectedJob && selectedJob.yourcity}
                      onChange={onChange}
                      // defaultChecked={selectedJob && `${selectedJob.yourCity}`}
                      // defaultValue={selectedJob && `${selectedJob.yourCity}`}
                      ref={cityRef}
                      name="yourcity"
                    >
                      <option>City</option>
                      <option>Lorem ipsum</option>
                      <option>Lorem ipsum dolor</option>
                      <option>Lorem ipsum dolor</option>
                      <option>Lorem ipsum dolor</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Zip Code"
                      // value={jobDetails.zipcode}
                      // onChange={onChange}
                      defaultValue={selectedJob && selectedJob.zipcode}
                      ref={zipcodeRef}
                      name="zipcode"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Your Location"
                      // value={jobDetails.yourlocation}
                      // onChange={onChange}
                      ref={yourLocationRef}
                      defaultValue={selectedJob && selectedJob.yourlocation}
                      name="yourlocation"
                    />
                  </div>
                  <button class="button primary-bg btn-block" onClick={saveJob}>
                    Save Job
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div class="apply-popup">
          <div
            id="apply-popup-id"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Are you sure you want to delete this job.
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span onClick={() => setOpen(false)} aria-hidden="true">
                      &times;
                    </span>
                  </button>
                </div>
                <div
                  style={{ flexDirection: "row", justifyContent: "center" }}
                  class="modal-body"
                >
                  <button
                    class="button primary-bg btn-block"
                    onClick={() => {
                      DeleteJob(jobs);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    class="button primary-bg btn-block"
                    onClick={() => {
                      setOpen(false);
                      setJobs(null);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Employer Dashboard</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Employer Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input
                    type="text"
                    placeholder="Enter Keywords"
                    value={searchVal}
                    onChange={(e) => setSearchVal(e.target.value)}
                  />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="dashboard-container">
                <div class="dashboard-content-wrapper">
                  <div class="manage-job-container">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th>Applications</th>
                          <th>Deadline</th>
                          {/* <th>Status</th> */}
                          <th class="action">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ownJobs.length > 0
                          ? ownJobs.map((jobs, index) => {
                            console.log("data[jobs]", ownJobs[jobs]);
                            const { title, location, type, date } = jobs;

                            return (
                              <tr class="job-items">
                                <td class="title">
                                  <h5>
                                    <a href="#">{title}</a>
                                  </h5>
                                  <div class="info">
                                    <span class="office-location">
                                      <a href="#">
                                        <i>
                                          <MapPin />
                                        </i>
                                        {location}
                                      </a>
                                    </span>
                                    <span class="job-type full-time">
                                      <a href="#">
                                        <i>
                                          <Clock />
                                        </i>
                                        {type}
                                      </a>
                                    </span>
                                  </div>
                                </td>
                                <td class="application">
                                  <Link
                                    to={{
                                      pathname: "/ManageCandidate",
                                      state: {
                                        applicant: jobs.applicant,
                                        jobname: jobs.title,
                                      },
                                    }}
                                  >
                                    {jobs.applicant
                                      ? jobs.applicant.length
                                      : 0}{" "}
                                    Application(s)
                                  </Link>
                                </td>
                                <td class="deadline">{date}</td>
                                {/* <td class="status active">Active</td> */}
                                <td class="action">
                                  <a
                                    onClick={() =>
                                      NavigateTojobDescription(jobs)
                                    }
                                    class="preview"
                                    title="Preview"
                                  >
                                    <i>
                                      <Eye />
                                    </i>
                                  </a>
                                  <a
                                    onClick={() => {
                                      console.log(
                                        "jobs category",
                                        jobs.category
                                      );
                                      setJobs(jobs);
                                      setSelectedJob(jobs);

                                      setOpenEdit(true);
                                    }}
                                    class="edit"
                                    title="Edit"
                                  >
                                    <i>
                                      <Edit2 />
                                    </i>
                                  </a>
                                  <a class="remove" title="Delete">
                                    <i>
                                      <Trash2
                                        onClick={() => {
                                          setJobs(jobs);
                                          setOpen(true);
                                        }}
                                      />
                                    </i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </table>
                    {/* <div class="pagination-list text-center">
                      <nav class="navigation pagination">
                        <div class="nav-links">
                          <a class="prev page-numbers" href="#">
                            <i class="fas fa-angle-left"></i>
                          </a>
                          <a class="page-numbers" href="#">
                            1
                          </a>
                          <span
                            aria-current="page"
                            class="page-numbers current"
                          >
                            2
                          </span>
                          <a class="page-numbers" href="#">
                            3
                          </a>
                          <a class="page-numbers" href="#">
                            4
                          </a>
                          <a class="next page-numbers" href="#">
                            <i class="fas fa-angle-right"></i>
                          </a>
                        </div>
                      </nav>
                    </div> */}
                  </div>
                </div>
                <div class={`${classes.hide} dashboard-sidebar`}>
                  <div class="company-info">
                    <div class="thumb">
                      <img src={userDetails?.avatar} class="img-fluid" alt="" />
                    </div>
                    <div class="company-body">
                      <h5>{aboutUser?.name}</h5>
                      <span>@username</span>
                    </div>
                  </div>

                  <div class="dashboard-menu">
                    {/* <ul>
                      <li>
                        <i class="fas fa-home"></i>
                        <a href="employer-dashboard.html">Dashboard</a>
                      </li>
                      <li>
                        <i class="fas fa-user"></i>
                        <a href="employer-dashboard-edit-profile.html">
                          Edit Profile
                        </a>
                      </li>
                      <li class="active">
                        <i class="fas fa-briefcase"></i>
                        <a href="employer-dashboard-manage-job.html">
                          Manage Jobs
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-users"></i>
                        <a href="employer-dashboard-manage-candidate.html">
                          Manage Candidates
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-heart"></i>
                        <a href="#">Shortlisted Resumes</a>
                      </li>
                      <li>
                        <i class="fas fa-plus-square"></i>
                        <a href="employer-dashboard-post-job.html">
                          Post New Job
                        </a>
                      </li>
                      <li>
                        <i class="fas fa-comment"></i>
                        <a href="employer-dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i class="fas fa-calculator"></i>
                        <a href="employer-dashboard-pricing.html">
                          Pricing Plans
                        </a>
                      </li>
                    </ul> */}
                    <EmployerDashboardSidebar />
                    <LogoutAndDelete />
                    {/* <!-- Modal --> */}
                    <div
                      class="modal fade modal-delete"
                      id="modal-delete"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body">
                            <h4>
                              <i>
                                <Trash2 />
                              </i>
                              Delete Account
                            </h4>
                            <p>
                              Are you sure! You want to delete your profile.
                              This can't be undone!
                            </p>
                            <form action="#">
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder="Enter password"
                                />
                              </div>
                              <div class="buttons">
                                <button class="delete-button">
                                  Save Update
                                </button>
                                <button class="">Cancel</button>
                              </div>
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                />
                                <label class="form-check-label">
                                  You accepts our{" "}
                                  <a href="#">Terms and Conditions</a> and{" "}
                                  <a href="#">Privacy Policy</a>
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </div >
  );
};
export default ManageJobs;
