import React, { useEffect, useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { Edit, Search, MapPin, Mail, PhoneCall } from "react-feather";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import FindYourJob from "../../components/FindYourJob";
const ContactUS = () => {
  const history = useHistory();
  const [inputValue, setinputValue] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = () => {
    history.push("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setinputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = () => {
    if (inputValue.name.length < 1) {
      setOpenAlert(true);
    } else if (inputValue.email.length < 1) {
      setOpenAlert(true);
    } else if (inputValue.phone.length < 1) {
      setOpenAlert(true);
    } else if (inputValue.subject.length < 1) {
      setOpenAlert(true);
    } else if (inputValue.message.length < 1) {
      setOpenAlert(true);
    } else {
      setOpenDialog(true);
      setinputValue({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <div>
      {/* <NotificationAndAccount  />
            <Header /> */}

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your message has been submitted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {openAlert ? (
        <>
          <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={() => setOpenAlert(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={() => setOpenAlert(false)} severity="warning">
              Fill out all the required textfields
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {/* <!-- Breadcrumb --> */}
      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Contact Us</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Contact Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb end */}
      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="contact-block">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="contact-address">
                      <h4>Contact Info</h4>
                      <ul>
                        <li>
                          <i>
                            <MapPin />
                          </i>
                          Lorem ipsum dolor sit amet consectetur
                        </li>
                        <li>
                          <i>
                            <Mail />
                          </i>
                          Lorem ipsum dolor sit amet consectetur
                        </li>
                        <li>
                          <i>
                            <PhoneCall />
                          </i>
                          +99 06 1234 566 88
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-7 offset-lg-1">
                    <div class="contact-form">
                      <h4>Keep In Touch</h4>
                      <form id="contactForm">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                name="name"
                                value={inputValue.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                type="email"
                                class="form-control"
                                placeholder="Email"
                                name="email"
                                value={inputValue.email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Phone"
                                name="phone"
                                value={inputValue.phone}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Subject"
                                name="subject"
                                value={inputValue.subject}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <textarea
                                class="form-control"
                                placeholder="Message"
                                name="message"
                                value={inputValue.message}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <p class="input-success">
                          Your message has been sent. Thanks for contact.
                        </p>
                        <p class="input-error">
                          Something went wrong while sending. Please try leter.
                        </p>
                      </form>
                      <button
                        class="button"
                        style={{
                          background: "#246df8",
                          borderRadius: "3px",
                          border: 0,
                          color: "#ffffff",
                          padding: "10px 30px",
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: 600,
                          fontSize: "1.4rem",
                        }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />

      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </div>
  );
};

export default ContactUS;
