import React, { useState, useEffect } from "react";
import "./style.css";
import {
  BarChart2,
  Edit,
  Feather,
  Briefcase,
  Package,
  PieChart,
  Command,
  LifeBuoy,
  Headphones,
  Radio,
  Heart,
  MapPin,
  Clock,
  User,
  MessageCircle,
} from "react-feather";

import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  TextField,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import recentJobIcon1 from "../../assests/images/company/company-logo-1.png";
import HomePageSlider from "./../../components/homePageSlider/HomePageSlider";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Footer,
  Header,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import firebase from "firebase";
import fire from "../../database";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import Slider from "react-slick";
import quoteImg from "../../assests/images/testimonial/quote.png";
import Profession from "../JobListing/professions.json";
import thumb1 from "../../assests/images/testimonial/thumb-1.jpg";
import thumb2 from "../../assests/images/testimonial/thumb-2.jpg";
import thumb3 from "../../assests/images/testimonial/thumb-3.jpg";
import thumb4 from "../../assests/images/testimonial/thumb-4.jpg";
import thumb5 from "../../assests/images/testimonial/thumb-5.jpg";

import slickLogo1 from "../../assests/images/testimonial/1.png";
import slickLogo2 from "../../assests/images/testimonial/2.png";
import slickLogo3 from "../../assests/images/testimonial/3.png";
import slickLogo4 from "../../assests/images/testimonial/4.png";
import slickLogo5 from "../../assests/images/testimonial/5.png";
import { Country, State, City } from "country-state-city";
import "./style.css";
import axios from "axios";
import CityState from "./CityState.json";
// import NotificationAndAccount from "../../components/NotificationAndAccount";
const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  formControl: {
    margin: "0 8px",
    minWidth: 120,
    // maxWidth: 300,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: "1.7rem",
    fontWeight: 500,
    color: "#212529",
  },
  dropdownHead: {
    "&.MuiAccordion-root .MuiAccordionSummary-root": {
      "&:hover div": {
        color: "blue",
      },
      "&:hover div > h4": {
        color: "#246df8",
      },
    },
  },
  listingContainer: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  dropdownLabel: {
    color: "#fff",
    fontSize: "1.5rem",
  },
  dropdownStyle: {
    "&.MuiInputBase-root .MuiSelect-selectMenu": {
      minHeight: "3.1876em",
      color: "#fff",
    },
    "&.MuiInputBase-root .MuiSvgIcon-root": {
      color: "#fff",
    },
  },

  searchInput: {
    marginTop: "12px",
    "&.MuiFormControl-root .MuiInputBase-root": {
      border: "2px solid #ffffff66",
      background: "#fff",
    },
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input": {
      width: "100% !important",
      height: "20px",
      borderBottom: "none",
      color: "black",
    },
    "&.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input::placeholder":
      {
        color: "black",
      },
    "&.MuiFormControl-root .MuiInputBase-root::before": {
      // borderBottom: "2px solid #ffffff66",
      borderBottom: "none",
    },
  },
}));

const isUser = { isAuthenticated: false };
const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));

  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const Home = (props) => {
  console.log(Profession.Sheet, "ProfessionProfessionProfession");
  const classes = useStyles();
  const [isloading, setIsloading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [blogKeys, setBlogKeys] = useState([]);
  const [blogUserKeys, setBloguserKeys] = useState([]);
  const [filteredSearch, setFilterSearch] = useState([]);
  const [filterLocation, setFilterLocation] = useState([]);
  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    let temp = [];
    let keys = [];
    let userKeys = [];
    firebase
      .database()
      .ref(`blogs`)
      .on("value", (snapshot) => {
        snapshot.forEach((users) => {
          users.forEach((inner) => {
            keys.push(inner.key);
            userKeys.push(users.key);
            temp.push(inner.val());
            console.log(inner.val(), "VVVVVVVVVVVVVVVVVVVVVV");
          });
        });
        setBlogs(temp);
        setBlogKeys(keys);
        setBloguserKeys(userKeys);
      });
  }, []);

  console.log(blogUserKeys, "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB");

  setTimeout(() => {
    setIsloading(false);
  }, 2000);

  const history = useHistory();
  const [user, setUser] = useState(null);
  const [user2, setUser2] = useState(null);
  const [nav1, setNav1] = React.useState(null);
  const [location, setLocation] = useState("");
  const [AllReviews, setAllReviews] = useState([]);
  const [category, setCategory] = useState("");
  const [nav2, setNav2] = React.useState(null);
  const [JobCategories, setJobCategories] = React.useState([]);
  const [JobCategoriesCount, setJobCategoriesCount] = useState([]);
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [jobType, setJobtype] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [JobData2, setJobData2] = useState([]);
  const [categoryAdmin, setCategoryAdmin] = useState("");
  const [allStates, setAllState] = useState([]);
  const [allcities, setAllCities] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [cityStateData, setCityStateData] = useState([]);
  //   { id: 0, category: "Select Category", noOfJobs: 0 },
  //   { id: 1, category: "Accounting / Finance", noOfJobs: 0 },
  //   { id: 2, category: "Health Care", noOfJobs: 0 },
  //   { id: 3, category: "Garments / Textile", noOfJobs: 0 },
  //   { id: 4, category: "Telecommunication", noOfJobs: 0 },
  // ]);

  console.log(CityState, "Asdakjdhasdkjahdakdh");

  useEffect(() => {
    let newArr = [...CityState[0].Canada, ...CityState[0].US];
    console.log(newArr, "arrrrrrrrrrrrrrrrrr");
    setCityStateData(newArr);
  }, []);

  const getCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition((result) => {
      console.log(result, "RRRER");
      axios
        .get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${result.coords.latitude}&longitude=${result.coords.longitude}&localityLanguage=en`
        )
        .then((res) => {
          console.log(res, "RES_FINAL");
          setCity(`${res.data.city} , ${res.data.countryName}`);
        });
    });
  };

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries/states")
      .then((res) => {
        let tempArr = [];

        res.data.data.map((country) => {
          country.states.map((val) => {
            let newVal = `${val.name}, ${country.name}`;
            tempArr.push(newVal);
          });
        });

        console.log(tempArr, "temppppppppppppppp");

        setAllCountries(tempArr);
        console.log(res.data.data, "Ressssssssssssssssssssssssssss");
      })
      .catch((err) => {
        console.log(err, "errrrrrrrr");
      });
  }, []);

  useEffect(() => {
    let temp = [];
    let tempNames = [];
    firebase
      .database()
      .ref("jobsCategory")
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        console.log(data, "DDDDDDDDDDDDDDDD");
        snapshot.forEach((values) => {
          console.log(values.key, "lEEEEEEEEEEEEEEEEs");
          tempNames.push(values.key);
          temp.push(values.numChildren());
        });
        setJobCategoriesCount(temp);
        setJobCategories(tempNames);
      });
  }, []);

  const [allCategory, setAllCategory] = useState([]);

  useEffect(() => {
    console.log(JobCategories, "JOBBBBBBBBBBBBBBBBB");
    let tempArr = [];
    firebase
      .database()
      .ref(`category`)
      .on("value", (snapshot) => {
        snapshot.forEach((inner) => {
          JobCategories.map((res) => {
            if (res == inner.val().category) tempArr.push(inner.val());
          });
        });
      });

    setAllCategory(tempArr);
  }, [JobCategories.length]);

  console.log(allCategory, "JOB_CAAAAAAAAAAAAAAAAAAAAAA");

  useEffect(() => {
    let res = State.getStatesOfCountry("US");
    setAllState(res);
  }, []);

  useEffect(() => {
    let selectedState = state.split(",");
    console.log(selectedState, "SLECCCCCCCCCCCCCCCCCC");
    if (state != "") {
      let res = City.getCitiesOfState("US", selectedState[1]);
      setAllCities(res);
    } else {
      setAllCities([]);
    }
  }, [state]);

  // useEffect(() => {
  //   let filter = JobData2.filter((item) => {
  //     console.log(item, "itemitemitemitem");
  //     console.log(state, item.yourstate, "locationsssssssssssssssssss");

  //     if (category && city && jobType && experience && qualification && state) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     if (category && city && jobType && experience && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (category && city && jobType && state && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (category && city && state && experience && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (category && city && jobType && experience && state) {
  //       console.log("CALEEEEEEEEEEEE", item);
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     if (category && state && jobType && experience && qualification) {
  //       return (
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (state && city && jobType && experience && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     //4 conditions
  //     if (category && city && jobType && experience) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase()
  //       );
  //     }
  //     if (category && city && jobType && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (category && city && experience && qualification) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (category && jobType && experience && qualification) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (city && jobType && experience && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (state && jobType && experience && qualification) {
  //       return (
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (state && city && experience && qualification) {
  //       return (
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (state && jobType && city && qualification) {
  //       return (
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (state && jobType && experience && city) {
  //       return (
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.yourcity.toLowerCase() == city.toLowerCase()
  //       );
  //     }

  //     // 3 conditions

  //     if (category && city && jobType) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase()
  //       );
  //     }
  //     if (category && city && experience) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase()
  //       );
  //     }
  //     if (category && city && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (city && jobType && experience) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase()
  //       );
  //     }
  //     if (city && jobType && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (category && jobType && experience) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase()
  //       );
  //     }
  //     if (category && jobType && qualification) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (jobType && experience && qualification) {
  //       return (
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (jobType && city && state) {
  //       return (
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     if (category && experience && qualification) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (category && state && qualification) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (city && experience && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (city && experience && state) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     if (city && state && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (state && experience && qualification) {
  //       return (
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (category && city && state) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     // 2 conditions
  //     if (category && location) {
  //       return (
  //         item.yourcity.toLowerCase() == location.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase()
  //       );
  //     }
  //     if (category && jobType) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.type.toLowerCase() == jobType.toLowerCase()
  //       );
  //     }
  //     if (category && experience) {
  //       return (
  //         item.yourcity.toLowerCase() == location.toLowerCase() &&
  //         item.category.toLowerCase() == category.toLowerCase()
  //       );
  //     }
  //     if (category && qualification) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }
  //     if (jobType && location) {
  //       return (
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.yourcity.toLowerCase() == location.toLowerCase()
  //       );
  //     }

  //     if (location && qualification) {
  //       return (
  //         item.yourcity.toLowerCase() == location.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (location && experience) {
  //       return (
  //         item.yourcity.toLowerCase() == location.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase()
  //       );
  //     }

  //     if (jobType && experience) {
  //       return (
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.exp.toLowerCase() == experience.toLowerCase()
  //       );
  //     }
  //     if (jobType && qualification) {
  //       return (
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (experience && qualification) {
  //       return (
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (jobType && state) {
  //       return (
  //         item.type.toLowerCase() == jobType.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     if (category && state) {
  //       return (
  //         item.category.toLowerCase() == category.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     if (experience && state) {
  //       return (
  //         item.exp.toLowerCase() == experience.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     if (state && qualification) {
  //       return (
  //         item.yourstate.toLowerCase() == state.toLowerCase() &&
  //         item.Qualification.toLowerCase() == qualification.toLowerCase()
  //       );
  //     }

  //     if (city && state) {
  //       return (
  //         item.yourcity.toLowerCase() == city.toLowerCase() &&
  //         item.yourstate.toLowerCase() == state.toLowerCase()
  //       );
  //     }

  //     //single condition

  //     if (category) {
  //       return item.category.toLowerCase() == category.toLowerCase();
  //     }
  //     if (jobType) {
  //       return item.type.toLowerCase() == jobType.toLowerCase();
  //     }
  //     if (experience) {
  //       return item.exp.toLowerCase() == experience.toLowerCase();
  //     }
  //     if (state) {
  //       console.log("LOGEDDDDDDDDDDDDDDDDDDD");
  //       return item.yourstate.toLowerCase() == state.toLowerCase();
  //     }
  //   });
  //   console.log(filter, "filterfilterfilterfilterfilter");

  //   setJobData(filter);

  //   if (
  //     location == "" &&
  //     category == "" &&
  //     jobType == "" &&
  //     experience == "" &&
  //     state == "" &&
  //     city == ""
  //   ) {
  //     setJobData(JobData2);
  //   }
  // }, [category, jobType, experience, qualification, state, city]);

  let slider1 = [];
  let slider2 = [];

  const options = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1280: {
        items: 5,
      },
    },
  };

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    console.log(userData?.uid, "ASDASDASDASD");
    setUser2(userData);
    firebase
      .database()
      .ref(`users/${userData?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setUser(data);
        sessionStorage.setItem("usertype", JSON.stringify(data));
      });
    // getJobCategory();
  }, []);

  const [appliedJob, setappliedJob] = useState([{}]);
  useEffect(() => {
    firebase
      .database()
      .ref(`applied/${user?.uuid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setappliedJob(HistoryItems);
      });
  }, []);

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    firebase
      .database()
      .ref(`reviews`)
      .on("value", (snapshot) => {
        let tempArr = [];
        snapshot.forEach((rev) => {
          tempArr.push(rev.val());
          console.log("rev", rev.val());
        });
        setAllReviews(tempArr);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/employer`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setCompanies(data);
      });
  }, []);

  let Company = Object.keys(companies);

  const getJobCategory = async () => {
    firebase
      .database()
      .ref(`jobsCategory`)
      .on("value", (snapshot) => {
        let tempArr = JobCategories;
        snapshot.forEach((cat) => {
          JobCategories.forEach((job) => {
            if (job.id == cat.key) {
              tempArr[cat.key] = {
                id: cat.key,
                category: JobCategories[cat.key].category,
                noOfJobs: cat.numChildren(),
              };
              // setJobCategories(tempArr);
            }
          });
          // JobCategories.forEach((job)=>{
          //   if(job.id == cat){
          //     console.log('job cate', job.category)
          //   }
          // })
        });
      });
  };

  const [keyword, setkeyword] = useState("");

  const Data = Object.keys(appliedJob);

  useEffect(() => {
    // firebase.database().ref(`profile/employer`)

    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  isUserAuthenticate();
  // console.log("is user", isUser);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
    // handleonChange();
  };
  const handleChangeLocation = (event) => {
    setState(event.target.value);
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };

  // const [categoryAdmin, setCategoryAdmin] = useState("")

  useEffect(function effectFunction() {
    firebase
      .database()
      .ref("category")
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setCategoryAdmin(HistoryItems);
      });
  }, []);

  const CatData = Object.keys(categoryAdmin);
  console.log(CatData, "CAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");

  const renderAppliedJob = () => {
    if (user == null) {
      return;
    }
    if (user.type == "Candidate") {
      return (
        <div className="section-padding alice-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header-2 section-header-with-right-content">
                  <h2>Recent Jobs</h2>
                  <Link to="/JobListing" className="header-right">
                    + Browse All Jobs
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {Data.length > 0 ? (
                  Data.map((data, index) => {
                    return (
                      <div className="job-list">
                        <div className="thumb">
                          <a>
                            <img src={recentJobIcon1} />
                          </a>
                        </div>
                        <div className="body">
                          <div className="content">
                            <h4>
                              <a href="job-details.html">
                                {appliedJob[data]?.title}
                              </a>
                            </h4>
                            <div className="info">
                              <span className="company">
                                <a href="#">
                                  <i>
                                    <Briefcase />{" "}
                                  </i>
                                  {appliedJob[data]?.companyName}
                                </a>
                              </span>
                              <span className="office-location">
                                <a href="#">
                                  <i>
                                    <MapPin />
                                  </i>
                                  {appliedJob[data]?.location}
                                </a>
                              </span>
                              <span className="job-type full-time">
                                <a href="#">
                                  <i>
                                    <Clock />
                                  </i>
                                  {appliedJob[data]?.type}
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="more">
                            <div className="buttons">
                              <a
                                className="button"
                                data-toggle="modal"
                                data-target="#apply-popup-id"
                              >
                                Already Applied
                              </a>
                              {/* <a href="#" className="favourite">
                              <i>
                                <Heart size="24" />
                              </i>
                            </a> */}
                            </div>
                            {/* <p className="deadline">{appliedJob[data].data.date}</p> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>No Jobs Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const goToJobListing = (val) => {
    history.push({
      pathname: "/JobListing",
      state: {
        location: city,
        // cityValue: "",
        jobTitle: val,
      },
    });
  };

  const [employerData, setEmployerData] = useState("");

  const checkDetailsOfEmployee = async (id) => {
    firebase
      .database()
      .ref(`profile/employer/${id}`)
      .on("value", (snapshot) => {
        let res = snapshot.val();
        history.push({ pathname: "/EmployerDetails", state: snapshot.val() });
      });
  };

  const sortDecending = () => {
    // alert("sort")
    Company.sort(
      (res1, res2) => companies[res1].totalJobs - companies[res2].totalJobs
    ).reverse();
    {
      return Company.slice(0, 5).map((data, index) => {
        // console.log(companies[data], "DDDDDDDDDDDDDDDDDDDD")
        // const {avatar} = companies[data]
        return (
          <div class="item">
            <div class="company-wrap" style={{ height: 400 }}>
              <div class="thumb">
                <a href="#">
                  <img src={companies[data].avatar} class="img-fluid" alt="" />
                </a>
              </div>
              <div class="body">
                <h4>
                  <a href="employer-details.html">
                    {companies[data].companyName}
                  </a>
                </h4>
                <span>Total Jobs {companies[data].totalJobs}</span>
                <a onClick={() => checkDetailsOfEmployee(data)} class="button">
                  See Details
                </a>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const [totalCompanies, setTotalCompanies] = useState([]);

  const callKeyWord = (value) => {
    if (keyword.length > 0) {
      setCategory("");
      setCity("");
      setState("");
      setkeyword(value);
    } else {
      setkeyword(value);
    }
  };
  useEffect(() => {
    let temp = [];
    firebase
      .database()
      .ref(`users`)
      .on("value", (snapshot) => {
        snapshot.forEach((data) => {
          if (data.val().type == "Employer") {
            temp.push(data.val());
          }
        });
      });
    setTotalCompanies(temp);
  }, []);

  useEffect(() => {
    let temp = [];
    firebase
      .database()
      .ref(`users`)
      .on("value", (snapshot) => {
        snapshot.forEach((data) => {
          if (data.val().type == "Employer") {
            temp.push(data.val());
          }
        });
      });
    setTotalCompanies(temp);
  }, []);

  // console.log;

  const [jobData, setJobData] = useState([]);

  console.log(jobData, "jobDatajobDatajobDatajobData");

  useEffect(() => {
    firebase
      .database()
      .ref(`jobs`)
      .once("value", (snapshot) => {
        let tempArr = [];
        snapshot.forEach((employers) => {
          employers.forEach((jobs) => {
            let data = { ...jobs.val() };
            tempArr.push(data);
          });
        });
        setJobData(tempArr);
      });
  }, []);

  if (isloading) {
    return (
      <div className="loader" style={{ display: isloading ? "flex" : "none" }}>
        <Loader
          visible={isloading}
          type="ThreeDots"
          color="white"
          height={100}
          width={100}
        />
      </div>
    );
  }

  const removeTag = (data) => {
    const regex = /(<([^>]+)>)/gi;
    const result = data?.replace(regex, "");
    const final = result?.replace("&nbsp", "");
    return `${final.substring(0, 60)}...`;
  };

  const handleChangeKeyword = (event) => {
    console.log(event.target.value, "VALUESS=>>>>>>");
    setFilterLocation([]);
    let word = event.target.value;
    setkeyword(event.target.value);
    let filter = Profession.Sheet.filter((item) => {
      console.log(item, "ITEMMMMMMMMMMM");
      return item.Professions.toLowerCase().startsWith(word.toLowerCase());
    });

    if (word == "") {
      setFilterSearch([]);
    } else {
      setFilterSearch(filter);
    }
  };

  console.log(cityStateData, "cityStateDatacityStateData");

  const handlelocationChange = (event) => {
    let word = event.target.value;
    setCity(event.target.value);

    let customArr = cityStateData.filter((item) => {
      return item.City?.toLowerCase().includes(word.toLowerCase());
    });

    console.log(customArr, "customArrcustomArrcustomArr");
    console.log(jobData, "jobDatajobDatajobData");
    let filter = jobData.filter((item) => {
      let combineLocation = item.yourcountry + item.yourcity + item.yourstate;

      return combineLocation.toLowerCase().includes(word.toLowerCase());
      // item.yourstate.toLowerCase().includes(word.toLowerCase()) ||
      // item.yourcity.toLowerCase().includes(word.toLowerCase())
    });
    if (word == "") {
      setFilterLocation([]);
    } else {
      setFilterLocation(customArr);
    }
  };

  const uniqueArr = [
    ...new Map(jobData.map((item) => [item["category"], item])).values(),
  ];

  console.log(keyword, "keywordkeywordkeyword");

  return (
    <>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header userData={user} />
            </div>
          </div>
        </div>
      </header>

      <div className="account-entry">
        <div
          className="modal fade"
          id="exampleModalLong"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i data-feather="user"></i>Login
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form action="javascript:void(0);">
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      placeholder="Password"
                      className="form-control"
                    />
                  </div>
                  <div className="more-option">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label className="form-check-label" for="defaultCheck1">
                        Remember Me
                      </label>
                    </div>
                    <a>Forget Password?</a>
                  </div>
                  <button className="button primary-bg btn-block">Login</button>
                </form>
                <div className="shortcut-login">
                  <span>Or connect with</span>
                  <div className="buttons">
                    <a className="facebook">
                      <i className="fab fa-facebook-f"></i>Facebook
                    </a>
                    <a className="google">
                      <i className="fab fa-google"></i>Google
                    </a>
                  </div>
                  <p>
                    Don't have an account? <a>Register</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModalLong2"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i data-feather="edit"></i>Registration
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="account-type">
                  <label for="idRegisterCan">
                    <input id="idRegisterCan" type="radio" name="register" />
                    <span>Candidate</span>
                  </label>
                  <label for="idRegisterEmp">
                    <input id="idRegisterEmp" type="radio" name="register" />
                    <span>Employer</span>
                  </label>
                </div>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Username"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      placeholder="Password"
                      className="form-control"
                    />
                  </div>
                  <div className="more-option terms">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                      />
                      <label className="form-check-label" for="defaultCheck2">
                        I accept the{" "}
                        <a href="terms-and-condition.html">
                          terms & conditions
                        </a>
                      </label>
                    </div>
                  </div>
                  <button className="button primary-bg btn-block">
                    Register
                  </button>
                </form>
                <div className="shortcut-login">
                  <span>Or connect with</span>
                  <div className="buttons">
                    <a className="facebook">
                      <i className="fab fa-facebook-f"></i>Facebook
                    </a>
                    <a className="google">
                      <i className="fab fa-google"></i>Google
                    </a>
                  </div>
                  <p>
                    Already have an account? <a href="login.html">Login</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HomePageSlider />

      <div className="banner banner-2 banner-2-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="banner-content"
                style={{ padding: "50px 0 170px" }}
              >
                <h1>Find the right job</h1>
                <p>
                  Your art is what you do when no one can tell you exactly how
                  to do it. Your art is the act of taking personal
                  responsibility, challenging the status quo, and changing
                  people
                </p>
                <div className="short-infos">
                  <div className="info">
                    <h4 style={{ marginLeft: "30px" }}>{jobData.length}</h4>
                    <span>Total Jobs</span>
                  </div>
                  <div className="info">
                    <h4 style={{ marginLeft: "50px" }}>
                      {totalCompanies.length}
                    </h4>
                    <span>Total Companies</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="searchAndFilter-wrapper-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="searchAndFilter-block">
                <div className="searchAndFilter searchAndFilter-2">
                  <form action="#" className="search-form">
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          style={{ width: "100%", position: "relative" }}
                          placeholder="Enter Keywords"
                          size="small"
                          variant="outlined"
                          className={classes.searchInput}
                          value={keyword}
                          onChange={handleChangeKeyword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                {keyword.length > 0 ? (
                                  <CloseIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setkeyword("");
                                      setFilterSearch([]);
                                    }}
                                  />
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <input
                          type="text"
                          placeholder="Enter Keywords"
                          style={{ width: "100%", position: "relative" }}
                          value={keyword}
                          onChange={handleChangeKeyword}
                        /> */}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            position: "absolute",
                            width: "335px",
                            height: "110px",
                            overflowY: "auto",
                            zIndex: 999,
                            background:
                              filteredSearch.length > 0
                                ? "#246df8"
                                : "transparent",
                          }}
                        >
                          {filteredSearch.length != 0 &&
                            filteredSearch.map((data, index) => {
                              console.log(data, "DATAAAAAAAAAAAAAAA");
                              return (
                                <div style={{ width: "100%", padding: "3px" }}>
                                  <MenuItem
                                    // disabled={keyword.length > 0}
                                    value={data.Professions}
                                    style={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      color: "#fff",
                                    }}
                                    onClick={() => {
                                      setkeyword(data.Professions);
                                      setFilterSearch([]);
                                    }}
                                  >
                                    {/* {removeTag(data.title)} */}
                                    {data.Professions}
                                  </MenuItem>
                                </div>
                              );
                            })}
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <div class="job-filter-dropdown same-pad location">
                          <TextField
                            style={{ width: "100%", position: "relative" }}
                            placeholder="Search for state or city here"
                            size="small"
                            variant="outlined"
                            className={classes.searchInput}
                            value={city}
                            onChange={handlelocationChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {city.length > 0 ? (
                                    <CloseIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setCity("");
                                        setFilterLocation([]);
                                      }}
                                    />
                                  ) : null}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            position: "absolute",
                            width: "250px",
                            height: "110px",
                            overflowY: "auto",
                            zIndex: 999,
                            background:
                              filterLocation.length > 0
                                ? "#246df8"
                                : "transparent",
                          }}
                        >
                          {filterLocation.length != 0 &&
                            filterLocation.map((data, index) => {
                              return (
                                <div style={{ width: "100%" }}>
                                  <MenuItem
                                    // disabled={keyword.length > 0}
                                    value={city}
                                    style={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      color: "#fff",
                                    }}
                                    onClick={() => {
                                      setCity(`${data.City} ${data.State}`);
                                      // setCity(
                                      //   `${data.yourcountry} ${data.yourcity} ${data.yourstate}`
                                      // );
                                      setFilterLocation([]);
                                    }}
                                  >
                                    {`${data.City} ${data.State}`}
                                    {/* {`${data.yourcity} ${data.yourcountry} ${data.yourstate}`} */}
                                  </MenuItem>
                                </div>
                              );
                            })}
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <button
                            className="button"
                            onClick={() => goToJobListing(keyword)}
                          >
                            <i className="fas fa-search"></i>Search Jobs
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                    {/* <input
                      type="text"
                      placeholder="Location"
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                    />
                    <input
                      type="text"
                      placeholder="Category"
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                    /> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="padding-bottom-60">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="categories">
                {jobData && jobData.length > 0
                  ? uniqueArr?.map((job, index) => {
                      return (
                        <div className="category">
                          <div className="icon">
                            <i>
                              {" "}
                              <img
                                src={job.image}
                                style={{ height: "24px", width: "24px" }}
                              />
                            </i>
                          </div>
                          <h5>
                            <Link to={"/categoryJobListing/" + job.category}>
                              {job.category}
                              <span>({JobCategoriesCount[index]})</span>
                            </Link>
                          </h5>
                        </div>
                      );
                    })
                  : null}

                <span className="vr vr-1"></span>
                <span className="vr vr-2"></span>
                <span className="vr vr-3"></span>
                <span className="vr vr-4"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderAppliedJob()}

      <div class="section-padding-top padding-bottom-90">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="section-header">
                <h2>Top Companies</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <OwlCarousel
                className="company-carousel owl-carousel"
                responsive={options.responsive}
                nav
                margin={20}
              >
                {sortDecending()}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="testimonial">
                <div className="testimonial-quote">
                  <img src={quoteImg} className="img-fluid" alt="" />
                </div>

                <Slider asNavFor={nav1} ref={(slider) => (slider1 = slider)}>
                  {AllReviews.map((item, index) => (
                    <div className="testimonial-item">
                      <p
                        style={{
                          fontSize: "2.0rem",
                          fontWeight: 400,
                          maxWidth: "700px",
                          margin: "30px auto 50px",
                          lineHeight: 1.85,
                        }}
                      >
                        "{item.message}"
                      </p>
                    </div>
                  ))}
                </Slider>

                <Slider
                  {...settings}
                  className="testimonial-nav"
                  asNavFor={nav1}
                  ref={(slider) => (slider2 = slider)}
                  swipeToSlide={true}
                  focusOnSelect={true}
                >
                  {AllReviews.map((item, index) => (
                    <div className="commenter-thumb">
                      <img
                        src={item.profileImage}
                        className="img-fluid commenter"
                        alt=""
                      />
                      <p>{item.name}</p>
                    </div>
                  ))}
                </Slider>

                {/* <div className="testimonial-nav">
                  <div className="commenter-thumb">
                    <img
                      src="images/testimonial/thumb-4.jpg"
                      className="img-fluid commenter"
                      alt=""
                    />
                    <img
                      src="images/testimonial/4.png"
                      className="comapnyLogo"
                      alt=""
                    />
                  </div>
                  <div className="commenter-thumb">
                    <img
                      src="images/testimonial/thumb-5.jpg"
                      className="img-fluid commenter"
                      alt=""
                    />
                    <img
                      src="images/testimonial/5.png"
                      className="comapnyLogo"
                      alt=""
                    />
                  </div>
                  <div className="commenter-thumb">
                    <img
                      src="images/testimonial/thumb-2.jpg"
                      className="img-fluid commenter"
                      alt=""
                    />
                    <img
                      src="images/testimonial/2.png"
                      className="comapnyLogo"
                      alt=""
                    />
                  </div>
                  <div className="commenter-thumb">
                    <img
                      src="images/testimonial/thumb-3.jpg"
                      className="img-fluid commenter"
                      alt=""
                    />
                    <img
                      src="images/testimonial/3.png"
                      className="comapnyLogo"
                      alt=""
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="padding-bottom-90">
        <div class="container">
          {/* <div class="row">
            <div class="col">
              <div class="section-header">
                <h2>Career Advice</h2>
              </div>
            </div>
          </div> */}
          {/* <div class="row">
            {blogs?.map((data, index) => {
              // console.log(data,"DADOMASomczoxmczpxocmzxpcozmcpozmcpzoxcm");
              return (
                <div class="col-md-6 col-lg-4">
                  <article class="blog-grid">
                    <div class="body">
                      <span class="date">
                        {moment(data.date).format("DD")}{" "}
                        <span>{moment(data.date).format("MMM")}</span>
                      </span>
                      <h3
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push({
                            pathname: "/BlogDetails",
                            state: {
                              postedrData: data,
                              key: blogKeys[index],
                              userKey: blogUserKeys[index],
                            },
                          })
                        }
                      >
                        {data.title}
                      </h3>
                      <p>
                        {removeTag(data.blog)}
                      </p>
                    </div>
                    <div class="info">
                      <span class="author">
                        <a>
                          <i data-feather="user"></i>
                          {data.name}
                        </a>
                      </span>
                      <span class="comments">
                        <a>
                          <i data-feather="message-circle"></i>
                          {data?.comments ? data.comments.length : 0}
                        </a>
                      </span>
                    </div>
                  </article>
                </div>
              );
            })}
          </div> */}
        </div>
      </div>

      {/* <div className="section-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="testimonial">

              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="padding-top-90 padding-bottom-90 app-download-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="app-download">
                <h2>Get Employ App</h2>
                {/* <p>
                  Quis lectus nulla at volutpat diam ut. Congue quisque egestas
                  diam in arcu cursus euismod quis
                </p> */}
                <div className="app-download-button">
                  <a
                    href="https://apps.apple.com/pk/app/employfind/id1594132355"
                    target="_blank"
                    className="apple-app"
                  >
                    Apple Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.qumail_raza.employeemobileapp"
                    target="_blank"
                    className="android-app"
                  >
                    Google Play
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer-bg">
        <SocialShare />
        <NewsLetterFooter />
        <Footer />
      </footer>
    </>
  );
};

export default Home;

// import React from "react"

// const Home = () => {
//   return (
//     <div>
//       Hello
//     </div>
//   )
// }

// export default Home
