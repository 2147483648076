import React, { useState, useCallback, useEffect } from "react";
import {
  Footer,
  Header,
  NewsLetterFooter,
  NotificationAndAccount,
  SocialShare,
} from "../../components";
import CandidateDashboardSidebar from "../../components/CandidateDashboardSidebar/CandidateDashboardSidebar";
import FindYourJob from "../../components/FindYourJob";
import Slider from "react-slick";
import LinkIcon from "@material-ui/icons/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ImgsViewer from "react-images";
import thumb1 from "../../assests/images/portfolio/thumb-1.jpg";
import thumb2 from "../../assests/images/portfolio/thumb-2.jpg";
import thumb3 from "../../assests/images/portfolio/thumb-3.jpg";
import firebase from "firebase";
import fire from "../../database";
import { Link } from "react-router-dom";
import { jsPDF } from "jspdf"


const sliderData = [
  {
    id: 1,
    image: thumb1,
    view: "",
    link: "#",
  },
  {
    id: 2,
    image: thumb2,
    view: "",
    link: "#",
  },
  {
    id: 3,
    image: thumb3,
    view: "",
    link: "#",
  },
  {
    id: 3,
    image: thumb3,
    view: "",
    link: "#",
  },
];

const CandidateResume = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [resume, setResume] = useState({});
  // const openImageViewer = useCallback((id) => {
  //   setCurrentImage(id);
  //   setIsViewerOpen(true);
  // }, []);

  // const closeImgsViewer = () => {
  //   setIsOpen(false);
  // };

  const [profile, setProfile] = useState({})

  useEffect(() => {
    console.log(props?.location.state, "props?.location?.stateprops?.location?.state");
    firebase
      .database()
      .ref(`resumes/${props?.location?.state}`)
      .on("value", (snapshot) => {
        console.log('res snap', snapshot.val())
        setResume(snapshot.val());
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/candidate/${props?.location?.state}`)
      .on("value", snapshot => {
        console.log(snapshot.val(), "SNASSSSSSSSSSSSSSSSSSSSSSSSS");
        setProfile(snapshot.val())
      })
  }, [])



  console.log("RESSSSSSSSSSSSSSSSSSSS", resume);

  let result = resume?.aboutYou?.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "");

  const removeTag = (data) => {
    const regex = /(<([^>]+)>)/gi;
    const result = data?.replace(regex, "");
    const final = result?.replace("&nbsp", "");
    return final;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // slidesToShow: 2,
    slidesToScroll: 1,
    // centerPadding: "30px !important",
  };

  const coverLetterGenerator = () => {
    var doc = new jsPDF('p', 'pt');
    doc.setFontSize(30);
    doc.text('Cover Letter', 100, 10);
    doc.setFontSize(10)
    doc.text(`${removeTag(resume.coverLetter)}`, 20, 30)
    doc.save(`${resume.fullname}_CoverLetter.pdf`);
  }

  const pdfGenerator = () => {
    var doc = new jsPDF('p', 'pt');

    // var imgWidth = 297;
    // var pageHeight = 210;
    // var imgHeight = canvas.height * imgWidth / canvas.width;

    doc.setFontSize(30);
    doc.text(resume.fullname, 80, 10);
    doc.setFontSize(10)
    doc.text(`Category: ${resume.category}`, 10, 20)
    doc.text(`Gender: ${resume.gender}`, 10, 30)
    doc.text(`Location: ${resume.location}`, 10, 40)
    doc.text(`Type: ${resume.type}`, 10, 50)
    doc.text(`Experience: ${resume.exp}`, 10, 60)
    doc.text(`Salary Range: ${resume.salaryRange}`, 10, 70)
    doc.text(`Qualification: ${resume.qualification}`, 10, 80)
    doc.text(`Special Qualification: ${resume.specialQualification}`, 10, 90)
    doc.text(`Skills: ${resume.skills}`, 10, 100)
    doc.text(`About: ${resume.aboutYou}`, 10, 110)
    doc.text(`Nationality: ${resume.nationality}`, 10, 120)
    doc.text(`Address: ${resume.address}`, 10, 130)
    doc.text(`Cover Letter: ${resume.coverLetter}`, 10, 140)
    doc.text(`DOB: ${resume.dateOfBirth}`, 10, 150)
    console.log('doc', doc.output())
    doc.text('Educations', 10, 160)
    let y = 170;
    resume.educationList.map((data, index) => {
      if (data.Designation) {
        console.log('daata', data)
        doc.text(`Education ${index + 1}`, 10, y)
        doc.text(`Designation ${data.Designation}`, 10, y + 10)
        doc.text(`Institute ${data.Institute}`, 10, y + 20)
        doc.text(`Period ${data.Period}`, 10, y + 30)
        doc.text(`Description ${data.Institute}`, 10, y + 40)
        y = y + 50;
      }

    })
    resume.workExpList.map((data, index) => {
      if (data.Designation) {
        console.log('daata', data)
        doc.text(`Work Experience ${index + 1}`, 20, y)
        doc.text(`Designation ${data.Designation}`, 20, y + 20)
        doc.text(`Company Name ${data.companyName}`, 20, y + 20)
        doc.text(`Period ${data.Period}`, 20, y + 30)
        doc.text(`Description ${data.Institute}`, 20, y + 40)
        y = y + 50;
      }
    })
    doc.save(`${resume.fullname}.pdf`);
  }

  const downloadCV = () => {
    console.log('resume', resume)
    if (resume) {
      if (resume.cvURL) {
        window.open(resume.cvURL, '_blank')
      }
      else {
        pdfGenerator()
      }
    }
    else {
      alert('The candidate have not uploaded resume')
    }

  }
  const downloadCL = () => {
    if (resume) {
      if (resume.coverLetter) {
        coverLetterGenerator()
      }
      else {
        alert('The candidate have not uploaded Cover Letter')
      }
    }
    else {
      alert('The candidate have not uploaded Letter')
    }

  }

  return (
    <>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>
      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Resume</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Resume
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i data-feather="search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="dashboard-container">
                <div class="dashboard-content-wrapper">
                  <div class="download-resume dashboard-section">
                    <Link onClick={downloadCV}>
                      Download CV<i data-feather="download"></i>
                    </Link>
                    <Link onClick={downloadCL}>
                      Download Cover Letter<i data-feather="download"></i>
                    </Link>
                  </div>
                  <div class="skill-and-profile dashboard-section">
                    <div class="skill">
                      <label>Skills:</label>
                      {resume?.skills}
                    </div>
                    <div class="social-profile">
                      <label>Social:</label>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-google-plus"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-pinterest-p"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-behance"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-dribbble"></i>
                      </a>
                      <a href="#">
                        <i class="fab fa-github"></i>
                      </a>
                    </div>
                  </div>
                  <div class="about-details details-section dashboard-section">
                    <h4>
                      <i data-feather="align-left"></i>About Me
                    </h4>
                    {removeTag(result)}
                    <div class="information-and-contact">
                      <div class="information">
                        <h4>Information</h4>
                        <ul>
                          <li>
                            <span>Category:</span> {resume?.category}
                          </li>
                          <li>
                            <span>Location:</span>
                            {resume?.location}
                          </li>
                          <li>
                            <span>Status:</span> {resume?.type}
                          </li>
                          <li>
                            <span>Experience:</span>
                            {resume?.exp}
                          </li>
                          <li>
                            <span>Salary:</span> {resume?.SalaryRange}
                          </li>
                          <li>
                            <span>Gender:</span>
                            {resume?.sex}
                          </li>
                          <li>
                            <span>Age:</span> 24 Year(s)
                          </li>
                          <li>
                            <span>Qualification:</span> {resume?.qualification}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="edication-background details-section dashboard-section">
                    <h4>
                      <i data-feather="book"></i>Education Background
                    </h4>
                    {resume?.educationList?.map((data, index) => {
                      console.log(data, "DAADADASDASDAS=>>>>");
                      return (
                        <div class="education-label">
                          <span class="study-year">{data.period}</span>
                          <h5>{data.Institute}</h5>
                          <p>{data.Description}</p>
                        </div>
                      );
                    })}

                    {/* <div class="education-label">
                      <span class="study-year">2014 - 2018</span>
                      <h5>
                        Diploma in Graphics Design
                        <span>@ Graphic Arts Institute</span>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Scelerisque fermentum
                      </p>
                    </div> */}
                    {/* <div class="education-label">
                      <span class="study-year">2008 - 2014</span>
                      <h5>
                        Secondary School Certificate
                        <span>@ Engineering High School</span>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Scelerisque fermentum
                      </p>
                    </div> */}
                  </div>
                  <div class="experience dashboard-section details-section">
                    <h4>
                      <i data-feather="briefcase"></i>Work Experiance
                    </h4>
                    {resume?.workExpList?.map((data, index) => {
                      return (
                        <div class="experience-section">
                          <span class="service-year">{data.Period}</span>
                          <h5>
                            {data.Designation}
                            <span>@ {data.companyName}</span>
                          </h5>
                          <p>{data.Description}</p>
                        </div>
                      );
                    })}
                    {/* <div class="experience-section">
                      <span class="service-year">2012 - 2016</span>
                      <h5>
                        Former Graphic Designer<span>@ Graphicreeeo CO</span>
                      </h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Scelerisque fermentum
                      </p>
                    </div> */}
                  </div>

                  <div class="professonal-skill dashboard-section details-section">
                    <h4>
                      <i data-feather="feather"></i>Professional Skill
                    </h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Scelerisque fermentum
                    </p>
                    <div class="progress-group">
                      {resume?.skillsList?.map((data, index) => {
                        return (
                          <div class="progress-item">
                            <div class="progress-head">
                              <p class="progress-on">{data.title}</p>
                            </div>
                            <div class="progress-body">
                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="70"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: 0 }}
                                ></div>
                              </div>
                              <p class="progress-to">{`${data.percentage}%`}</p>
                            </div>
                          </div>
                        );
                      })}

                      {/* <div class="progress-item">
                        <div class="progress-head">
                          <p class="progress-on">HTML/CSS</p>
                        </div>
                        <div class="progress-body">
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ width: 0 }}></div>
                          </div>
                          <p class="progress-to">90%</p>
                        </div>
                      </div>
                      <div class="progress-item">
                        <div class="progress-head">
                          <p class="progress-on">JavaScript</p>
                        </div>
                        <div class="progress-body">
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="74" aria-valuemin="0" aria-valuemax="100" style={{ width: 0 }}></div>
                          </div>
                          <p class="progress-to">74%</p>
                        </div>
                      </div>
                      <div class="progress-item">
                        <div class="progress-head">
                          <p class="progress-on">PHP</p>
                        </div>
                        <div class="progress-body">
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-valuenow="86" aria-valuemin="0" aria-valuemax="100" style={{ width: 0 }}></div>
                          </div>
                          <p class="progress-to">86%</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div class="special-qualification dashboard-section details-section">
                    <h4>
                      <i data-feather="gift"></i>Special Qualification
                    </h4>
                    {removeTag(resume?.specialQualification)}
                  </div>
                  <div class="portfolio dashboard-section details-section">
                    <h4>
                      <i data-feather="gift"></i>Portfolio
                    </h4>
                    {/* <div class="portfolio-slider owl-carousel"> */}

                    {/* {console.log(resume.portfolioList.length, "resssssssssss")} */}

                    <Slider
                      {...settings}
                      slidesToShow={resume?.portfolioList?.length > 3 ? 3 : 2}
                      className="portfolio-slider"
                    >
                      {resume?.portfolioList?.map((slide, i) => {
                        console.log(slide, "sliderImage=>>>>");
                        return (
                          <>
                            <div class="portfolio-item" key={slide.id}>
                              <img
                                src={slide.image}
                                class="img-fluid"
                                width="95%"
                                alt=""
                              />
                              <div class="overlay">
                                <a href={slide.view}>
                                  <VisibilityIcon
                                    style={{ fontSize: "28px" }}
                                    onClick={() => setIsOpen(true)}
                                  // src={src}
                                  // onClick={() => openImageViewer(slide.id)}
                                  />
                                </a>
                                <a href={slide.link}>
                                  <LinkIcon style={{ fontSize: "28px" }} />
                                </a>
                              </div>
                            </div>
                          </>
                        );
                      })}



                      {/* <ImgsViewer
                        imgs={[
                          { src: "../images/photo-1.jpg" },
                          // { src: "../images/photo-2.jpg" },
                        ]}
                        isOpen={isOpen}
                        onClickPrev={gotoPrevImg}
                        onClickNext={gotoNextImg}
                        onClose={closeImgsViewer}
                      /> */}
                      {/* 
                      <div class="portfolio-item">
                      <img
                      src={thumb2}
                          class="img-fluid"
                          width="95%"
                          alt=""
                        />
                        <div class="overlay">
                          <a href="#">
                            <VisibilityIcon style={{ fontSize: "28px" }} />
                          </a>
                          <a href="#">
                            <LinkIcon style={{ fontSize: "28px" }} />
                          </a>
                        </div>
                      </div>
                      <div class="portfolio-item">
                        <img
                          src={thumb3}
                          class="img-fluid"
                          width="95%"
                          alt=""
                        />
                        <div class="overlay">
                          <a href="#">
                            <VisibilityIcon style={{ fontSize: "28px" }} />
                          </a>
                          <a href="#">
                            <LinkIcon style={{ fontSize: "28px" }} />
                          </a>
                        </div>
                      </div>
                      <div class="portfolio-item">
                        <img
                          src={thumb1}
                          class="img-fluid"
                          width="95%"
                          alt=""
                        />
                        <div class="overlay">
                          <a href="#">
                            <VisibilityIcon style={{ fontSize: "28px" }} />
                          </a>
                          <a href="#">
                            <LinkIcon style={{ fontSize: "28px" }} />
                          </a>
                        </div>
                      </div>
                      <div class="portfolio-item">
                        <img
                          src={thumb2}
                          class="img-fluid"
                          width="95%"
                          alt=""
                        />
                        <div class="overlay">
                          <a href="#">
                            <VisibilityIcon style={{ fontSize: "28px" }} />
                          </a>
                          <a href="#">
                            <LinkIcon style={{ fontSize: "28px" }} />
                          </a>
                        </div>
                      </div> */}
                    </Slider>
                    {/* </div> */}

                    <h4>
                      <i data-feather="gift"></i>Video
                    </h4>
                    <div>
                      {profile.video ?
                        <iframe
                        className="video-styling-candidate"
                          style={{ borderRadius: 5, textAlign: 'center', marginTop: 20, marginBottom: 20 }}
                          src={profile?.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        :
                        <h5>
                          No Video Found
                        </h5>
                      }
                    </div>
                  </div>
                  <div class="personal-information dashboard-section last-child details-section">
                    <h4>
                      <i data-feather="user-plus"></i>Personal Deatils
                    </h4>
                    <ul>
                      <li>
                        <span>Full Name:</span>
                        {resume?.fullname}
                      </li>
                      <li>
                        <span>Father's Name:</span> {resume?.fatherName}
                      </li>
                      <li>
                        <span>Mother's Name:</span> {resume?.motherName}
                      </li>
                      <li>
                        <span>Date of Birth:</span> {resume?.dateOfBirth}
                      </li>
                      <li>
                        <span>Nationality:</span> {resume?.nationality}
                      </li>
                      <li>
                        <span>Sex:</span>
                        {resume?.sex}
                      </li>
                      <li>
                        <span>Address:</span>
                        {resume?.address}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="dashboard-sidebar">
                  <div class="user-info">
                    <div class="thumb">
                      <img
                        src="dashboard/images/user-1.jpg"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="user-body">
                      <h5>Ahmed</h5>
                      <span>@username</span>
                    </div>
                  </div>
                  {/* <div class="profile-progress">
                                        <div class="progress-item">
                                            <div class="progress-head">
                                                <p class="progress-on">Profile</p>
                                            </div>
                                            <div class="progress-body">
                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div>
                                                </div>
                                                <p class="progress-to">70%</p>
                                            </div>
                                        </div>
                                    </div> */}
                  <div class="dashboard-menu">
                    <CandidateDashboardSidebar dashboardLink="candidateResume" />
                    {/* <ul>
                      <li>
                        <i class="fas fa-home"></i>
                        <a href="dashboard.html">Dashboard</a>
                      </li>
                      <li>
                        <i class="fas fa-user"></i>
                        <a href="dashboard-edit-profile.html">Edit Profile</a>
                      </li>
                      <li class="active">
                        <i class="fas fa-file-alt"></i>
                        <a href="resume.html">Resume</a>
                      </li>
                      <li>
                        <i class="fas fa-edit"></i>
                        <a href="edit-resume.html">Edit Resume</a>
                      </li>
                      <li>
                        <i class="fas fa-heart"></i>
                        <a href="dashboard-bookmark.html">Bookmarked</a>
                      </li>
                      <li>
                        <i class="fas fa-check-square"></i>
                        <a href="dashboard-applied.html">Applied Job</a>
                      </li>
                      <li>
                        <i class="fas fa-comment"></i>
                        <a href="dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i class="fas fa-calculator"></i>
                        <a href="dashboard-pricing.html">Pricing Plans</a>
                      </li>
                    </ul> */}
                    <ul class="delete">
                      <li>
                        <i class="fas fa-power-off"></i>
                        <a href="#">Logout</a>
                      </li>
                      {/* <li>
                        <i class="fas fa-trash-alt"></i>
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#modal-delete"
                        >
                          Delete Profile
                        </a>
                      </li> */}
                    </ul>

                    <div
                      class="modal fade modal-delete"
                      id="modal-delete"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body">
                            <h4>
                              <i data-feather="trash-2"></i>Delete Account
                            </h4>
                            <p>
                              Are you sure! You want to delete your profile.
                              This can't be undone!
                            </p>
                            <form action="#">
                              <div class="form-group">
                                <input
                                  type="password"
                                  class="form-control"
                                  placeholder="Enter password"
                                />
                              </div>
                              <div class="buttons">
                                <button class="delete-button">
                                  Save Update
                                </button>
                                <button class="">Cancel</button>
                              </div>
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                />
                                <label class="form-check-label">
                                  You accepts our{" "}
                                  <a href="#">Terms and Conditions</a> and{" "}
                                  <a href="#">Privacy Policy</a>
                                </label>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CandidateResume;
