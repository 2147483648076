import React, { useState, useEffect } from "react";
import {
  Button,
  makeStyles,
  Typography,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import firebase from "firebase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CheckIcon from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ImageIcon from "@material-ui/icons/Image";
import Fade from "@material-ui/core/Fade";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import moment from "moment"

const useStyles = makeStyles((theme) => ({
  myDropdown: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
    },
  },
  mainMenu: {
    marginTop: "42px",
    borderRadius: "5px",
    "&.MuiPopover-root > .MuiPaper-root": {
      width: "320px",
    },
  },
  mainMenu2: {
    marginTop: "55px",
    // marginRight: "100px",
    borderRadius: "5px",
    "&.MuiPopover-root > .MuiPaper-root": {
      width: "320px",
    },
  },
  menuHead: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
  },
  mark: {
    color: "#aaa",
    "&:hover": {
      color: "blue",
    },
  },
  mainList: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    cursor: "pointer",
    "&:hover>div>svg:first-child": {
      color: "#01d13d",
    },
  },
  listContaier: {
    maxHeight: "300px",
    overflowX: "hidden",
    overflowY: "scroll",
  },

  itemText: {
    "&.MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "14px",
      color: "#6f7484",
    },
    "&.MuiListItemText-root .MuiListItemText-secondary": {
      fontSize: "1.3rem",
      color: "#aaafcb",
      fontStyle: "italic",
    },
  },
  icon: {
    color: "#01d13d80",
    fontSize: "15px",
    // "&:hover": {
    // },
  },
  notification: {
    margin: "7px 0",
    textAlign: "center",
  },

  itemText2: {
    "&.MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#6f7484",
    },
    "&.MuiListItemText-root .MuiListItemText-secondary": {
      fontSize: "1.3rem",
      color: "#aaafcb",
      fontStyle: "italic",
    },
  },
  icon2: {
    minWidth: "30px",
  },
  item2: {
    cursor: "pointer",
    "&:hover>div>svg": {
      color: "#246df8 !important",
      // background: "red",
    },
    "&:hover>div>span": {
      color: "#246df8 !important",
      // background: "red",
    },
  },
  text3: {
    "&.MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "14px",
      color: "#6f7484",
    },
    // "&.MuiListItemText-root .MuiListItemText-secondary": {
    //   fontSize: "1.3rem",
    //   color: "#aaafcb",
    // },
  },
}));

const NotificationAndAccount = () => {
  const [notification, setNotifications] = useState([])
  const isUser = { isAuthenticated: false };

  useEffect(() => {
    firebase.database().ref(`notifications/${firebase?.auth()?.currentUser?.uid}`)
      .on("value", snapshot => {
        let data = snapshot.val() ? snapshot.val() : {}
        let HistoryItems = { ...data };
        setNotifications(HistoryItems)
      })
  }, [])

  const Data = Object.keys(notification)

  const [user, setUser] = useState({});
  const isUserAuthenticate = () => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      firebase
        .database()
        .ref(`users/${userData.uid}`)
        .on("value", (snapshot) => {
          console.log("Noti account data", snapshot.val());
          setUser(snapshot.val());
        });
      isUser.isAuthenticated = true;
    } else {
      isUser.isAuthenticated = false;
    }
  };
  useEffect(() => {
    isUserAuthenticate();
  }, []);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setOpenProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogOut = () => {
    // console.log("handle ligitu");
    // alert("log out")
    setOpenProfile(null);
    firebase
      .auth()
      .signOut()
      .then(console.log("signout success"))
      .catch((err) => {
        console.log(err);
      });
    sessionStorage.removeItem("user");
    window.location.replace("/");
  };

  // console.log("iserrrr", user);

  console.log(notification[Data], "DataDataDataDataDataDataData");

  return (
    <div className="header-top">
      <div>
        <a href="#">
          <img className="mainlogo" src={logoMain} />
        </a>
      </div>
 
      {/* {
                isUser.isAuthenticated && */}
      <div className="top-nav">
        {user.type ? (
          <div
            className={`${classes.myDropdown} dropdown header-top-notification`}
          >
            <a href="#" className="notification-button" onClick={handleClick}>
              Notifications
            </a>
            <div className="notification-card">
              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                className={classes.mainMenu}
              >
                <div className={classes.menuHead}>
                  <span style={{ color: "#6f7484" }}>Notifications</span>
                  {/* <a href="#" className={classes.mark}>
                    Mark all as read
                  </a> */}
                </div>

                <div className={classes.listContaier}>
                  {Data.map((data, index) => {
                    const { title, time } = notification[data]
                    return (
                      <List className={classes.mainList} onClick={handleClose}>
                        <ListItemIcon style={{ minWidth: "30px" }}>
                          <FlashOnIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={title}
                          secondary={moment(time).format("DD-MM-YYYY h:mm A")}
                          className={classes.itemText}
                        />
                      </List>
                    )
                  })

                  }
                  {/* <List className={classes.mainList} onClick={handleClose}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <ArrowDownwardIcon
                        className={classes.icon}
                        style={{
                          borderRadius: "50%",
                          border: "1px solid #01d13d",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Someone Download Resume"
                      secondary="11 hours ago"
                      className={classes.itemText}
                    />
                  </List> */}
                  {/* <List className={classes.mainList} onClick={handleClose}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <CheckIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary="You applid for Project Manager"
                      secondary="11 hours ago"
                      className={classes.itemText}
                    />
                  </List>
                  <List className={classes.mainList} onClick={handleClose}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <PersonIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary="You changed password"
                      secondary="5 hours ago"
                      className={classes.itemText}
                    />
                  </List> */}
                  {/* <List className={classes.mainList} onClick={handleClose}>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <ArrowDownwardIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Someone Download Resume"
                      secondary="11 hours ago"
                      className={classes.itemText}
                    />
                  </List> */}
                </div>

                <div className={classes.notification}>
                  <a
                    href="#"
                    className={classes.mark}
                    style={{ textTransform: "uppercase" }}
                  >
                    See All Notifications
                  </a>
                </div>
              </Menu>
              <div className="notification-footer">
                <a href="#">See all notification</a>
              </div>
            </div>
          </div>
        ) : null}

        {user.type ? (
          <div className="dropdown header-top-account">
            <a
              style={{ cursor: "pointer" }}
              className="account-button"
              onClick={handleClickProfile}
            >
              My Account
            </a>

            <div className="dropdown-menu">
              <Menu
                // id="simple-menu"
                anchorEl={openProfile}
                keepMounted
                open={Boolean(openProfile)}
                onClose={handleCloseProfile}
                className={classes.mainMenu2}
                style={{ width: "300px" }}
              >
                <ListItem style={{ outline: "none" }}>
                  <ListItemAvatar>
                    <Avatar
                      src={{ uri: "https://picsum.photos/id/237/200/300" }}
                    >
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user.name}
                    secondary={user.email}
                    className={classes.itemText2}
                  />
                </ListItem>

                <List>
                  <Link
                    to={
                      user.type == "Candidate"
                        ? "/CandidateDashboard"
                        : "/EmployerDashboard"
                    }
                  >
                    <ListItem
                      className={classes.item2}
                      onClick={handleCloseProfile}
                    >
                      <ListItemIcon className={classes.icon2}>
                        <PersonOutlineOutlinedIcon
                          style={{ fontSize: "20px", color: "#aaa" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Account"
                        // secondary={secondary ? "Secondary text" : null}
                        className={classes.text3}
                      />
                    </ListItem>
                  </Link>
                </List>
                <List>
                  <Link
                    to={
                      user.type == "Candidate"
                        ? "/CandidateProfile"
                        : "/EmployerEditProfile"
                    }
                  >
                    <ListItem
                      className={classes.item2}
                      onClick={handleCloseProfile}
                    >
                      <ListItemIcon className={classes.icon2}>
                        <SettingsIcon
                          style={{ fontSize: "20px", color: "#aaa" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Settings"
                        // secondary={secondary ? "Secondary text" : null}
                        className={classes.text3}
                      />
                    </ListItem>
                  </Link>
                </List>
                <ListItem
                  className={classes.item2}
                  // onClick={handleCloseProfile}
                  onClick={handleLogOut}
                >
                  <ListItemIcon className={classes.icon2}>
                    <PowerSettingsNewIcon
                      style={{ fontSize: "20px", color: "#aaa" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="LogOut"
                    // secondary={secondary ? "Secondary text" : null}
                    className={classes.text3}
                  />
                </ListItem>
              </Menu>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationAndAccount;
