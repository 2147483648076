import React, { useEffect, useState } from "react"
import firebase from "firebase"
import fire from "../../database"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const LogoutAndDelete = () => {

    const [user, setUser] = useState([])
    const [alertData, setAlertData] = useState({
        open: false,
        severity: "",
        msg: "",
    });
    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("usertype"));
        setUser(userData)
    }, [])

    const logOutandclear = () => {
        sessionStorage.removeItem("user")
        sessionStorage.removeItem("usertype")
        window.location.replace("/")
        firebase.auth().signOut()
    }

    const deleteProfile = () => {
        if (user.type == "Candidate") {
            firebase.database().ref(`profile/candidate/${firebase.auth().currentUser.uid}`).remove()
                .then(res => {
                    setAlertData({
                        open: true,
                        severity: "success",
                        msg: "Profile Removed Successfully"
                    })
                })
                .catch(err => {
                    console.log(err, "ERRR")
                })
        }
        else {
            firebase.database().ref(`profile/employer/${firebase.auth().currentUser.uid}`).remove()
                .then(res => {
                    setAlertData({
                        open: true,
                        severity: "success",
                        msg: "Profile Removed Successfully"
                    })
                })
                .catch(err => {
                    console.log(err, "ERRR")
                })
        }
    }

    const handleClose = () => {
        setAlertData({
            open: false,
            severity: "",
            msg: "",
        });
    };

    return (
        <>
            <Snackbar
                open={alertData.open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={alertData.severity}>
                    <span style={{ fontSize: 15 }}>{alertData.msg}</span>
                </Alert>
            </Snackbar>
            <ul className="delete">
                <li>
                    <i className="fas fa-power-off"></i>
                    <a onClick={logOutandclear}>Logout</a>
                </li>
                {/* <li>
                    <i className="fas fa-trash-alt"></i>
                    <a
                        onClick={deleteProfile}
                        data-toggle="modal"
                        data-target="#modal-delete"
                    >
                        Delete Profile
          </a>
                </li> */}
            </ul>
        </>
    )
}

export default LogoutAndDelete