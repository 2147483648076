import React, { useState, useEffect } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Search,
  Briefcase,
  MapPin,
  Clock,
  Heart,
  Edit2,
  Menu,
} from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/employer/thumb-1.png";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase";
import fire from "../../database";
import FindYourJob from "../../components/FindYourJob";
import { makeStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MenuItem, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: "0 8px",
    minWidth: 120,
    // maxWidth: 300,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  listingContainer: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  category: {
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20px",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isUser = { isAuthenticated: false };
const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const EmployerListing = () => {
  isUserAuthenticate();
  const classes = useStyles();
  const [category, setCategory] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState("");
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const history = useHistory();

  const submitForm = () => {
    setOpen(false);
    setAlertData({
      open: true,
      severity: "success",
      msg: "Applied Successfully",
    });
  };
  const [employerData, setEmployerData] = useState([]);
  const [employerData2, setEmployerData2] = useState([]);
  const [jobLength, setJobLength] = useState(0);
  // const [jobData, setJobData] = useState([]);

  // useEffect(() => {

  //   firebase
  //     .database()
  //     .ref(`profile/employer`)
  //     .on("value", (snapshot) => {
  //       let tempArr = [];
  //       snapshot.forEach((employers) => {
  //         tempArr.push(employers.val());
  //         console.log("tempArrrrr=>>>>", tempArr);
  //         setEmployerData(tempArr);
  //       });
  //     });
  // }, []);


  const [searchVal, setSearchVal] = useState("")

  useEffect(async () => {
    if (searchVal == "") {
      let tempArr = []
      firebase
        .database()
        .ref(`profile/employer`)
        .on("value", (snapshot) => {
          let data = snapshot.val() ? snapshot.val() : {};
          let HistoryItems = { ...data };
          snapshot.forEach(inner => {
            console.log(inner.val(), "VALUESSSSSSSSSSSSSS");
            tempArr.push(inner.val())
          })
        });
      setEmployerData(tempArr);
      setEmployerData2(tempArr)
    }
    else {
      let results = employerData2.filter((item) => {
        return item.companyName.toLowerCase().includes(searchVal.toLowerCase());
        // console.log(item, "ITMMMMMMMMMMMMMMMMMMMMMMMMM");
      });
      console.log(
        results,
        "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO_RESSSSSSSSSSSSs"
      );
      await setEmployerData(results);
    }
  }, [searchVal]);

  console.log("employerData=>>>", employerData);
  const Data = Object.keys(employerData);
  // useEffect(() => {
  //   let tempArr = [];
  //   if (employerData) {
  //     firebase
  //       .database()
  //       .ref(`jobs`)
  //       .on("value", (snapshot) => {

  //       });
  //     // alert("called")
  //   }

  // }, []);

  // useEffect(() => {
  //   firebase.database().ref(`jobs`)
  // }, [])

  const [number, setNumber] = useState(0)

  // useEffect(() => {
  //   alert("changess")
  // }, [number])

  console.log("employerData=>>>>>>", employerData);

  const [test, settest] = useState([])

  const checkOpenPosition = (data) => {
    var num = 0;
    let arr = []
    console.log(data, "Dataasdasdasdsdds=>>>>>");
    firebase
      .database()
      .ref(`jobs/${data.uid}`)
      .on("value", (snapshot) => {
        num = snapshot.numChildren();
        // setNumber(num)
        arr.push(num)
      });


    return (
      <div class="button-area">
        <a>{num} Open Position</a>
      </div>
    );
    // let dataxx = Object.keys(jobLength)
    // console.log(dataxx.length, "ASDASDASDSDASDASDASDASD");
  };

  return (
    <>
      {/* <NotificationAndAccount  />
            <Header /> */}

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div>
        {/* BreadCrumb  */}

        <div class="alice-bg padding-top-70 padding-bottom-70">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="breadcrumb-area">
                  <h1>Employer Listing</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        Employer List
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div class="col-md-6">
                <div class="breadcrumb-form">
                  <form action="#">
                    <input type="text" placeholder="Enter Keywords" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} />
                    <button>
                      <i>
                        <Search />
                      </i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bread Crumb End      */}
        {/* <!-- Job Listing --> */}
        <div class="alice-bg section-padding-bottom">
          <div class="container">
            <div class="row no-gutters">
              <div class="col">
                <Grid
                  container
                  spacing={1}
                  className={classes.listingContainer}
                >
                  <Grid item xs={12} sm={8} md={9}>
                    <div class="filtered-employer-wrapper">
                      {/* <div class="employer-view-controller-wrapper">
                      <div class="employer-view-controller">
                        <div class="controller list active">
                          <i>
                            <Menu />
                          </i>
                        </div>
                        <div class="controller grid">
                          <i>
                            <Grid />
                          </i>
                        </div>
                        <div class="employer-view-filter">
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Filter
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={age}
                              onChange={handleChange}
                              className="select-picker"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={10}>Most Recent</MenuItem>
                              <MenuItem value={20}>Top Rated</MenuItem>
                              <MenuItem value={30}>Most Popular</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div class="showing-number">
                        <span>Showing 1–12 of 28 Jobs</span>
                      </div>
                    </div> */}
                      <div class="employer-filter-result">
                        {employerData?.map((data) => {
                          console.log(
                            "datadataxxxxxxxxxxxxxxxxxx=>>>>",
                            employerData
                          );
                          return (
                            <div class="employer">
                              <div class="thumb">
                                <a href="#">
                                  <img
                                    src={
                                      data.companyName
                                        ? data.avatar
                                        : thumb
                                    }
                                    class="img-fluid"
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div class="body">
                                <div class="content">
                                  <h4>
                                    <Link
                                      to={{
                                        pathname: "/EmployerDetails",
                                        state: data,
                                      }}
                                    >

                                      {data.companyName}
                                    </Link>
                                  </h4>
                                  <div class="info">
                                    <span class="company-category">
                                      <a>
                                        <i>
                                          <Briefcase />
                                        </i>
                                        {data.category}
                                      </a>
                                    </span>
                                    <span class="location">
                                      <a>
                                        <i>
                                          <MapPin />
                                        </i>
                                        {data.address}
                                      </a>
                                    </span>
                                  </div>
                                </div>

                                {checkOpenPosition(data)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* <div class="pagination-list text-center">
                      <nav class="navigation pagination">
                      <div class="nav-links">
                      <a class="prev page-numbers" href="#">
                            <i class="fas fa-angle-left"></i>
                            </a>
                          <a class="page-numbers" href="#">
                          1
                          </a>
                          <span
                            aria-current="page"
                            class="page-numbers current"
                            >
                            2
                            </span>
                          <a class="page-numbers" href="#">
                          3
                          </a>
                          <a class="page-numbers" href="#">
                          4
                          </a>
                          <a class="next page-numbers" href="#">
                            <i class="fas fa-angle-right"></i>
                          </a>
                          </div>
                      </nav>
                    </div> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <div
                      className={`${classes.category} employer-filter-wrapper`}
                    >
                      <div class="selected-options same-pad">
                        <div class="selection-title">
                          <h4>You Selected</h4>
                          <a href="#">Clear All</a>
                        </div>
                        <ul class="filtered-options"></ul>
                      </div>
                      <div class="employer-filter-dropdown same-pad category">
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            onChange={handleChangeCategory}
                          >
                            <MenuItem value={1}>Category</MenuItem>
                            <MenuItem value={2}>Accounting / Finance</MenuItem>
                            <MenuItem value={3}>Education</MenuItem>
                            <MenuItem value={4}>Design Creative</MenuItem>
                            <MenuItem value={5}>Health Care</MenuItem>
                            <MenuItem value={6}>Engineer & Architects</MenuItem>
                            <MenuItem value={7}>Marketing & Sales</MenuItem>
                            <MenuItem value={8}>Garments / Textile</MenuItem>
                            <MenuItem value={9}>Customer Support</MenuItem>
                            <MenuItem value={10}>Digital Media</MenuItem>
                            <MenuItem value={11}>Telecommunication</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div class="employer-filter-dropdown same-pad location">
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Location
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={location}
                            onChange={handleChangeLocation}
                          >
                            <MenuItem value={1}>New York City</MenuItem>
                            <MenuItem value={2}>San Francisco</MenuItem>
                            <MenuItem value={3}>Washington</MenuItem>
                            <MenuItem value={4}>Boton</MenuItem>
                            <MenuItem value={5}>Los Angeles</MenuItem>
                            <MenuItem value={6}>Seattle</MenuItem>
                            <MenuItem value={6}>Las Vegas</MenuItem>
                            <MenuItem value={7}>San Diego</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Job Listing End --> */}

        <FindYourJob />
        <SocialShare />
        <NewsLetterFooter />
        <Footer />
      </div>
    </>
  );
};

export default EmployerListing;
