import moment from "moment";
import React, { useState } from "react";
import firebase from "firebase";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import renderHTML from "react-render-html";
import {
    Modal,
    Backdrop,
    Fade,
    TextField,
    TextareaAutosize,
    Typography,
    FormControlLabel,
    Radio,
    Grid,
    Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #0000000",
        borderRadius: "5px",
        // boxShadow: theme.shadows[5],
        padding: "60px 30px",
        width: "450px",
    },
    commentHeading: {
        fontSize: "1.8rem",
        color: "#101725",
        fontWeight: 600,
    },
    textFieldssss: {
        "&.MuiTextField-root > .MuiInputBase-root": {
            paddingBottom: "12px",
        },
        "&.MuiTextField-root > .MuiInputBase-root input": {
            fontSize: "1.4rem",
        },
    },
    textAreaaas: {
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        width: "100%",
    },
    readioCheck: {
        "&.MuiFormControlLabel-root .MuiButtonBase-root": {
            color: "#246df8",
        },
        "&.MuiFormControlLabel-root .MuiTypography-root": {
            fontSize: "14px",
            margin: "20px 0",
        },
    },
    submitBtn: {
        background: "#023b92",
        color: "#fff",
        border: "1px solid transparent",
        borderRadius: "3px",
        transition: "all .3s ease",
        padding: "10px 30px",
        fontSize: "16px",
        textTransform: "none",
        "&:hover": {
            background: "#023b92",
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BlogDetails = (props) => {
    const classes = useStyles();
    const history = useHistory();
    console.log(props, "PDPASDASPDASPDASPDPPSD");
    const { state } = props.location;

    const [alertData, setAlertData] = useState({
        open: false,
        severity: "",
        msg: "",
    });

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [openReplyDialog, setOpenReplyDialog] = useState(false);

    const submitComment = () => {
        if (!message || !name || !email) {
            setAlertData({
                open: true,
                severity: "error",
                msg: "Please fill complete Information",
            });
        }
        else {
            let params = {
                message,
                name,
                email,
                date: new Date().toLocaleString(),
            };
            let comments = state?.postedrData?.comments
                ? state?.postedrData?.comments
                : [];
            comments.push(params);
            // alert(JSON.stringify(firebase.auth().currentUser))
            // if (firebase.auth().currentUser == undefined) {
            //     setAlertData({
            //         open: true,
            //         severity: "error",
            //         msg: "Login First"
            //     })
            //     return
            // }
            firebase
                .database()
                .ref(`blogs/${state.userKey}/${state.key}`)
                .update({
                    comments,
                })
                .then((res) => {
                    setAlertData({
                        open: true,
                        severity: "success",
                        msg: "Comment Done Successfully",
                    });
                    setTimeout(() => {
                        history.push("/");
                    }, 2000);
                });
        }
    };

    const handleClose = () => {
        setAlertData({
            open: false,
            severity: "",
            msg: "",
        });
    };

    const [selectedIndex, setSelectedIndex] = useState("")

    const handleOpenReplyDialog = (index) => {
        setSelectedIndex(index)
        setOpenReplyDialog(true);
    };



    const [Replyname, setReplyName] = useState("")
    const [Replyemail, setReplyEmail] = useState("")
    const [comment, setComment] = useState("")

    const handleCloseReplyDialog = () => {
        setOpenReplyDialog(false);
    };

    const submitBlog = () => {
        let res = state?.postedrData?.comments[selectedIndex]
        // console.log(res, "RRRRRRRRRRRRRRRRRRR");
        let replies = res?.replies
            ? res.replies
            : [];
        let params = {
            Replyname,
            Replyemail,
            comment,
            date: new Date().toLocaleString(),
        }
        replies.push(params);
        res["replies"] = replies
        console.log(res, "RRRRRRRRRRRRRRR");

        firebase
            .database()
            .ref(`blogs/${state.userKey}/${state.key}`)
            .child(`/comments/${selectedIndex}`)
            .update({
                replies
            })
            .then((res) => {
                setAlertData({
                    open: true,
                    severity: "success",
                    msg: "Reply Done Successfully",
                });
                setOpenReplyDialog(false);
                setTimeout(() => {
                    history.push("/");
                }, 2000);
            });
    }

    return (
        <>
            <Snackbar
                open={alertData.open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={alertData.severity}>
                    <span style={{ fontSize: 15 }}>{alertData.msg}</span>
                </Alert>
            </Snackbar>
            <header class="header-2">
                <div class="container">
                    <div class="row">
                        <div class="col"></div>
                    </div>
                </div>
            </header>

            <div class="alice-bg padding-top-60 section-padding-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="blog-post-details-container">
                                <div class="blog-details-wrapper">
                                    <div class="info">
                                        <span class="date">
                                            {moment(state.postedrData.date).format("DD-MMMM,YYYY")}
                                        </span>
                                        <span class="author">
                                            <a href="#">
                                                <i data-feather="user"></i>
                                                {state.postedrData.name}
                                            </a>
                                        </span>
                                        <span class="comments">
                                            <i data-feather="message-circle"></i>
                                            {state.postedrData.commentCount}
                                        </span>
                                    </div>
                                    <div class="post-content">
                                        <h2>{state.title}</h2>
                                        {renderHTML(state.postedrData.blog)}
                                    </div>
                                </div>
                                <div class="post-meta">
                                    <div class="post-author">
                                        {/* <div class="avatar">
                                            <img src="images/blog/author.jpg" class="img-fluid" alt="" />
                                        </div> */}
                                        <div class="name">
                                            <p>Post By</p>
                                            <h5>
                                                <a href="#">{state.postedrData.name}</a>
                                            </h5>
                                        </div>
                                    </div>
                                    {/* <div class="post-tag">
                                        <h6>Post Tag</h6>
                                        <div class="tags">
                                            <a href="#">Design</a>
                                            <a href="#">Creative</a>
                                            <a href="#">Photoshop</a>
                                            <a href="#">Tech</a>
                                        </div>
                                    </div>
                                    <div class="post-share">
                                        <h6>Post Share</h6>
                                        <div class="social-buttons">
                                            <a href="#" class="facebook"><i class="fab fa-facebook-f"></i></a>
                                            <a href="#" class="twitter"><i class="fab fa-twitter"></i></a>
                                            <a href="#" class="google"><i class="fab fa-google-plus-g"></i></a>
                                            <a href="#" class="linkedin"><i class="fab fa-linkedin-in"></i></a>
                                            <a href="#" class="pinterest"><i class="fab fa-pinterest-p"></i></a>
                                            <a href="#" class="instagram"><i class="fab fa-instagram"></i></a>
                                        </div>
                                    </div> */}
                                </div>
                                <div class="post-comment-block">
                                    <div class="comment-respond">
                                        <h4>Write A Comment</h4>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        type="text"
                                                        placeholder="Name"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        type="email"
                                                        placeholder="Email address"
                                                        class="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <textarea
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        placeholder="Comment"
                                                        class="form-control"
                                                    ></textarea>
                                                </div>
                                                {/* <div class="form-group">
                                                        <input class="custom-radio" type="checkbox" id="radio-4" name="termsandcondition" />
                                                        <label for="radio-4">
                                                            <span class="dot"></span> Notify me of follow-up comments by email.
                                                        </label>
                                                    </div> */}
                                            </div>
                                        </div>
                                        <button onClick={submitComment} class="button primary-bg">
                                            Submit
                                        </button>
                                    </div>
                                    <div class="comment-area">
                                        <h4>
                                            {state?.postedrData?.comments
                                                ? state?.postedrData?.comments.length
                                                : state.postedrData.commentCount}{" "}
                                            Comments
                                        </h4>
                                        {state?.postedrData?.comments?.map((data, index) => {
                                            return (
                                                <ul class="comments">
                                                    <li class="comment">
                                                        <div class="comment-wrap">
                                                            <div class="comment-info">
                                                                <div class="commenter-thumb">
                                                                    <img
                                                                        src="images/blog/2.jpg"
                                                                        class="img-fluid"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div class="commenter-info">
                                                                    <span class="commenter-name">
                                                                        {data.name}
                                                                    </span>
                                                                    <span class="date">
                                                                        {moment(data.date).format("DD-MMM-YYYY h:mm A")}
                                                                    </span>
                                                                </div>
                                                                <div class="reply">
                                                                    <button
                                                                        type="button"
                                                                        class="reply-button"
                                                                        data-toggle="modal"
                                                                        data-target="#modal-skill25"
                                                                        onClick={() => handleOpenReplyDialog(index)}
                                                                    >
                                                                        Reply
                                                                    </button>
                                                                    <div>
                                                                        <Modal
                                                                            aria-labelledby="transition-modal-title"
                                                                            aria-describedby="transition-modal-description"
                                                                            className={classes.modal}
                                                                            open={openReplyDialog}
                                                                            onClose={handleCloseReplyDialog}
                                                                            closeAfterTransition
                                                                            BackdropComponent={Backdrop}
                                                                            BackdropProps={{
                                                                                timeout: 500,
                                                                            }}
                                                                        >
                                                                            <Fade in={openReplyDialog}>
                                                                                <div className={classes.paper}>
                                                                                    <Typography
                                                                                        variant="h4"
                                                                                        className={classes.commentHeading}
                                                                                    >
                                                                                        Write A Comment
                                                                                    </Typography>
                                                                                    <Grid
                                                                                        container
                                                                                        spacing={2}
                                                                                        style={{ padding: "20px 0" }}
                                                                                    >
                                                                                        <Grid item xs={12} sm={6}>
                                                                                            <TextField
                                                                                                id="name"
                                                                                                name="name"
                                                                                                value={Replyname}
                                                                                                onChange={(e) => setReplyName(e.target.value)}
                                                                                                placeholder="Name"
                                                                                                fullWidth
                                                                                                className={
                                                                                                    classes.textFieldssss
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={6}>
                                                                                            <TextField
                                                                                                id="email"
                                                                                                name="email"
                                                                                                placeholder="Email Address"
                                                                                                fullWidth
                                                                                                value={Replyemail}
                                                                                                onChange={(e) => setReplyEmail(e.target.value)}
                                                                                                className={
                                                                                                    classes.textFieldssss
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <TextareaAutosize
                                                                                        aria-label="minimum height"
                                                                                        rowsMin={3}
                                                                                        placeholder="Comment"
                                                                                        value={comment}
                                                                                        onChange={(e) => setComment(e.target.value)}
                                                                                        className={classes.textAreaaas}
                                                                                    />
                                                                                    {/* <div>
                                            <FormControlLabel
                                              value="notify"
                                              control={<Radio />}
                                              className={classes.readioCheck}
                                              label="Notify me of follow-up comments by email."
                                            />
                                          </div> */}

                                                                                    <Button
                                                                                        onClick={submitBlog}
                                                                                        variant="contained"
                                                                                        size="large"
                                                                                        className={classes.submitBtn}
                                                                                    // style={{ marginTop: "20px" }}
                                                                                    >
                                                                                        Submit
                                                                                    </Button>
                                                                                </div>
                                                                            </Fade>
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="comment-body">
                                                                <p>{data.message}</p>
                                                            </div>
                                                        </div>
                                                        {data?.replies?.map((reply, index) => {
                                                            return (
                                                                <ul class="comments child-comment">
                                                                    <li class="comment">
                                                                        <div class="comment-wrap">
                                                                            <div class="comment-info">
                                                                                <div class="commenter-thumb">
                                                                                    <img
                                                                                        src="images/blog/3.jpg"
                                                                                        class="img-fluid"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div class="commenter-info">
                                                                                    <span class="commenter-name">
                                                                                        {reply.Replyname}
                                                                                    </span>
                                                                                    <span class="date">{moment(reply.date).format("DD-MMM-YYYY h:mm A")}</span>
                                                                                </div>
                                                                                {/* <div class="reply">
                                                                                    <button
                                                                                        type="button"
                                                                                        class="reply-button"
                                                                                        data-toggle="modal"
                                                                                        data-target="#modal-skill26"
                                                                                    >
                                                                                        Reply
                                                                                    </button>
                                                                                    <div
                                                                                        class="modal fade"
                                                                                        id="modal-skill26"
                                                                                        tabindex="-1"
                                                                                        role="dialog"
                                                                                        aria-hidden="true"
                                                                                    >
                                                                                        <div
                                                                                            class="modal-dialog"
                                                                                            role="document"
                                                                                        >
                                                                                            <div class="modal-content">
                                                                                                <div class="modal-body">
                                                                                                    <div class="comment-respond">
                                                                                                        <h4>Write A Comment</h4>
                                                                                                        <form action="#">
                                                                                                            <div class="row">
                                                                                                                <div class="col-md-6">
                                                                                                                    <div class="form-group">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            placeholder="Name"
                                                                                                                            class="form-control"
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-md-6">
                                                                                                                    <div class="form-group">
                                                                                                                        <input
                                                                                                                            type="email"
                                                                                                                            placeholder="Email address"
                                                                                                                            class="form-control"
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="row">
                                                                                                                <div class="col-md-12">
                                                                                                                    <div class="form-group">
                                                                                                                        <textarea
                                                                                                                            placeholder="Comment"
                                                                                                                            class="form-control"
                                                                                                                        ></textarea>
                                                                                                                    </div>
                                                                                                                    <div class="form-group">
                                                                                                                        <input
                                                                                                                            class="custom-radio"
                                                                                                                            type="checkbox"
                                                                                                                            id="radio-7"
                                                                                                                            name="termsandcondition3"
                                                                                                                        />
                                                                                                                        <label for="radio-7">
                                                                                                                            <span class="dot"></span>{" "}
                                                                                                                            Notify me of
                                                                                                                            follow-up comments
                                                                                                                            by email.
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <button class="button primary-bg">
                                                                                                                Submit
                                                                                                            </button>
                                                                                                        </form>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                            <div class="comment-body">
                                                                                <p>
                                                                                    {reply.comment}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            );
                                                        })}
                                                    </li>
                                                </ul>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* 
            <div class="call-to-action-bg padding-top-90 padding-bottom-90">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="call-to-action-2">
                                <div class="call-to-action-content">
                                    <h2>For Find Your Dream Job or Candidate</h2>
                                    <p>Add resume or post a job. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec.</p>
                                </div>
                                <div class="call-to-action-button">
                                    <a href="add-resume.html" class="button">Add Resume</a>
                                    <span>Or</span>
                                    <a href="post-job.html" class="button">Post A Job</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <footer class="footer-bg">
                <div class="footer-top border-bottom section-padding-top padding-bottom-40">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="footer-logo">
                                    <a href="#">
                                        <img src="images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg" class="img-fluid" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="footer-social">
                                    <ul class="social-icons">
                                        <li><a href="#"><i data-feather="facebook"></i></a></li>
                                        <li><a href="#"><i data-feather="twitter"></i></a></li>
                                        <li><a href="#"><i data-feather="linkedin"></i></a></li>
                                        <li><a href="#"><i data-feather="instagram"></i></a></li>
                                        <li><a href="#"><i data-feather="youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-widget-wrapper padding-bottom-60 padding-top-80">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-2 col-sm-6">
                                <div class="footer-widget footer-shortcut-link">
                                    <h4>Information</h4>
                                    <div class="widget-inner">
                                        <ul>
                                            <li><a href="about-us.html">About Us</a></li>
                                            <li><a href="contact.html">Contact Us</a></li>
                                            <li><a href="terms-and-condition.html">Privacy Policy</a></li>
                                            <li><a href="terms-and-condition.html">Terms &amp; Conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-6">
                                <div class="footer-widget footer-shortcut-link">
                                    <h4>Job Seekers</h4>
                                    <div class="widget-inner">
                                        <ul>
                                            <li><a href="register.html">Create Account</a></li>
                                            <li><a href="javascript:void(0);">Career Counseling</a></li>
                                            <li><a href="javascript:void(0);">My Oficiona</a></li>
                                            <li><a href="faq.html">FAQ</a></li>
                                            <li><a href="javascript:void(0);">Video Guides</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-sm-6">
                                <div class="footer-widget footer-shortcut-link">
                                    <h4>Employers</h4>
                                    <div class="widget-inner">
                                        <ul>
                                            <li><a href="register.html">Create Account</a></li>
                                            <li><a href="javascript:void(0);">Products/Service</a></li>
                                            <li><a href="employer-dashboard-post-job.html">Post a Job</a></li>
                                            <li><a href="faq.html">FAQ</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 offset-lg-1 col-sm-6">
                                <div class="footer-widget footer-newsletter">
                                    <h4>Newsletter</h4>
                                    <p>Quis lectus nulla at volutpat diam ut. Congue quisque egestas diam in arcu.</p>
                                    <form action="#" class="newsletter-form form-inline">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Email address..." />
                                        </div>
                                        <button class="btn button primary-bg">Submit<i class="fas fa-caret-right"></i></button>
                                        <p class="newsletter-error">0 - Please enter a value</p>
                                        <p class="newsletter-success">Thank you for subscribing!</p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom-area">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="footer-bottom border-top">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <p class="copyright-text">By <a href="javascript:void(0);">Geneo Tech</a> 2021, All right reserved</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="back-to-top">
                                                <a href="javascript:void(0);">Back to top<i class="fas fa-angle-up"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
        </>
    );
};

export default BlogDetails;
