import React from "react";
import { Link } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import GroupIcon from "@material-ui/icons/Group";
import BallotIcon from "@material-ui/icons/Ballot";

const EmployerDashboardSidebar = ({ dashboardLink }) => {
  // console.log(props, "Dasboard Link");
  return (
    <ul>
      <li className={dashboardLink === "dashboard" ? "active" : ""}>
        <i class="fas fa-home"></i>
        <Link to="/EmployerDashboard">Dashboard</Link>
      </li>
      <li className={dashboardLink === "editProfile" ? "active" : ""}>
        <i class="fas fa-user"></i>
        <Link to="/EmployerEditProfile">Edit Profile</Link>
      </li>
      <li className={dashboardLink === "manageJobs" ? "active" : ""}>
        <i class="fas fa-briefcase"></i>
        <Link to="/ManageJobs">Manage Jobs</Link>
      </li>
      {/* <li className={dashboardLink === "manageCandidate" ? "active" : ""}>
        <i class="fas fa-users"></i>
        <Link to="/ManageCandidate">Manage Candidates</Link>
      </li> */}
      {/* <li>
        <i class="fas fa-heart"></i>
        <Link href="#">Shortlisted Resumes</Link>
      </li> */}
      <li className={dashboardLink === "postJob" ? "active" : ""}>
        <i class="fas fa-plus-square"></i>
        <Link to="/PostJob">Post New Job</Link>
      </li>
      <li className={dashboardLink === "dashboardMessage" ? "active" : ""}>
        <i class="fas fa-comment"></i>
        <Link to="/DashboardMessage">Message</Link>
      </li>
      <li>
        <i class="fas fa-calculator"></i>
        <Link to={{ pathname: "/CheckOut", state: "skip" }}>Pricing Plans</Link>
      </li>
    </ul>
  );
};

export default EmployerDashboardSidebar;
