import React, { useState, useEffect, useRef } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import {
  Edit,
  Search,
  Menu,
  Grid,
  Briefcase,
  MapPin,
  Clock,
  Heart,
  Edit2,
  AlignLeft,
  Zap,
  Book,
  Upload,
  Gift,
  Mail,
  Printer,
  Flag,
} from "react-feather";
import CompanyLogo1 from "../../assests/images/job/company-logo-1.png";
import CompanyLogo2 from "../../assests/images/job/company-logo-2.png";
import CompanyLogo3 from "../../assests/images/job/company-logo-3.png";
import CompanyLogo8 from "../../assests/images/job/company-logo-8.png";
import CompanyLogo9 from "../../assests/images/job/company-logo-9.png";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";
import FindYourJob from "../../components/FindYourJob";
import { makeStyles } from "@material-ui/core";
import firebase from "firebase";
import fire, { app } from "../../database";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  applyBtn: {
    background: "#023b92 !important",
    "&:hover": {
      background: "#246df8 !important",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Categories = [
  { title: "Select Category", id: 0 },
  { title: "Accounting / Finance", id: 1 },
  { title: "Health Care", id: 2 },
  { title: "Garments / Textile", id: 3 },
  { title: "Telecommunication", id: 4 },
];

const JobDetails = (props) => {
  const jobDetailss = props.location.state.data
  console.log("propssssssssssssssssssssssssssssss", props.location);
  const [ArrayData, setArrayData] = useState([]);
  const [appliedJob, setappliedJob] = useState([]);
  const [selectedResume, setSelectedResume] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userResume, setUserResume] = useState({});
  const [selectedJob, setSelectedJob] = useState(null);
  const [similarJob, setSimilarJobs] = useState([]);
  const [resumeUrl, setResumeUrl] = useState("");
  const [isSelectResumeUrl, setIsSelectResumeUrl] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [JobData2, setJobData2] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    firebase
      .database()
      .ref(`bookmark/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        console.log(
          data,
          "dDDADASDASDASDASDASDASASDASD",
          firebase.auth()?.currentUser?.uid
        );
        let HistoryItems = { ...data };
        setArrayData(HistoryItems);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`applied/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        console.log(
          data,
          "dDDADASDASDASDASDASDASASDASD",
          firebase.auth()?.currentUser?.uid
        );
        let HistoryItems = { ...data };
        setappliedJob(HistoryItems);
      });
  }, []);

  const Data = Object.keys(ArrayData);

  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const [open, setOpen] = React.useState(false);
  console.log("prosp", props.location);
  const jobDetailData = props.location.state?.data;
  const classes = useStyles();
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };

  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const ResumeRef = useRef();

  const removeTag = (data) => {
    const regex = /(<([^>]+)>)/gi;
    const result = data?.replace(regex, "");
    const final = result?.replace("&nbsp;", "");
    return final;
  };

  const emptyAllFields = () => {
    setResumeUrl(false);
    setIsSelectResumeUrl(false);
    setSelectedResume(false);
    setUserResume({});
    nameRef.current.value = "";
    emailRef.current.value = "";
    messageRef.current.value = "";
  };

  // useEffect(() => {
  //   console.log('ppp', props.location.state)
  //   let type = props.location.state.data.category.length <= 2 ? props.location.state.data.category : Categories.find(item => item.title == props.location.state.data.category)
  //   firebase.database().ref(`jobsCategory/${type.length < 2 ? type : type.id}`).on('value', snapshot => {
  //     console.log('snap', snapshot.val())
  //     let tempArr = []
  //     snapshot.forEach((job) => {
  //       if (props.location.state.data.jobId !== job.val().jobId) {
  //         tempArr.push(job.val())
  //       }

  //     })
  //     console.log('temps', tempArr)
  //     setSimilarJobs(tempArr)
  //   })

  // }, [jobDetailData])

  const applyJob = (appliedJob) => {
    setLoading(true);

    if (
      nameRef.current.value &&
      emailRef.current.value &&
      (selectedResume || isSelectResumeUrl)
    ) {
      firebase
        .database()
        .ref(`jobs`)
        .once("value", (snapshot) => {
          snapshot.forEach((employers) => {
            employers.forEach((jobs) => {
              if (selectedJob.jobId == jobs.val().jobId) {
                let arr = [];
                // let params = {
                //   applicant: userResume,
                //   email: emailRef.current.value,
                //   name: nameRef.current.value
                // }
                if (selectedResume) {
                  let params = {
                    userResume,
                    id: firebase.auth().currentUser.uid,
                  };
                } else {
                  let params = {
                    resumeUrl,
                    id: firebase.auth().currentUser.uid,
                  };
                }

                if (jobs.val().applicant) {
                  if (selectedResume) {
                    let params = {
                      userResume,
                      id: firebase.auth().currentUser.uid,
                    };
                    arr = jobs.val().applicant;
                    arr.push({ ...params });
                    // console.log(selectedJob, "SELECTEDD_JOBBBB");
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: arr,
                      })
                      .then((res) => {
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });

                        setOpenDialog(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  } else {
                    let params = {
                      userResume: {
                        cvURL: resumeUrl,
                        name: nameRef.current.value,
                        email: emailRef.current.value,
                        message: messageRef.current.value,
                      },
                      id: firebase.auth().currentUser.uid,
                    };
                    emptyAllFields();
                    arr = jobs.val().applicant;
                    arr.push({ ...params });
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: arr,
                      })
                      .then((res) => {
                        // console.log(selectedJob, "SELECTEDD_JOBBBB");
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });

                        setOpenDialog(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  }
                } else {
                  if (selectedResume) {
                    let params = {
                      userResume,
                      id: firebase.auth().currentUser.uid,
                    };
                    // console.log(selectedJob, "SELECTEDD_JOBBBB");
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: [params],
                      })
                      .then((res) => {
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });
                        setOpenDialog(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      })
                      .then((res) => {
                        setOpenDialog(false);
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  } else {
                    let params = {
                      userResume: {
                        cvURL: resumeUrl,
                        name: nameRef.current.value,
                        email: emailRef.current.value,
                        message: messageRef.current.value,
                      },
                      id: firebase.auth().currentUser.uid,
                    };
                    // console.log(selectedJob, "SELECTEDD_JOBBBB");
                    firebase
                      .database()
                      .ref(`jobs/${employers.key}/${jobs.key}`)
                      .update({
                        applicant: [params],
                      })
                      .then((res) => {
                        firebase
                          .database()
                          .ref(
                            `notifications/${firebase.auth().currentUser.uid}`
                          )
                          .push({
                            title: `You Applied for ${selectedJob.title} `,
                            time: Date().toLocaleString(),
                          });
                        setOpenDialog(false);
                        setLoading(false);
                        emptyAllFields();
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      })
                      .then((res) => {
                        setOpenDialog(false);
                        setAlertData({
                          msg: "Applied Successfully",
                          open: true,
                          severity: "success",
                        });
                      });
                  }
                }
                firebase
                  .database()
                  .ref(`applied/${firebase.auth().currentUser.uid}`)
                  .push({
                    ...selectedJob,
                  });
                // firebase.database().ref(`jobs/${employers.key}/${jobs.key}`).update({
                //  sinan:"hello"
                // })
              }
            });
          });
        });
    } else {
      setLoading(false);
      setAlertData({
        open: true,
        severity: "error",
        msg: "fill all the fields",
      });
    }
  };

  const bookmarkJob = () => {
    firebase
      .database()
      .ref(`bookmark/${firebase?.auth()?.currentUser?.uid}`)
      .push({
        ...jobDetailData,
      })
      .then((res) => {
        setAlertData({
          open: true,
          severity: "success",
          msg: "Bookmarked job Succesfully",
        });
      })
      .catch((err) => {
        setAlertData({
          open: true,
          severity: "error",
          msg: "Some error has occured",
        });
      });
  };

  const bookmarkJobSimilar = (data) => {
    firebase
      .database()
      .ref(`bookmark/${firebase?.auth()?.currentUser?.uid}`)
      .push({
        ...data,
      })
      .then((res) => {
        setAlertData({
          open: true,
          severity: "success",
          msg: "Bookmarked job Succesfully",
        });
      })
      .catch((err) => {
        setAlertData({
          open: true,
          severity: "error",
          msg: "Some error has occured",
        });
      });
  };

  const removeBookmarkJob = (id) => {
    firebase
      .database()
      .ref(`bookmark/${firebase.auth().currentUser.uid}/${id}`)
      .remove()
      .then((res) => {
        setAlertData({
          open: true,
          severity: "success",
          msg: "Bookmark removed Successfully",
        });
      });
  };

  const removeBookmarkJobSimilar = (id, data) => {
    Data.map((data) => {
      if (ArrayData[data].jobId == id) {
        firebase
          .database()
          .ref(`bookmark/${firebase.auth().currentUser.uid}/${data}`)
          .remove()
          .then((res) => {
            setAlertData({
              open: true,
              severity: "success",
              msg: "Bookmark removed Successfully",
            });
          });
      } else {
      }
    });
  };

  const renderButton = () => {
    const { data } = props.location.state;
    console.log("ceddddddddddddddddd", ArrayData[data], data);
    // const isFind = Data.find((dataxxx) => console.log(ArrayData[dataxxx],data,"DAAAAAAAAA_ARRRRRRRRRRRRRRR"))
    const isFind = Data.find(
      (dataxxx) => ArrayData[dataxxx].jobId == data.jobId
    );
    console.log(isFind, "isFIND=>>>>>>>>>>>>>>");
    if (isFind) {
      return (
        <a class="save" onClick={() => removeBookmarkJob(isFind)}>
          <i>
            <Heart color="red" />
          </i>
          Saved Job
        </a>
      );
    } else {
      return (
        <a class="save" onClick={bookmarkJob}>
          <i>
            <Heart />
          </i>
          Save Job
        </a>
      );
    }
  };

  const renderButtonSimilar = (id, data) => {
    const isFind = Data.find((data) => ArrayData[data].jobId == id);
    if (isFind) {
      return (
        <a onClick={() => removeBookmarkJobSimilar(id, data)} class="favourite">
          <i>
            <Heart color="red" />
          </i>
        </a>
      );
    } else {
      return (
        <a onClick={() => bookmarkJobSimilar(data)} class="favourite">
          <i>
            <Heart />
          </i>
        </a>
      );
    }
  };

  const openDialogBox = (jobData) => {
    setSelectedJob(jobData);
    setOpenDialog(true);
  };

  const appliedData = Object.keys(appliedJob);

  const renderApplyButton = () => {
    const { data } = props.location.state;
    console.log(props.location.state.data.jobId, data, " Bookmark_DATA");
    const isFind = appliedData.find(
      (dataxxx) => appliedJob[dataxxx].jobId == data.jobId
    );
    console.log(isFind, "ASDASDAXZCXZCXZCXZCXZCXZCZASD");
    if (isFind) {
      return (
        <a
          className={`${classes.applyBtn} apply`}
          // onClick={checkForLogin}
          data-toggle="modal"
          data-target="#apply-popup-id"
        >
          {" "}
          Already Applied
        </a>
      );
    } else {
      return (
        <a
          className={`${classes.applyBtn} apply`}
          // onClick={checkForLogin}
          onClick={checkForLogin}
          data-toggle="modal"
          data-target="#apply-popup-id"
        >
          Apply Online
        </a>
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const checkForLogin = () => {
    const user = sessionStorage.getItem("user");
    if (user) {
      openDialogBox(jobDetailData);
    } else {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Login first",
      });
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    }
  };

  const printPage = () => {
    window.print();
  };

  const reportJob = () => { };

  console.log(jobDetailData, "jobDetailDatajobDetailDatajobDetailData");

  const fileRefer = useRef();

  return (
    <>
      {/* <div style={{padding:30}}>
            <NotificationAndAccount  />
            <Header />
          </div>   */}
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div class="apply-popup">
          <div
            id="apply-popup-id"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    <i>
                      <Edit2 />
                    </i>
                    APPLY FOR THIS JOB
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span
                      onClick={() => {
                        setOpenDialog(false);
                      }}
                      aria-hidden="true"
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <input
                      ref={nameRef}
                      type="text"
                      placeholder="Full Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      ref={emailRef}
                      type="email"
                      placeholder="Email Address"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      ref={messageRef}
                      class="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="form-group file-input-wrap">
                    <label>Upload CV</label>
                    <input
                      ref={fileRefer}
                      type="file"
                      disabled={selectedResume}
                      onChange={(e) => {
                        if (!selectedResume) {
                          setIsSelectResumeUrl(!isSelectResumeUrl);
                          if (!isSelectResumeUrl) {
                            if (e.target.files[0]) {
                              setLoading(true);
                              const file = e.target.files[0];
                              const storageRef = app.storage().ref();
                              const fileRef = storageRef.child(file.name);
                              fileRef.put(file).then((res) => {
                                storageRef
                                  .child(file.name)
                                  .getDownloadURL()
                                  .then(function (url) {
                                    setResumeUrl(url);
                                    setLoading(false);
                                    // setUserCv({
                                    //   ...userCv,
                                    //   cvURL:url
                                    // })
                                    // setLoader(false)/
                                    // setUploadMedia(url)
                                  });
                              });
                            }
                          }
                        } else {
                          setAlertData({
                            open: true,
                            severity: "error",
                            msg: "You have already selected created resume!",
                          });
                        }
                      }}
                      title="Upload CV"
                    />
                    {isSelectResumeUrl && (
                      <button
                        onClick={() => {
                          setIsSelectResumeUrl(false);
                          setResumeUrl("");
                          fileRefer.current.value = null;
                        }}
                      >
                        Remove
                      </button>
                    )}
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 20,
                      }}
                    >
                      <h4>Or</h4>
                    </div>
                    <button
                      class="button secondary-bg btn-block"
                      style={{
                        backgroundColor: selectedResume ? "#94d1ff" : "#f0eded",
                        color: "black",
                      }}
                      onClick={() => {
                        if (!isSelectResumeUrl) {
                          setSelectedResume(!selectedResume);
                          if (!selectedResume) {
                            firebase
                              .database()
                              .ref(`resumes`)
                              .on("value", (snapshot) => {
                                let tempArr = [];
                                snapshot.forEach((resume) => {
                                  tempArr.push(resume.key);
                                });
                                let isResume = tempArr.find(
                                  (id) => id == firebase.auth().currentUser.uid
                                );
                                if (isResume) {
                                  firebase
                                    .database()
                                    .ref(`resumes/${isResume}`)
                                    .on("value", (snapshot) => {
                                      setUserResume(snapshot.val());
                                    });
                                } else {
                                  setAlertData({
                                    open: true,
                                    severity: "error",
                                    msg: "Please make your resume first in Add resume section!",
                                  });
                                  setSelectedResume(false);
                                }
                              });
                          }
                        } else {
                          setAlertData({
                            open: true,
                            severity: "error",
                            msg: "You have already selected Uploaded resume!",
                          });
                        }
                      }}
                    >
                      Select created resume
                    </button>
                  </div>
                  <button
                    disabled={loading}
                    style={{ backgroundColor: loading && "grey" }}
                    class="button primary-bg btn-block"
                    onClick={applyJob}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      {/* call to action end */}

      {/* Candidate details */}

      <div class="alice-bg padding-top-60 section-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="job-listing-details">
                <div class="job-title-and-info">
                  <div class="title">
                    <div class="thumb">
                      {console.log(jobDetailss, "jobDetailssjobDetailssjobDetailss")}
                      <img src={jobDetailss.userDetails.avatar} class="img-fluid" alt="" />
                    </div>
                    <div class="title-body">
                      <h4>{jobDetailData?.title}</h4>
                      <div class="info">
                        <span class="company">
                          <a href="#">
                            <i>
                              <Briefcase />
                            </i>
                            Theoreo
                          </a>
                        </span>
                        <span class="office-location">
                          <a href="#">
                            <i>
                              <MapPin />
                            </i>
                            {jobDetailData?.location}
                          </a>
                        </span>
                        <span class="job-type full-time">
                          <a href="#">
                            <i>
                              <Clock />
                            </i>
                            {jobDetailData?.type}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  {props.location.state.type == "Candidate" ? (
                    <div class="buttons">
                      {renderButton()}
                      {/* <a class="save" onClick={bookmarkJob}>
                      <i>
                        <Heart />
                      </i>
                      Save Job
                    </a> */}
                      {/* <a
                        className={`${classes.applyBtn} apply`}
                        onClick={checkForLogin}
                        data-toggle="modal"
                        data-target="#apply-popup-id"
                      >
                        Apply Online
                    </a> */}
                      {renderApplyButton()}
                    </div>
                  ) : null}
                </div>

                <div class="details-information section-padding-60">
                  <div class="row">
                    <div class="col-xl-7 col-lg-8">
                      <div class="description details-section">
                        <h4>
                          <i>
                            <AlignLeft />
                          </i>
                          Job Description
                        </h4>
                        <p>{removeTag(jobDetailData?.jobDes)}</p>
                        <p></p>
                      </div>
                      <div class="responsibilities details-section">
                        <h4>
                          <i>
                            <Zap />
                          </i>
                          Responsibilities
                        </h4>
                        <ul>
                          <li>{removeTag(jobDetailData?.respo)}</li>
                        </ul>
                      </div>
                      <div class="edication-and-experience details-section">
                        <h4>
                          <i>
                            <Book />
                          </i>
                          Education + Experience
                        </h4>
                        <ul>
                          <li>{removeTag(jobDetailData?.education)}</li>
                          <li>{jobDetailData?.Qualification}</li>
                          <li>{jobDetailData?.exp}.</li>
                        </ul>
                      </div>
                      <div class="other-benifit details-section">
                        <h4>
                          <i>
                            <Gift />
                          </i>
                          Other Benefits
                        </h4>
                        <ul>
                          <li>{removeTag(jobDetailData?.benefits)}.</li>
                        </ul>
                      </div>
                      {/* {props.location.state.type == "Candidate" ?
                        <div class="job-apply-buttons">
                          <a
                            href="#"
                            class="apply"
                            data-toggle="modal"
                            data-target="#apply-popup-id"
                          >
                            Apply Online
                        </a>
                          <a href="#" class="email">
                            <i>
                              <Mail />
                            </i>
                          Email Job
                        </a>
                        </div>
                        : null

                      } */}
                    </div>
                    <div class="col-xl-4 offset-xl-1 col-lg-4">
                      <div class="information-and-share">
                        <div class="job-summary">
                          <h4>Job Summary</h4>
                          <ul>
                            <li>
                              <span>Published on:</span>{" "}
                              {moment(jobDetailData?.publishDate).format(
                                "YYYY-MM-DD"
                              )}
                            </li>
                            <li>
                              <span>Vacancy:</span> {jobDetailData?.vacancy}
                            </li>
                            <li>
                              <span>Employment Status:</span>{" "}
                              {jobDetailData?.type}
                            </li>
                            <li>
                              <span>Experience:</span> {jobDetailData?.exp}
                            </li>
                            <li>
                              <span>Job Location:</span>
                              {jobDetailData?.location}
                            </li>
                            <li>
                              <span>Salary:</span> {jobDetailData?.salaryRange}
                            </li>
                            {console.log(
                              jobDetailData,
                              "jobDetailDatajobDetailData"
                            )}
                            <li>
                              <span>Gender:</span> {jobDetailData?.gender}
                            </li>
                            <li>
                              <span>Application Deadline:</span>{" "}
                              {jobDetailData?.date}
                            </li>
                          </ul>
                        </div>
                        {/* <div class="share-job-post">
                          <span class="share">
                            <i class="fas fa-share-alt"></i>Share:
                          </span>
                          <a href="#">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                          <a href="#">
                            <i class="fab fa-twitter"></i>
                          </a>
                          <a href="#">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                          <a href="#">
                            <i class="fab fa-google-plus-g"></i>
                          </a>
                          <a href="#">
                            <i class="fab fa-pinterest-p"></i>
                          </a>
                          <a href="#" class="add-more">
                            <span class="ti-plus"></span>
                          </a>
                        </div> */}
                        <div class="buttons">
                          <a onClick={printPage} class="button print">
                            <i>
                              <Printer />
                            </i>
                            Print Job
                          </a>
                          {/* <a onClick={reportJob} class="button report">
                            <i>
                              <Flag />
                            </i>
                            Report Job
                          </a> */}
                        </div>
                        <div class="job-location">
                          <h4>Job Location</h4>
                          <div id="map-area">
                            <div
                              class="cp-map"
                              id="location"
                              data-lat="40.713355"
                              data-lng="-74.005535"
                              data-zoom="10"
                            ></div>
                            {/* <!-- <div class="cp-map" id="location" data-lat="40.713355" data-lng="-74.005535" data-zoom="10"></div> --> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-7 col-lg-8">
                    <div class="company-information details-section">
                      <h4>
                        <i>
                          <Briefcase />
                        </i>
                        About the Company
                      </h4>
                      <ul>
                        <li>
                          <span>Company Name:</span>{" "}
                          {jobDetailData?.companyName}
                        </li>
                        <li>
                          <span>Address:</span>
                          {jobDetailData?.webAddress} {jobDetailData?.yourcity},{" "}
                          {jobDetailData?.yourlocation}
                        </li>
                        {/* <li>
                          <span>Company Profile:</span>
                        </li> */}
                        <li>{jobDetailData?.companyProfile}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Candidate detail end */}

      <div class="apply-popup">
        <div
          class="modal fade"
          id="apply-popup-id"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  <i>
                    <Edit2 />
                  </i>
                  APPLY FOR THIS JOB
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form action="#">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Full Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="Email Address"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="form-group file-input-wrap">
                    <label for="up-cv">
                      <input id="up-cv" type="file" />
                      <i>
                        <Upload />
                      </i>
                      <span>
                        Upload your resume <span>(pdf,zip,doc,docx)</span>
                      </span>
                    </label>
                  </div>
                  <div class="more-option">
                    <input
                      class="custom-radio"
                      type="checkbox"
                      id="radio-4"
                      name="termsandcondition"
                    />
                    <label for="radio-4">
                      <span class="dot"></span> I accept the{" "}
                      <a href="#">terms & conditions</a>
                    </label>
                  </div>
                  <button class="button primary-bg btn-block">Apply Now</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Candidate DEtail end */}

      <div class="apply-popup">
        <div
          class="modal fade"
          id="apply-popup-id"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  <i>
                    <Edit2 />
                  </i>
                  APPLY FOR THIS JOB
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form action="#">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Full Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="Email Address"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="form-group file-input-wrap">
                    <label for="up-cv">
                      <input id="up-cv" type="file" />
                      <i>
                        <Upload />
                      </i>
                      <span>
                        Upload your resume <span>(pdf,zip,doc,docx)</span>
                      </span>
                    </label>
                  </div>
                  <div class="more-option">
                    <input
                      class="custom-radio"
                      type="checkbox"
                      id="radio-4"
                      name="termsandcondition"
                    />
                    <label for="radio-4">
                      <span class="dot"></span> I accept the{" "}
                      <a href="#">terms & conditions</a>
                    </label>
                  </div>
                  <button class="button primary-bg btn-block">Apply Now</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* JObs       */}
      {props.location.state.type == "Candidate" ? (
        <div class="section-padding-bottom alice-bg">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="section-header section-header-2 section-header-with-right-content">
                  <h2>Similar Jobs</h2>
                  <Link to="/jobListing" class="header-right">
                    + Browse All Jobs
                  </Link>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                {similarJob &&
                  similarJob.map((job, index) => (
                    <div class="job-list">
                      <div class="thumb">
                        <a href="#">
                          <img src={CompanyLogo1} class="img-fluid" alt="" />
                        </a>
                      </div>
                      <div class="body">
                        <div class="content">
                          <h4>
                            <Link
                              to={{
                                pathname: "/JobDetails",
                                state: { data: job, type: "Candidate" },
                              }}
                            >
                              {job.title}
                            </Link>
                          </h4>
                          <div class="info">
                            <span class="company">
                              <a href="#">
                                <i>
                                  <Briefcase />
                                </i>
                                Theoreo
                              </a>
                            </span>
                            <span class="office-location">
                              <a href="#">
                                <i>
                                  <MapPin />
                                </i>
                                {job.location}
                              </a>
                            </span>
                            <span class="job-type full-time">
                              <a href="#">
                                <i>
                                  <Clock />
                                </i>
                                {job.type}
                              </a>
                            </span>
                          </div>
                        </div>
                        <div class="more">
                          <div class="buttons">
                            <Link
                              onClick={() => window.scrollTo(0, 0)}
                              to={{
                                pathname: "/JobDetails",
                                state: { data: job, type: "Candidate" },
                              }}
                              class="button"
                            >
                              Apply Now
                            </Link>
                            {renderButtonSimilar(job.jobId, job)}
                          </div>
                          <p class="deadline">{job.date}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <FindYourJob />

      {/* footer */}
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default JobDetails;
