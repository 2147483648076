import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import NotificationAndAccount from "./../../components/NotificationAndAccount/index";
import Header from "./../../components/Header/index";
import FindYourJob from "../../components/FindYourJob";
import { Footer, NewsLetterFooter, SocialShare } from "../../components";
import DescriptionIcon from "@material-ui/icons/Description";
import GroupIcon from "@material-ui/icons/Group";
import BallotIcon from "@material-ui/icons/Ballot";
import Dashboard from "../../components/Dashboard/Dashboard";
import EmployerDashboardSidebar from "./../../components/EmployerDashboardSidebar/index";

const EmployeeDashboard = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <Dashboard
        name="Employer Dashboard"
        DashboardSidebar={
          <EmployerDashboardSidebar dashboardLink="dashboard" />
        }
      />

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};
export default EmployeeDashboard;
