import React, { useEffect, useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { Edit, Search } from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";

import thumb1 from "../../assests/images/testimonial/thumb-1.jpg";
import thumb2 from "../../assests/images/testimonial/thumb-2.jpg";
import thumb3 from "../../assests/images/testimonial/thumb-3.jpg";
import thumb4 from "../../assests/images/testimonial/thumb-4.jpg";
import thumb5 from "../../assests/images/testimonial/thumb-5.jpg";

import slickLogo1 from "../../assests/images/testimonial/1.png";
import slickLogo2 from "../../assests/images/testimonial/2.png";
import slickLogo3 from "../../assests/images/testimonial/3.png";
import slickLogo4 from "../../assests/images/testimonial/4.png";
import slickLogo5 from "../../assests/images/testimonial/5.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FindYourJob from "./../../components/FindYourJob";

const HowItWorks = () => {
  const history = useHistory();
  const [nav1, setNav1] = React.useState(null);
  const [nav2, setNav2] = React.useState(null);
  let slider1 = [];
  let slider2 = [];
  const navigate = () => {
    history.push("/");
  };

  // React.useEffect(() => {
  //   setNav1(slider1);
  //   setNav2(slider2);
  // }, [slider1, slider2]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const [WorkData, setWorksData] = useState({})

  useEffect(() => {
    firebase.database().ref("howworks").on("value", snapshot => {
      let data = snapshot.val() ? snapshot.val() : {}
      setWorksData(data)
    })
  }, [])

  const Data = Object.keys(WorkData)
  console.log(Data, "DADSAMDASdomasdoasmdoasmd");
  return (
    <div>
      {/* <NotificationAndAccount  />
            <Header /> */}

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>How It Work</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      How It Work
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <Search />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb End */}
      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="white-bg others-block">
                <div class="how-it-work">
                  <div class="row">
                    <div class="col-lag-6 order-lg-2">
                      <div class="working-process-thumb">
                        {/* <img
                          width="120px"
                          src={ThumbLogo}
                          class="img-fluid"
                          alt=""
                        /> */}
                      </div>
                    </div>
                    <div class="col-lg-6 order-lg-1">
                      {Data.map((data, index) => {
                        return (
                          <div class="working-process">
                            <h3>
                              <span>#{index + 1}.</span>{WorkData[data].howWorksDes}
                            </h3>
                            <p>
                              {WorkData[data].howWorks}
                            </p>
                          </div>
                        )
                      })
                      }

                      {/* <div class="working-process">
                        <h3>
                          <span>#02.</span>Sponsor your job to hire great people faster
                        </h3>
                        <p>
                          Although thousands of jobs are added to Employ each day, the free job postings lose visibility.  To hire faster, pay to promote your postings as sponsor jobs to hire faster.Sponsored Jobs appear for longer than non-sponsored jobs, and give you access to quality candidates right with video content when you post. Learn more.
                        </p>
                      </div>
                      <div class="working-process">
                        <h3>
                          <span>#03.</span> Manage your Candidates
                        </h3>
                        <p>
                          You’ll receive job applications and resumes via email. You can also manage jobs and candidates from your Employ dashboard.
                          Review job applications, view their personal video profiles, schedule interviews and view recommended candidates all from one place.
                        </p>
                      </div>
                      <div class="working-process">
                        <h3>
                          <span>#03.</span>Find the Right Fit
                        </h3>
                        <p>
                          Update your job description, budget, and company intro video any time to drive more people to your jobs and convert them to apply.

                          Attract best-fit talent with your free company profile page with our video features.  A strong employer brand will helps you compete for the right talent to fit your company—when they’re actively searching and applying for jobs on Employ. The exact candidates you need by searching on Employ resumes

                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* <div class="padding-bottom-90">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="testimonial">
                          <div class="testimonial-quote">
                            <img src={quoteLogo} class="img-fluid" alt="" />
                          </div>
                          <Slider
                            asNavFor={nav1}
                            ref={(slider) => (slider1 = slider)}
                          >
                            <div className="testimonial-item">
                              <p
                                style={{
                                  fontSize: "2.0rem",
                                  fontWeight: 400,
                                  maxWidth: "700px",
                                  margin: "30px auto 50px",
                                  lineHeight: 1.85,
                                }}
                              >
                                "Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua
                                egestas"
                              </p>
                            </div>
                            <div className="testimonial-item">
                              <p>
                                "Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua"
                              </p>
                            </div>
                            <div className="testimonial-item">
                              <p>
                                "Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna"
                              </p>
                            </div>
                            <div className="testimonial-item">
                              <p>
                                "Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua
                                cursus"
                              </p>
                            </div>
                            <div className="testimonial-item">
                              <p>
                                "Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua
                                egestas"
                              </p>
                            </div>
                          </Slider>

                          <Slider
                            {...settings}
                            className="testimonial-nav"
                            asNavFor={nav1}
                            ref={(slider) => (slider2 = slider)}
                          // swipeToSlide={true}
                          // focusOnSelect={true}
                          >
                            <div className="commenter-thumb">
                              <img
                                src={thumb1}
                                className="img-fluid commenter"
                                alt=""
                              />
                              <img
                                src={slickLogo1}
                                className="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div className="commenter-thumb">
                              <img
                                src={thumb2}
                                className="img-fluid commenter"
                                alt=""
                              />
                              <img
                                src={slickLogo2}
                                className="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div className="commenter-thumb">
                              <img
                                src={thumb3}
                                className="img-fluid commenter"
                                alt=""
                              />
                              <img
                                src={slickLogo3}
                                className="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div className="commenter-thumb">
                              <img
                                src={thumb4}
                                className="img-fluid commenter"
                                alt=""
                              />
                              <img
                                src={slickLogo4}
                                className="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div className="commenter-thumb">
                              <img
                                src={thumb5}
                                className="img-fluid commenter"
                                alt=""
                              />
                              <img
                                src={slickLogo5}
                                className="comapnyLogo"
                                alt=""
                              />
                            </div>
                            <div className="commenter-thumb">
                              <img
                                src={thumb5}
                                className="img-fluid commenter"
                                alt=""
                              />
                              <img
                                src={slickLogo5}
                                className="comapnyLogo"
                                alt=""
                              />
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />

      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </div>
  );
};

export default HowItWorks;
