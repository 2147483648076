import React, { useEffect, useRef, useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { Search, Briefcase, MapPin, Clock, Heart, Edit2 } from "react-feather";
import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase";
import fire from "../../database";
import FindYourJob from "./../../components/FindYourJob";
import JobListDropDown from "../../components/JobListDropDown/JobListDropDown";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import MenuIcon from "@material-ui/icons/Menu";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { Country, State, City } from "country-state-city";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  formControl: {
    margin: "0 8px",
    minWidth: 120,
    // maxWidth: 300,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  heading: {
    fontSize: "1.7rem",
    fontWeight: 500,
    color: "#212529",
  },
  dropdownHead: {
    "&.MuiAccordion-root .MuiAccordionSummary-root": {
      "&:hover div": {
        color: "blue",
      },
      "&:hover div > h4": {
        color: "#246df8",
      },
    },
  },
  categoryJobStyle: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isUser = { isAuthenticated: false };
const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const CategoryJobListing = () => {
  const params = useParams();
  console.log("params", params);
  const classes = useStyles();
  const [jobData, setJobData] = useState([]);
  const [JobData2, setJobData2] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [ArrayData, setArrayData] = useState([]);
  const [age, setAge] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [selectedResume, setSelectedResume] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [changeIcon, setChangeIcon] = useState(false);
  const [changeIconExp, setChangeIconExp] = useState(false);
  const [jobType, setJobtype] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");

  const [allStates, setAllState] = useState([]);
  const [allcities, setAllCities] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [changeIconQualification, setChangeIconQualification] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });
  const [userResume, setUserResume] = useState({});
  isUserAuthenticate();
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const ResumeRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v2/all")
      .then((res) => {
        setAllCountries(res.data);
        console.log(res, "Ressssssssssssssssssssssssssss");
      })
      .catch((err) => {
        console.log(err, "errrrrrrrr");
      });
  }, []);

  useEffect(() => {
    let filter = JobData2.filter((item) => {
      if (city && jobType && experience && qualification && state) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (city && jobType && experience && qualification) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (state && jobType && experience && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (state && jobType && experience && city) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase()
        );
      }

      if (state && jobType && city && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      if (state && city && experience && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }

      // 3 conditions

      if (jobType && experience && qualification) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (state && experience && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (jobType && state && qualification) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (jobType && experience && state) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }
      if (city && experience && state) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }
      if (state && city && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (jobType && state && city) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      if (country && city && state) {
        let newCountry = country.split(",");
        let newState = state.split(",");
        return (
          item.yourcountry.toLowerCase() == newCountry[1].toLowerCase() &&
          item.yourstate.toLowerCase() == newState[1].toLowerCase() &&
          item.yourcity.toLowerCase() == city.toLowerCase()
        );
      }

      //4 conditions
      if (jobType && experience) {
        return (
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.type.toLowerCase() == jobType.toLowerCase()
        );
      }
      if (jobType && qualification) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (jobType && state) {
        return (
          item.type.toLowerCase() == jobType.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }
      if (experience && qualification) {
        return (
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (experience && state) {
        return (
          item.exp.toLowerCase() == experience.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }
      if (state && qualification) {
        return (
          item.yourstate.toLowerCase() == state.toLowerCase() &&
          item.Qualification.toLowerCase() == qualification.toLowerCase()
        );
      }
      if (city && state) {
        return (
          item.yourcity.toLowerCase() == city.toLowerCase() &&
          item.yourstate.toLowerCase() == state.toLowerCase()
        );
      }

      // single conditions

      if (qualification) {
        return item.Qualification.toLowerCase() == qualification.toLowerCase();
      }

      if (experience) {
        return item.exp.toLowerCase() == experience.toLowerCase();
      }

      if (jobType) {
        return item.type.toLowerCase() == jobType.toLowerCase();
      }
      if (state) {
        return item.yourstate.toLowerCase() == state.toLowerCase();
      }
    });
    console.log(filter, "FEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");

    setJobData(filter);

    if (
      qualification == "" &&
      jobType == "" &&
      experience == "" &&
      state == "" &&
      city == ""
    ) {
      setJobData(JobData2);
    }
  }, [jobType, qualification, experience, state, city, country]);

  useEffect(() => {
    // alert("ran component")
    let tempArr = [];
    firebase
      .database()
      .ref(`jobsCategory/${params.id}`)
      .once("value", (snapshot) => {
        snapshot.forEach((values) => {
          tempArr.push(values.val());
        });
      });
    console.log(tempArr, "TEMPPPPPPPPPPPPP");
    setJobData(tempArr);
    setJobData2(tempArr);
  }, []);
  // if (category && jobType && experience && qualification) {
  //   return (
  //     item.category.toLowerCase() == category.toLowerCase() &&
  //     item.type.toLowerCase() == jobType.toLowerCase() &&
  //     item.exp.toLowerCase() == experience.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }

  console.log(jobData, JobData2, "JOBSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
  // 3 conditions

  // if (category && jobType) {

  // }
  // if (category && experience) {

  // }
  // if (category && qualification) {

  // }
  // if (jobType && experience) {

  // }
  // if (jobType && qualification) {

  // }
  // if (category && jobType && experience) {
  //   return (
  //     item.category.toLowerCase() == category.toLowerCase() &&
  //     item.type.toLowerCase() == jobType.toLowerCase() &&
  //     item.exp.toLowerCase() == experience.toLowerCase()
  //   );
  // }
  // if (category && jobType && qualification) {
  //   return (
  //     item.category.toLowerCase() == category.toLowerCase() &&
  //     item.type.toLowerCase() == jobType.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }
  // if (jobType && experience && qualification) {
  //   return (
  //     item.type.toLowerCase() == jobType.toLowerCase() &&
  //     item.exp.toLowerCase() == experience.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }
  // if (category && experience && qualification) {
  //   return (
  //     item.category.toLowerCase() == category.toLowerCase() &&
  //     item.exp.toLowerCase() == experience.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }
  // if (location && experience && qualification) {
  //   return (
  //     item.location.toLowerCase() == location.toLowerCase() &&
  //     item.exp.toLowerCase() == experience.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }

  // 2 conditions
  // if (category) {

  // }
  // if (category && jobType) {
  //   return (
  //     item.category.toLowerCase() == category.toLowerCase() &&
  //     item.type.toLowerCase() == jobType.toLowerCase()
  //   );
  // }
  // if (category && experience) {
  //   return (
  //     item.location.toLowerCase() == location.toLowerCase() &&
  //     item.category.toLowerCase() == category.toLowerCase()
  //   );
  // }
  // if (category && qualification) {
  //   return (
  //     item.category.toLowerCase() == category.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }
  // if (jobType && location) {
  //   return (
  //     item.type.toLowerCase() == jobType.toLowerCase() &&
  //     item.location.toLowerCase() == location.toLowerCase()
  //   );
  // }

  // if (jobType && qualification) {
  //   return (
  //     item.type.toLowerCase() == jobType.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }

  // if (experience && qualification) {
  //   return (
  //     item.exp.toLowerCase() == experience.toLowerCase() &&
  //     item.Qualification.toLowerCase() == qualification.toLowerCase()
  //   );
  // }

  //single condition

  // if (category) {
  //   return item.category.toLowerCase() == category.toLowerCase();
  // }
  // if (location) {
  //   return item.location.toLowerCase() == location.toLowerCase();
  // }
  // if (jobType) {
  //   return item.type.toLowerCase() == jobType.toLowerCase();
  // }
  // if (experience) {
  //   return item.exp.toLowerCase() == experience.toLowerCase();
  // }
  // console.log(snapshot.val(), "VASDASDOMASDOSMD");
  // let tempArr = [];
  // console.log("key ", snapshot.key);
  // snapshot.forEach((jobs) => {
  //   console.log("employer key", jobs.key);
  //   tempArr.push(jobs.val());
  // });
  // console.log(tempArr, "TEMPPPPPPPP");
  // setJobData(tempArr);
  // setJobData2(tempArr);
  //   });

  // }, []);

  console.log("jobDataisHere=>>>>", jobData);

  useEffect(() => {
    firebase
      .database()
      .ref(`bookmark/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        console.log(
          data,
          "dDDADASDASDASDASDASDASASDASD",
          firebase.auth()?.currentUser?.uid
        );
        let HistoryItems = { ...data };
        setArrayData(HistoryItems);
      });
  }, []);

  const openDialogBox = (jobData) => {
    setSelectedJob(jobData);
    setOpen(true);
  };
  const Data = Object.keys(ArrayData);

  const bookmarkJob = (data) => {
    firebase
      .database()
      .ref(`bookmark/${firebase?.auth()?.currentUser?.uid}`)
      .push({
        ...data,
      })
      .then((res) => {
        setAlertData({
          open: true,
          severity: "success",
          msg: "Bookmarked job Succesfully",
        });
      })
      .catch((err) => {
        setAlertData({
          open: true,
          severity: "error",
          msg: "Some error has occured",
        });
      });
  };

  const applyJob = (appliedJob) => {
    console.log("selectedJob=>>>", selectedJob);
    console.log("appliedJob=>>>", selectedJob);
    console.log("selectedResume=>>>", selectedResume);
    if (nameRef.current.value && emailRef.current.value && selectedResume) {
      firebase
        .database()
        .ref(`jobs`)
        .once("value", (snapshot) => {
          console.log("key ", snapshot.key);
          snapshot.forEach((employers) => {
            console.log("employer key", employers.key);
            employers.forEach((jobs) => {
              console.log("job key", jobs.key);

              if (selectedJob.jobId == jobs.val().jobId) {
                let arr = [];
                // let params = {
                //   applicant: userResume,
                //   email: emailRef.current.value,
                //   name: nameRef.current.value
                // }
                if (jobs.val().applicant) {
                  arr = jobs.val().applicant;
                  console.log(
                    typeof arr,
                    arr.length,
                    arr,
                    "TPEEEEEEEEEEEEEEEEEE"
                  );
                  arr.push({ ...userResume });
                  firebase
                    .database()
                    .ref(`jobs/${employers.key}/${jobs.key}`)
                    .update({
                      applicant: arr,
                    })
                    .then((res) => {
                      setOpen(false);
                      setAlertData({
                        msg: "Applied Successfully",
                        open: true,
                        severity: "success",
                      });
                    });
                  console.log(jobs.val().applicant, "APPLICARIONSSS");
                } else {
                  firebase
                    .database()
                    .ref(`jobs/${employers.key}/${jobs.key}`)
                    .update({
                      applicant: [userResume],
                    })
                    .then((res) => {
                      setOpen(false);
                      setAlertData({
                        msg: "Applied Successfully",
                        open: true,
                        severity: "success",
                      });
                    });
                }
                firebase
                  .database()
                  .ref(`applied/${firebase.auth().currentUser.uid}`)
                  .push({
                    ...selectedJob,
                  });
                // firebase.database().ref(`jobs/${employers.key}/${jobs.key}`).update({
                //  sinan:"hello"
                // })
              }
            });
          });
        });
    } else {
      setAlertData({
        open: true,
        severity: "error",
        msg: "fill all the fields",
      });
    }
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAlert = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const removeBookmarkJob = (id, data) => {
    Data.map((data) => {
      if (ArrayData[data].jobId == id) {
        firebase
          .database()
          .ref(`bookmark/${firebase.auth().currentUser.uid}/${data}`)
          .remove()
          .then((res) => {
            setAlertData({
              open: true,
              severity: "success",
              msg: "Bookmark removed Successfully",
            });
          });
      } else {
      }
    });
  };

  const renderButton = (id, data) => {
    console.log("ceddddddddddddddddd", Data);
    const isFind = Data.find((data) => ArrayData[data].jobId == id);
    if (isFind) {
      return (
        <a onClick={() => removeBookmarkJob(id, data)} class="favourite">
          <i>
            <Heart color="red" />
          </i>
        </a>
      );
    } else {
      return (
        <a onClick={() => bookmarkJob(data)} class="favourite">
          <i>
            <Heart />
          </i>
        </a>
      );
    }
  };

  const history = useHistory();

  const submitForm = () => {
    setOpen(false);
    setAlertData({
      open: true,
      severity: "success",
      msg: "Applied Successfully",
    });
  };

  console.log(userResume, "USRRRRRR_RESUMMEEE=>>>>>");

  const [categoryAdmin, setCategoryAdmin] = useState("");

  useEffect(function effectFunction() {
    firebase
      .database()
      .ref("category")
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setCategoryAdmin(HistoryItems);
      });
  }, []);

  const CatData = Object.keys(categoryAdmin);
  console.log(CatData, "CAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");

  let Dax = Object.keys(jobData);
  console.log(Dax, "DASXESSSSSSSSSSSSSSSSSSSSSSSS");

  const callfunction = (e, data) => {
    console.log(jobData, data, "XXXXXXXXXXXXXXXXXXXXXXXXXX");
    e.preventDefault();
    setJobtype("");
    setCategory("");
    setExperience("");
    setQualification("");
    setState("");
    setCountry("");
    setCity("");
    setJobData([...data]);
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };

  const [searchVal, setSearchVal] = useState("");

  const performSearch = (val) => {
    console.log(JobData2, "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO");
    setSearchVal(val);
    let results = JobData2.filter((item) => {
      return item.title.toLowerCase().includes(val.toLowerCase());
    });
    console.log(
      results,
      jobData,
      "JOOOOOOOOOOOOOOOOOOOOOOOOOOOO_RESSSSSSSSSSSSs"
    );
    setJobData(results);
  };

  const handleChangeState = (event) => {
    setState(event.target.value);
  };

  useEffect(() => {
    let countryVal = country.split(",");
    if (country != "") {
      let res = State.getStatesOfCountry(countryVal[0]);
      console.log(res, "RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
      setAllState(res);
    } else {
      setAllState([]);
      setAllCities([]);
    }
  }, [country]);

  useEffect(() => {
    let selectedState = state.split(",");
    let countryVal = country.split(",");
    if (state != "") {
      let res = City.getCitiesOfState(countryVal[0], selectedState[0]);

      setAllCities(res);
    } else {
      setAllCities([]);
    }
  }, [state]);

  console.log(state, "STAEEEEEEEEEEEEEEEEEEEEes");
  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div class="apply-popup">
          <div
            id="apply-popup-id"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    <i>
                      <Edit2 />
                    </i>
                    APPLY FOR THIS JOB
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span onClick={() => setOpen(false)} aria-hidden="true">
                      &times;
                    </span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <input
                      ref={nameRef}
                      type="text"
                      placeholder="Full Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      ref={emailRef}
                      type="email"
                      placeholder="Email Address"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      ref={messageRef}
                      class="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="form-group file-input-wrap">
                    <label for="up-cv">
                      <input id="up-cv" type="file" />
                      <i data-feather="upload-cloud"></i>
                      <span>
                        Upload your resume <span>(pdf,zip,doc,docx)</span>
                      </span>
                    </label>
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 20,
                      }}
                    >
                      <h4>Or</h4>
                    </div>
                    <button
                      class="button secondary-bg btn-block"
                      style={{
                        backgroundColor: selectedResume ? "#94d1ff" : "#f0eded",
                        color: "black",
                      }}
                      onClick={() => {
                        setSelectedResume(!selectedResume);
                        console.log("selected resume", selectedResume);
                        if (!selectedResume) {
                          firebase
                            .database()
                            .ref(`resumes`)
                            .on("value", (snapshot) => {
                              console.log("snapShot", snapshot);
                              let tempArr = [];
                              snapshot.forEach((resume) => {
                                tempArr.push(resume.key);
                              });
                              console.log("tempArr", tempArr);
                              let isResume = tempArr.find(
                                (id) => id == firebase.auth().currentUser.uid
                              );
                              if (isResume) {
                                firebase
                                  .database()
                                  .ref(`resumes/${isResume}`)
                                  .on("value", (snapshot) => {
                                    console.log(
                                      snapshot.val(),
                                      "VALUESSSSSSSS"
                                    );
                                    setUserResume(snapshot.val());
                                  });
                              } else {
                                setAlertData({
                                  open: true,
                                  severity: "error",
                                  msg: "Please make your resume first in Add resume section!",
                                });
                                setSelectedResume(false);
                              }
                            });
                        }
                      }}
                    >
                      Select created resume
                    </button>
                  </div>
                  <div class="more-option">
                    <input
                      class="custom-radio"
                      type="checkbox"
                      id="radio-4"
                      name="termsandcondition"
                    />
                    <label for="radio-4">
                      <span class="dot"></span> I accept the{" "}
                      <a>terms & conditions</a>
                    </label>
                  </div>
                  <button
                    class="button primary-bg btn-block"
                    onClick={applyJob}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* <div style={{ padding: 30 }}>
        <NotificationAndAccount />
        <Header />
      </div> */}
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Job Listing</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a>Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Job Listing
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input
                    type="text"
                    placeholder="Enter Keywords"
                    value={searchVal}
                    onChange={(e) => performSearch(e.target.value)}
                  />
                  {/* <button>
                    <Search />
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Jo Listing */}
      <div class="alice-bg section-padding-bottom">
        <div class="container">
          <div class="row no-gutters">
            <div class="col">
              <div class="job-listing-container">
                <div
                  class={`${classes.categoryJobStyle} filtered-job-listing-wrapper`}
                >
                  <div class="job-view-controller-wrapper">
                    {/* <div class="job-view-controller"> */}
                    {/* <div class="controller list active">
                        <MenuIcon style={{ fontSize: "26px" }} />
                      </div>
                      <div class="controller grid">
                        <BorderAllIcon style={{ fontSize: "26px" }} />
                      </div> */}
                    {/* <div class="job-view-filter">
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Filter
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={age}
                            onChange={handleChange}
                          > */}
                    {/* <MenuItem value="">
                              <em>None</em>
                            </MenuItem> */}
                    {/* <MenuItem value={10}>Most Recent</MenuItem>
                            <MenuItem value={20}>Top Rated</MenuItem>
                            <MenuItem value={30}>Most Popular</MenuItem>
                          </Select>
                        </FormControl>
                      </div> */}

                    {/* <select class="selectpicker">
                        <option value="" selected>
                          Most Recent
                        </option>
                        <option value="california">Top Rated</option>
                        <option value="las-vegas">Most Popular</option>
                      </select> */}
                    {/* </div> */}
                    {/* <div class="showing-number">
                      <span>Showing 1–12 of 28 Jobs</span>
                    </div> */}
                  </div>
                  <div class="job-filter-result">
                    {Dax.length > 0 ? (
                      Dax.map((data) => {
                        console.log("jobData=>>", jobData);
                        return (
                          <div class="job-list">
                            <div class="thumb">
                              <a>
                                <img
                                  src="images/job/company-logo-8.png"
                                  class="img-fluid"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="body">
                              <div class="content">
                                <h4>
                                  <Link
                                    to={{
                                      pathname: "/JobDetails",
                                      state: { data: jobData[data] },
                                    }}
                                  >
                                    {jobData[data].title}
                                  </Link>
                                </h4>
                                <div class="info">
                                  <span class="company">
                                    <a>
                                      <i>
                                        <Briefcase />
                                      </i>
                                      {jobData[data].location}
                                    </a>
                                  </span>
                                  <span class="office-location">
                                    <a>
                                      <i>
                                        <MapPin />
                                      </i>
                                      {jobData[data].yourlocation}
                                      {`${jobData[data].yourcity} ${jobData[data].yourcountry}`}
                                    </a>
                                  </span>
                                  <span class="job-type temporary">
                                    <a>
                                      <i>
                                        <Clock />
                                      </i>
                                      {jobData[data].type}
                                    </a>
                                  </span>
                                </div>
                              </div>
                              <div class="more">
                                <div class="buttons">
                                  <button
                                    onClick={() => {
                                      isUser.isAuthenticated
                                        ? openDialogBox(jobData)
                                        : setAlertData({
                                            open: true,
                                            severity: "error",
                                            msg: "Please Login App first",
                                          });
                                      //  setTimeout(()=>{
                                      //    history.push('/login')
                                      //  }, 2000)
                                    }}
                                    // href="#"
                                    class="button"
                                    data-toggle="modal"
                                    data-target="#apply-popup-id"
                                  >
                                    Apply Now
                                  </button>

                                  {renderButton(
                                    jobData[data].jobId,
                                    jobData[data]
                                  )}
                                </div>
                                <p class="deadline">
                                  Deadline: {jobData[data].date}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "30px",
                        }}
                      >
                        No Jobs Found{" "}
                      </div>
                    )}
                  </div>
                  {/* <div class="pagination-list text-center">
                    <nav class="navigation pagination">
                      <div class="nav-links">
                        <a class="prev page-numbers" href="#">
                          <i class="fas fa-angle-left"></i>
                        </a>
                        <a class="page-numbers" href="#">
                          1
                        </a>
                        <span aria-current="page" class="page-numbers current">
                          2
                        </span>
                        <a class="page-numbers" href="#">
                          3
                        </a>
                        <a class="page-numbers" href="#">
                          4
                        </a>
                        <a class="next page-numbers" href="#">
                          <i class="fas fa-angle-right"></i>
                        </a>
                      </div>
                    </nav>
                  </div> */}
                </div>
                <div class="job-filter-wrapper">
                  <button
                    onClick={(e) => callfunction(e, JobData2)}
                    class="button"
                    style={{ marginTop: 5, marginLeft: 10 }}
                  >
                    Remove Filters
                  </button>

                  <div class="job-filter-dropdown same-pad category">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={country}
                        onChange={handleChangeCountry}
                      >
                        {allCountries.length > 0 &&
                          allCountries.map((item) => {
                            return (
                              <MenuItem
                                value={`${item.alpha2Code},${item.name}`}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <div class="job-filter-dropdown same-pad location">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state}
                        onChange={handleChangeState}
                      >
                        {allStates.map((res) => {
                          return (
                            <MenuItem value={`${res.isoCode},${res.name}`}>
                              {`${res.name},${res.isoCode}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>

                  <div class="job-filter-dropdown same-pad location">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={city}
                        onChange={handleChangeCity}
                      >
                        {allcities.map((res) => {
                          return (
                            <MenuItem value={res.name}>{res.name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>

                  <div data-id="job-type" class="job-filter job-type same-pad">
                    <Accordion
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                      // onClick={()=>alert("claed")}
                    >
                      <AccordionSummary
                        expandIcon={
                          !changeIcon ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        // onChange={handleChangeIcon}
                        onClick={() => setChangeIcon(!changeIcon)}
                      >
                        <Typography variant="h4" className={classes.heading}>
                          Job Type
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <ul style={{ padding: 0 }}>
                          <li class="full-time">
                            <i>
                              <Clock />
                            </i>
                            <a
                              style={{
                                color: jobType == "Full Time" ? "blue" : "",
                              }}
                              onClick={() => setJobtype("Full Time")}
                              data-attr="Full Time"
                            >
                              Full Time
                            </a>
                          </li>
                          <li class="part-time">
                            <i>
                              <Clock />
                            </i>
                            <a
                              style={{
                                color: jobType == "Part Time" ? "blue" : "",
                              }}
                              onClick={() => setJobtype("Part Time")}
                              data-attr="Part Time"
                            >
                              Part Time
                            </a>
                          </li>
                          <li class="freelance">
                            <i>
                              <Clock />
                            </i>
                            <a
                              style={{
                                color: jobType == "Freelance" ? "blue" : "",
                              }}
                              onClick={() => setJobtype("Freelance")}
                              data-attr="Freelance"
                            >
                              Freelance
                            </a>
                          </li>
                          <li class="temporary">
                            <i>
                              <Clock />
                            </i>
                            <a
                              style={{
                                color: jobType == "Temporary" ? "blue" : "",
                              }}
                              onClick={() => setJobtype("Temporary")}
                              data-attr="Temporary"
                            >
                              Temporary
                            </a>
                          </li>
                          <li class="permanent">
                            <i>
                              <Clock />
                            </i>
                            <a
                              style={{
                                color: jobType == "Permanent" ? "blue" : "",
                              }}
                              onClick={() => setJobtype("Permanent")}
                              data-attr="Permanent"
                            >
                              Permenant
                            </a>
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                    {/* <h4 class="option-title">Job Type</h4> */}
                  </div>
                  <div
                    data-id="experience"
                    class="job-filter experience same-pad"
                  >
                    <Accordion
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                    >
                      <AccordionSummary
                        expandIcon={
                          !changeIconExp ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() => setChangeIconExp(!changeIconExp)}
                      >
                        <Typography variant="h4" className={classes.heading}>
                          Experience
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <ul style={{ padding: 0 }}>
                          <li onClick={(e) => setExperience("Fresh")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color: experience == "Fresh" ? "blue" : "",
                              }}
                              data-attr="Fresh"
                            >
                              Fresh
                            </a>
                          </li>
                          <li
                            value="Less than 1 year"
                            onClick={(e) => setExperience("Less than 1 year")}
                          >
                            <a
                              style={{
                                cursor: "pointer",
                                color:
                                  experience == "Less than 1 year"
                                    ? "blue"
                                    : "",
                              }}
                              data-attr="Less than 1 year"
                            >
                              Less than 1 year
                            </a>
                          </li>
                          <li onClick={(e) => setExperience("2 Year")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color: experience == "2 Year" ? "blue" : "",
                              }}
                              data-attr="2 Year"
                            >
                              2 Year
                            </a>
                          </li>
                          <li onClick={(e) => setExperience("3 Year")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color: experience == "3 Year" ? "blue" : "",
                              }}
                              data-attr="3 Year"
                            >
                              3 Year
                            </a>
                          </li>
                          <li onClick={(e) => setExperience("4 Year")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color: experience == "4 Year" ? "blue" : "",
                              }}
                              data-attr="4 Year"
                            >
                              4 Year
                            </a>
                          </li>
                          <li onClick={(e) => setExperience("5 Year")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color: experience == "5 Year" ? "blue" : "",
                              }}
                              data-attr="5 Year"
                            >
                              5 Year
                            </a>
                          </li>
                          <li onClick={(e) => setExperience("Above 5 Years")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color:
                                  experience == "Above 5 Years" ? "blue" : "",
                              }}
                              data-attr="Avobe 5 Years"
                            >
                              Above 5 Years
                            </a>
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* <div class="job-filter same-pad">
                    <Accordion
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                    >
                      <AccordionSummary
                        expandIcon={
                          !changeIconRange ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() => setChangeIconRange(!changeIconRange)}
                      >
                        <Typography variant="h4" className={classes.heading}>
                          Salary Range
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <div class="price-range-slider">
                          <div
                            class="nstSlider"
                            data-range_min="0"
                            data-range_max="10000"
                            data-cur_min="0"
                            data-cur_max="6130"
                          >
                            <div class="bar"></div>
                            <div class="leftGrip"></div>
                            <div class="rightGrip"></div>
                            <div class="grip-label">
                              <span class="leftLabel"></span>
                              <span class="rightLabel"></span>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div> */}
                  {/* <div data-id="post" class="job-filter post same-pad">
                    <Accordion
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                    >
                      <AccordionSummary
                        expandIcon={
                          !changeIconPosted ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() => setChangeIconPosted(!changeIconPosted)}
                      >
                        <Typography variant="h4" className={classes.heading}>
                          Date Posted
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <ul style={{ padding: 0 }}>
                          <li>
                            <a data-attr="Last hour">Last hour</a>
                          </li>
                          <li>
                            <a data-attr="Last 24 hour">Last 24 hour</a>
                          </li>
                          <li>
                            <a data-attr="Last 7 days">Last 7 days</a>
                          </li>
                          <li>
                            <a data-attr="Last 14 days">Last 14 days</a>
                          </li>
                          <li>
                            <a data-attr="Last 30 days">Last 30 days</a>
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div> */}
                  {/* <div data-id="gender" class="job-filter same-pad gender">
                    <Accordion
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                    >
                      <AccordionSummary
                        expandIcon={
                          !changeIconGender ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() => setChangeIconGender(!changeIconGender)}
                      >
                        <Typography variant="h4" className={classes.heading}>
                          Gender
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <ul style={{ padding: 0 }}>
                          <li>
                            <a data-attr="Male">Male</a>
                          </li>
                          <li>
                            <a data-attr="Female">Female</a>
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div> */}
                  <div
                    data-id="qualification"
                    class="job-filter qualification same-pad"
                  >
                    <Accordion
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #ffffff, #f7f9fc)",
                        boxShadow: "none",
                      }}
                      className={classes.dropdownHead}
                    >
                      <AccordionSummary
                        expandIcon={
                          !changeIconQualification ? (
                            <AddIcon style={{ fontSize: "20px" }} />
                          ) : (
                            <RemoveIcon style={{ fontSize: "20px" }} />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: 0 }}
                        onClick={() =>
                          setChangeIconQualification(!changeIconQualification)
                        }
                      >
                        <Typography variant="h4" className={classes.heading}>
                          Qualification
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <ul style={{ padding: 0 }}>
                          <li
                            onClick={(e) =>
                              setQualification("Elementary School")
                            }
                          >
                            <a
                              style={{
                                cursor: "pointer",
                                color:
                                  qualification == "Elementary School"
                                    ? "blue"
                                    : "",
                              }}
                            >
                              Elementary School
                            </a>
                          </li>
                          <li
                            onClick={(e) => setQualification("Middle School")}
                          >
                            <a
                              style={{
                                cursor: "pointer",
                                color:
                                  qualification == "Middle School"
                                    ? "blue"
                                    : "",
                              }}
                            >
                              Middle School
                            </a>
                          </li>
                          <li onClick={(e) => setQualification("Diploma")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color: qualification == "Diploma" ? "blue" : "",
                              }}
                            >
                              Diploma
                            </a>
                          </li>
                          <li onClick={(e) => setQualification("High School")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color:
                                  qualification == "High School" ? "blue" : "",
                              }}
                            >
                              High School
                            </a>
                          </li>
                          <li
                            onClick={(e) => setQualification("Undergraduate")}
                          >
                            <a
                              style={{
                                cursor: "pointer",
                                color:
                                  qualification == "Undergraduate"
                                    ? "blue"
                                    : "",
                              }}
                            >
                              Undergraduate
                            </a>
                          </li>
                          <li onClick={(e) => setQualification("Graduate")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color:
                                  qualification == "Graduate" ? "blue" : "",
                              }}
                            >
                              Graduate
                            </a>
                          </li>
                          <li onClick={(e) => setQualification("Master")}>
                            <a
                              style={{
                                cursor: "pointer",
                                color: qualification == "Master" ? "blue" : "",
                              }}
                            >
                              Master
                            </a>
                          </li>
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />

      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CategoryJobListing;
