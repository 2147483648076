import React, { useState, useEffect, createRef } from "react";
import { Send, Image, Paperclip, ThumbsUp } from "react-feather";
import firebase from "firebase";
import {
  senderMsg,
  ReceivedMsg,
  senderImageMsg,
  ReceivedImageMsg,
} from "../../database/messages";
import ChatBox from "../ChatBox";
import CustomDialog from "../../components/CustomDialog";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import axios from "axios";

const MessageBox = ({ activeUser, candidate }) => {
  console.log(activeUser, candidate, "candidatecandidatecandidatecandidate");
  const [messages, setMessages] = useState([]);
  const [msgValue, setMsgValue] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const [alertData, setAlertData] = useState({
    msg: "Applied Successfully",
    open: true,
    severity: "success",
  });

  useEffect(() => {
    try {
      firebase
        .database()
        .ref("messages")
        .child(activeUser)
        .child(candidate.id)
        .on("value", (datasnapshot) => {
          let msg = [];
          datasnapshot.forEach((child) => {
            console.log(child, "CHILDDDDDDDDDD");
            msg.push({
              sendBy: child.val().message.sender,
              ReceivedBy: child.val().message.receiver,
              msg: child.val().message.msg,
              time: child.val().message.time,
              type: child.val().message.type,
            });
          });
          setMessages(msg);
        });
    } catch (error) {
      // alert("sdadasdsd")
    }
  }, [candidate]);

  // const handleChange = (text) => {
  //     setMsgValue(text)
  // }

  const handleSend = (event) => {
    // event.preventDefault()
    // alert("calledddd")
    if (msgValue) {
      senderMsg(msgValue, activeUser, candidate.id)
        .then(() => {
          setMsgValue("");
        })
        .catch((err) => {
          setMsgValue("");

          alert(err);
        });
      ReceivedMsg(msgValue, activeUser, candidate.id)
        .then(() => {
          setMsgValue("");
        })
        .catch((err) => {
          setMsgValue("");

          alert(err);
        });
    }
  };

  const renderDialogContent = () => {
    const inputRef = React.createRef();
    const inputImageRef = React.createRef();

    const handleOpenfileselect = (ref) => {
      ref.current.click();
    };
    const handleImageChange = (e) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("upload_preset", "pn7uz8ak");
      axios
        .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", formData)
        .then((res) => {
          setImage(res.data.secure_url);
          setLoader(false);
        })
        .catch((err) => {
          // alert("something went wrong")
          setLoader(false);
          setAlertData({
            isOPen: true,
            snackbarMessage: "Failed to Upload File ",
            severity: "error",
          });
        });
    };

    const onChange = (e) => {
      // setInputs({
      //     ...inputs,
      //     [e.target.name]: e.target.value
      // })
    };

    console.log(image, "IMAGEEEEEEEEEEEEEEEEEEEEEe");

    return (
      <div class="row">
        <input
          type="file"
          id="file"
          ref={inputImageRef}
          style={{ display: "none" }}
          onChange={(e) => {
            handleImageChange(e);
          }}
        />
        {image && <img style={{ height: "200px" }} src={image} />}
        {loader ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={() => handleOpenfileselect(inputImageRef)}
            style={{ height: "41px", marginTop: "17px" }}
            variant="contained"
            fullWidth
            color="secondary"
          >
            Upload Image
          </Button>
        )}
      </div>
    );
  };

  const onSubmit = () => {
    senderImageMsg(image, activeUser, candidate.id)
      .then(() => {
        setImage(null);
      })
      .catch((err) => {
        setMsgValue("");

        alert(err);
      });
    ReceivedImageMsg(image, activeUser, candidate.id)
      .then(() => {
        setImage(null);
      })
      .catch((err) => {
        setMsgValue("");

        alert(err);
      });
    setShowModal(false);
  };

  const onClose = () => {
    setImage(null);
    setShowModal(false);
  };

  const handleModal = () => {
    setShowModal(true);
  };

  console.log(messages, "MESSAGESSSS");
  return (
    <>
      <CustomDialog
        title="Upload Image"
        renderDialogBody={() => renderDialogContent()}
        onSubmit={onSubmit}
        isOPen={showModal}
        onClose={onClose}
      />
      <div class="message-box" style={{ width: "470px" }}>
        <div class="message-box-header">
          <a href="#">
            <i class="fas fa-ellipsis-h"></i>
          </a>
          <h5>{candidate.name}</h5>
        </div>
        <ul class="dashboard-conversation">
          {messages.map((data, index) => {
            console.log(data, "MESSAGES_ARE_HEHE");
            return (
              <ChatBox msg={data.msg} userId={data.sendBy} type={data.type} />
            );
          })}
        </ul>
        <div
          class="conversation-write-wrap"
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <form action="#"> */}
          <label class="send-file">
            <input type="file" />
            <i>
              {" "}
              <Paperclip />{" "}
            </i>
          </label>
          <label class="send-image" style={{ margin: "0 10px" }}>
            {/* <input type="file" /> */}
            <i onClick={handleModal}>
              {" "}
              <Image />
            </i>
          </label>
          <textarea
            placeholder="Type a message"
            value={msgValue}
            onChange={(e) => setMsgValue(e.target.value)}
            style={{
              border: "none",
              resize: "none",
              outline: "none",
              width: "250px",
              marginTop: "20px",
            }}
          ></textarea>
          <a href="#" class="emoji" style={{ margin: "0 10px" }}>
            {/* <i data-feather="thumbs-up"></i> */}
            <ThumbsUp style={{ color: "rgb(170, 170, 170)" }} />
          </a>
          <button
            onClick={() => handleSend()}
            style={{
              height: "60px",
              width: "60px",
              color: "rgb(255, 255, 255)",
              cursor: "pointer",
              borderWidth: "0px",
              borderRadius: "50%",
              background: "rgb(2, 59, 146)",
              outline: "none",
            }}
          >
            <i>
              <Send />
            </i>
          </button>
          {/* </form> */}
        </div>
      </div>
    </>
  );
};

export default MessageBox;
