import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const EditorTable = (props) => {
  const handleEditorChange = (content, editor) => {
    props.getAboutContent(content, props.type)
  };

  return (
    <Editor
      initialValue={props.initialValue ? props.initialValue : "<p>This is the initial content of the editor</p>"} 
      init={{
        height: 300,
        width: "100%",

        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | table",
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default EditorTable;
