import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import firebase from "firebase"
import { useHistory } from "react-router-dom"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  btns: {
    background: "#023b92 !important",
    "&:hover": {
      background: "#fff !important",
    },
  },
}));

const FindYourJob = () => {

  const history = useHistory()

  const [userData, setUserData] = useState("")

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    setUserData(userData)
  }, [])
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const classes = useStyles();

  console.log(userData, "UUUUUUUUUUUUUUUUUUUUU");

  const navigate = (key) => {
    if (userData != null) {
      history.push(`/${key}`)
    }
    else {
      setAlertData({
        open: true,
        severity: "error",
        msg: "Please Login First"
      })
    }
  }

  const handleClose = () => {
    setAlertData({
        open: false,
        severity: "",
        msg: "",
    });
}

  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <div class="call-to-action-bg padding-top-90 padding-bottom-90">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="call-to-action-2">
                <div class="call-to-action-content">
                  <h2>For Find Your Dream Job or Candidate</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                </div>
                <div class="call-to-action-button">
                  <a
                    onClick={() => navigate("AddResume")}
                    // href="add-resume.html"
                    className={`${classes.btns} button`}
                  >
                    Add Resume
                </a>
                  <span>Or</span>
                  <a
                    onClick={() => navigate("PostJob")}
                    // href="post-job.html"
                    className={`${classes.btns} button`}
                  >
                    Post A Job
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindYourJob;
