import React from "react";
// import Header from "../../components/Header";
import { Link } from "react-router-dom";
import NotificationAndAccount from "./../../components/NotificationAndAccount/index";
import Header from "./../../components/Header/index";
import Dashboard from "../../components/Dashboard/Dashboard";
import appLogo from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import "../../assests/assets/css/fontawesome-all.min.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Footer, NewsLetterFooter, SocialShare } from "../../components";
import FindYourJob from "../../components/FindYourJob";
import CandidateDashboardSidebar from "./../../components/CandidateDashboardSidebar/CandidateDashboardSidebar";
import LogoutAndDelete from "../../components/logoutAndDelete";

const CandidateDashboard = () => {
  return (
    <>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <Dashboard
        name="Candidates Dashboard"
        DashboardSidebar={
          <CandidateDashboardSidebar dashboardLink="dashboard" />
        }
        LogoutAndDelete={
          <LogoutAndDelete />
        }
      />

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CandidateDashboard;
