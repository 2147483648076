import React from "react";
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "react-feather";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
const SocialShare = () => {
  return (
    <div className="footer-top border-bottom section-padding-top padding-bottom-40">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="footer-logo">
              <a href="#">
                <img className="mainlogo img-fluid" src={logoMain} />
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="footer-social">
              <ul className="social-icons">
                <li>
                  <a href="#">
                    {" "}
                    <Facebook size="24" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <Twitter size="24" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <Linkedin size="24" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <Instagram size="24" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <Youtube size="24" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
