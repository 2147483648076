import React, { useState, useEffect } from "react";
import NotificationAndAccount from "./../../components/NotificationAndAccount/index";
import { Header, Footer, SocialShare } from "./../../components";
import NewsLetterFooter from "./../../components/NewsLetterFooter/index";
import FindYourJob from "../../components/FindYourJob";
import { makeStyles } from "@material-ui/core";
import thumb1 from "../../assests/images/portfolio/thumb-1.jpg";
import EditorTable from "./../../components/Editor/Editor";
import axios from "axios";
import firebase from "firebase";
import fire from "../../database";
import { PDF } from "../../components/PDF";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { app } from "../../database/index";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { event } from "jquery";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  uploadBox: {
    height: "34px",
    "&:hover": {
      background: "#246df8e6",
    },
  },
  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const data = [
  {
    name: "Facebook",
  },
  {
    name: "Twitter",
  },
  {
    name: "Linkedin",
  },
  {
    name: "Instagram",
  },
];

const AddResume = () => {
  const classes = useStyles();
  const [picture, setPicture] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [userCv, setUserCv] = useState({
    fullname: "",
    category: "",
    location: "",
    type: "",
    exp: "",
    salaryRange: "",
    qualification: "",
    gender: "",
    skills: "",
    aboutYou: "",
    personalName: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: "",
    nationality: "",
    sex: "",
    address: "",
    specialQualification: "",
    coverLetter: "",
  });
  const [terms, setTerms] = useState(false);
  const [educationList, seteducationList] = useState([
    { Designation: "", Institute: "", Period: "", Description: "" },
  ]);
  const [workExpList, setworkExpList] = useState([
    { Designation: "", companyName: "", Period: "", Description: "" },
  ]);
  const [skillsList, setskillsList] = useState([{ title: "", percentage: "" }]);
  const [portfolioList, setPortfoliolist] = useState([
    { title: "", image: "", link: "" },
  ]);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  const [socialLinks, setSocialLinks] = useState({
    Facebook: "",
    Twitter: "",
    Linkedin: "",
    Instagram: "",
  });

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    console.log("EEE_AND_INDEX=>>>", name, value, index);
    const list = [...educationList];
    list[index][name] = value;
    seteducationList(list);
  };

  const handleWorkExperienceChange = (e, index) => {
    const { name, value } = e.target;
    console.log("EEE_AND_INDEX=>>>", name, value, index);
    const list = [...workExpList];
    list[index][name] = value;
    setworkExpList(list);
  };

  const handleProfessionalSkillChange = (e, index) => {
    const { name, value } = e.target;
    console.log("EEE_AND_INDEX=>>>", name, value, index);
    const list = [...skillsList];
    list[index][name] = value;
    setskillsList(list);
  };

  const handlePortfolioChange = (e, index) => {
    const { name, value } = e.target;
    console.log("EEE_AND_INDEX=>>>", name, value, index);
    const list = [...portfolioList];
    list[index][name] = value;
    setPortfoliolist(list);
  };

  const handlePortfolioImageChange = (data, index) => {
    const list = [...portfolioList];
    list[index]["image"] = data.url;
    setPortfoliolist(list);
  };

  const handleAddExperience = (event) => {
    event.preventDefault();
    seteducationList([
      ...educationList,
      { Institute: "", Period: "", Description: "" },
    ]);
  };
  const handleAddWorkExperience = (event) => {
    event.preventDefault();
    setworkExpList([
      ...workExpList,
      { Designation: "", companyName: "", Period: "", Description: "" },
    ]);
  };
  const handleAddSkills = (event) => {
    event.preventDefault();
    setskillsList([...skillsList, { title: "", percentage: "" }]);
  };

  const handleAddPortfolio = (event) => {
    event.preventDefault();
    setPortfoliolist([...portfolioList, { title: "", image: "", link: "" }]);
  };

  const handleRemoveWorkExperience = (event) => {
    event.preventDefault();
    seteducationList([
      ...educationList,
      { Designation: "", Institute: "", Period: "", Description: "" },
    ]);
  };

  const handleEducationRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...educationList];
    list.splice(index, 1);
    seteducationList(list);
  };

  const handlePortfolioRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...portfolioList];
    list.splice(index, 1);
    setPortfoliolist(list);
  };

  const handleSkillRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...skillsList];
    list.splice(index, 1);
    setskillsList(list);
  };

  const handleExperienceRemove = (event, index) => {
    event.preventDefault();
    console.log("remove");
    const list = [...workExpList];
    list.splice(index, 1);
    setworkExpList(list);
  };

  const onChangePicture = async (e, index) => {
    const form = new FormData();
    form.append("file", e.target.files[0]);
    form.append("upload_preset", "pn7uz8ak");
    try {
      let res = await axios.post(
        "https://api.cloudinary.com/v1_1/dnaleybjd/upload",
        form
      );
      if (res) {
        handlePortfolioImageChange(res.data, index);
      }
    } catch (error) {
      console.log(error, "ERRRRR=>>>>>");
    }
    // axios
    //   .post("https://api.cloudinary.com/v1_1/dnaleybjd/upload", form)
    //   .then((res) => {
    //     setImage(res.data.url);
    //   })
    //   .catch((err) => {
    //     console.log("rrrrrrr=>>>>", err);
    //   });
  };

  // fullname: "",
  // category: "",
  // location: "",
  // type: "",
  // exp: "",
  // salaryRange: "",
  // qualification: "",
  // gender: "",
  // date: "",
  // skills: "",
  // aboutYou: "",
  // personalName: "",
  // fatherName: "",
  // motherName: "",
  // dateOfBirth: "",
  // nationality: "",
  // sex: "",
  // address: "",
  // specialQualification: "",
  // coverLetter: ""
  const pdfGenerator = () => {
    var doc = new jsPDF("p", "pt");

    // var imgWidth = 297;
    // var pageHeight = 210;
    // var imgHeight = canvas.height * imgWidth / canvas.width;

    doc.setFontSize(30);
    doc.text(userCv.fullname, 80, 10);
    doc.setFontSize(10);
    doc.text(`Category: ${userCv.category}`, 10, 20);
    doc.text(`Gender: ${userCv.gender}`, 10, 30);
    doc.text(`Location: ${userCv.location}`, 10, 40);
    doc.text(`Type: ${userCv.type}`, 10, 50);
    doc.text(`Experience: ${userCv.exp}`, 10, 60);
    doc.text(`Salary Range: ${userCv.salaryRange}`, 10, 70);
    doc.text(`Qualification: ${userCv.qualification}`, 10, 80);
    doc.text(`Special Qualification: ${userCv.specialQualification}`, 10, 90);
    doc.text(`Skills: ${userCv.skills}`, 10, 100);
    doc.text(`About: ${userCv.aboutYou}`, 10, 110);
    doc.text(`Nationality: ${userCv.nationality}`, 10, 120);
    doc.text(`Address: ${userCv.address}`, 10, 130);
    doc.text(`Cover Letter: ${userCv.coverLetter}`, 10, 140);
    doc.text(`DOB: ${userCv.dateOfBirth}`, 10, 150);
    console.log("doc", doc.output());
    doc.text("Educations", 10, 160);
    let y = 170;
    educationList.map((data, index) => {
      if (data.Designation) {
        console.log("daata", data);
        doc.text(`Education ${index + 1}`, 10, y);
        doc.text(`Designation ${data.Designation}`, 10, y + 10);
        doc.text(`Institute ${data.Institute}`, 10, y + 20);
        doc.text(`Period ${data.Period}`, 10, y + 30);
        doc.text(`Description ${data.Institute}`, 10, y + 40);
        y = y + 50;
      }
    });
    workExpList.map((data, index) => {
      if (data.Designation) {
        console.log("daata", data);
        doc.text(`Work Experience ${index + 1}`, 10, y);
        doc.text(`Designation ${data.Designation}`, 10, y + 10);
        doc.text(`Company Name ${data.companyName}`, 10, y + 20);
        doc.text(`Period ${data.Period}`, 10, y + 30);
        doc.text(`Description ${data.Institute}`, 10, y + 40);
        y = y + 50;
      }
    });

    // var pdf = doc.output(); //returns raw body of resulting PDF returned as a string as per the plugin documentation.
    // var data = new FormData();
    // data.append("data", pdf);
    // const storageRef = app.storage().ref()
    // const fileRef = storageRef.child(pdf)

    // fileRef.put(data).then(res => {
    //   storageRef.child("testing").getDownloadURL().then(function (url) {
    //     console.log(url, "RRSADASDSDSDA");
    //   }).catch(err => {
    //     console.log(err, "ERRRRRRRRRRRRRRRR=>>>>>>>>>>>>>>>>");
    //   })
    // })

    // let pdf = doc.output()
    // console.log(pdf.name, "DPDDDDDDDDDDDd");
    // const storageRef = app.storage().ref()
    // const fileRef = storageRef.child("testing")
    // var blob = doc.output('blob');
    // var formData = new FormData();
    // formData.append('pdf', blob);

    // fileRef.put(formData).then(res => {
    //   storageRef.child("testing").getDownloadURL().then(function (url) {
    //     console.log(url, "RRSADASDSDSDA");
    //   }).catch(err => {
    //     console.log(err, "ERRRRRRRRRRRRRRRR=>>>>>>>>>>>>>>>>");
    //   })
    // })

    doc.save("cv.pdf");
  };

  const onChange = (e) => {
    console.log(e.target.name, e.target.value, "TINGGGGGGGGGGGGGGG");
    setUserCv({
      ...userCv,
      [e.target.name]: e.target.value,
    });
  };

  const getAboutContent = (content, type) => {
    // if (type == "aboutYou") {
    setUserCv({
      ...userCv,
      [type]: content,
    });
    // }
    console.log("content=>>>>>>>>>>>", content);
  };

  const submitResume = (event) => {
    event.preventDefault();
    // pdfGenerator();
    const {
      fullname,
      category,
      location,
      type,
      exp,
      salaryRange,
      qualification,
      gender,
      date,
      skills,
      aboutYou,
      personalName,
      fatherName,
      motherName,
      dateOfBirth,
      nationality,
      sex,
      address,
      specialQualification,
      coverLetter,
      cvURL,
    } = userCv;
    console.log(userCv, "USRRRRRRRRRRRRRRRR_CVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV");
    if (
      fullname &&
      category &&
      location &&
      type &&
      exp &&
      salaryRange &&
      qualification &&
      gender &&
      skills &&
      aboutYou &&
      personalName &&
      fatherName &&
      motherName &&
      dateOfBirth &&
      nationality &&
      sex &&
      address &&
      specialQualification &&
      coverLetter
    ) {
      firebase
        .database()
        .ref(`resumes/${firebase?.auth().currentUser?.uid}`)
        .set({
          ...userCv,
          portfolioList,
          workExpList,
          educationList,
          skillsList,
          socialLinks,
        })
        .then((res) => {
          setAlertData({
            open: true,
            msg: "Resume Created Successfully",
            severity: "success",
          });
          setUserCv({
            fullname: "",
            category: "",
            location: "",
            type: "",
            exp: "",
            salaryRange: "",
            qualification: "",
            gender: "",
            skills: "",
            aboutYou: "",
            personalName: "",
            fatherName: "",
            motherName: "",
            dateOfBirth: "",
            nationality: "",
            sex: "",
            address: "",
            specialQualification: "",
            coverLetter: "",
            cvURL: "",
          });
          firebase
            .database()
            .ref(`notifications/${firebase.auth().currentUser.uid}`)
            .push({
              title: "Resume Added Successfully",
            });

          seteducationList([
            { Designation: "", Institute: "", Period: "", Description: "" },
          ]);
          setworkExpList([
            { Designation: "", companyName: "", Period: "", Description: "" },
          ]);
          setSocialLinks({
            Facebook: "",
            Twitter: "",
            Linkedin: "",
            Instagram: "",
          });
          setTerms(false);
          setskillsList([{ title: "", percentage: "" }]);
          setPortfoliolist([{ title: "", image: "", link: "" }]);
          setPicture(null);
        })
        .catch((err) => {
          setAlertData({
            open: true,
            msg: "Error Occured",
            severity: "error",
          });
        });
    } else {
      setAlertData({
        open: true,
        msg: "Please fill every detail",
        severity: "error",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  const changeInSocialLink = (event) => {
    console.log(event.target.name, event.target.value, "NAEM AND VALUEEEEE");
    setSocialLinks({
      ...socialLinks,
      [event.target.name]: event.target.value,
    });
  };

  // console.log("eductionLIST=>>>", educationList);
  console.log("workexpList=>>>", socialLinks);
  console.log("PIctureeeeee=>>>", picture);
  console.log("userCv=>>>", userCv);

  const [categoryAdmin, setCategoryAdmin] = useState("");

  useEffect(() => {
    firebase
      .database()
      .ref(`category`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        setCategoryAdmin(data);
      });
  }, []);

  const Data = Object.keys(categoryAdmin);

  return (
    <>
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>
      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              <NotificationAndAccount />
              <Header />
            </div>
          </div>
        </div>
      </header>

      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Add Resume</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Add Resume
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i data-feather="search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alice-bg section-padding-bottom">
        <div class="container no-gliters">
          <div class="row no-gliters">
            <div class="col">
              <div class="post-container">
                <div class="post-content-wrapper" id="Contenttt">
                  <form action="#" class="job-post-form">
                    <div class="basic-info-input">
                      <h4>
                        <i data-feather="plus-circle"></i>Add Resume
                      </h4>
                      <div id="full-name" class="form-group row">
                        <label class="col-md-3 col-form-label">Full Name</label>
                        <div class="col-md-9">
                          <input
                            value={userCv.fullname}
                            onChange={onChange}
                            name="fullname"
                            type="text"
                            class="form-control"
                            placeholder="Your Name"
                          />
                        </div>
                      </div>
                      <div id="information" class="row">
                        <label class="col-md-3 col-form-label">
                          Information
                        </label>
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  value={userCv.category}
                                  onChange={onChange}
                                  name="category"
                                >
                                  <option>Select Category</option>
                                  {Data.map((data, index) => {
                                    return (
                                      <option
                                        value={categoryAdmin[data].category}
                                      >
                                        {categoryAdmin[data].category}
                                      </option>
                                    );
                                  })}
                                </select>
                                <i class="fa fa-caret-down"></i>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  value={userCv.location}
                                  onChange={onChange}
                                  name="location"
                                  type="text"
                                  class="form-control"
                                  placeholder="Your Location"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  value={userCv.type}
                                  onChange={onChange}
                                  name="type"
                                >
                                  <option>Job Type</option>
                                  <option>Part Time</option>
                                  <option>Full Time</option>
                                  <option>Temperory</option>
                                  <option>Permanent</option>
                                  <option>Freelance</option>
                                </select>
                                <i class="fa fa-caret-down"></i>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  value={userCv.exp}
                                  onChange={onChange}
                                  name="exp"
                                >
                                  <option>Experience (Optional)</option>
                                  <option>Less than 1 Year</option>
                                  <option>2 Year</option>
                                  <option>3 Year</option>
                                  <option>4 Year</option>
                                  <option>Over 5 Year</option>
                                </select>
                                <i class="fa fa-caret-down"></i>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  value={userCv.salaryRange}
                                  onChange={onChange}
                                  name="salaryRange"
                                  type="text"
                                  class="form-control"
                                  placeholder="Salary Range"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  value={userCv.qualification}
                                  onChange={onChange}
                                  name="qualification"
                                >
                                  <option>Select Qualification</option>
                                  <option>Elementary School</option>
                                  <option>Middle School</option>
                                  <option>Diploma</option>
                                  <option>High School</option>
                                  <option>Undergraduate</option>
                                  <option>Graduate</option>
                                  <option>Master</option>
                                </select>
                                <i class="fa fa-caret-down"></i>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  value={userCv.gender}
                                  onChange={onChange}
                                  name="gender"
                                >
                                  <option>Gender</option>
                                  <option>Male</option>
                                  <option>Female</option>
                                </select>
                                <i class="fa fa-caret-down"></i>
                              </div>
                            </div>
                            {/* <div class="col-md-6">
                              <div class="form-group">
                                <input value={userCv.date}
                                  onChange={onChange}
                                  name="date"
                                  type="date" class="form-control" />
                              </div>
                            </div> */}
                            <div class="col-md-12">
                              <div class="form-group datepicker">
                                <input
                                  value={userCv.skills}
                                  onChange={onChange}
                                  name="skills"
                                  type="text"
                                  class="form-control"
                                  placeholder="Your Skill"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="about" class="row">
                        <label class="col-md-3 col-form-label">About You</label>
                        <div class="col-md-9">
                          {/* <textarea
                            class="tinymce-editor-1"
                            placeholder="Description text here"
                          ></textarea> */}
                          <EditorTable
                            getAboutContent={getAboutContent}
                            type="aboutYou"
                          />
                        </div>
                      </div>
                      {educationList.map((list, index) => {
                        return (
                          <>
                            {/* <div id="education" class="row">
                              <label class="col-md-3 col-form-label">
                                {index == 0 ? "Education" : ""}
                              </label>
                              <div class="col-md-9">
                                <div class="form-group">
                                  <input
                                    value={list.Designation}
                                    name="Designation"
                                    onChange={(e) => handleExperienceChange(e, index)}
                                    type="text"
                                    class="form-control"
                                    placeholder="Designation"
                                  />
                                </div>
                              </div>
                            </div> */}
                            <div class="row">
                              <label class="col-md-3 col-form-label">
                                EDUCATION {index + 1}
                              </label>
                              <div class="col-md-9">
                                <>
                                  <div class="form-group">
                                    <input
                                      value={list.Institute}
                                      onChange={(e) =>
                                        handleExperienceChange(e, index)
                                      }
                                      type="text"
                                      class="form-control"
                                      name="Institute"
                                      placeholder="Institute"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <input
                                      value={list.Period}
                                      type="text"
                                      class="form-control"
                                      name="Period"
                                      placeholder="Period"
                                      onChange={(e) =>
                                        handleExperienceChange(e, index)
                                      }
                                    />
                                  </div>
                                  <div class="form-group">
                                    <textarea
                                      value={list.Description}
                                      class="form-control"
                                      name="Description"
                                      placeholder="Description (Optional)"
                                      onChange={(e) =>
                                        handleExperienceChange(e, index)
                                      }
                                    ></textarea>
                                  </div>
                                </>
                                {educationList.length - 1 === index &&
                                  educationList.length > 1 && (
                                    <button
                                      onClick={(e) =>
                                        handleEducationRemove(e, index)
                                      }
                                      class="add-new-field"
                                      style={{
                                        marginRight: 20,
                                        backgroundColor: "grey",
                                        color: "#fff",
                                      }}
                                    >
                                      Remove
                                    </button>
                                  )}
                                {educationList.length - 1 === index && (
                                  <button
                                    onClick={handleAddExperience}
                                    class="add-new-field"
                                  >
                                    + Add Education
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {workExpList.map((list, index) => {
                        return (
                          <>
                            <div class="row">
                              <label class="col-md-3 col-form-label">
                                Experience {index + 1}
                              </label>
                              <div class="col-md-9">
                                <div class="form-group">
                                  <input
                                    name="companyName"
                                    value={list.companyName}
                                    onChange={(e) =>
                                      handleWorkExperienceChange(e, index)
                                    }
                                    type="text"
                                    class="form-control"
                                    placeholder="Company Name"
                                  />
                                </div>
                                <div class="form-group">
                                  <input
                                    name="Period"
                                    value={list.Period}
                                    onChange={(e) =>
                                      handleWorkExperienceChange(e, index)
                                    }
                                    type="text"
                                    class="form-control"
                                    placeholder="Period"
                                  />
                                </div>
                                <div class="form-group">
                                  <textarea
                                    name="Description"
                                    value={list.Description}
                                    onChange={(e) =>
                                      handleWorkExperienceChange(e, index)
                                    }
                                    class="form-control"
                                    placeholder="Description (Optional)"
                                  ></textarea>
                                </div>
                                {/* <a href="#" class="add-new-field">
                                  + Add Experience
                          </a> */}
                                {workExpList.length - 1 === index &&
                                  workExpList.length > 1 && (
                                    <button
                                      onClick={(e) =>
                                        handleExperienceRemove(e, index)
                                      }
                                      class="add-new-field"
                                      style={{
                                        marginRight: 20,
                                        backgroundColor: "grey",
                                        color: "#fff",
                                      }}
                                    >
                                      Remove
                                    </button>
                                  )}
                                {workExpList.length - 1 === index && (
                                  <button
                                    onClick={handleAddWorkExperience}
                                    class="add-new-field"
                                  >
                                    + Add Experience
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {skillsList.map((skill, index) => {
                        return (
                          <>
                            <div id="skill" class="row">
                              <label class="col-md-3 col-form-label">
                                Professional Skill
                              </label>
                            </div>
                            <div class="row">
                              <label class="col-md-3 col-form-label">
                                SKILL {index + 1}
                              </label>
                              <div class="col-md-9">
                                <div class="form-group">
                                  <input
                                    value={skill.title}
                                    onChange={(e) => {
                                      handleProfessionalSkillChange(e, index);
                                    }}
                                    name="title"
                                    type="text"
                                    class="form-control"
                                    placeholder="Title"
                                  />
                                </div>
                                <div class="form-group">
                                  <input
                                    name="percentage"
                                    onChange={(e) => {
                                      handleProfessionalSkillChange(e, index);
                                    }}
                                    value={skill.percentage}
                                    type="number"
                                    class="form-control"
                                    placeholder="value (Percen)"
                                  />
                                </div>
                                {/* <a href="#" class="add-new-field">
                                  + Add Skill
                            </a> */}
                                {skillsList.length - 1 === index &&
                                  skillsList.length > 1 && (
                                    <button
                                      onClick={(e) =>
                                        handleSkillRemove(e, index)
                                      }
                                      class="add-new-field"
                                      style={{
                                        marginRight: 20,
                                        backgroundColor: "grey",
                                        color: "#fff",
                                      }}
                                    >
                                      Remove
                                    </button>
                                  )}
                                {skillsList.length - 1 === index && (
                                  <button
                                    onClick={handleAddSkills}
                                    class="add-new-field"
                                  >
                                    + Add Skill
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}

                      <div id="qualification" class="row">
                        <label class="col-md-3 col-form-label">
                          Special Qualification
                        </label>
                        <div class="col-md-9">
                          <EditorTable
                            type="specialQualification"
                            getAboutContent={getAboutContent}
                          />
                        </div>
                      </div>
                      {portfolioList.map((list, index) => {
                        return (
                          <>
                            <div id="portfolio" class="row">
                              <label class="col-md-3 col-form-label">
                                Portfolio
                              </label>
                              <div class="col-md-9">
                                <div class="form-group">
                                  <input
                                    value={list.title}
                                    name="title"
                                    onChange={(e) =>
                                      handlePortfolioChange(e, index)
                                    }
                                    type="text"
                                    class="form-control"
                                    placeholder="Title"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <label class="col-md-3 col-form-label">
                                PORTFOLIO {index + 1}
                              </label>
                              <div class="col-md-9">
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">Image</div>
                                    </div>
                                    <div class="upload-portfolio-image">
                                      <div class="update-photo">
                                        <img
                                          class="image"
                                          src={list.image ? list.image : thumb1}
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        class={`${classes.uploadBox} file-upload`}
                                      >
                                        <input
                                          type="file"
                                          class="file-input"
                                          onChange={(e) =>
                                            onChangePicture(e, index)
                                          }
                                        />
                                        {/* <i data-feather="plus"></i> */}
                                        <p style={{ fontSize: "15px" }}>+</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <input
                                    value={list.link}
                                    name="link"
                                    onChange={(e) =>
                                      handlePortfolioChange(e, index)
                                    }
                                    type="text"
                                    class="form-control"
                                    placeholder="Portfolio Link (Optional)"
                                  />
                                </div>
                                {portfolioList.length - 1 === index &&
                                  portfolioList.length > 1 && (
                                    <button
                                      onClick={(e) =>
                                        handlePortfolioRemove(e, index)
                                      }
                                      class="add-new-field"
                                      style={{
                                        marginRight: 20,
                                        backgroundColor: "grey",
                                        color: "#fff",
                                      }}
                                    >
                                      Remove
                                    </button>
                                  )}
                                {portfolioList.length - 1 === index && (
                                  <button
                                    onClick={handleAddPortfolio}
                                    class="add-new-field"
                                  >
                                    + Add Portfolio
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                      {/* <div id="cv" class="row form-group">
                        <label class="col-md-3 col-form-label">Upload CV</label>
                        <div class="col-md-9">
                          <input type="file" onChange={(e) => {
                            console.log('e', e.target.files[0])
                            if (e.target.files[0]) {
                              const file = e.target.files[0]
                              const storageRef = app.storage().ref()
                              const fileRef = storageRef.child(file.name)
                              fileRef.put(file).then(res => {
                                storageRef.child(file.name).getDownloadURL().then(function (url) {
                                  console.log(url, 'docUrldocUrl')
                                  setUserCv({
                                    ...userCv,
                                    cvURL: url
                                  })
                                  // setLoader(false)/
                                  // setUploadMedia(url)
                                });
                              })
                            }
                          }} title="Upload CV" />
                     
                        </div>
                      </div> */}

                      <div id="cover" class="row">
                        <label class="col-md-3 col-form-label">
                          Cover Letter
                        </label>
                        <div class="col-md-9">
                          {/* <textarea
                            class="tinymce-editor-1"
                            placeholder="Description text here"
                          ></textarea> */}
                          <EditorTable
                            type="coverLetter"
                            getAboutContent={getAboutContent}
                          />
                        </div>
                      </div>
                      <div id="profile" class="row">
                        <label class="col-md-3 col-form-label">
                          Social Profile
                        </label>
                        <div class="col-md-9">
                          {data.map((names, index) => {
                            return (
                              <div class="form-group">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text dropdown-label">
                                      {names.name}
                                      {/* <select class="form-control" onChange={changeInSocialLink}>
                                        <option>{names.name}</option>
                                      </select> */}
                                      {/* <i class="fa fa-caret-down"></i> */}
                                    </div>
                                  </div>

                                  <input
                                    type="text"
                                    class="form-control"
                                    id="inlineFormInputGroup"
                                    placeholder="Input Profile Link"
                                    onChange={changeInSocialLink}
                                    name={names.name}
                                  />
                                </div>
                              </div>
                            );
                          })}

                          {/* <a href="#" class="add-new-field">
                            + Add Social
                          </a> */}
                        </div>
                      </div>
                      <div id="details" class="row">
                        <label class="col-md-3 col-form-label">
                          Personal Details
                        </label>
                        <div class="col-md-9">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  value={userCv.personalName}
                                  onChange={onChange}
                                  name="personalName"
                                  type="text"
                                  class="form-control"
                                  placeholder="Your Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  value={userCv.fatherName}
                                  onChange={onChange}
                                  name="fatherName"
                                  type="text"
                                  class="form-control"
                                  placeholder="Father's Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  value={userCv.motherName}
                                  onChange={onChange}
                                  name="motherName"
                                  type="text"
                                  class="form-control"
                                  placeholder="Mother's Name"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  value={userCv.dateOfBirth}
                                  onChange={onChange}
                                  name="dateOfBirth"
                                  type="date"
                                  class="form-control"
                                  min="2010-01-01"
                                  max="2030-01-01"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  value={userCv.nationality}
                                  onChange={onChange}
                                  name="nationality"
                                  type="text"
                                  class="form-control"
                                  placeholder="Nationality"
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  value={userCv.sex}
                                  onChange={onChange}
                                  name="sex"
                                >
                                  <option>Sex</option>
                                  <option>Male</option>
                                  <option>Female</option>
                                </select>
                                <i class="fa fa-caret-down"></i>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <input
                                  value={userCv.address}
                                  onChange={onChange}
                                  name="address"
                                  type="text"
                                  class="form-control"
                                  placeholder="Your Address"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-9 offset-md-3">
                          <div class="form-group terms">
                            <input
                              class="custom-radio"
                              type="checkbox"
                              id="radio-4"
                              name="termsandcondition"
                              value={terms}
                              onChange={(e) => setTerms(!terms)}
                            />

                            <label for="radio-4">
                              <span class="dot"></span> You accepts our{" "}
                              <a href="#">Terms and Conditions</a> and{" "}
                              <a href="#">Privacy Policy</a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label"></label>
                        <div class="col-md-9">
                          <button class="button" onClick={submitResume}>
                            Publish Resume
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className={`${classes.hide} post-sidebar`}>
                  <h5>
                    <i data-feather="arrow-down-circle"></i>Navigation
                  </h5>
                  <ul class="sidebar-menu">
                    <li>
                      <a href="#full-name">Full Name</a>
                    </li>
                    <li>
                      <a href="#information">Informations</a>
                    </li>
                    <li>
                      <a href="#about">About You</a>
                    </li>
                    <li>
                      <a href="#education">Education</a>
                    </li>
                    <li>
                      <a href="#experience">Work Experiance</a>
                    </li>
                    <li>
                      <a href="#skill">Professional Skill</a>
                    </li>
                    <li>
                      <a href="#qualification">Special Qualification</a>
                    </li>
                    <li>
                      <a href="#portfolio">Portfolio</a>
                    </li>
                    <li>
                      <a href="#cv">Upload CV</a>
                    </li>
                    <li>
                      <a href="#cover">Cover Letter</a>
                    </li>
                    <li>
                      <a href="#profile">Social Profile</a>
                    </li>
                    <li>
                      <a href="#details">Personal Details</a>
                    </li>
                  </ul>
                  {/* <div class="signin-option">
                    <p>
                      Have an Account ? Before submit job you need to sign in !
                    </p>
                    <div class="buttons">
                      <a href="#" class="signin">
                        Sign in
                      </a>
                      <a href="#" class="register">
                        Register
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default AddResume;
