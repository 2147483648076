import React from "react";
import thumb1 from "../../assests/images/portfolio/thumb-1.jpg";
import thumb2 from "../../assests/images/portfolio/thumb-2.jpg";
import thumb3 from "../../assests/images/portfolio/thumb-3.jpg";

import {
  NewsLetterFooter,
  SocialShare,
  Header,
  NotificationAndAccount,
  Footer,
} from "../../components";
import { Link, useHistory } from "react-router-dom";
import ThumbLogo from "../../assests/images/bg/banner-1-bg.jpg";
import quoteLogo from "../../assests/images/testimonial/quote.png";
import thumb from "../../assests/images/testimonial/thumb-1.jpg";
import FindYourJob from "../../components/FindYourJob";
import Slider from "react-slick";
import LinkIcon from "@material-ui/icons/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReactPlayer from "react-player/vimeo";

const CandidateDetais = () => {
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    // centerPadding: "30px !important",
  };

  return (
    <>
      <div>
        {/* <NotificationAndAccount  />
            <Header /> */}

        <header className="header-2">
          <div className="container">
            <div className="row">
              <div className="col">
                <NotificationAndAccount />
                <Header />
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* Candidate details */}
      <div class="alice-bg padding-top-60 section-padding-bottom">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="candidate-details">
                <div class="title-and-info">
                  <div class="title">
                    <div class="thumb">
                      <img
                        src="images/candidate/thumb-1.jpg"
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                    <div class="title-body">
                      <h4>AHMED</h4>
                      <div class="info">
                        <span class="candidate-designation">
                          <i data-feather="check-square"></i>ios Developer
                        </span>
                        <span class="candidate-location">
                          <i data-feather="map-pin"></i>Lorem ipsum
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="download-resume">
                    <a href="#">
                      Download CV <i data-feather="download"></i>
                    </a>
                  </div>
                </div>
                <div class="skill-and-profile">
                  <div class="skill">
                    <label>Skills:</label>
                    <a href="#">Design</a>
                    <a href="#">Illustration</a>
                    <a href="#">iOS</a>
                  </div>
                  <div class="social-profile">
                    <label>Social:</label>
                    <a href="#">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-google-plus"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-pinterest-p"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-behance"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-dribbble"></i>
                    </a>
                    <a href="#">
                      <i class="fab fa-github"></i>
                    </a>
                  </div>
                </div>
                <div class="details-information section-padding-60">
                  <div class="row">
                    <div class="col-xl-7 col-lg-8">
                      <div class="about-details details-section">
                        <h4>
                          <i data-feather="align-left"></i>About Me
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Vestibulum morbi blandit cursus
                          risus at ultrices mi tempus. Felis bibendum ut
                          tristique et egestas quis ipsum.
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Vestibulum morbi blandit cursus
                          risus at ultrices mi tempus. Felis bibendum ut
                          tristique et egestas quis ipsum.
                        </p>
                      </div>
                      <div class="intor-video details-section">
                        <h4>
                          <i data-feather="video"></i>Intro Video
                        </h4>
                        <div class="video-area">
                          {/* <div
                            id="player"
                            data-plyr-provider="vimeo"
                            data-plyr-embed-id="126777001"
                          ></div> */}

                          <ReactPlayer
                            url="https://player.vimeo.com/video/126777001"
                            // playing={true}
                            controls={true}
                            width="570px"
                          />
                        </div>
                      </div>
                      <div class="edication-background details-section">
                        <h4>
                          <i data-feather="book"></i>Education Background
                        </h4>
                        <div class="education-label">
                          <span class="study-year">2018 - Present</span>
                          <h5>
                            Masters in Software Engineering
                            <span>@ Engineering University</span>
                          </h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Vestibulum morbi blandit cursus
                            risus at ultrices mi tempus.
                          </p>
                        </div>
                        <div class="education-label">
                          <span class="study-year">2014 - 2018</span>
                          <h5>
                            Diploma in Graphics Design
                            <span>@ Graphic Arts Institute</span>
                          </h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                        </div>
                        <div class="education-label">
                          <span class="study-year">2008 - 2014</span>
                          <h5>
                            Secondary School Certificate
                            <span>@ Engineering High School</span>
                          </h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                        </div>
                      </div>
                      <div class="experience details-section">
                        <h4>
                          <i data-feather="briefcase"></i>Work Experiance
                        </h4>
                        <div class="experience-section">
                          <span class="service-year">2016 - Present</span>
                          <h5>
                            Lead UI/UX Designer<span>@ Codepassengers LTD</span>
                          </h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                        </div>
                        <div class="experience-section">
                          <span class="service-year">2012 - 2016</span>
                          <h5>
                            Former Graphic Designer
                            <span>@ Graphicreeeo CO</span>
                          </h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                        </div>
                      </div>
                      <div class="professonal-skill details-section">
                        <h4>
                          <i data-feather="feather"></i>Professional Skill
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                        <div class="progressS-group">
                          <div class="progress-item">
                            <div class="progress-head">
                              <p class="progress-on">Photoshop</p>
                            </div>
                            <div class="progress-body">
                              <div class="progress">
                                {/* <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div> */}
                              </div>
                              <p class="progress-to">70%</p>
                            </div>
                          </div>
                          <div class="progress-item">
                            <div class="progress-head">
                              <p class="progress-on">HTML/CSS</p>
                            </div>
                            <div class="progress-body">
                              <div class="progress">
                                {/* <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div> */}
                              </div>
                              <p class="progress-to">90%</p>
                            </div>
                          </div>
                          <div class="progress-item">
                            <div class="progress-head">
                              <p class="progress-on">JavaScript</p>
                            </div>
                            <div class="progress-body">
                              <div class="progress">
                                {/* <div class="progress-bar" role="progressbar" aria-valuenow="74" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div> */}
                              </div>
                              <p class="progress-to">74%</p>
                            </div>
                          </div>
                          <div class="progress-item">
                            <div class="progress-head">
                              <p class="progress-on">PHP</p>
                            </div>
                            <div class="progress-body">
                              <div class="progress">
                                {/* <div class="progress-bar" role="progressbar" aria-valuenow="86" aria-valuemin="0" aria-valuemax="100" style="width: 0;"></div> */}
                              </div>
                              <p class="progress-to">86%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="special-qualification details-section">
                        <h4>
                          <i data-feather="gift"></i>Special Qualification
                        </h4>
                        <ul>
                          <li>Lorem ipsum dolor sit amet, consectetur.</li>
                          <li>Lorem ipsum dolor sit amet, consectetur.</li>
                          <li>Lorem ipsum dolor sit amet, consectetur.</li>
                          <li>Lorem ipsum dolor sit amet, consectetur.</li>
                        </ul>
                      </div>
                      <div class="portfolio details-section">
                        <h4>
                          <i data-feather="gift"></i>Portfolio
                        </h4>
                        <Slider {...settings} className="portfolio-slider">
                          <div class="portfolio-item">
                            <img
                              src={thumb1}
                              class="img-fluid"
                              width="95%"
                              alt=""
                            />
                            <div class="overlay">
                              <a href="#">
                                <VisibilityIcon style={{ fontSize: "28px" }} />
                              </a>
                              <a href="#">
                                <LinkIcon style={{ fontSize: "28px" }} />
                              </a>
                            </div>
                          </div>

                          <div class="portfolio-item">
                            <img
                              src={thumb2}
                              class="img-fluid"
                              width="95%"
                              alt=""
                            />
                            <div class="overlay">
                              <a href="#">
                                <VisibilityIcon style={{ fontSize: "28px" }} />
                              </a>
                              <a href="#">
                                <LinkIcon style={{ fontSize: "28px" }} />
                              </a>
                            </div>
                          </div>
                          <div class="portfolio-item">
                            <img
                              src={thumb3}
                              class="img-fluid"
                              width="95%"
                              alt=""
                            />
                            <div class="overlay">
                              <a href="#">
                                <VisibilityIcon style={{ fontSize: "28px" }} />
                              </a>
                              <a href="#">
                                <LinkIcon style={{ fontSize: "28px" }} />
                              </a>
                            </div>
                          </div>
                          <div class="portfolio-item">
                            <img
                              src={thumb1}
                              class="img-fluid"
                              width="95%"
                              alt=""
                            />
                            <div class="overlay">
                              <a href="#">
                                <VisibilityIcon style={{ fontSize: "28px" }} />
                              </a>
                              <a href="#">
                                <LinkIcon style={{ fontSize: "28px" }} />
                              </a>
                            </div>
                          </div>
                          <div class="portfolio-item">
                            <img
                              src={thumb2}
                              class="img-fluid"
                              width="95%"
                              alt=""
                            />
                            <div class="overlay">
                              <a href="#">
                                <VisibilityIcon style={{ fontSize: "28px" }} />
                              </a>
                              <a href="#">
                                <LinkIcon style={{ fontSize: "28px" }} />
                              </a>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div class="col-xl-4 offset-xl-1 col-lg-4">
                      <div class="information-and-contact">
                        <div class="information">
                          <h4>Information</h4>
                          <ul>
                            <li>
                              <span>Category:</span> Design & Creative
                            </li>
                            <li>
                              <span>Location:</span> Lorem ipsum
                            </li>
                            <li>
                              <span>Status:</span> Full-time
                            </li>
                            <li>
                              <span>Experience:</span> 3 year(s)
                            </li>
                            <li>
                              <span>Salary:</span> Lorem ipsum
                            </li>
                            <li>
                              <span>Gender:</span> Male
                            </li>
                            <li>
                              <span>Age:</span> 24 Year(s)
                            </li>
                            <li>
                              <span>Qualification:</span> Gradute
                            </li>
                          </ul>
                        </div>
                        <div class="buttons">
                          <a
                            href="#"
                            class="button contact-button"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Contact Me
                          </a>
                          <a href="#" class="button cover-download">
                            <i data-feather="download"></i>Cover Letter
                          </a>
                          <a href="#" class="button contact-download">
                            <i data-feather="download"></i>Contact
                          </a>
                        </div>

                        <div
                          class="modal fade contact-form-modal"
                          id="exampleModal"
                          tabindex="-1"
                          role="dialog"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-body">
                                <h4>
                                  <i data-feather="edit"></i>Contact Me
                                </h4>
                                <form action="#">
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Your Name"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <input
                                      type="email"
                                      class="form-control"
                                      placeholder="Your Email"
                                    />
                                  </div>
                                  <div class="form-group">
                                    <textarea
                                      class="form-control"
                                      placeholder="Your Message"
                                    ></textarea>
                                  </div>
                                  <button class="button">Submit</button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-7 col-lg-8">
                    <div class="personal-information details-section">
                      <h4>
                        <i data-feather="user-plus"></i>Personal Deatils
                      </h4>
                      <ul>
                        <li>
                          <span>Full Name:</span> Lorem ipsum
                        </li>
                        <li>
                          <span>Father's Name:</span> Lorem ipsum
                        </li>
                        <li>
                          <span>Mother's Name:</span> Lorem ipsum
                        </li>
                        <li>
                          <span>Date of Birth:</span> 22/08/1992
                        </li>
                        <li>
                          <span>Nationality:</span> Lorem ipsum
                        </li>
                        <li>
                          <span>Sex:</span> Lorem ipsum
                        </li>
                        <li>
                          <span>Address:</span> Lorem ipsum consfert
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindYourJob />

      {/* footer */}
      <SocialShare />
      <NewsLetterFooter />
      <Footer />
    </>
  );
};

export default CandidateDetais;
