import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  Search,
  Briefcase,
  MapPin,
  Clock,
  Heart,
  Edit2,
  DollarSign,
} from "react-feather";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import firebase from "firebase";
import fire from "../../database";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  jobMenu: {
    "&.MuiPopover-root .MuiPaper-root": {
      width: "80%",
      top: "60px !important",
      left: "150px !important",
      [theme.breakpoints.down("sm")]: {
        left: "50px !important",
      },
    },
  },
  links: {
    margin: "8px",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    padding: "10px",
  },
  text: {
    "&.MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "1.5rem",
      color: "#6f7484",
    },
  },
  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const isUser = { isAuthenticated: false };
const isUserAuthenticate = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  console.log("user data", userData);
  if (userData) {
    isUser.isAuthenticated = true;
  } else {
    isUser.isAuthenticated = false;
  }
};

const Header = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [homeAnchorEl, setHomeAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openHome = Boolean(homeAnchorEl);
  const [openJobs, setOpenJobs] = React.useState(false);
  const [openCandidates, setOpenCandidates] = React.useState(false);
  const [openEmployers, setOpenEmployers] = React.useState(false);
  const [openDashboard, setOpenDashboard] = React.useState(false);
  const [candidateDashboard, setCandidateDashboard] = React.useState(false);
  const [empDashboard, setEmployerDashboard] = React.useState(false);
  const [openPages, setOpenPages] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const reviewRef = useRef();
  const [profileData, setProfileData] = useState({})
  const isUserAuthenticate = () => {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (userData) {
      firebase
        .database()
        .ref(`users/${userData.uid}`)
        .on("value", (snapshot) => {
          setUser(snapshot.val());
        });
      isUser.isAuthenticated = true;
      firebase.database().ref(`users/${firebase?.auth()?.currentUser?.uid}`).on("value", snapshot => {
        let res = snapshot.val()
        let type = snapshot?.val()?.type?.toLowerCase()
        getProfile(type);
      })

    } else {
      isUser.isAuthenticated = false;
    }
  };

  const openDialogBox = (jobData) => {

    setOpenDialog(true);
  };

  const onSubmit = () => {
    console.log('revire', reviewRef.current.value)
    if (profileData) {
      if (reviewRef.current.value) {
        firebase
          .database()
          .ref(`/reviews/${firebase.auth().currentUser.uid}`)
          .set({
            message: reviewRef.current.value,
            profileImage: profileData.avatar,
            name: profileData.companyName ? profileData.companyName : profileData.fullName

          })
          .then((res) => {
            // setAlertData({
            //   open: true,
            //   severity: "success",
            //   msg: "successfully Signed In",
            // });
            setOpenDialog(false)
          });
      }
      else {
        alert("Please Enter your message to submit review")
      }

    }
    else {
      alert("Please upload your profile to  submit review!")
    }

  }

  useEffect(() => {
    isUserAuthenticate();
  }, []);

  const getProfile = (type) => {
    firebase.database().ref(`profile/${type}/${firebase.auth().currentUser.uid}`).on("value", snapshot => {
      setProfileData(snapshot.val())
    })
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuHome = (event) => {
    setHomeAnchorEl(event.currentTarget);
  };

  const handleCloseHome = () => {
    setHomeAnchorEl(null);
  };

  // const renderAuthNav = () => {
  //   console.log("userrr=>", user);
  //   return (
  //     <nav className="navbar navbar-expand-lg cp-nav-2">
  //       {/* <button
  //         className="navbar-toggler"
  //         data-toggle="collapse"
  //         data-target="#navbarSupportedContent"
  //         aria-controls="navbarSupportedContent"
  //         type="button"
  //         aria-expanded="false"
  //         aria-label="Toggle navigation"
  //         onClick={handleMenu}
  //       > */}
  //       <IconButton
  //         // aria-label="account of current user"
  //         aria-label="Toggle navigation"
  //         // aria-controls="menu-appbar"
  //         aria-controls="navbarSupportedContent"
  //         className="navbar-toggler"
  //         aria-haspopup="true"
  //         onClick={handleMenu}
  //         color="inherit"
  //       >
  //         <span className="navbar-toggler-icon"></span>
  //       </IconButton>
  //       {/* </button> */}

  //       <div>
  //         <Menu
  //           // id="menu-appbar"
  //           anchorEl={anchorEl}
  //           keepMounted
  //           // transformOrigin={{
  //           //   vertical: "top",
  //           //   horizontal: "right",
  //           // }}
  //           open={open}
  //           onClose={handleClose}
  //           className={classes.jobMenu}
  //         >
  //           <ul className="navbar-nav">
  //             <Link to="/">
  //               <ListItem style={{ paddingLeft: "8px" }}>
  //                 <ListItemText primary="Home" className={classes.text} />
  //               </ListItem>
  //             </Link>
  //             {user.type == "Candidate" ? (
  //               // <li className="menu-item dropdown">
  //               <>
  //                 <ListItem
  //                   onClick={() => setOpenJobs(!openJobs)}
  //                   style={{ paddingLeft: "8px" }}
  //                 >
  //                   <ListItemText primary="Jobs" className={classes.text} />
  //                   {openJobs ? <ExpandLess /> : <ExpandMore />}
  //                 </ListItem>
  //                 {/* <Collapse in={openJobs} timeout="auto" unmountOnExit> */}
  //                 <List component="div" className={classes.links}>
  //                   <li className="menu-item">
  //                     <Link to="/JobListing">
  //                       <ListItemText
  //                         primary="Job Listing"
  //                         className={classes.text}
  //                       />
  //                     </Link>
  //                   </li>
  //                   {/* <li className="menu-item">
  //                 <Link to="/JobDetails">Job Details</Link>
  //               </li> */}
  //                 </List>
  //                 {/* </Collapse> */}
  //               </>
  //             ) : // </li>
  //             null}
  //             <li className="menu-item dropdown">
  //               {/* <ul className="dropdown-menu"> */}
  //               {user.type == "Employer" ? (
  //                 <>
  //                   <ListItem
  //                     onClick={() => setOpenCandidates(!openCandidates)}
  //                     style={{ paddingLeft: "8px" }}
  //                   >
  //                     <ListItemText
  //                       primary="Candidates"
  //                       className={classes.text}
  //                     />
  //                     {openCandidates ? <ExpandLess /> : <ExpandMore />}
  //                   </ListItem>

  //                   <Collapse in={openCandidates} timeout="auto" unmountOnExit>
  //                     <List component="div" className={classes.links}>
  //                       <li className="menu-item">
  //                         <Link to="/CandidateListing">
  //                           <ListItemText
  //                             primary="Candidate Listing"
  //                             className={classes.text}
  //                           />
  //                         </Link>
  //                       </li>
  //                     </List>
  //                   </Collapse>
  //                   {/* <li className="menu-item">
  //                       <Link to="/CandidateListing">Candidate Listing</Link>
  //                     </li> */}
  //                   {/* <li className="menu-item">
  //                     <Link to="/CandidateDetails">Candidate Details</Link>
  //                   </li> */}
  //                 </>
  //               ) : null}
  //               {user.type == "Candidate" ? (
  //                 // <li className="menu-item">
  //                 //   <Link to="/AddResume">Add Resume</Link>
  //                 // </li>
  //                 <Link to="/AddResume">
  //                   <ListItem style={{ paddingLeft: "8px" }}>
  //                     <ListItemText
  //                       primary="Add Resume"
  //                       className={classes.text}
  //                     />
  //                   </ListItem>
  //                 </Link>
  //               ) : null}
  //               {/* </ul> */}
  //             </li>
  //             {user.type == "Candidate" ? (
  //               <li className="menu-item dropdown">
  //                 <ListItem
  //                   onClick={() => setOpenEmployers(!openEmployers)}
  //                   style={{ paddingLeft: "8px" }}
  //                 >
  //                   <ListItemText
  //                     primary="Employers"
  //                     className={classes.text}
  //                   />
  //                   {openEmployers ? <ExpandLess /> : <ExpandMore />}
  //                 </ListItem>
  //                 {/* <ul className="dropdown-menu"> */}
  //                 {user.type != "Employer" ? (
  //                   <>
  //                     {/* <li className="menu-item">
  //                         <Link to="/EmployerListing">Employer Listing</Link>
  //                       </li> */}
  //                     <Collapse in={openEmployers} timeout="auto" unmountOnExit>
  //                       <List component="div" className={classes.links}>
  //                         <li className="menu-item">
  //                           <Link to="/EmployerListing">
  //                             <ListItemText
  //                               primary="Employer Listing"
  //                               className={classes.text}
  //                             />
  //                           </Link>
  //                         </li>
  //                       </List>
  //                     </Collapse>
  //                     {/* <li className="menu-item">
  //                     <Link to="/EmployerDetails">Employer Details</Link>
  //                   </li> */}
  //                   </>
  //                 ) : null}

  //                 {user.type != "Candidate" ? (
  //                   // <li className="menu-item">
  //                   //   <Link to="./PostJob">Post a Job</Link>
  //                   // </li>
  //                   <Link to="/PostJob">
  //                     <ListItem style={{ paddingLeft: "8px" }}>
  //                       <ListItemText
  //                         primary="Post a Job"
  //                         className={classes.text}
  //                       />
  //                     </ListItem>
  //                   </Link>
  //                 ) : // <Collapse in={openJobs} timeout="auto" unmountOnExit>
  //                 //   <List component="div" className={classes.links}>
  //                 //     <li className="menu-item">
  //                 //       <Link to="/PostJob">
  //                 //         <ListItemText
  //                 //           primary="Post a Job"
  //                 //           className={classes.text}
  //                 //         />
  //                 //       </Link>
  //                 //     </li>
  //                 //   </List>
  //                 // </Collapse>
  //                 null}
  //                 {/* </ul> */}
  //               </li>
  //             ) : null}

  //             <li className="menu-item dropdown">
  //               <ListItem
  //                 onClick={() => setOpenDashboard(!openDashboard)}
  //                 style={{ paddingLeft: "8px" }}
  //               >
  //                 <ListItemText primary="Dashboard" className={classes.text} />
  //                 {openDashboard ? <ExpandLess /> : <ExpandMore />}
  //               </ListItem>
  //               {/* <ul className="dropdown-menu"> */}
  //               {user.type == "Candidate" ? (
  //                 <>
  //                   <li className="menu-item dropdown">
  //                     <Collapse in={openDashboard} timeout="auto" unmountOnExit>
  //                       <List component="div" className={classes.links}>
  //                         <li className="menu-item">
  //                           <ListItem
  //                             onClick={() =>
  //                               setCandidateDashboard(!candidateDashboard)
  //                             }
  //                             style={{ paddingLeft: "8px" }}
  //                           >
  //                             <ListItemText
  //                               primary="Candidate Dashboard"
  //                               className={classes.text}
  //                             />
  //                             {candidateDashboard ? (
  //                               <ExpandLess />
  //                             ) : (
  //                               <ExpandMore />
  //                             )}
  //                           </ListItem>
  //                         </li>

  //                         <Collapse
  //                           in={candidateDashboard}
  //                           timeout="auto"
  //                           unmountOnExit
  //                         >
  //                           <List component="div" className={classes.links}>
  //                             <li className="menu-item">
  //                               <Link to="/CandidateDashboard">
  //                                 <ListItemText
  //                                   primary="Dashboard"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/CandidateProfile">
  //                                 <ListItemText
  //                                   primary="Edit Profile"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/AddResume">
  //                                 <ListItemText
  //                                   primary="Add Resume"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/CandidateEditResume">
  //                                 <ListItemText
  //                                   primary="Edit Resume"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/CandidateBookmark">
  //                                 <ListItemText
  //                                   primary="Bookmarked"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/CandidateApplied">
  //                                 <ListItemText
  //                                   primary="Applied"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/CandidateMessage">
  //                                 <ListItemText
  //                                   primary="Message"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/Alert">
  //                                 <ListItemText
  //                                   primary="Alert"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                           </List>
  //                         </Collapse>
  //                       </List>
  //                     </Collapse>

  //                     {/* <ul className="dropdown-menu"> */}

  //                     {/* <li className="menu-item">
  //                       <Link to="/CandidateDashboard">Dashboard</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/CandidateProfile">Edit Profile</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/AddResume">Add Resume</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                         <Link to="/CandidateResume">Resume</Link>
  //                       </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/CandidateEditResume">Edit Resume</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/CandidateBookmark">Bookmarked</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/CandidateApplied">Applied</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/CandidateMessage">Message</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/Alert">Alert</Link>
  //                     </li> */}
  //                     {/* </ul> */}
  //                   </li>
  //                 </>
  //               ) : (
  //                 <>
  //                   <li className="menu-item dropdown">
  //                     {/* <ListItem
  //                       onClick={handleClick}
  //                       style={{ paddingLeft: "8px" }}
  //                     >
  //                       <ListItemText
  //                         primary="Employer Dashboard"
  //                         className={classes.text}
  //                       />
  //                       {openJobs ? <ExpandLess /> : <ExpandMore />}
  //                     </ListItem> */}
  //                     {/* <ul className="dropdown-menu"> */}

  //                     <Collapse in={openDashboard} timeout="auto" unmountOnExit>
  //                       <List component="div" className={classes.links}>
  //                         <li className="menu-item">
  //                           <ListItem
  //                             onClick={() =>
  //                               setEmployerDashboard(!empDashboard)
  //                             }
  //                             style={{ paddingLeft: "8px" }}
  //                           >
  //                             <ListItemText
  //                               primary="Employer Dashboard"
  //                               className={classes.text}
  //                             />
  //                             {empDashboard ? <ExpandLess /> : <ExpandMore />}
  //                           </ListItem>
  //                         </li>

  //                         <Collapse
  //                           in={empDashboard}
  //                           timeout="auto"
  //                           unmountOnExit
  //                         >
  //                           <List component="div" className={classes.links}>
  //                             <li className="menu-item">
  //                               <Link to="/EmployerDashboard">
  //                                 <ListItemText
  //                                   primary="Employer Dashboard"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/EmployerEditProfile">
  //                                 <ListItemText
  //                                   primary="Edit Profile"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/ManageJobs">
  //                                 <ListItemText
  //                                   primary="Manage Jobs"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                             <li className="menu-item">
  //                               <Link to="/DashboardMessage">
  //                                 <ListItemText
  //                                   primary="Dashboard Message"
  //                                   className={classes.text}
  //                                 />
  //                               </Link>
  //                             </li>
  //                           </List>
  //                         </Collapse>
  //                       </List>
  //                     </Collapse>

  //                     {/* <li className="menu-item">
  //                       <Link to="/EmployerDashboard">Employer Dashboard</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/EmployerEditProfile">Edit Profile</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/ManageCandidate">Manage Candidate</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/ManageJobs">Manage Job</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                       <Link to="/DashboardMessage">Dashboard Message</Link>
  //                     </li> */}
  //                     {/* <li className="menu-item">
  //                         <Link to="/PostJob">Post Job</Link>
  //                       </li> */}
  //                     {/* </ul> */}
  //                   </li>
  //                 </>
  //               )}
  //               {/* </ul> */}
  //             </li>
  //             <li className="menu-item dropdown">
  //               <ListItem
  //                 onClick={() => setOpenPages(!openPages)}
  //                 style={{ paddingLeft: "8px" }}
  //               >
  //                 <ListItemText primary="Pages" className={classes.text} />
  //                 {openPages ? <ExpandLess /> : <ExpandMore />}
  //               </ListItem>

  //               {/* <ul className="dropdown-menu"> */}
  //               <Collapse in={openPages} timeout="auto" unmountOnExit>
  //                 <List component="div" className={classes.links}>
  //                   <li className="menu-item">
  //                     <Link to="/AboutUs">
  //                       <ListItemText
  //                         primary="About Us"
  //                         className={classes.text}
  //                       />
  //                     </Link>
  //                   </li>
  //                   <li className="menu-item">
  //                     <Link to="/howItWorks">
  //                       <ListItemText
  //                         primary="How It Works"
  //                         className={classes.text}
  //                       />
  //                     </Link>
  //                   </li>
  //                   <li className="menu-item">
  //                     <Link to="/FAQ">
  //                       <ListItemText
  //                         primary="FAQ's"
  //                         className={classes.text}
  //                       />
  //                     </Link>
  //                   </li>
  //                   <li className="menu-item">
  //                     <Link to="/TermsAndConditions">
  //                       <ListItemText
  //                         primary="Terms & Conditions"
  //                         className={classes.text}
  //                       />
  //                     </Link>
  //                   </li>
  //                   {/* <li className="menu-item">
  //                 <Link to="/JobDetails">Job Details</Link>
  //               </li> */}

  //                   {/* <li className="menu-item">
  //                     <Link to="/AboutUs">About Us</Link>
  //                   </li>
  //                   <li className="menu-item">
  //                     <Link to="/howItWorks">How It Works</Link>
  //                   </li>
  //                   <li className="menu-item">
  //                     <Link to="/FAQ">FAQ's</Link>
  //                   </li> */}
  //                   {/* <li className="menu-item">
  //                 <Link to="/Checkout">Checkout</Link>
  //                 </li>
  //                 <li className="menu-item">
  //                 <Link to="/Payment">Payment Complete</Link>
  //               </li> */}
  //                   {/* <li className="menu-item">
  //                     <Link to="TermsAndConditions">Terms & Conditions</Link>
  //                   </li> */}
  //                 </List>
  //               </Collapse>
  //               {/* </ul> */}
  //             </li>
  //             {/* <li className="menu-item">
  //               <Link to="/ContactUs">Contact Us</Link>
  //             </li> */}
  //             <Link to="/ContactUs">
  //               <ListItem style={{ paddingLeft: "8px" }}>
  //                 <ListItemText primary="Contact Us" className={classes.text} />
  //               </ListItem>
  //             </Link>
  //             {user.type == "Employer" ? (
  //               // <li className="menu-item post-job">
  //               //   <Link to="/PostJob">
  //               //     <i className="fas fa-plus"></i>Post a Job
  //               //   </Link>
  //               // </li>

  //               <Link to="/PostJob">
  //                 <ListItem style={{ paddingLeft: "8px" }}>
  //                   <ListItemIcon style={{ minWidth: "25px" }}>
  //                     <AddIcon />
  //                   </ListItemIcon>
  //                   <ListItemText primary="Post Job" className={classes.text} />
  //                 </ListItem>
  //               </Link>
  //             ) : (
  //               // <li className="menu-item post-job">
  //               //   <Link to="/JobListing">
  //               //     <i className="fas fa-plus"></i>Find a Job
  //               //   </Link>
  //               // </li>
  //               <Link to="/JobListing">
  //                 <ListItem style={{ paddingLeft: "8px" }}>
  //                   <ListItemIcon style={{ minWidth: "25px" }}>
  //                     <AddIcon />
  //                   </ListItemIcon>
  //                   <ListItemText
  //                     primary="Find a Job"
  //                     className={classes.text}
  //                   />
  //                 </ListItem>
  //               </Link>
  //             )}
  //           </ul>
  //         </Menu>
  //       </div>
  //     </nav>
  //   );
  // };

  const renderNewAuthNav = () => {
    return (
      <>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div class="apply-popup">
            <div
              id="apply-popup-id"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      <i>
                        <Edit2 />
                      </i>
                      Write a Review/Feedback here.
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span onClick={() => setOpenDialog(false)} aria-hidden="true">
                        &times;
                      </span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group">
                      <textarea
                        ref={reviewRef}
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Type here..."
                        class="form-control"
                      />
                    </div>


                    <button
                      // disabled={loading}
                      // style={{ backgroundColor: loading && "grey" }}
                      class="button primary-bg btn-block"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <nav className="navbar navbar-expand-lg cp-nav-2">
          {/* <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            type="button"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleMenu}
          > */}
          <IconButton
            // aria-label="account of current user"
            aria-label="Toggle navigation"
            // aria-controls="menu-appbar"
            aria-controls="navbarSupportedContent"
            className="navbar-toggler"
            aria-haspopup="true"
            style={{ border: "none" }}
            onClick={handleMenu}
            color="inherit"
          >
            {/* <span className="navbar-toggler-icon"></span> */}
            <MoreVertIcon style={{ fontSize: "22px" }} />
          </IconButton>
          {/* </button> */}

          <div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
              className={classes.jobMenu}
            >
              <ul className="navbar-nav">
                <Link to="/">
                  <ListItem style={{ paddingLeft: "8px" }}>
                    <ListItemText primary="Home" className={classes.text} />
                  </ListItem>
                </Link>
                {user.type == "Candidate" ? (
                  // <li className="menu-item dropdown">
                  <>
                    <ListItem
                      onClick={() => setOpenJobs(!openJobs)}
                      style={{ paddingLeft: "8px" }}
                    >
                      <ListItemText primary="Jobs" className={classes.text} />
                      {openJobs ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openJobs} timeout="auto" unmountOnExit>
                      <List component="div" className={classes.links}>
                        <li className="menu-item">
                          <Link to="/JobListing">
                            <ListItemText
                              primary="Job Listing"
                              className={classes.text}
                            />
                          </Link>
                        </li>
                        {/* <li className="menu-item">
                  <Link to="/JobDetails">Job Details</Link>
                </li> */}
                      </List>
                    </Collapse>
                  </>
                ) : // </li>
                  null}
                <li className="menu-item dropdown">
                  {/* <ul className="dropdown-menu"> */}
                  {user.type == "Employer" ? (
                    <>
                      <ListItem
                        onClick={() => setOpenCandidates(!openCandidates)}
                        style={{ paddingLeft: "8px" }}
                      >
                        <ListItemText
                          primary="Candidates"
                          className={classes.text}
                        />
                        {openCandidates ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>

                      <Collapse
                        in={openCandidates}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" className={classes.links}>
                          <li className="menu-item">
                            <Link to="/CandidateListing">
                              <ListItemText
                                primary="Candidate Listing"
                                className={classes.text}
                              />
                            </Link>
                          </li>
                        </List>
                      </Collapse>
                      {/* <li className="menu-item">
                        <Link to="/CandidateListing">Candidate Listing</Link>
                      </li> */}
                      {/* <li className="menu-item">
                      <Link to="/CandidateDetails">Candidate Details</Link>
                    </li> */}
                    </>
                  ) : null}
                  {user.type == "Candidate" ? (
                    // <li className="menu-item">
                    //   <Link to="/AddResume">Add Resume</Link>
                    // </li>
                    <Link to="/AddResume" className={classes.hide}>
                      <ListItem style={{ paddingLeft: "8px" }}>
                        <ListItemText
                          primary="Add Resume"
                          className={classes.text}
                        />
                      </ListItem>
                    </Link>
                  ) : null}
                  {/* </ul> */}
                </li>
                {user.type == "Candidate" ? (
                  <li className="menu-item dropdown">
                    <ListItem
                      onClick={() => setOpenEmployers(!openEmployers)}
                      style={{ paddingLeft: "8px" }}
                    >
                      <ListItemText
                        primary="Employers"
                        className={classes.text}
                      />
                      {openEmployers ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    {/* <ul className="dropdown-menu"> */}
                    {user.type != "Employer" ? (
                      <>
                        {/* <li className="menu-item">
                          <Link to="/EmployerListing">Employer Listing</Link>
                        </li> */}
                        <Collapse
                          in={openEmployers}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" className={classes.links}>
                            <li className="menu-item">
                              <Link to="/EmployerListing">
                                <ListItemText
                                  primary="Employer Listing"
                                  className={classes.text}
                                />
                              </Link>
                            </li>
                          </List>
                        </Collapse>
                        {/* <li className="menu-item">
                      <Link to="/EmployerDetails">Employer Details</Link>
                    </li> */}
                      </>
                    ) : null}

                    {user.type != "Candidate" ? (
                      // <li className="menu-item">
                      //   <Link to="./PostJob">Post a Job</Link>
                      // </li>
                      <Link to="/PostJob">
                        <ListItem style={{ paddingLeft: "8px" }}>
                          <ListItemText
                            primary="Post a Job"
                            className={classes.text}
                          />
                        </ListItem>
                      </Link>
                    ) : // <Collapse in={openJobs} timeout="auto" unmountOnExit>
                      //   <List component="div" className={classes.links}>
                      //     <li className="menu-item">
                      //       <Link to="/PostJob">
                      //         <ListItemText
                      //           primary="Post a Job"
                      //           className={classes.text}
                      //         />
                      //       </Link>
                      //     </li>
                      //   </List>
                      // </Collapse>
                      null}
                    {/* </ul> */}
                  </li>
                ) : null}

                <li className="menu-item dropdown">
                  <ListItem
                    onClick={() => setOpenDashboard(!openDashboard)}
                    style={{ paddingLeft: "8px" }}
                  >
                    <ListItemText
                      primary="Dashboard"
                      className={classes.text}
                    />
                    {openDashboard ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {/* <ul className="dropdown-menu"> */}
                  {user.type == "Candidate" ? (
                    <>
                      <li className="menu-item dropdown">
                        <Collapse
                          in={openDashboard}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" className={classes.links}>
                            <li className="menu-item">
                              <ListItem
                                onClick={() =>
                                  setCandidateDashboard(!candidateDashboard)
                                }
                                style={{ paddingLeft: "8px" }}
                              >
                                <ListItemText
                                  primary="Candidate Dashboard"
                                  className={classes.text}
                                />
                                {candidateDashboard ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                            </li>

                            <Collapse
                              in={candidateDashboard}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" className={classes.links}>
                                <li className="menu-item">
                                  <Link to="/CandidateDashboard">
                                    <ListItemText
                                      primary="Dashboard"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/CandidateProfile">
                                    <ListItemText
                                      primary="Edit Profile"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li
                                  className="menu-item"
                                >
                                  <Link to="/AddResume">
                                    <ListItemText
                                      primary="Add Resume"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/CandidateEditResume">
                                    <ListItemText
                                      primary="Edit Resume"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/CandidateBookmark">
                                    <ListItemText
                                      primary="Bookmarked"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/CandidateApplied">
                                    <ListItemText
                                      primary="Applied"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/CandidateMessage">
                                    <ListItemText
                                      primary="Message"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/Alert">
                                    <ListItemText
                                      primary="Alert"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                              </List>
                            </Collapse>
                          </List>
                        </Collapse>

                        {/* <ul className="dropdown-menu"> */}

                        {/* <li className="menu-item">
                        <Link to="/CandidateDashboard">Dashboard</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/CandidateProfile">Edit Profile</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/AddResume">Add Resume</Link>
                      </li> */}
                        {/* <li className="menu-item">
                          <Link to="/CandidateResume">Resume</Link>
                        </li> */}
                        {/* <li className="menu-item">
                        <Link to="/CandidateEditResume">Edit Resume</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/CandidateBookmark">Bookmarked</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/CandidateApplied">Applied</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/CandidateMessage">Message</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/Alert">Alert</Link>
                      </li> */}
                        {/* </ul> */}
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="menu-item dropdown">
                        {/* <ListItem
                        onClick={handleClick}
                        style={{ paddingLeft: "8px" }}
                      >
                        <ListItemText
                          primary="Employer Dashboard"
                          className={classes.text}
                        />
                        {openJobs ? <ExpandLess /> : <ExpandMore />}
                      </ListItem> */}
                        {/* <ul className="dropdown-menu"> */}

                        <Collapse
                          in={openDashboard}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" className={classes.links}>
                            <li className="menu-item">
                              <ListItem
                                onClick={() =>
                                  setEmployerDashboard(!empDashboard)
                                }
                                style={{ paddingLeft: "8px" }}
                              >
                                <ListItemText
                                  primary="Employer Dashboard"
                                  className={classes.text}
                                />
                                {empDashboard ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                            </li>

                            <Collapse
                              in={empDashboard}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" className={classes.links}>
                                <li className="menu-item">
                                  <Link to="/EmployerDashboard">
                                    <ListItemText
                                      primary="Employer Dashboard"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/EmployerEditProfile">
                                    <ListItemText
                                      primary="Edit Profile"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/ManageJobs">
                                    <ListItemText
                                      primary="Manage Jobs"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                                <li className="menu-item">
                                  <Link to="/DashboardMessage">
                                    <ListItemText
                                      primary="Dashboard Message"
                                      className={classes.text}
                                    />
                                  </Link>
                                </li>
                              </List>
                            </Collapse>
                          </List>
                        </Collapse>

                        {/* <li className="menu-item">
                        <Link to="/EmployerDashboard">Employer Dashboard</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/EmployerEditProfile">Edit Profile</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/ManageCandidate">Manage Candidate</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/ManageJobs">Manage Job</Link>
                      </li> */}
                        {/* <li className="menu-item">
                        <Link to="/DashboardMessage">Dashboard Message</Link>
                      </li> */}
                        {/* <li className="menu-item">
                          <Link to="/PostJob">Post Job</Link>
                        </li> */}
                        {/* </ul> */}
                      </li>
                    </>
                  )}
                  {/* </ul> */}
                </li>
                <li className="menu-item dropdown">
                  <ListItem
                    onClick={() => setOpenPages(!openPages)}
                    style={{ paddingLeft: "8px" }}
                  >
                    <ListItemText primary="Pages" className={classes.text} />
                    {openPages ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  {/* <ul className="dropdown-menu"> */}
                  <Collapse in={openPages} timeout="auto" unmountOnExit>
                    <List component="div" className={classes.links}>
                      <li className="menu-item">
                        <Link to="/AboutUs">
                          <ListItemText
                            primary="About Us"
                            className={classes.text}
                          />
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/howItWorks">
                          <ListItemText
                            primary="How It Works"
                            className={classes.text}
                          />
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/FAQ">
                          <ListItemText
                            primary="FAQ's"
                            className={classes.text}
                          />
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/TermsAndConditions">
                          <ListItemText
                            primary="Terms & Conditions"
                            className={classes.text}
                          />
                        </Link>
                      </li>
                      {/* <li className="menu-item">
                  <Link to="/JobDetails">Job Details</Link>
                </li> */}

                      {/* <li className="menu-item">
                      <Link to="/AboutUs">About Us</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/howItWorks">How It Works</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/FAQ">FAQ's</Link>
                    </li> */}
                      {/* <li className="menu-item">
                  <Link to="/Checkout">Checkout</Link>
                  </li>
                  <li className="menu-item">
                  <Link to="/Payment">Payment Complete</Link>
                </li> */}
                      {/* <li className="menu-item">
                      <Link to="TermsAndConditions">Terms & Conditions</Link>
                    </li> */}
                    </List>
                  </Collapse>
                  {/* </ul> */}
                </li>
                {/* <li className="menu-item">
                <Link to="/ContactUs">Contact Us</Link>
              </li> */}
                <Link to="/ContactUs">
                  <ListItem style={{ paddingLeft: "8px" }}>
                    <ListItemText
                      primary="Contact Us"
                      className={classes.text}
                    />
                  </ListItem>
                </Link>
                {user.type == "Employer" ? (
                  // <li className="menu-item post-job">
                  //   <Link to="/PostJob">
                  //     <i className="fas fa-plus"></i>Post a Job
                  //   </Link>
                  // </li>

                  <Link to="/PostJob">
                    <ListItem style={{ paddingLeft: "8px" }}>
                      <ListItemIcon style={{ minWidth: "25px" }}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Post Job"
                        className={classes.text}
                      />
                    </ListItem>
                  </Link>
                ) : (
                  // <li className="menu-item post-job">
                  //   <Link to="/JobListing">
                  //     <i className="fas fa-plus"></i>Find a Job
                  //   </Link>
                  // </li>
                  <Link to="/JobListing">
                    <ListItem style={{ paddingLeft: "8px" }}>
                      <ListItemIcon style={{ minWidth: "25px" }}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Find a Job"
                        className={classes.text}
                      />
                    </ListItem>
                  </Link>
                )}

              </ul>
            </Menu>
          </div>
        </nav>

        <nav className="navbar navbar-expand-lg cp-nav-2">
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="menu-item active">
                <Link to="/">Home</Link>
              </li>
              {user.type == "Candidate" ? (
                <li className="menu-item dropdown">
                  <a
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Jobs
                  </a>
                  <ul className="dropdown-menu">
                    {/* <li className="menu-item">
                      <Link to="/JobDetails">Job Details</Link>
                    </li> */}
                    <li className="menu-item">
                      <Link to="/JobListing">Job Listing</Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {user.type == "Employer" ? (
                <li className="menu-item dropdown">
                  <a
                    title=""
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Candidates
                  </a>
                  <ul className="dropdown-menu">
                    <li className="menu-item">
                      <Link to="/CandidateListing">Candidate Listing</Link>
                    </li>
                    {/* <li className="menu-item">
                  <Link to="/CandidateDetails">Candidate Details</Link>
                  </li>
                  <li className="menu-item">
                  <Link to="/AddResume">Add Resume</Link>
                </li> */}
                  </ul>
                </li>
              ) : null}

              {user.type == "Candidate" ? (
                <li className="menu-item dropdown">
                  <a
                    title=""
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Employers
                  </a>
                  <ul className="dropdown-menu">
                    <li className="menu-item">
                      <Link to="/EmployerListing">Employer Listing</Link>
                    </li>
                    {/* <li className="menu-item">
                  <Link to="/EmployerDetails">Employer Details</Link>
                </li>
                <li className="menu-item">
                  <Link to="/EmployerListing">Post a Job</Link>
                </li> */}
                  </ul>
                </li>
              ) : null}

              {user.type == "Candidate" ? (
                <li className="menu-item dropdown">
                  <a
                    title=""
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dashboard
                  </a>
                  <ul className="dropdown-menu">
                    <li className="menu-item dropdown">
                      <Link
                        title=""
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Candidate Dashboard
                      </Link>

                      <ul className="dropdown-menu">
                        <li className="menu-item">
                          <Link to="/CandidateDashboard">Dashboard</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/CandidateProfile">Edit Profile</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/AddResume">Add Resume</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/CandidateResume">Resume</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/CandidateEditResume">Edit Resume</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/CandidateBookmark">Bookmarked</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/CandidateApplied">Applied</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/CandidateMessage">Message</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/Alert">Alert</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="menu-item dropdown">
                  <a
                    title=""
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dashboard
                  </a>
                  <ul className="dropdown-menu">
                    <li className="menu-item dropdown">
                      <Link
                        title=""
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Employer Dashboard
                      </Link>

                      <ul className="dropdown-menu">
                        <li className="menu-item">
                          <Link to="/EmployerDashboard">
                            Employer Dashboard
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/EmployerEditProfile">Edit Profile</Link>
                        </li>
                        {/* <li className="menu-item">
                        <Link to="/ManageCandidate">Manage Candidate</Link>
                      </li> */}
                        <li className="menu-item">
                          <Link to="/ManageJobs">Manage Job</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/DashboardMessage">Dashboard Message</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/PostJob">Post Job</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              )}

              <li className="menu-item dropdown">
                <a
                  title=""
                  href="javascript:void(0);"
                  data-toggle="dropdown"
                  className="dropdown-toggle"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About
                </a>
                <ul className="dropdown-menu">
                  <li className="menu-item">
                    <Link to="/AboutUs">About Us</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/howItWorks">How It Works</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/FAQ">FAQ's</Link>
                  </li>
                  {/* <li className="menu-item">
                  <Link to="/Checkout">Checkout</Link>
                </li>  */}
                  {/* <li className="menu-item">
                    <Link to="/Payment">Payment Complete</Link>
                  </li> */}
                  {/* <li className="menu-item">
                    <Link to="TermsAndConditions">Terms & Conditions</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="Blog">Blog</Link>
                  </li> */}

                  {/* <li className="menu-item">
                  <Link to="/login">Login</Link>
                </li>
                <li className="menu-item">
                  <Link to="/register">Register</Link>
                </li> */}
                </ul>
              </li>
              <li className="menu-item">
                <Link to="/ContactUs">Contact Us</Link>
              </li>

              {user.type == "Employer" ? (
                // <li className="menu-item post-job">
                //   <Link to="/PostJob">
                //     <i className="fas fa-plus"></i>Post a Job
                //   </Link>
                // </li>

                <Link to="/PostJob">
                  <ListItem style={{ paddingLeft: "8px" }}>
                    <ListItemIcon style={{ minWidth: "25px" }}>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Post Job" className={classes.text} />
                  </ListItem>
                </Link>
              ) : (
                // <li className="menu-item post-job">
                //   <Link to="/JobListing">
                //     <i className="fas fa-plus"></i>Find a Job
                //   </Link>
                // </li>
                <Link to="/JobListing">
                  <ListItem style={{ paddingLeft: "8px" }}>
                    <ListItemIcon style={{ minWidth: "25px" }}>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Find a Job"
                      className={classes.text}
                    />
                  </ListItem>
                </Link>
              )}
              <Link onClick={() => setOpenDialog(true)}>
                <ListItem style={{ paddingLeft: "8px" }}>
                  <ListItemText
                    primary="Write a Review"
                    className={classes.text}
                  />
                </ListItem>
              </Link>
            </ul>
          </div>
        </nav>
      </>
    );
  };

  const renderUnAuthNav = () => {
    return (
      <nav className="navbar navbar-expand-lg cp-nav-2">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          style={{ border: "none" }}
          onClick={handleMenuHome}
          aria-label="Toggle navigation"
        >
          {/* <span className="navbar-toggler-icon"></span> */}
          <MoreVertIcon style={{ fontSize: "22px" }} />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Menu
            id="menu-appbar"
            anchorEl={homeAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openHome}
            onClose={handleCloseHome}
          >
            <MenuItem onClick={handleCloseHome} style={{ fontSize: "18px" }}>
              <Link to="/login">Login</Link>
            </MenuItem>
            <MenuItem onClick={handleCloseHome} style={{ fontSize: "18px" }}>
              <Link to="/register">Register</Link>
            </MenuItem>
          </Menu>

          <ul className="navbar-nav">
            <li className="menu-item active">
              <Link to="/">Home</Link>
            </li>
            <li className="menu-item dropdown">
              <a
                href="javascript:void(0);"
                data-toggle="dropdown"
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Jobs
              </a>
              <ul className="dropdown-menu">
                <li className="menu-item">
                  <Link to="/JobListing">Job Listing</Link>
                </li>
              </ul>
            </li>
            {/* <li className="menu-item dropdown">
              <a
                title=""
                href="javascript:void(0);"
                data-toggle="dropdown"
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Candidates
              </a>
              <ul className="dropdown-menu">
                <li className="menu-item">
                  <Link to="/CandidateListing">Candidate Listing</Link>
                </li>
        
              </ul>
            </li> */}

            {/* <li className="menu-item dropdown">
              <a
                title=""
                href="javascript:void(0);"
                data-toggle="dropdown"
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Employers
              </a>
              <ul className="dropdown-menu">
                <li className="menu-item">
                  <Link to="/EmployerListing">Employer Listing</Link>
                </li>
              
              </ul>
            </li> */}

            {/* <li className="menu-item dropdown">
              <a
                title=""
                href="javascript:void(0);"
                data-toggle="dropdown"
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dashboard
              </a>
              <ul className="dropdown-menu">
                <li className="menu-item dropdown">
                  <Link
                    to="/EmployerListing"
                    title=""
                    href="javascript:void(0);"
                    data-toggle="dropdown"
                    className="dropdown-toggle"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Candidate Dashboard
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/EmployerListing">Employer Dashboard</Link>
                </li>
              </ul>
            </li> */}

            <li className="menu-item dropdown">
              <a
                title=""
                href="javascript:void(0);"
                data-toggle="dropdown"
                className="dropdown-toggle"
                aria-haspopup="true"
                aria-expanded="false"
              >
                About
              </a>
              <ul className="dropdown-menu">
                <li className="menu-item">
                  <Link to="/AboutUs">About Us</Link>
                </li>
                <li className="menu-item">
                  <Link to="/howItWorks">How It Works</Link>
                </li>
                <li className="menu-item">
                  <Link to="/FAQ">FAQ's</Link>
                </li>
                {/* <li className="menu-item">
                  <Link to="/Checkout">Checkout</Link>
                </li> */}
                {/* <li className="menu-item">
                  <Link to="/Payment">Payment Complete</Link>
                </li> */}
                {/* <li className="menu-item">
                  <Link to="TermsAndConditions">Terms & Conditions</Link>
                </li> */}

                {/* <li className="menu-item">
                  <Link to="/login">Login</Link>
                </li>
                <li className="menu-item">
                  <Link to="/register">Register</Link>
                </li> */}
              </ul>
            </li>
            <li className="menu-item">
              <Link to="/ContactUs">Contact Us</Link>
            </li>
          </ul>
          <Link to="/login">
            <Button
              // onClick={handleLogOut}
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#023b92",
                color: "white",
                fontSize: "15px",
                padding: 10,
                width: "150px",
                height: "50px",
                textTransform: "none",
                marginRight: 20,
              }}
            >
              Login
            </Button>
          </Link>
          <Link to="/register">
            <Button
              // onClick={handleLogOut}
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#023b92",
                color: "white",
                fontSize: "15px",
                padding: 10,
                width: "150px",
                height: "50px",
                textTransform: "none",
              }}
            >
              Register
            </Button>
          </Link>
        </div>
      </nav>
    );
  };

  // return isUser.isAuthenticated ? renderAuthNav() : renderUnAuthNav();
  return isUser.isAuthenticated ? renderNewAuthNav() : renderUnAuthNav();
};

export default Header;
