import React, { useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { useHistory } from "react-router-dom";
import {
  Button,
  Snackbar,
  Grid,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import firebase from "firebase"
import Alert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios"
import LoaderComponent from "../../components/Loader";

const useStyles = makeStyles((theme) => ({
  checkoutBtn: {
    background: "#023b92 !important",
    "&:hover": {
      background: "#246df8 !important",
    },
  },
  nameStyle: {
    fontSize: "22px",
    fontWeight: "bold",
    margin: "5px 0",
  },

  cardContainer: {
    background: "#fff",
    padding: "20px",
    borderRadius: "1rem",
    transition:
      ".3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12)",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
    "&:hover": {
      transform: "scale(1.05)",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    height: "600px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  priceStyle: { fontSize: "17px", fontWeight: 500 },
  durationStyle: {
    fontSize: "16px",
    fontWeight: 400,
  },
  listItemStyle: {
    paddingLeft: 0,
    "&.MuiListItem-root .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },
    "&.MuiListItem-root .MuiListItemText-root .MuiListItemText-primary": {
      fontSize: "14px !important",
    },
  },
  selectBtn: {
    background: "#246df8",
    color: "#fff",
    textTransform: "none",
    fontSize: "13px",
    "&:hover": {
      background: "#246df8",
    },
  },
}));

const packagesData = [
  {
    id: 1,
    packageName: "Bronze",
    price: "25",
    duration: "3 months",
    btnColor: "green",
    description: [
      {
        id: 1,
        name: "30 secs of Video Profile",
      },
      {
        id: 2,
        name: "3 months of FREE trial for job look-ups",
      },
      {
        id: 3,
        name: "Notifications for applied position(s)",
      },
      {
        id: 4,
        name: "Information on Short-listings with the searched Industry or area",
      },
    ],
  },
  {
    id: 2,
    packageName: "Silver",
    price: "50",
    duration: "6 months",
    btnColor: "green",
    description: [
      {
        id: 1,
        name: "6 months of FREE trial for job look-ups",
      },
      {
        id: 2,
        name: "30 secs of Video Profile",
      },
      {
        id: 3,
        name: "Information on Short-listings with the searched Industry or area",
      },
      {
        id: 4,
        name: "Get personalised emails directly to your inbox from Employ with recent openings and much more",
      },
      {
        id: 5,
        name: "Direct Interaction with live chat feature communicating with HR Companies, Job Postings and other Enquiries",
      },
    ],
  },
  {
    id: 3,
    packageName: "Gold",
    price: "100",
    btnColor: "green",
    duration: "1 year save $20 off",
    description: [
      {
        id: 1,
        name: "30 secs of Video Profile",
      },
      {
        id: 2,
        name: "1 year of FREE trial for job look-ups, with Premium Candidate badge",
      },
      {
        id: 3,
        name: "Information on Short-listings with the searched Industry or area",
      },
      {
        id: 4,
        name: "Get personalised emails directly to your inbox from Employ with recent openings and much more",
      },
      {
        id: 5,
        name: "Direct Interaction with live chat feature communicating with HR Companies, Job Postings and other Enquiries",
      },
      {
        id: 6,
        name: "Personalised Account Manager Facility for forwarding to interested HR Individuals, HR Companies and other organisations",
      },
    ],
  },
];

const stripe = loadStripe("pk_test_51JjwKGJupJFOjlfOYQHUxOpxw8hJTUU3Z3v6cczKb1k3Bn7jg71LO0D7vHUO9kgfMtYxEqhLUsMJ2lsGZSXcGKnn00XATnoPFu")

const Component = ({ plan, activePrice }) => {
  console.log(plan, "PLANNNNNNNNn");
  const stripe = useStripe();
  const history = useHistory();
  const elements = useElements();
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const handleProceedPayment = async () => {
    if (plan != null) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      setLoading(true)
      // use stripe.createToken to get a unique token for the card
      const { error, token } = await stripe.createToken(cardElement);

      if (!error) {
        // Backend is not implemented yet, but once there isn’t any errors,
        // you can pass the token and payment data to the backend to complete
        // the charge
        axios
          .post("https://employfind.herokuapp.com/api/payment", {
            token: token.id,
            currency: "USD",
            price: activePrice * 100, // or 10 pounds (10*100). Stripe charges with the smallest price unit allowed
          })
          .then((resp) => {
            firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).update({
              package: plan
            })
            history.push({ pathname: "/Payment", state: plan })
            setLoading(false)
          })
          .catch((err) => {
            alert("Something went wrong")
            setLoading(false)
          });
      }

      else {
        console.log(error);
        setLoading(false)
        alert(error.code)
      }
    }
    else {
      alert("Please Select plan first")
    }
    // stripe.crea
    // alert("called")
    // setAlertData({
    //   open: true,
    //   severity: "success",
    //   msg: "Payment Done Successfully",
    // });

    // setTimeout(() => {
    //   history.push("/");
    // }, 3000);
  };

  return (
    <>
      <LoaderComponent isloading={loading} />
      <CardElement />
      <button
        style={{ marginTop: 20 }}
        onClick={handleProceedPayment}
        class={`${classes.checkoutBtn} button`}
      >
        Proceed Payment
      </button>
    </>
  )
}


const Checkout = (props) => {
  const { state } = props.history.location
  const classes = useStyles();
  // const stripe = useStripe()
  const history = useHistory();
  const [pricePlan, setPricePlan] = useState(null);
  const [toggleActive, setToggleActive] = useState("credit");
  const [isShownHeader, setIsShownHeader] = useState(false);
  const [isShownSkip, setIsShownSkip] = useState(true);
  const [activeBtn, setActiveBtn] = useState("");
  const [activePrice, setActivePrice] = useState("");
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    msg: "",
  });

  // const handleChangePrice = (e) => {
  //   setPricePlan(e.target.value);
  // };
  const handleSelectPckg = (id, pckg, price) => {
    setActiveBtn(id);
    setPricePlan(pckg);
    setActivePrice(price);
  };



  const handleSkip = () => {
    setAlertData({
      open: true,
      severity: "success",
      msg: "Your basic package has been initialized",
    });

    setTimeout(() => {
      history.push("/");
    }, 3000);
  };

  const handleClose = () => {
    setAlertData({
      open: false,
      severity: "",
      msg: "",
    });
  };

  return (
    <>
      {/* <NotificationAndAccount  />
            <Header /> */}
      <Snackbar
        open={alertData.open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.severity}>
          <span style={{ fontSize: 15 }}>{alertData.msg}</span>
        </Alert>
      </Snackbar>

      <header className="header-2">
        <div className="container">
          <div className="row">
            <div className="col">
              {/* <NotificationAndAccount /> */}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "20px 0",
                }}
              >
                <a href="#">
                  <img className="mainlogo" src={logoMain} />
                </a>
                {state != "skip" ?
                  <Button
                    onClick={handleSkip}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#023b92",
                      color: "white",
                      fontSize: "15px",
                      padding: 10,
                      width: "150px",
                      height: "50px",
                      textTransform: "none",
                      marginRight: 20,
                    }}
                  >
                    Skip
                  </Button>
                  :
                  null
                }
              </div>
              {/* <Header isShownHeader={isShownHeader} /> */}
            </div>
          </div>
        </div>
      </header>

      {/* <!-- Breadcrumb --> */}
      {/* <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>Checkout Page</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Checkout Page
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <i>
                      <Search />
                    </i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="alice-bg">
        <div class="container-fluid">
          <div class="row">
            <div class="col" style={{ padding: 0 }}>
              <div class="block-wrapper">
                <div style={{ textAlign: "center", marginBottom: "40px" }}>
                  <h3>
                    Please Select From <br />
                    Following Packages
                  </h3>
                </div>
                <div
                  style={{
                    background: "#f7f9fc",
                    margin: "30px 0",
                    padding: "50px 0",
                  }}
                >
                  <Container>
                    <Grid container spacing={3}>
                      {packagesData.map((pckg, i) => {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.cardContainer}>
                              <div>
                                <Typography
                                  variant="h4"
                                  className={classes.nameStyle}
                                >
                                  {pckg.packageName}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  className={classes.priceStyle}
                                >
                                  Price: ${pckg.price}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  className={classes.durationStyle}
                                >
                                  Duration: {pckg.duration}
                                </Typography>
                                <List>
                                  {pckg.description.map((item) => {
                                    return (
                                      <ListItem
                                        className={classes.listItemStyle}
                                      >
                                        <ListItemIcon>
                                          <CheckIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                      </ListItem>
                                    );
                                  })}
                                </List>
                              </div>

                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  size="large"
                                  className={classes.selectBtn}
                                  onClick={() =>
                                    handleSelectPckg(
                                      pckg.id,
                                      pckg.packageName,
                                      pckg.price
                                    )
                                  }
                                  style={{
                                    background:
                                      activeBtn == pckg.id
                                        ? pckg.btnColor
                                        : "#246df8",
                                  }}
                                >
                                  Select
                                </Button>
                              </div>
                            </div>

                            {/* <ul>
                                return <li>{item.name}</li>;
                            </ul> */}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Container>
                </div>

                <div class="row">
                  <div class="col-lg-8">
                    <div class="checkout-form">
                      <div class="row">
                        {/* <label class="col-md-3 col-form-label">
                            Select Package
                          </label> */}
                        <div class="col-md-9">
                          <div class="row">
                            {/* <div class="col">
                                <div class="package-select">
                                  <div class="package-select-inputs">
                                    <div class="form-group">
                                      <input
                                        class="custom-radio"
                                        type="radio"
                                        id="radio_1"
                                        name="free"
                                        onChange={handleChangePrice}
                                        value="free"
                                        checked={pricePlan === "free"}
                                      />
                                      <label for="radio_1">
                                        <span class="dot"></span>{" "}
                                        <span class="package-type">Free</span>{" "}
                                        RS:0.00
                                      </label>
                                    </div>
                                    <div class="form-group">
                                      <input
                                        class="custom-radio"
                                        type="radio"
                                        id="radio_2"
                                        name="starter"
                                        onChange={handleChangePrice}
                                        value="starter"
                                        checked={pricePlan === "starter"}
                                      />
                                      <label for="radio_2">
                                        <span class="dot"></span>{" "}
                                        <span class="package-type">
                                          Starter
                                        </span>{" "}
                                        RS:22.00
                                      </label>
                                    </div>
                                    <div class="form-group">
                                      <input
                                        class="custom-radio"
                                        type="radio"
                                        id="radio_3"
                                        name="advance"
                                        onChange={handleChangePrice}
                                        value="advance"
                                        checked={pricePlan === "advance"}
                                      />
                                      <label for="radio_3">
                                        <span class="dot"></span>{" "}
                                        <span class="package-type">
                                          Advance
                                        </span>{" "}
                                        RS:44.00
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            {/* <div class="w-100"></div> */}
                            <div class="col"></div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-9 offset-md-3">
                          <div class="payment-method">
                            <a
                              class={`${toggleActive === "credit"
                                ? classes.checkoutBtn
                                : null
                                } credit active`}
                              onClick={() => setToggleActive("credit")}
                              style={{ cursor: "pointer" }}
                            >
                              <i class="fas fa-credit-card"></i>Credit Card
                            </a>
                            {/* <a
                                class={`${
                                  toggleActive === "paypal"
                                    ? classes.checkoutBtn
                                    : null
                                } paypal active`}
                                style={{ cursor: "pointer" }}
                                onClick={() => setToggleActive("paypal")}
                              >
                                <i class="fab fa-cc-paypal"></i>PayPal
                              </a> */}
                          </div>
                          <Elements stripe={stripe}>
                            <Component activePrice={activePrice} plan={pricePlan} />
                          </Elements>

                          {/* <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cardholder Name"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Cardholder Number"
                                  />
                                </div>
                              </div>
                              <div class="w-100"></div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Expired Month"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Expired Year"
                                  />
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="CVV"
                                  />
                                </div>
                              </div> */}
                          {/* <div class="w-100"></div> */}
                          {/* <div class="col">
                                <div class="form-group terms">
                                  <input
                                    class="custom-radio"
                                    type="checkbox"
                                    id="radio-4"
                                    name="termsandcondition"
                                  />
                                  <label for="radio-4">
                                    <span class="dot"></span> You accepts our{" "}
                                    <a href="#">Terms and Conditions</a> and{" "}
                                    <a href="#">Privacy Policy</a>
                                  </label>
                                </div>
                                <button
                                  onClick={handleProceedPayment}
                                  class={`${classes.checkoutBtn} button`}
                                >
                                  Proceed Payment
                                </button>
                              </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="payment-summery">
                      <h4>Summary</h4>
                      <ul>
                        <li class="payment-list">
                          <span class="title">Starter Plan</span>{" "}
                          <span class="amount">
                            $:{" "}
                            {pricePlan === "Free"
                              ? "0"
                              : pricePlan === "Bronze"
                                ? "25"
                                : pricePlan === "Silver"
                                  ? "50"
                                  : pricePlan === "Gold"
                                    ? "100"
                                    : ""}
                          </span>
                        </li>
                        {/* <li class="payment-list">
                          <span class="title">VAT (15%)</span>{" "}
                          <span class="amount">$:5.00</span>
                        </li> */}
                      </ul>
                      <div class="total-amount">
                        <span class="title">Final Price</span>
                        <span class="amount">
                          $:{" "}
                          {pricePlan === "free"
                            ? Number("0.00")
                            : pricePlan === "Bronze"
                              ? Number("25")
                              : pricePlan === "Silver"
                                ? Number("50")
                                : pricePlan === "Gold"
                                  ? Number("100")
                                  : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <FindYourJob />

      <SocialShare />
      <NewsLetterFooter />
      <Footer /> */}
    </>
  );
};

export default Checkout;
