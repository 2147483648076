import React, { useEffect, useState } from "react";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import SearchIcon from "@material-ui/icons/Search";
import firebase from "firebase";
import fire from "../../database";
import { Link } from "react-router-dom";
import LogoutAndDelete from "../../components/logoutAndDelete";
// import EmployeeDashboard from "./../../containers/EmployerDashboard/index";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hide: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const Dashboard = ({ name, DashboardSidebar }) => {
  const classes = useStyles();
  const [ArrayData, setArrayData] = useState([]);
  const [appliedJobs, setappliedJobs] = useState([]);
  const [viewJobs, setviewJobs] = useState([]);
  const [jobPosted, setjobPosted] = useState([]);
  const [profile, setProfile] = useState({});
  const [user, setUser] = useState(null);
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("usertype"));
    setUser(userData);
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`bookmark/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        console.log(
          data,
          "dDDADASDASDASDASDASDASASDASD",
          firebase.auth()?.currentUser?.uid
        );
        let HistoryItems = { ...data };
        setArrayData(HistoryItems);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`profile/candidate/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        console.log(HistoryItems, "asdasdasdaxzcxzcijsdiahsd");
        setProfile(HistoryItems);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`applied/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setappliedJobs(HistoryItems);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`jobs/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setjobPosted(HistoryItems);
      });
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref(`views/${firebase.auth()?.currentUser?.uid}`)
      .on("value", (snapshot) => {
        let data = snapshot.val() ? snapshot.val() : {};
        let HistoryItems = { ...data };
        setviewJobs(HistoryItems);
      });
  }, []);

  const Favorite = Object.keys(ArrayData);
  const Applied = Object.keys(appliedJobs);
  const views = Object.keys(viewJobs);
  const jobPost = Object.keys(jobPosted);

  console.log(profile, "profileprofileprofile");

  return (
    <>
      <div class="alice-bg padding-top-70 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="breadcrumb-area">
                <h1>{name}</h1>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <div class="col-md-6">
              <div class="breadcrumb-form">
                <form action="#">
                  <input type="text" placeholder="Enter Keywords" />
                  <button>
                    <SearchIcon style={{ fontSize: "25px" }} />
                  </button>
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="alice-bg section-padding-bottom">
        <div className="container no-gliters">
          <div className="row no-gliters">
            <div className="col">
              <div className="dashboard-container">
                <div className="dashboard-content-wrapper">
                  {user?.type == "Candidate" ? (
                    <div className="dashboard-section user-statistic-block">
                      <div className="user-statistic">
                        <QueryBuilderIcon />
                        <h3>{views.length}</h3>
                        <span>Companies Viewed</span>
                      </div>
                      <div className="user-statistic">
                        <BusinessCenterIcon />
                        <h3>{Applied.length}</h3>
                        <span>Applied Jobs</span>
                      </div>
                      <div className="user-statistic">
                        <FavoriteBorderIcon />
                        <h3>{Favorite.length}</h3>
                        <span>Favourite Jobs</span>
                      </div>
                    </div>
                  ) : (
                    <div className="dashboard-section user-statistic-block">
                      <div className="user-statistic">
                        <QueryBuilderIcon />
                        <h3>{jobPost.length}</h3>
                        <span>Total Job Posted</span>
                      </div>
                      <div className="user-statistic">
                        <BusinessCenterIcon />
                        <h3>123</h3>
                        <span>Application Submit</span>
                      </div>
                      <div className="user-statistic">
                        <FavoriteBorderIcon />
                        <h3>32</h3>
                        <span>Call for interview</span>
                      </div>
                    </div>
                  )}
                  <div className="dashboard-section dashboard-view-chart">
                    <canvas id="view-chart" width="400" height="200"></canvas>
                  </div>
                  {/* <div className="dashboard-section dashboard-recent-activity">
                    <h4 className="title">Recent Activity</h4>
                    <div className="activity-list">
                      <i className="fas fa-bolt"></i>
                      <div className="content">
                        <h5>Lorem ipsum dolor sit amet</h5>
                        <span className="time">Lorem</span>
                      </div>
                      <div className="close-activity">
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                    <div className="activity-list">
                      <i className="fas fa-arrow-circle-down"></i>
                      <div className="content">
                        <h5>Lorem ipsum dolor sit amet</h5>
                        <span className="time">Lorem</span>
                      </div>
                      <div className="close-activity">
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                    <div className="activity-list">
                      <i className="fas fa-check-square"></i>
                      <div className="content">
                        <h5>Lorem ipsum dolor sit amet</h5>
                        <span className="time">Lorem</span>
                      </div>
                      <div className="close-activity">
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                    <div className="activity-list">
                      <i className="fas fa-check-square"></i>
                      <div className="content">
                        <h5>Lorem ipsum dolor sit amet</h5>
                        <span className="time">Lorem</span>
                      </div>
                      <div className="close-activity">
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                    <div className="activity-list">
                      <i className="fas fa-user"></i>
                      <div className="content">
                        <h5>Lorem ipsum dolor sit amet</h5>
                        <span className="time">Lorem</span>
                      </div>
                      <div className="close-activity">
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                    <div className="activity-list">
                      <i className="fas fa-heart"></i>
                      <div className="content">
                        <h5>Lorem ipsum dolor sit amet</h5>
                        <span className="time">Lorem</span>
                      </div>
                      <div className="close-activity">
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className={`${classes.hide} dashboard-sidebar`}>
                  <div className="user-info">
                    <div className="thumb">
                      <img src={profile?.avatar} className="img-fluid" alt="" />
                    </div>
                    <div className="user-body">
                      <h5>{user?.name}</h5>
                      <span>@username</span>
                    </div>
                  </div>
                  <div className="profile-progress">
                    <div className="progress-item">
                      <div className="progress-head">
                        <p className="progress-on">Profile</p>
                      </div>
                      {/* <div className="progress-body">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: 0 }}
                          ></div>
                        </div>
                        <p className="progress-to">70%</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="dashboard-menu">
                    {DashboardSidebar}
                    {/* {LogoutAndDelete} */}
                    <LogoutAndDelete />
                    {/* <ul>
                      <li className="active">
                        <i className="fas fa-home"></i>
                        <Link to="/CandidateDashboard">Dashboard</Link>
                      </li>
                      <li>
                        <i className="fas fa-user"></i>
                        <Link to="/CandidateProfile">Edit Profile</Link>
                      </li>
                      <li>
                        <i className="fas fa-file-alt"></i>
                        <Link to="/CandidateResume">Resume</Link>
                      </li>
                      <li>
                        <i className="fas fa-edit"></i>
                        <a href="edit-resume.html">Edit Resume</a>
                      </li>
                      <li>
                        <i className="fas fa-heart"></i>
                        <a href="dashboard-bookmark.html">Bookmarked</a>
                      </li>
                      <li>
                        <i className="fas fa-check-square"></i>
                        <a href="dashboard-applied.html">Applied Job</a>
                      </li>
                      <li>
                        <i className="fas fa-comment"></i>
                        <a href="dashboard-message.html">Message</a>
                      </li>
                      <li>
                        <i className="fas fa-calculator"></i>
                        <a href="dashboard-pricing.html">Pricing Plans</a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
