import React, { useState } from "react";
import logoMain from "../../assests/images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg";
import { User } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase";
import fire from "../../database";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Login = (props) => {
    const history = useHistory();
    const [alertData, setAlertData] = useState({
        open: false,
        severity: "",
        msg: "",
    });
    const [email, setEmail] = useState("");

    //     const handleFacebookSignIn = () => {
    //         var provider = new firebase.auth.FacebookAuthProvider();
    //         firebase
    //   .auth()
    //   .signInWithPopup(provider)
    //   .then((result) => {
    //     console.log('facebook login', result)
    //     var credential = result.credential;

    //     // The signed-in user info.
    //     var user = result.user;

    //     // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    //     var accessToken = credential.accessToken;

    //     // ...
    //   })
    //   .catch((error) => {
    //     // Handle Errors here.
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     // The email of the user's account used.
    //     var email = error.email;
    //     // The firebase.auth.AuthCredential type that was used.
    //     var credential = error.credential;

    //     // ...
    //   });
    //     }

    //     const handleGoogleSignIn = () => {
    //         var provider = new firebase.auth.GoogleAuthProvider();
    //         provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    //         firebase.auth()
    //   .signInWithPopup(provider)
    //   .then((result) => {
    //     console.log('result', result)
    //     var credential = result.credential;

    //     // This gives you a Google Access Token. You can use it to access the Google API.
    //     var token = credential.accessToken;
    //     console.log('token', token)
    //     // The signed-in user info.
    //     var user = result.user;
    //     // ...
    //   }).catch((error) => {
    //     // Handle Errors here.
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     // The email of the user's account used.
    //     var email = error.email;
    //     // The firebase.auth.AuthCredential type that was used.
    //     var credential = error.credential;
    //     // ...
    //   });
    //     }


    const handleClose = () => {
        setAlertData({
            open: false,
            severity: "",
            msg: "",
        });
    };

    const handleForgotPassword = () => {
        firebase.auth().sendPasswordResetEmail(email)
            .then(res => {
                setAlertData({
                    open: true,
                    severity: "success",
                    msg: "Reset email Has been sent to your email Address",
                })
                setTimeout(() => {
                    props.history.push("/")
                }, 2000);
            })
            .catch(err => {
                setAlertData({
                    open: true,
                    severity: "error",
                    msg: err.message,
                })
            })
    }

    return (
        <>
            <Snackbar
                open={alertData.open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={alertData.severity}>
                    <span style={{ fontSize: 15 }}>{alertData.msg}</span>
                </Alert>
            </Snackbar>
            <header class="header-2 access-page-nav">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="header-top">
                                <div class="logo-area">
                                    <a href="index.html">
                                        <img
                                            src={logoMain}
                                            className="img-fluid"
                                            style={{ height: "68px" }}
                                            alt=""
                                        />
                                    </a>
                                </div>
                                {/* <div class="top-nav">
                  <Link
                    to="/register"
                    class="account-page-link"
                    style={{ background: "#023b92" }}
                  >
                    Register
                  </Link>
                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div class="padding-top-90 padding-bottom-90 access-page-bg">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-md-6">
                            <div class="access-form">
                                <div class="form-header">
                                    <h5>
                                        <User />
                                        Forgot Password
                                    </h5>
                                </div>
                                {/* <form action="#"> */}
                                <div class="form-group">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        class="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>


                                <button
                                    onClick={handleForgotPassword}
                                    class="button primary-bg btn-block"
                                    style={{ background: "#023b92" }}
                                >
                                    Login
                                </button>
                                {/* </form> */}
                                <div class="shortcut-login">
                                    {/* <span>Or connect with</span>
                                    <div class="buttons">
                                        <a onClick={handleFacebookSignIn} class="facebook"><i class="fab fa-facebook-f"></i>Facebook</a>
                                        <a onClick={handleGoogleSignIn} class="google"><i class="fab fa-google"></i>Google</a>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
