import firebase from 'firebase'

export const senderMsg = async (msgValue, currentUserId, guestUserId) => {
    try {
        return await firebase.database().ref('messages/' + currentUserId).child(guestUserId).push({
            message: {
                sender: currentUserId,
                receiver: guestUserId,
                msg: msgValue,
                time: Date().toLocaleString(),
                type: 1,

                // img: img
            }
        }).then(res => {
            // alert("RESSS")
            // alert(token)
            // sendNotification(token,msgValue,currentUserName)
        })

    } catch (error) {
        return error
    }
}

export const senderImageMsg = async (msgValue, currentUserId, guestUserId) => {
    try {
        return await firebase.database().ref('messages/' + currentUserId).child(guestUserId).push({
            message: {
                sender: currentUserId,
                receiver: guestUserId,
                msg: msgValue,
                time: Date().toLocaleString(),
                type: 2
                // img: img
            }
        }).then(res => {
            // alert("RESSS")
            // alert(token)
            // sendNotification(token,msgValue,currentUserName)
        })

    } catch (error) {
        return error
    }
}


export const ReceivedMsg = async (msgValue, currentUserId, guestUserId) => {
    try {
        return await firebase.database().ref('messages/' + guestUserId).child(currentUserId).push({
            message: {
                sender: currentUserId,
                receiver: guestUserId,
                msg: msgValue,
                time: Date().toLocaleString(),
                type: 1
                // img: img
            }
        }).then(res => {
            // alert("receve")
        })

    } catch (error) {
        return error
    }
}

export const ReceivedImageMsg = async (msgValue, currentUserId, guestUserId) => {
    try {
        return await firebase.database().ref('messages/' + guestUserId).child(currentUserId).push({
            message: {
                sender: currentUserId,
                receiver: guestUserId,
                msg: msgValue,
                time: Date().toLocaleString(),
                type: 2
                // img: img
            }
        }).then(res => {
            // alert("receve")
        })

    } catch (error) {
        return error
    }
}