import React, { useState, useEffect } from "react";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer class="footer-bg">
      {/* <div class="footer-top border-bottom section-padding-top padding-bottom-40">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="footer-logo">
                <a href="#">
                  <img
                    src="images/bbecde0d-45fc-478e-8dfb-160544fa420ee.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="footer-social">
                <ul class="social-icons">
                  <li>
                    <a href="#">
                      <i data-feather="facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i data-feather="twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i data-feather="linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i data-feather="instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i data-feather="youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-widget-wrapper padding-bottom-60 padding-top-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-sm-6">
              <div class="footer-widget footer-shortcut-link">
                <h4>Information</h4>
                <div class="widget-inner">
                  <ul>
                    <li>
                      <a href="about-us.html">About Us</a>
                    </li>
                    <li>
                      <a href="contact.html">Contact Us</a>
                    </li>
                    <li>
                      <a href="terms-and-condition.html">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="terms-and-condition.html">
                        Terms &amp; Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-sm-6">
              <div class="footer-widget footer-shortcut-link">
                <h4>Job Seekers</h4>
                <div class="widget-inner">
                  <ul>
                    <li>
                      <a href="register.html">Create Account</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Career Counseling</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">My Oficiona</a>
                    </li>
                    <li>
                      <a href="faq.html">FAQ</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Video Guides</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-sm-6">
              <div class="footer-widget footer-shortcut-link">
                <h4>Employers</h4>
                <div class="widget-inner">
                  <ul>
                    <li>
                      <a href="register.html">Create Account</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Products/Service</a>
                    </li>
                    <li>
                      <a href="employer-dashboard-post-job.html">Post a Job</a>
                    </li>
                    <li>
                      <a href="faq.html">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-5 offset-lg-1 col-sm-6">
              <div class="footer-widget footer-newsletter">
                <h4>Newsletter</h4>
                <p>
                  Quis lectus nulla at volutpat diam ut. Congue quisque egestas
                  diam in arcu.
                </p>
                <form action="#" class="newsletter-form form-inline">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email address..."
                    />
                  </div>
                  <button class="btn button primary-bg">
                    Submit<i class="fas fa-caret-right"></i>
                  </button>
                  <p class="newsletter-error">0 - Please enter a value</p>
                  <p class="newsletter-success">Thank you for subscribing!</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="footer-bottom-area">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="footer-bottom border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <p class="copyright-text">
                      By <a href="javascript:void(0);">Employee</a> 2021, All
                      right reserved
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <div class="back-to-top">
                      <a href="javascript:void(0);" onClick={scrollToTop}>
                        Back to top<i class="fas fa-angle-up"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
